import type { HomePageMainNewsDTO, BaseRecordDTO } from '@fontanka/news-api-client';

import type { MainNews as MainNewsAlias } from '../domain';
import { MAIN_NEWS_IDS_MAP } from '../domain';

type MainNews = MainNewsAlias;

export class MainNewsMapper {
  public static toDO(mainNewsDTO: HomePageMainNewsDTO['data']): MainNews {
    return Object.entries(mainNewsDTO)
      .map(
        pair =>
          ({
            id: pair[0],
            text: MAIN_NEWS_IDS_MAP[pair[0]].text,
            position: MAIN_NEWS_IDS_MAP[pair[0]].position,
            data: (pair[1] as BaseRecordDTO[]) ?? []
          } as MainNews[0])
      )
      .sort((a, b) => a.position - b.position);
  }
}
