import type { BasePageDTO, CommonConfigDTO } from '@fontanka/news-api-client';

import type { PageCommon } from '../domain';

import { FeedbackMapper } from './feedback-mapper.service';
import { ThemesMapper, type ThemesSpecData } from './themes-mapper.service';

export class NewCommonMapper {
  public static toDO(input: {
    config: CommonConfigDTO;
    data: BasePageDTO['data'];
    isMobile: boolean;
  }): PageCommon {
    const {
      config: { themes },
      data: blockSpecData,
      isMobile
    } = input;

    return {
      feedback: FeedbackMapper.toDo(isMobile),
      themes: themes
        ? ThemesMapper.toDo({
            blockConfig: themes,
            blockSpecData: blockSpecData as ThemesSpecData
          })
        : null
    };
  }
}
