import {
  DATA_IMAGE_TYPE_TO_NAME_MAP,
  ImageAttachDTO
} from '@fontanka/news-api-client';

import { ImageAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createImageAttach = (attach: ImageAttachDTO): ImageAttach => {
  const typeName = attach.media['image-type']
    ? DATA_IMAGE_TYPE_TO_NAME_MAP[attach.media['image-type']]
    : DATA_IMAGE_TYPE_TO_NAME_MAP.photo;
  const isInfographics = attach.media.class === 'center-vertical';
  const author = isInfographics
    ? attach.value.author
    : attach.value.author
    ? `${typeName}: ${attach.value.author}`
    : '';
  const isMainPhoto = attach.media['main-photo'] === '1';

  const image = createImage(attach.value);

  return {
    type: 'images',
    value:
      image !== null
        ? {
            ...image,
            isInfographics,
            author,
            isMainPhoto
          }
        : image
  };
};
