import type { CurrencyPageCurrencyDTO } from '@fontanka/news-api-client';

import type { CurrencyInfo as CurrencyInfoAlias } from '../domain';

type CurrencyInfo = CurrencyInfoAlias;

export class CurrencyMapper {
  public static toDO(currencyDTO: CurrencyPageCurrencyDTO['data']): CurrencyInfo {
    const INITIAL = {
      base: [] as CurrencyPageCurrencyDTO['data'],
      rest: [] as CurrencyPageCurrencyDTO['data']
    };

    return currencyDTO.reduce((acc, current) => {
      if (current.name === 'usd' || current.name === 'eur') {
        acc.base.push(current);
      } else {
        acc.rest.push(current);
      }

      return acc;
    }, INITIAL);
  }
}
