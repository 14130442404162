import type {
  AdvertConfigDTO,
  BasePageDTO,
  AdvBlockInfo
} from '@fontanka/news-api-client';

import type { AdvData } from '../domain';

import { AdvMapper } from './adv-mapper.service';

export class AdvDataMapper {
  public static toDO(
    config: AdvertConfigDTO,
    specData: BasePageDTO['data']
  ): AdvData {
    return Object.entries(config).reduce((acc, current) => {
      const key = current[0];

      if (current[1]) {
        const advConfig = current[1];
        const advInfo = specData[advConfig.path] as AdvBlockInfo;
        acc[key] = AdvMapper.toDO(advConfig, advInfo);
      }

      return acc;
    }, {});
  }
}
