import { IframeAttachDTO } from '@fontanka/news-api-client';
import { IframeAttach } from '../../../domain';

export const createIframeAttach = (attach: IframeAttachDTO): IframeAttach => {
  const entities = [['&amp;', '&']];
  // нужно заменить html symbols
  const src = entities.reduce(
    (acc, current) => (acc = acc.replace(new RegExp(current[0], 'g'), current[1])),
    attach.media.src
  );

  return {
    type: 'iframe',
    value: {
      description: attach.media.content ? attach.media.content : '',
      author: attach.media.author ? attach.media.author : '',
      src,
      height: attach.media.height,
      width: attach.media.width
    }
  };
};
