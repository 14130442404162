import { guardUnspecified } from '@portal/utils/util-guards';

import type { NewsDTO, NewsFeedBlockConfig } from '@fontanka/news-api-client';

import type { BaseRecordDTO } from '../../services';
import { BaseRecordMapper } from '../../services';
import type { NewsFeed } from '../domain';

const newsFeedABCPresent = (
  r: BaseRecordDTO,
  key: string | undefined
): BaseRecordDTO => {
  if (guardUnspecified(key)) {
    return {
      ...r,
      publishDateForWindow: r[key] as string
    };
  }
  return r;
};

export class NewsFeedMapper {
  public static toDO(
    newsDataDTO: NewsDTO['data'],
    newsConfig: NewsFeedBlockConfig
  ): NewsFeed {
    return {
      data: {
        all:
          newsDataDTO.all
            ?.map(r =>
              newsFeedABCPresent(r, newsConfig.params?.publish_date_for_window)
            )
            .map(r => BaseRecordMapper.toDO(r)) ?? [],
        spb:
          newsDataDTO.spb
            ?.map(r =>
              newsFeedABCPresent(r, newsConfig.params?.publish_date_for_window)
            )
            .map(r => BaseRecordMapper.toDO(r)) ?? []
      },
      settings: newsConfig.settings ?? {}
    };
  }
}
