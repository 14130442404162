export const pad = (num: number, size: number) => {
  let s = num + '';
  while (s.length < size) s = '0' + s;
  return s;
};

const MIN_NUMBER_WITHOUT_LEAD_ZERO = 10;

export const leadZeroPad = (num: number): string =>
  num < MIN_NUMBER_WITHOUT_LEAD_ZERO ? '0' + num : num.toString();
