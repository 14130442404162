import type {
  HomePageDTO,
  HomePageWindowDTO,
  HomePageMainNewsDTO,
  HomePageBusinessNewsDTO,
  HomePageOpinionsDTO,
  HomePageOurCollectionDTO,
  HomePageSocialsDTO,
  HomePageDiscussionsDTO
} from '@fontanka/news-api-client';

import type { WeSocial, BaseRecord, Rubric } from '../../../domain';
import { WeSocialMapper, BaseRecordMapper } from '../../../services';
import type { HomePage, MainNews } from '../domain';

import { MainNewsMapper } from './main-news-mapper.service';
import { RubricsMapper } from './rubrics-mapper.service';
import { WindowMapper } from './window-mapper.service';

const BUSINESS_NEWS_IMAGE_WIDTH = 220;
const OUR_COLLECTION_IMAGE_WIDTH = 120;

export class HomePageMapper {
  public static toDO(pageDTO: HomePageDTO): HomePage {
    const INITIAL = {
      window: undefined,
      mainNews: [] as MainNews,
      businessNews: [] as BaseRecord[],
      opinions: [] as BaseRecord[],
      rubrics: [] as Rubric[],
      ourCollection: [] as BaseRecord[],
      socials: [] as WeSocial[],
      discussions: [] as BaseRecord[]
    } as HomePage;

    INITIAL.rubrics = RubricsMapper.toDO(pageDTO);

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'window') {
        acc.window = WindowMapper.toDO(block.data as HomePageWindowDTO['data']);

        return acc;
      }

      if (current.type === 'main_news') {
        acc.mainNews = MainNewsMapper.toDO(
          block.data as HomePageMainNewsDTO['data']
        );

        return acc;
      }

      if (current.type === 'business_news') {
        const data = (block.data as HomePageBusinessNewsDTO['data']) ?? [];
        acc.businessNews = data.map(record =>
          BaseRecordMapper.toDO(record, {
            crop: false,
            width: BUSINESS_NEWS_IMAGE_WIDTH
          })
        );

        return acc;
      }

      if (current.type === 'opinions') {
        const data = (block.data as HomePageOpinionsDTO['data']) ?? [];
        acc.opinions = data.map(record =>
          BaseRecordMapper.toDO(record, {
            width: BUSINESS_NEWS_IMAGE_WIDTH
          })
        );

        return acc;
      }

      if (current.type === 'our_collection') {
        const data = (block.data as HomePageOurCollectionDTO['data']) ?? [];
        acc.ourCollection = data.map(record =>
          BaseRecordMapper.toDO(record, {
            width: OUR_COLLECTION_IMAGE_WIDTH
          })
        );
        return acc;
      }

      if (current.type === 'socials') {
        acc.socials = WeSocialMapper.toDO(block.data as HomePageSocialsDTO['data']);

        return acc;
      }

      if (current.type === 'discussions') {
        const data = (block.data as HomePageDiscussionsDTO['data']) ?? [];
        acc.discussions = data.map(record => BaseRecordMapper.toDO(record));

        return acc;
      }

      return acc;
    }, INITIAL);
  }
}
