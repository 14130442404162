import type { BaseRecord } from '../../../domain';

import type { FilterLink } from './filter-link';

const ALL_URL = '/all.html';
const NEWS_URL = '/news.html';
const ARTICLES_URL = '/articles.html';
const COMMERCIAL_URL = '/businews.html';
const ARTICLES_URL_WITH_RUBRIC = '/article.html';

export type LastNewsBlocks = {
  type: 'last_news';
  data: {
    date: string;
    records: BaseRecord[];
  }[];
}[];

export const getFilterLinks = (rubricId?: 'all' | string): FilterLink[] => [
  {
    url: getPageUrl('all', rubricId),
    text: 'Все',
    type: 'all'
  },
  {
    url: getPageUrl('news', rubricId),
    text: 'Новости',
    type: 'news'
  },
  {
    url: getPageUrl('articles', rubricId),
    text: 'Статьи',
    type: 'articles'
  },
  {
    url: getPageUrl('commercial', rubricId),
    text: 'Новости компаний',
    type: 'commercial'
  }
];

export const getPageUrl = (type: FilterLink['type'], rubricId?: 'all' | string) => {
  if (!rubricId || rubricId === 'all') {
    if (type === 'all') {
      return ALL_URL;
    }

    if (type === 'news') {
      return NEWS_URL;
    }

    if (type === 'articles') {
      return ARTICLES_URL;
    }

    return COMMERCIAL_URL;
  }

  if (type === 'all') {
    return `/${rubricId}/last${ALL_URL}`;
  }

  if (type === 'news') {
    return `/${rubricId}/last${NEWS_URL}`;
  }

  if (type === 'articles') {
    return `/${rubricId}/last${ARTICLES_URL_WITH_RUBRIC}`;
  }

  return `/${rubricId}/last${COMMERCIAL_URL}`;
};
