import { State, Action } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { BaseRecord } from '../../../domain';
import type { ThemePage as ThemePageAlias } from '../services/theme-page-mapper.service';
import { ThemePageMapper } from '../services/theme-page-mapper.service';
import { ThemeRecordsDataMapper } from '../services/theme-records-data-mapper.service';

type ThemePage = ThemePageAlias;

export const THEME_MODULE_NAMESPACE = ['theme_module'];

export class ThemeModule {
  @State()
  public blocks: ThemePage['blocks'];

  @State()
  public records: ThemePage['recordsInfo']['records'];

  @State()
  public totalRecords = 0;

  private readonly _themeRecordsDataMapper: ThemeRecordsDataMapper;

  private readonly _recordsPageSize = 12;

  /**
   * Начинаем со 2-ой страницы, потому что с сервера придет первая
   */
  private _currentRecordsPage = 2;

  constructor(private _root: RootModule) {
    this._themeRecordsDataMapper = new ThemeRecordsDataMapper(this._root.envType);

    const { blocks, recordsInfo } = ThemePageMapper.toDO(this._root.pageSpec);

    this.records = recordsInfo.records;
    this.totalRecords = recordsInfo.total;
    this.blocks = blocks;
  }

  @Action()
  async fetchRecords(theme: string) {
    const records = await this._themeRecordsDataMapper.getThemeRecords(
      theme,
      this._currentRecordsPage,
      this._recordsPageSize
    );

    if (records.length !== 0) {
      this._addRecords(records);

      this._currentRecordsPage = this._currentRecordsPage + 1;
    }
  }

  private _addRecords(records: BaseRecord[]) {
    this.records = [...this.records, ...records];
  }
}
