import { Context } from '@fontanka/data';

type RecursiveRecord = {
  [key: string]: number | string | RecursiveRecord;
};

export const getSdkSettings = (data: Context['pageSpec']): RecursiveRecord => {
  const adfoxParams =
    (data.data?.sdk_settings?.data as { adfox: Record<string, string> }[])
      ?.filter(setting => 'adfox' in setting)
      .reduce(
        (acc, { adfox }) => ({
          ...acc,
          ...adfox
        }),
        {}
      ) ?? {};

  return {
    adfoxParams
  };
};
