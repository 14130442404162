import { guardEmptyArray } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin, storeMixin } from '@apps/frontend';
import {
  extractScriptsInfoFromHtmlStr,
  generateUniqueId,
  injectScriptToHead
} from '@fontanka/html';

import styles from './adv.styles.scss';

const RECORD_WITHOUT_ADV = [71983220];

export type Creative = {
  adserver: string;
  placement: string;
  layout: string;
  isMobile: boolean;
  placeholderEnabled: boolean;
  placeholderConfig: {
    height: number;
    backgroundEnabled: boolean;
    bordersEnabled: boolean;
    advMarkEnabled: boolean;
  };
  refreshEnabled: boolean;
  refreshConfig: {
    refreshInterval: number;
    viewportTime: number;
  };
  adfoxConfig: {
    p1: string;
    p2: string;
  };
};

export type AdvData =
  | {
      sticky: boolean;
      types?: {
        desktop?: boolean;
        laptop?: boolean;
        tablet?: boolean;
        mobile?: boolean;
      };
      data: Creative | string;
      responsive?: boolean;
    }
  | null
  | undefined;

export default componentFactory
  .mixin(storeMixin)
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      adv: {
        type: Object as () => AdvData,
        required: true as const
      },
      type: {
        type: String,
        default: ''
      },
      index: {
        type: Number,
        default: undefined
      },
      hasPlaceholder: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isMainPage(): boolean {
        return this.$route.name === 'main' && this.type !== 'branding';
      },
      isDesktopTopAdv(): boolean {
        return this.type === 'headerAdvert' && !this.hasUserAdblock;
      },
      isMobileTopAdv(): boolean {
        return this.type === 'mobileTopAdv' && !this.hasUserAdblock;
      },
      hasUserAdblock(): boolean {
        return this.store.isAdblock;
      },
      isResponsive(): boolean {
        return this.adv?.responsive ?? false;
      },
      isInread(): boolean {
        return this.type === 'inread';
      },
      advHtml(): string {
        if (this.adv?.data === undefined || typeof this.adv?.data !== 'string') {
          return '';
        }

        return this.index === undefined
          ? this.adv?.data
          : generateUniqueId(this.adv?.data, String(this.index));
      },
      recordId(): number {
        return this.$route.name === 'record' ? Number(this.$route.params.id) : 0;
      },
      isRender(): boolean {
        if (RECORD_WITHOUT_ADV.includes(this.recordId)) {
          return false;
        }

        return true;
      }
    },
    mounted() {
      if (this.$root._isMounted) {
        this.injectAdvScript();
      }
    },
    methods: {
      injectAdvScript() {
        const advContent = extractScriptsInfoFromHtmlStr(this.advHtml);
        if (guardEmptyArray(advContent)) {
          void injectScriptToHead(advContent[0])();
        }
      }
    },
    render() {
      return this.adv && this.isRender ? (
        <div
          class={{
            [styles.adv]: true,
            [styles.desktopTopAdv]: this.isDesktopTopAdv,
            [styles.mobileTopAdv]: this.isMobileTopAdv,
            [styles.placeholder]: this.hasPlaceholder,
            [styles.inread]: this.isInread,
            [styles.responsive]: this.isResponsive
          }}
          domPropsInnerHTML={this.advHtml}
        />
      ) : (
        <div />
      );
    }
  });
