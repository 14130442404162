import * as tsx from 'vue-tsx-support';

export default tsx.componentFactory.create({
  props: {
    header: {
      type: String,
      required: false,
      default: ''
    }
  },
  render() {
    return (
      <p class="stat-p">
        <a class="stat-link" href="/__site/price/" target="_self">
          Прайс-листы
        </a>
        <strong>
          <span class="stat-sep"> | </span>
        </strong>
        <a class="stat-link" href="/__site/mediakit/" target="_self">
          Медиакиты
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="/__site/action/" target="_self">
          Акции и предложения
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="/__site/place/" target="_self">
          Расположение
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="/__site/events/" target="_blank">
          Городские события
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="/__site/meeting/" rel="nofollow" target="_blank">
          Круглые столы
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="/__site/special/" target="_blank">
          Спецпроекты
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="//advert.fontanka.ru/obraz_gizni" target="_blank">
          Образ жизни
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="/longreads/proect_stroitelstvo/" target="_blank">
          Проект Строительство
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="//advert.fontanka.ru/projects" target="_blank">
          Лучшие сюжеты
        </a>
        <span class="stat-sep">
          <strong> | </strong>
        </span>
        <a class="stat-link" href="/__site/tech/">
          Технические требования
        </a>
      </p>
    );
  }
});
