
































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../../ui-image';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';
import type { NewsGroupItem, NewsGroupTheme } from '../ui-news-group.contract';

@Component({
  name: 'UiNewsGroupItem',
  components: {
    UiStatistic,
    UiImage,
    UiObserveVisibility
  }
})
export default class UiNewsGroupItem extends Vue {
  @Prop({
    required: true,
    type: Object as () => NewsGroupItem
  })
  item: NewsGroupItem;

  @Prop({
    default: 'light',
    type: String as () => NewsGroupTheme
  })
  theme: NewsGroupTheme;

  @Prop({
    default: false,
    type: Boolean
  })
  hasBookmarks: boolean;

  get linkClass() {
    return this.item.isPrimary ? 'primary' : 'secondary';
  }

  get statisticTheme() {
    return this.theme === 'dark' ? 'light' : 'dark';
  }

  @Emit('commentClicked')
  emitClickToComment(event: Event) {
    return event;
  }

  @Emit('articleClicked')
  emitClickToArticle(event: Event) {
    return event;
  }

  @Emit('bookmarkClicked')
  emitClickToBookmark(event: Event) {
    return event;
  }

  @Emit('articleShown')
  emitShowToArticle(event: Event) {
    return event;
  }
}
