import { createAllAuthors } from '@fontanka/news';
import type {
  AllAuthorsPageDTO,
  AllAuthorsPageAuthorDTO
} from '@fontanka/news-api-client';

import type { AllAuthorsPage } from '../domain';

export class AllAuthorsPageMapper {
  public static toDO(pageDTO: AllAuthorsPageDTO): AllAuthorsPage {
    const INITIAL: AllAuthorsPage = {
      authors: [],
      meta: {
        total: 0
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path] as AllAuthorsPageAuthorDTO;

      if (current.type === 'authors') {
        acc.authors = createAllAuthors(block.data);

        acc.meta.total = block.meta?.total ?? 0;

        return acc;
      }

      return acc;
    }, INITIAL);
  }
}
