import { leadZeroPad } from '@fontanka/numbers';

const START_FILTER_YEAR = 2000;

type DateInfo = {
  timestamp: number;
  day: string;
  month: string;
  year: string;
  hours: string;
  minutes: string;
};

export const getYearsRange = (startValue = START_FILTER_YEAR): number[] => {
  const currentYear = new Date().getFullYear();
  const yearsCount = currentYear - startValue + 1;
  return [...Array(yearsCount).keys()].reverse().map(key => key + startValue);
};

export const PITER_OFFSET_MS = 3 * 3600 * 1000;

export const getSpbDateInfo = (): DateInfo => {
  const timestampNow = Date.now() + PITER_OFFSET_MS;
  const date = new Date(timestampNow);

  const day = leadZeroPad(date.getUTCDate());
  const month = leadZeroPad(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear().toString();

  const hours = leadZeroPad(date.getUTCHours());
  const minutes = leadZeroPad(date.getUTCMinutes());

  return {
    timestamp: timestampNow,
    day,
    month,
    year,
    hours,
    minutes
  };
};
