import type {
  ThemePageDTO,
  ThemePageThemeNewsDTO,
  FormatPageFormatNewsDTO
} from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { FormatRecordsMapper } from '../../../services';

export type ThemePage = {
  blocks: {
    type: ThemePageThemeNewsDTO['type'];
  }[];
  recordsInfo: {
    total: number;
    records: BaseRecord[];
  };
};

export class ThemePageMapper {
  public static toDO(pageDTO: ThemePageDTO) {
    const INITIAL_DATA: ThemePage = {
      blocks: [],
      recordsInfo: {
        total: 0,
        records: []
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'theme_news') {
        acc.recordsInfo = {
          total: block.meta?.total ?? 0,
          records: FormatRecordsMapper.toDO(
            block.data as FormatPageFormatNewsDTO['data']
          )
        };

        acc.blocks.push({
          type: current.type
        });
      }

      return acc;
    }, INITIAL_DATA);
  }
}
