export const ACTION_RESULT_ROUTING = [
  {
    path: '/subscription/:subscriberId(\\d+)/unsubscribe/',
    component: () =>
      import(
        /* webpackChunkName: "action-result-page" */ './pages/action-result.page'
      )
  },
  // страница реактивации подписки на рассылку
  {
    path: '/subscriptions/:subscriberId(\\d+)/reactivation/',
    component: () =>
      import(
        /* webpackChunkName: "action-result-page" */ './pages/action-result.page'
      )
  },
  // страница отписки от авторассылки для зарегистрированных
  {
    path: '/subscription/register/:subscriberId(\\d+)/unsubscribe/',
    component: () =>
      import(
        /* webpackChunkName: "action-result-page" */ './pages/action-result.page'
      )
  }
];
