import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { SearchRubrics } from '../../../domain';
import type { TagPage } from '../domain';
import { TagPageMapper } from '../services';

export const TAG_PAGE_MODULE_NAMESPACE = ['tag_page_module'];

export class TagPageModule {
  @State()
  public blocks: TagPage['blocks'];

  @State()
  public rubrics: SearchRubrics;

  @State()
  public title: TagPage['title'];

  constructor(private _root: RootModule) {
    const { rubrics, blocks, title } = TagPageMapper.toDO(this._root.pageSpec);
    this.rubrics = rubrics;
    this.blocks = blocks;
    this.title = title;
  }
}
