import { isBrowser } from '@fontanka/browser-or-node';
import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { BaseParams } from '../params';
import { PhotosVideosPageDTO } from './photos-videos-page-dto';
import { FormatNewsItemDTO } from '../dtos';
import { SearchResult } from '../search-http-client';

export type PhotosVideosPageParams = BaseParams & {
  page: number;
  pagesize: number;
};

type PhotosVideosSearchParams = Pick<
  PhotosVideosPageParams,
  Exclude<keyof PhotosVideosPageParams, 'headers'>
>;

export class PhotosVideosHttpClient extends BaseHttpClient {
  private readonly _photosPageUrl = '/pages/fontanka/photos_of_the_day/';
  private readonly _videosPageUrl = '/pages/fontanka/videos_of_the_day/';
  private readonly _searchPhotosUrl = '/public/fontanka/services/photos_of_the_day';
  private readonly _searchVideosUrl = '/public/fontanka/services/videos_of_the_day';

  constructor(config: Config) {
    super(config);
  }

  public async fetchPhotosPageData(params: PhotosVideosPageParams) {
    return this._fetchPageData(params, 'photo');
  }

  public async fetchVideosPageData(params: PhotosVideosPageParams) {
    return this._fetchPageData(params, 'video');
  }

  public async searchPhotosRecords(params: PhotosVideosSearchParams) {
    return this._searchRecords(params, 'photo');
  }

  public async searchVideosRecords(params: PhotosVideosSearchParams) {
    return this._searchRecords(params, 'video');
  }

  private async _fetchPageData(
    params: PhotosVideosPageParams,
    type: 'photo' | 'video'
  ): Promise<Result<PhotosVideosPageDTO>> {
    const { page, pagesize, headers } = params;
    const url = `${this.host}${
      type === 'photo' ? this._photosPageUrl : this._videosPageUrl
    }`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<PhotosVideosPageDTO>>(
      url,
      {
        headers,
        withCredentials,
        params: {
          regionId: this.regionId,
          page,
          pagesize
        }
      }
    );

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  private async _searchRecords(
    params: PhotosVideosSearchParams,
    type: 'photo' | 'video'
  ): Promise<SearchResult<FormatNewsItemDTO[] | null>> {
    const { page, pagesize } = params;
    const url = `${this.host}${
      type === 'photo' ? this._searchPhotosUrl : this._searchVideosUrl
    }`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<
      SearchResult<FormatNewsItemDTO[] | null>
    >(url, {
      withCredentials,
      params: {
        regionId: this.regionId,
        page,
        pagesize
      }
    });

    return response.data;
  }
}
