import type { ArchiveFilterLinkType } from '@fe/common/components';

type ArchiveCalendarMeta = {
  type: ArchiveFilterLinkType;
};

const ALL_META: ArchiveCalendarMeta = {
  type: 'all'
};

const NEWS_META: ArchiveCalendarMeta = {
  type: 'news'
};

const ARTICLES_META: ArchiveCalendarMeta = {
  type: 'articles'
};

const COMMERCIAL_META: ArchiveCalendarMeta = {
  type: 'commercial'
};

export const ARCHIVE_ROUTING = [
  {
    path: '/24hours.html',
    name: 'archiveBy24Hours',
    component: () =>
      import(
        /* webpackChunkName: "daily-news" */ './pages/daily-news/daily-news.page'
      ),
    meta: ALL_META
  },
  {
    path: '/24hours_news.html',
    name: 'archiveBy24HoursNews',
    component: () =>
      import(
        /* webpackChunkName: "daily-news" */ './pages/daily-news/daily-news.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/24hours_articles.html',
    name: 'archiveBy24HoursArticles',
    component: () =>
      import(
        /* webpackChunkName: "daily-news" */ './pages/daily-news/daily-news.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/:rubric/last/24hours.html',
    name: 'archiveBy24HoursRubric',
    component: () =>
      import(
        /* webpackChunkName: "daily-news" */ './pages/daily-news/daily-news.page'
      ),
    meta: ALL_META
  },
  {
    path: '/:rubric/last/24hours_news.html',
    name: 'archiveBy24HoursRubricNews',
    component: () =>
      import(
        /* webpackChunkName: "daily-news" */ './pages/daily-news/daily-news.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/:rubric/last/24hours_article.html',
    name: 'archiveBy24HoursRubricArticles',
    component: () =>
      import(
        /* webpackChunkName: "daily-news" */ './pages/daily-news/daily-news.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/all.html',
    name: 'archive',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: ALL_META
  },
  {
    path: '/news.html',
    name: 'archiveNews',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: NEWS_META
  },
  {
    path: '/articles.html',
    name: 'archiveArticles',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: ARTICLES_META
  },
  {
    path: '/businews.html',
    name: 'archiveBusinews',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: COMMERCIAL_META
  },
  {
    path: '/:rubric/last/all.html',
    name: 'archiveRubric',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: ALL_META
  },
  {
    path: '/:rubric/last/news.html',
    name: 'archiveRubricNews',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: NEWS_META
  },
  {
    path: '/:rubric/last/article.html',
    name: 'archiveRubricArticles',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: ARTICLES_META
  },
  {
    path: '/:rubric/last/businews.html',
    name: 'archiveRubricBusinews',
    component: () =>
      import(/* webpackChunkName: "last-news" */ './pages/last-news/last-news.page'),
    meta: COMMERCIAL_META
  },
  {
    path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/all.html',
    name: 'archiveByDate',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: ALL_META
  },
  {
    path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/news.html',
    name: 'archiveByDateNews',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/article.html',
    name: 'archiveByDateArticles',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/businews.html',
    name: 'archiveByDateBusinews',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: COMMERCIAL_META
  },
  {
    path: '/:rubric/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/all.html',
    name: 'archiveByDateRubric',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: ALL_META
  },
  {
    path: '/:rubric/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/news.html',
    name: 'archiveByDateRubricNews',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/:rubric/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/article.html',
    name: 'archiveByDateRubricArticles',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/:rubric/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/businews.html',
    name: 'archiveByDateRubricBusinews',
    component: () =>
      import(
        /* webpackChunkName: "day-archive" */ './pages/day-archive/day-archive.page'
      ),
    meta: COMMERCIAL_META
  },
  {
    path: '/arc/all.html',
    name: 'archiveCalendar',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: ALL_META
  },
  {
    path: '/:rubric/arc/all.html',
    name: 'archiveCalendarRubric',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: ALL_META
  },
  {
    path: '/arc/:year(\\d{4})/all.html',
    name: 'archiveCalendarYear',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: ALL_META
  },
  {
    path: '/:rubric/arc/:year(\\d{4})/all.html',
    name: 'archiveCalendarRubricYear',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    alias: ['/:rubric/arc/all.html', '/arc/:year(\\d{4})/all.html'],
    meta: ALL_META
  },
  {
    path: '/arc/news.html',
    name: 'archiveCalendarNews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/arc/:year(\\d{4})/news.html',
    name: 'archiveCalendarYearNews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/:rubric/arc/news.html',
    name: 'archiveCalendarRubricNews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/:rubric/arc/:year(\\d{4})/news.html',
    name: 'archiveCalendarRubricYearNews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: NEWS_META
  },
  {
    path: '/arc/article.html',
    name: 'archiveCalendarArticle',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/arc/:year(\\d{4})/article.html',
    name: 'archiveCalendarYearArticle',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/:rubric/arc/article.html',
    name: 'archiveCalendarRubricArticle',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/:rubric/arc/:year(\\d{4})/article.html',
    name: 'archiveCalendarRubricYearArticle',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: ARTICLES_META
  },
  {
    path: '/arc/businews.html',
    name: 'archiveCalendarBusinews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: COMMERCIAL_META
  },
  {
    path: '/arc/:year(\\d{4})/businews.html',
    name: 'archiveCalendarYearBusinews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: COMMERCIAL_META
  },
  {
    path: '/:rubric/arc/businews.html',
    name: 'archiveCalendarRubricBusinews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: COMMERCIAL_META
  },
  {
    path: '/:rubric/arc/:year(\\d{4})/businews.html',
    name: 'archiveCalendarRubricYearBusinews',
    component: () =>
      import(
        /* webpackChunkName: "calendar-archive" */ './pages/calendar-archive/calendar-archive.page'
      ),
    meta: COMMERCIAL_META
  }
];
