import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { TrustedBadge, UiImage } from '@fe/common/components';
import avatarIcon from 'images/avatar.png';

import styles from './comment-info.styles.scss';

type User = {
  id: number;
  avatar: {
    url: string;
    sources: {
      srcset: string;
      type: string;
    }[];
  };
  link: string;
  nick: string;
  trusted: boolean;
};

type Events = {
  onReplyLinkClicked: void;
  onCommentAuthorClicked: number;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'CommentInfo',
    props: {
      showReplyBtn: {
        type: Boolean,
        default: true
      },
      date: {
        type: String,
        required: true as const
      },
      user: {
        type: Object as () => User,
        required: true as const
      }
    },
    methods: {
      emitReplyLinkEvent(event: MouseEvent) {
        event.preventDefault();
        this.$emit('replyLinkClicked');
      },
      emitAuthorClicked() {
        this.$emit('commentAuthorClicked', this.user.id);
      }
    },
    render() {
      return (
        <div class={styles.infoWrapper}>
          <div class={styles.userInfo}>
            <div class={styles.userLink}>
              <a href={this.user.link} onClick={this.emitAuthorClicked}>
                <UiImage
                  class={styles.avatarWrap}
                  sources={this.user.avatar.sources}
                >
                  <img
                    class={styles.avatarImg}
                    src={this.user.avatar.url || avatarIcon}
                    alt=""
                    width="30"
                    height="30"
                  />
                </UiImage>
              </a>

              <div class={styles.nickTimeWrapper}>
                <a
                  class={styles.userNick}
                  href={this.user.link}
                  data-test="comment-user-nick"
                  onClick={this.emitAuthorClicked}
                >
                  {this.user.nick}
                </a>

                {this.user.trusted && (
                  <TrustedBadge
                    class={styles.trustedBadge}
                    parentElement={this.$el as HTMLElement}
                  />
                )}

                <a class={styles.date} href={this.user.link}>
                  <time datetime={this.date}>{this.date}</time>
                </a>
              </div>
            </div>

            <div class={styles.statsWrapper}>
              <time class={styles.date} datetime={this.date}>
                {this.date}
              </time>

              {this.showReplyBtn ? (
                <a
                  class={styles.replyLink}
                  href="#"
                  title=""
                  data-test="comment-reply"
                  onClick={e => this.emitReplyLinkEvent(e)}
                >
                  Ответить
                </a>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
  });
