











import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { ObserverVisibility } from '@fontanka/observe-visibility';
import type { ObsVisibilityHook } from '@fontanka/observe-visibility';

@Component({
  name: 'UiObserveVisibility'
})
export default class UiObserveVisibility extends Vue {
  @Prop({
    type: String as () => ObsVisibilityHook,
    default: 'half'
  })
  hook: ObsVisibilityHook;

  @Prop({
    type: String,
    default: 'div'
  })
  tag: string;

  observer: ObserverVisibility | null = null;

  created() {
    this.observer = new ObserverVisibility(this.hook);
  }

  mounted() {
    this.observer?.event.subscribe(({ hook, value }) => {
      if (hook === this.hook && value) {
        this.emitVisible();
      }
    });
  }

  beforeDestroy() {
    this.observer?.destroy();
  }

  @Emit('visible')
  emitVisible(): void {
    return;
  }
}
