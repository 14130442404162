import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { RightCol } from '../../domain';
import { RightColMapper } from '../../services';

export const RIGHT_COL_MODULE_NAMESPACE = ['right_col_module'];

export class RightColModule {
  @State()
  public blocks: RightCol['blocks'];

  @State()
  public hasOnlyMobileBlocks = false;

  constructor(private _root: RootModule) {
    const { blocks, hasOnlyMobileBlocks } = RightColMapper.toDO(
      this._root.pageSpec.config.content,
      this._root.pageSpec.data
    );

    this.blocks = blocks;
    this.hasOnlyMobileBlocks = hasOnlyMobileBlocks;
  }
}
