import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import { CommentHeader } from '../comment-header';

import styles from './direct-comment.styles.scss';

type ComponentData = {
  isHidden: boolean;
};

type DirectParentComment = {
  id: number;
  text: string;
  date: string;
  user: {
    nick: string;
    link: string;
    trusted: boolean;
  };
};

type Events = {
  onCommentAuthorClicked: number;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      comment: {
        type: Object as () => DirectParentComment,
        required: true as const
      }
    },
    data(): ComponentData {
      return {
        isHidden: true
      };
    },
    methods: {
      toggleHidden() {
        this.isHidden = !this.isHidden;
      },
      emitAuthorClicked() {
        this.$emit('commentAuthorClicked');
      }
    },
    render() {
      return (
        <div class={styles.directComment} onClick={() => this.toggleHidden()}>
          <CommentHeader
            user={this.comment.user}
            date={this.comment.date}
            onCommentAuthorClicked={() => this.emitAuthorClicked()}
          />
          <div
            class={[styles.text, this.isHidden ? styles.hidden : '']}
            domPropsInnerHTML={this.comment.text}
          />
        </div>
      );
    }
  });
