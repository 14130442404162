










































import { guardEmptyString } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiLabel } from '../ui-label';
import { UiObserveVisibility } from '../ui-observe-visibility';
import { UiStatistic } from '../ui-statistic';

import type { OpinionStatistic, OpinionAuthor } from './ui-opinion-card.contract';

@Component({
  components: {
    UiLabel,
    UiObserveVisibility,
    UiStatistic
  },
  name: 'UiOpinionCard'
})
export default class UiOpinionCard extends Vue {
  @Prop({
    type: String,
    required: true
  })
  label!: string;

  @Prop({
    type: String,
    required: false
  })
  labelLink!: string;

  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  link!: string;

  @Prop({
    type: Object as () => OpinionStatistic,
    required: true
  })
  statistic!: OpinionStatistic;

  @Prop({
    type: Object as () => OpinionAuthor,
    default: null
  })
  author: OpinionAuthor;

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  get hasAuthorJobPosition(): boolean {
    return guardEmptyString(this.author.jobPosition);
  }

  get labelAttrs() {
    return this.labelLink ? { href: this.labelLink } : {};
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }

  @Emit('labelClicked')
  labelClicked(event: Event): Event {
    return event;
  }

  @Emit('titleClicked')
  titleClicked(event: Event): Event {
    return event;
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('blockClicked')
  blockClicked(event: Event): Event {
    return event;
  }
}
