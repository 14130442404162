import { TAG_INFO } from '../domain';

export const TAG_REQUEST_DATE_FORMAT = 'dd.MM.yyyy';
export const TAG_API_DATE_FORMAT = 'dd.MM.yyyy';

export const TAG_PARAMS_MAP = {
  page: 'page',
  tag: 'tag',
  rubric: 'rubric',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo'
};

export class TagMapper {
  public static getDateFrom(dateFrom: string): string {
    return dateFrom;
  }

  public static getDateTo(dateTo?: string): string {
    return dateTo ?? TAG_INFO.getDefaultDateTo();
  }

  public static getApiDateFrom(dateFrom: string): string {
    return dateFrom;
  }

  public static getApiDateTo(dateTo?: string): string {
    return dateTo ?? TAG_INFO.getDefaultDateTo();
  }

  public static getPageSize() {
    return TAG_INFO.PAGE_SIZE;
  }

  public static getPageStart(page?: string | number) {
    const pageNumber = Number(page);
    return page && !isNaN(pageNumber) ? pageNumber : TAG_INFO.DEFAULT_START_PAGE;
  }

  public static getTag(tag: string) {
    return tag;
  }

  public static getRubric(rubric?: string) {
    return rubric && typeof rubric === 'string' ? rubric : TAG_INFO.DEFAULT_RUBRIC;
  }
}
