import { clickOutside } from '@portal/utils/util-click-outside';
import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import FntArrowDownIcon from '~icons/fnt-common/arrow-down';

import styles from './ui-select.styles.scss';

type SelectItem = {
  name: string;
  value?: string;
  id?: string;
  selected?: boolean;
};

type ComponentData = {
  selectedItem: null | SelectItem;
  customOptionsHidden: boolean;
  optionsHidden: boolean;
};

type Events = {
  onChange: SelectItem;
};

const ICON_SIZE = 24;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiSelect',
    props: {
      options: {
        type: Array as () => SelectItem[],
        default: (): [] => []
      },
      placeholder: {
        type: String,
        default: ''
      }
    },
    data(): ComponentData {
      return {
        optionsHidden: true,
        customOptionsHidden: true,
        selectedItem: null
      };
    },
    computed: {
      selectedName(): string {
        return this.selectedItem?.name || '';
      }
    },
    created() {
      this.selectedItem =
        this.options.find(option => {
          if (option.selected) {
            return true;
          }
        }) || null;
    },
    mounted() {
      if (guardUnspecified(this.$refs.uiSelect)) {
        clickOutside(
          this.$refs.uiSelect as HTMLElement,
          () => {
            if (!this.optionsHidden) {
              this.optionsHidden = true;
              this.customOptionsHidden = true;
            }
          },
          { isKeepAlive: true }
        );
      }
    },
    methods: {
      optionsToggle(isCustomOptions = false): void {
        this.optionsHidden = !this.optionsHidden;

        if (isCustomOptions) {
          this.customOptionsHidden = this.optionsHidden;
        }
      },
      setValue(index?: number): void {
        if (guardUnspecified(index)) {
          this.selectedItem = this.options[index];
        }
      },
      nativeOptionChanged(target: HTMLInputElement): void {
        const index = Number(target.value);

        this.setValue(index);
        this.optionsToggle();

        this.emitChangeEvent(this.options[index]);
      },
      customOptionChanged(index: number): void {
        this.setValue(index);
        this.optionsToggle(true);

        this.emitChangeEvent(this.options[index]);
      },
      isSelected(item: SelectItem): boolean {
        if (guardUnspecified(this.selectedItem)) {
          if (this.selectedItem.name === item.name) {
            return true;
          }
        }
        return false;
      },
      emitChangeEvent(item: SelectItem): void {
        this.$emit('change', item);
      }
    },
    render() {
      return (
        <div class={styles.selectWrap} ref="uiSelect">
          <div
            class={styles.customSelect}
            onClick={() => {
              this.optionsToggle(true);
            }}
          >
            <div class={styles.textField}>
              {this.selectedName}
              {!guardEmptyString(this.selectedName) && (
                <span class={styles.placeholder}>{this.placeholder}</span>
              )}
            </div>
            <FntArrowDownIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              class={[styles.icon, !this.optionsHidden ? styles.rotate : '']}
            />
          </div>
          <select
            class={styles.nativeSelect}
            onChange={(e: Event) =>
              this.nativeOptionChanged(e.target as HTMLInputElement)
            }
          >
            {this.options.map((item: SelectItem, index: number) => (
              <option
                key={item?.id || item.name}
                selected={item.selected}
                value={index}
              >
                {item.name}
              </option>
            ))}
          </select>
          {!this.customOptionsHidden && (
            <ul class={styles.customOptionsBox}>
              {this.options.map((item: SelectItem, index) => (
                <li
                  key={item?.id || item.name}
                  class={[
                    styles.customOptionItem,
                    this.isSelected(item) ? styles.selected : ''
                  ]}
                  onClick={() => this.customOptionChanged(index)}
                >
                  {item.name}
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }
  });
