import {
  guardEmptyString,
  guardUnspecified,
  guardZeroNumber
} from '@smh/utils/guards';
import { isAbsoluteUrl } from '@smh/utils/url';

import type {
  CommentsStatistic,
  DateStatistic,
  Statistic,
  ViewsStatistic
} from '../../domain';

import type {
  CommentsDTO,
  DateDTO,
  StatisticDTO,
  ViewsDTO
} from './base-article.dto';

export class BaseArticlePresenter {
  public presentComments(data: CommentsDTO): CommentsStatistic | undefined {
    const commentsCount = data.commentsCount ?? 0;
    const isDiscuss = !guardZeroNumber(commentsCount);
    const commentsUrl = data.urls?.urlComments ?? '';
    const discussUrl = `${commentsUrl}?discuss=1`;
    const link = isDiscuss ? discussUrl : commentsUrl;

    return data.isCommentsAllowed
      ? {
          text: isDiscuss ? 'Обсудить' : `${commentsCount}`,
          link,
          target: isAbsoluteUrl(link) ? '_blank' : '_self'
        }
      : undefined;
  }

  public presentViews(data: ViewsDTO): ViewsStatistic | undefined {
    return guardUnspecified(data.viewsCount) && guardZeroNumber(data.viewsCount)
      ? {
          count: data.viewsCount.toLocaleString('ru-RU')
        }
      : undefined;
  }

  public presentDate(data: DateDTO): DateStatistic | undefined {
    return guardUnspecified(data?.publishedAgo) &&
      guardEmptyString(data.publishedAgo)
      ? {
          publishedAgo: data.publishedAgo
        }
      : undefined;
  }

  public presentStatistic(data: StatisticDTO): Statistic {
    return {
      comments: this.presentComments(data),
      date: this.presentDate(data)
    };
  }
}
