import type {
  EditProfilePageDTO,
  EditProfilePageProfileDTO
} from '@fontanka/news-api-client';

import type { EditProfilePage } from '../domain';

import { ProfileInfoMapper } from './profile-info-mapper.service';

export class EditProfilePageMapper {
  public static toDO(pageDTO: EditProfilePageDTO): EditProfilePage {
    const INITIAL: EditProfilePage = {
      blocks: [],
      profile: null
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'profile') {
        acc.blocks.push({
          type: 'profile'
        });

        const data = block.data as EditProfilePageProfileDTO['data'];
        acc.profile = data !== null ? ProfileInfoMapper.toDO(data) : null;

        return acc;
      }

      return acc;
    }, INITIAL);
  }
}
