import { AuthorHttpClient } from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { BaseRecordMapper } from '../../../services';

export class AuthorRecordsDataMapper {
  private readonly _authorHttpClient: AuthorHttpClient;

  constructor(envType: string) {
    this._authorHttpClient = new AuthorHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getAuthorRecords(
    id: number,
    page: number,
    pagesize: number
  ): Promise<BaseRecord[]> {
    let authorRecords: BaseRecord[] = [];

    try {
      const data = await this._authorHttpClient.fetchAuthorRecords({
        id,
        page,
        pagesize
      });
      authorRecords = data?.map(record => BaseRecordMapper.toDO(record)) ?? [];
    } catch (err) {
      console.error(err);
    }

    return authorRecords;
  }
}
