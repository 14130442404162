






















import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'UiAlert',
})
export default class UiLayout extends Vue {
  @Prop({
    type: String as () => 'success' | 'error',
    default: 'success',
  })
  type: 'success' | 'error';

  get hasActionsBlock() {
    return guardUnspecified(this.$slots.actions);
  }
  get hasCaptionBlock() {
    return guardUnspecified(this.$slots.caption);
  }
  get hasCloseBtn() {
    return guardUnspecified(this.$slots.close);
  }
}
