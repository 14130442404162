const NAME_MIN_LENGTH = 3;
const NAME_MAX_LENGTH = 35;
const EMPTY_NAME_ERROR = 'Введите ваше имя';
const MIN_LENGTH_NAME_ERROR = 'Имя должно быть не меньше трех символов';
const MAX_LENGTH_NAME_ERROR = `Имя должно быть не больше ${NAME_MAX_LENGTH} символов`;
const ABOUT_MAX_LENGTH = 250;
const MAX_LENGTH_ABOUT_ERROR = `Информация о себе должна быть не больше ${ABOUT_MAX_LENGTH} символов`;
const INVALID_BIRTHDAY_DATE_ERROR = 'Введите корректную дату рождения';
const EMPTY_NICK_ERROR = 'Введите ваш никнейм';
const MIN_LENGTH_NICK_ERROR = 'Никнейм должен быть не менее 3 символов';
const MAX_LENGTH_NICK_ERROR = `Никнейм должен быть не больше ${NAME_MAX_LENGTH} символов`;
const EMPTY_EMAIL_ERROR = 'Введите E-mail';
const INVALID_EMAIL_ERORR = 'Укажите корректный адрес e-mail';
const PASSWORD_MIN_LENGTH = 6;
const PASSWORD_MAX_LENGTH = 20;
const MIN_LENGTH_PASSWORD_ERROR = `Пароль должен быть не менее ${PASSWORD_MIN_LENGTH} символов`;
const MAX_LENGTH_PASSWORD_ERROR = `Пароль должен быть не больше ${PASSWORD_MAX_LENGTH} символов`;
const PASSWORD_ALLOW_SYMBOLS_REG_EX = /^[0-9a-zA-Z`!@#\$%\^&\*\(\)\-_=\+\[\]{};:"\\\|,\.<>\/\?]+$/;
const SYMBOLS_REG_EX = /[`!@#\$%\^&\*\(\)\-_=\+\[\]{};:"\\\|,\.<>\/\?]+/;
const LETTERS_REG_EX = /[a-zA-Z]+/;
const NUMBERS_REG_EX = /\d+/;
const HTML_TAG_REG_EX = /<(|\/|[^\/>][^>]+|\/[^>][^>]+)>|(\">)/i;
const YA_RULES_PASSWORD_SYMBOLS_ERROR = `Пароль должен содержать от 6 до 20 символов: латинские буквы, цифры и спецсимволы (допускаются знаки \` ! @ # $ % ^ & * ( ) - _ = + [ ] { } ; : "  | \\ , . < > / ?, не допускаются ~ и \')`;
const CONFIRM_PASSRORD_ERROR = 'Пароли не совпадают';
const PASSWORD_LIKE_LOGIN_ERROR = 'Пароль не должен совпадать с логином';
const NICKNAME_WRONG_SYMBOLS_ERROR = 'Имя содержит недопустимый набор символов';
const ABOUT_WRONG_SYMBOLS_ERROR =
  'Недопустимые символы в поле «О себе». Введите корректный текст';

export const FORMS_INFO = {
  NAME_MIN_LENGTH,
  NAME_MAX_LENGTH,
  EMPTY_NAME_ERROR,
  MIN_LENGTH_NAME_ERROR,
  MAX_LENGTH_NAME_ERROR,
  ABOUT_MAX_LENGTH,
  MAX_LENGTH_ABOUT_ERROR,
  INVALID_BIRTHDAY_DATE_ERROR,
  EMPTY_NICK_ERROR,
  MIN_LENGTH_NICK_ERROR,
  MAX_LENGTH_NICK_ERROR,
  EMPTY_EMAIL_ERROR,
  INVALID_EMAIL_ERORR,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  MIN_LENGTH_PASSWORD_ERROR,
  MAX_LENGTH_PASSWORD_ERROR,
  CONFIRM_PASSRORD_ERROR,
  YA_RULES_PASSWORD_SYMBOLS_ERROR,
  PASSWORD_ALLOW_SYMBOLS_REG_EX,
  SYMBOLS_REG_EX,
  LETTERS_REG_EX,
  NUMBERS_REG_EX,
  PASSWORD_LIKE_LOGIN_ERROR,
  HTML_TAG_REG_EX,
  NICKNAME_WRONG_SYMBOLS_ERROR,
  ABOUT_WRONG_SYMBOLS_ERROR
};
