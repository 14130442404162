import { componentFactoryOf } from 'vue-tsx-support';
import type { SyntheticEvent, InputHTMLAttributes } from 'vue-tsx-support/types/dom';

import { injectStylesMixin } from '@apps/frontend';
import calendarIcon from 'images/actual/calendar.svg';

import { ErrorMsg } from '../error-msg';

import styles from './date-input.styles.scss';

let inputmask = (val: string) => ({ mask: (el: HTMLElement) => ({}) });
if (process.env.VUE_ENV !== 'server') {
  inputmask = require('inputmask/dist/inputmask/inputmask.numeric.extensions');
}

type Events = {
  onInput: string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    directives: {
      mask(el, binding) {
        inputmask(binding.value as string).mask(el);
      }
    },
    props: {
      id: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      date: {
        type: String,
        default: ''
      },
      mask: {
        type: String,
        default: '99.99.9999'
      },
      error: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        dateValue: this.date ?? '',
        options: {
          mask: this.mask,
          showMaskOnHover: false,
          showMaskOnFocus: true,
          rightAlign: false,
          positionCaretOnClick: 'radixFocus'
        }
      };
    },
    computed: {
      hasError(): boolean {
        return this.error !== undefined && this.error.length > 0;
      }
    },
    watch: {
      date() {
        this.dateValue = this.date ? this.date : '';
      }
    },
    methods: {
      emitInputEvent(event: SyntheticEvent<InputHTMLAttributes, Event>) {
        this.$emit('input', event.target.value?.toString() ?? '');
      }
    },
    render() {
      return (
        <div class={styles.dateInput}>
          <label for={this.id} class={styles.label}>
            {this.label}
          </label>
          <div class={styles.inputWrapper}>
            <img class={styles.calendarIcon} src={calendarIcon} />

            <input
              class={[styles.input, this.hasError ? styles.error : '']}
              v-mask={this.options}
              id={this.id}
              value={this.dateValue}
              type="text"
              onInput={this.emitInputEvent}
            />
          </div>

          {this.hasError ? (
            <ErrorMsg class={styles.errorMsg}>{this.error}</ErrorMsg>
          ) : null}
        </div>
      );
    }
  });
