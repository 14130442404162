import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';
import { UiAdvLabel } from '@fe/common/ui';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import FntHeadphonesIcon from '~icons/fnt-common/headphones';
import FntNewImageIcon from '~icons/fnt-common/new-image';
import FntNewVideoIcon from '~icons/fnt-common/new-video';

import styles from './news-feed-item.styles.scss';

type Events = {
  onVisible: void;
  onClicked: void;
};

const HEADER_ICON_SIZE = 16;

const HEADER_ICON_PROPS = {
  strokeWidth: 1,
  width: HEADER_ICON_SIZE,
  height: HEADER_ICON_SIZE
};

export type NewsFeedRecord = {
  hasVideo: boolean;
  hasPhoto: boolean;
  hasAudio: boolean;
  urls: {
    url: string;
  };
  header: string;
  publishDateForWindow: string;
  isPictureOfTheDay: boolean;
  hasMainPhotoCommercialLabel?: boolean;
};
export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'NewsFeedItem',
    props: {
      record: {
        type: Object as () => NewsFeedRecord,
        required: true as const
      }
    },
    data() {
      return {
        newsFeedItemVisibility: new ObserverVisibility('all')
      };
    },
    methods: {
      emitClicked(): void {
        this.$emit('clicked');
      }
    },
    mounted(): void {
      this.newsFeedItemVisibility.event.subscribe(state => {
        if (state.value) {
          this.$emit('visible');
        }
      });
    },
    render() {
      return (
        <div
          v-observe-visibility={this.newsFeedItemVisibility.getOptions('all')}
          class={[styles.item, this.record.isPictureOfTheDay ? styles.top : '']}
        >
          <div class={styles.header}>
            <span class={[styles.time, typography.primary.body2.mobile]}>
              {this.record.publishDateForWindow}
            </span>

            {this.record.hasVideo && (
              <FntNewVideoIcon
                width={HEADER_ICON_PROPS.width}
                height={HEADER_ICON_PROPS.height}
                strokeWidth={HEADER_ICON_PROPS.strokeWidth}
              />
            )}
            {this.record.hasAudio && (
              <FntHeadphonesIcon
                width={HEADER_ICON_PROPS.width}
                height={HEADER_ICON_PROPS.height}
                strokeWidth={HEADER_ICON_PROPS.strokeWidth}
              />
            )}
            {this.record.hasPhoto && (
              <FntNewImageIcon
                width={HEADER_ICON_PROPS.width}
                height={HEADER_ICON_PROPS.height}
                strokeWidth={HEADER_ICON_PROPS.strokeWidth}
              />
            )}
          </div>

          <a
            class={[styles.link, typography.primary.body2.mobile]}
            href={this.record.urls.url}
            target="_self"
            onClick={this.emitClicked}
            domPropsInnerHTML={this.record.header}
          />
          {this.record.hasMainPhotoCommercialLabel && (
            <UiAdvLabel hasFrame={false} class={styles.advMarker} />
          )}
        </div>
      );
    }
  });
