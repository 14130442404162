import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import { UiAdvLabel } from '@fe/common/ui';
import { stripTags } from '@fontanka/news';

import styles from './slider-horizontal-item.styles.scss';

type Author = {
  name: string;
  url: string;
};

type Image = {
  url: string;
  sources: {
    type: string;
    srcset: string;
  }[];
};

type Events = {
  onClickedPhoto: void;
  onClickedAuthor: void;
  onClickedHeader: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SliderHorizontalItem',
    props: {
      url: {
        type: String,
        required: true as const
      },
      header: {
        type: String,
        required: true as const
      },
      image: {
        type: Object as () => Image,
        required: true as const
      },
      publishAt: {
        type: String,
        required: false
      },
      author: {
        type: Object as () => Author,
        required: false
      },
      hasCommercialLabel: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      emitClickPhotoEvent() {
        this.$emit('clickedPhoto');
      },
      emitClickAuthorEvent() {
        this.$emit('clickedAuthor');
      },
      emitClickHeaderEvent() {
        this.$emit('clickedHeader');
      }
    },
    render() {
      return (
        <div class={styles.item}>
          <a
            class={styles.imgWrapLink}
            href={this.author?.url || this.url}
            title={stripTags(this.header)}
            onClick={this.emitClickPhotoEvent}
          >
            <UiImage sources={this.image.sources}>
              <img class={styles.img} src={this.image.url} alt="" />
            </UiImage>
          </a>

          <div class={styles.contentWrap}>
            {this.author?.url && this.author?.name && (
              <a
                class={[styles.link, styles.author]}
                href={this.author.url}
                title={this.author.name}
                onClick={this.emitClickAuthorEvent}
              >
                {this.author.name}:
              </a>
            )}

            <a
              class={[
                styles.link,
                styles.recordTitle,
                !this.publishAt ? styles.noDate : ''
              ]}
              href={this.url}
              domPropsInnerHTML={this.header}
              onClick={this.emitClickHeaderEvent}
            />

            {this.hasCommercialLabel && (
              <UiAdvLabel hasFrame={false} class={styles.advMarker} />
            )}

            {this.publishAt && <time class={styles.time}>{this.publishAt}</time>}
          </div>
        </div>
      );
    }
  });
