import type { CropType } from '@fontanka/cropping';
import {
  leadToString,
  createAuthor,
  isAudioRecord,
  isPhotoRecord,
  isVideoRecord,
  createImage,
  getMainRubric
} from '@fontanka/news';
import type {
  LeadDTO,
  ImageDTO,
  AuthorDTO,
  BaseRubricDTO,
  BaseThemeDTO,
  BaseFormatsDTO
} from '@fontanka/news-api-client';

import type { BaseRecord } from '../domain';

const BUSINESS_RUBRIC_ID = 'bt';

type Params = {
  crop?: boolean;
  cropType?: CropType;
  width?: number;
  height?: number;
};

export type BaseRecordDTO = {
  id: number;
  header: string;
  subheader?: string;
  lead?: LeadDTO;
  mainPhoto?: ImageDTO | null;
  authors?: AuthorDTO[];
  isCommentsAllowed?: boolean;
  commentsCount?: number;
  urls?: {
    url: string;
    urlComments: string;
  };
  hasVideoMark?: boolean;
  headerKeywords?: string[];
  publishAt?: string;
  rubrics?: BaseRubricDTO[];
  themes?: BaseThemeDTO[];
  formats?: BaseFormatsDTO[];
  publishDate?: string;
  isSpb?: boolean;
  publishTime?: string;
  publishDateForWindow?: string;
  publishDateForRecordHeader?: string;
  publishDateForRecordPage?: string;
  isPictureOfTheDay?: boolean;
  isCommercial: boolean;
  isMatureContent: boolean;
  viewsCount: number;
  hasMainPhotoCommercialLabel?: boolean;
};

export class BaseRecordMapper {
  public static toDO(recordDTO: BaseRecordDTO, params?: Params): BaseRecord {
    const isCommentsAllowed = recordDTO.isCommentsAllowed ?? false;
    const commentsCount = recordDTO.commentsCount ?? 0;
    const canShowComments = isCommentsAllowed && commentsCount > 0;

    return {
      id: recordDTO.id,
      header: recordDTO.header,
      subheader: recordDTO.subheader ?? '',
      leadStr: leadToString(recordDTO.lead ?? []),
      image: createImage(recordDTO.mainPhoto ?? null, params),
      author: recordDTO?.authors?.[0]
        ? createAuthor(recordDTO.authors[0], params)
        : null,
      isCommentsAllowed,
      commentsCount,
      canShowComments,
      urls: recordDTO.urls ?? {
        url: '',
        urlComments: ''
      },
      hasAudio: isAudioRecord(recordDTO.headerKeywords ?? []),
      hasPhoto: isPhotoRecord(recordDTO.headerKeywords ?? []),
      hasVideo:
        recordDTO.hasVideoMark ?? isVideoRecord(recordDTO.headerKeywords ?? []),
      publishAt: recordDTO.publishAt ?? '',
      rubrics: recordDTO.rubrics ?? [],
      themes: recordDTO.themes ?? [],
      formats: recordDTO.formats ?? [],
      isCommercial: recordDTO.isCommercial,
      isMatureContent: recordDTO.isMatureContent,
      isBusiness: recordDTO.rubrics
        ? recordDTO.rubrics.findIndex(rubric => rubric.id === BUSINESS_RUBRIC_ID) !==
          -1
        : false,
      publishDate: recordDTO.publishDate ?? '',
      mainRubric: getMainRubric(recordDTO.rubrics),
      isSpb: recordDTO.isSpb ?? false,
      publishTime: recordDTO.publishTime ?? '',
      publishDateForWindow: recordDTO.publishDateForWindow ?? '',
      publishDateForRecordHeader: recordDTO.publishDateForRecordHeader ?? '',
      publishDateForRecordPage: recordDTO.publishDateForRecordPage ?? '',
      isPictureOfTheDay: recordDTO.isPictureOfTheDay ?? false,
      viewsCount: recordDTO.viewsCount ?? 0,
      hasMainPhotoCommercialLabel: recordDTO.hasMainPhotoCommercialLabel ?? false
    };
  }
}
