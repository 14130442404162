import { getSizeByRatioValues } from '@fontanka/cropping';
import { TestAttachDTO } from '@fontanka/news-api-client';
import { TestAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

const TEST_ID_PREFIX = 'test';
const MAX_IMAGE_WIDTH = 909;
const MAX_IMAGE_HEIGHT = 511;
const RATIO = { x: 16, y: 9 };

export const createTestAttach = (attach: TestAttachDTO): TestAttach => ({
  type: 'test',
  value: {
    id: Number(attach.media.poll.replace(TEST_ID_PREFIX, '')),
    answers: attach.value.test.map(answerDTO => ({
      answer: Number(answerDTO.answer),
      content: answerDTO.content,
      description: answerDTO.title || '',
      id: `${attach.media.poll}_${answerDTO.answer}`,
      isCorrect: !!Number(answerDTO.iscorrect),
      isUserAnswer: false,
      name: attach.media.poll
    })),
    hasUserAnswer: false,
    image: attach.value.pollImage
      ? createImage(attach.value.pollImage, {
          ...getSizeByRatioValues({
            imageWidth: attach.value.pollImage.width,
            imageHeight: attach.value.pollImage.height,
            maxImageWidth: MAX_IMAGE_WIDTH,
            maxImageHeight: MAX_IMAGE_HEIGHT,
            ratio: RATIO.x / RATIO.y
          }),
          crop: true
        })
      : null,
    title: attach.value.pollTitle?.text || ''
  }
});

export const createTestValue = (
  testValue: TestAttach['value'],
  currentAnswer: number
): TestAttach['value'] => ({
  ...testValue,
  answers: testValue.answers.map(answer => ({
    ...answer,
    isUserAnswer: answer.answer === currentAnswer
  })),
  hasUserAnswer: true
});
