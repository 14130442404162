import {
  guardEmptyString,
  guardMaxLengthString,
  guardUnspecified,
  guardZeroNumber
} from '@smh/utils/guards';
import { truncate, removeHtmlTags } from '@smh/utils/strings';
import { addQueryString } from '@smh/utils/url';

import type { SingleCardDTO } from '@fontanka/news-api-client';

import type { SingleCard, SingleCardAuthor } from '../domain';

export class SingleCardBlockPresenter {
  private static readonly _anonymousAuthorLabel = 'Анонимный автор';
  private static readonly _maxTextLength = 120;

  private static _cutText(text: string) {
    const noTagText = removeHtmlTags(text);

    return guardMaxLengthString(noTagText, SingleCardBlockPresenter._maxTextLength)
      ? text
      : truncate({
          subject: noTagText,
          length: SingleCardBlockPresenter._maxTextLength,
          postfix: '...',
          removeLastWord: true
        });
  }

  private static _presentSingleCardAuthor(card: SingleCardDTO['card']) {
    const result: SingleCardAuthor = {
      name: SingleCardBlockPresenter._anonymousAuthorLabel,
      jobPosition: '',
      isAnonymous: true
    };

    if (guardEmptyString(card.authorName)) {
      result.name = SingleCardBlockPresenter._cutText(card.authorName);
      result.jobPosition = SingleCardBlockPresenter._cutText(card.authorDescription);
      result.isAnonymous = false;
    }
    return result;
  }

  static presentSingleCard(data: SingleCardDTO): SingleCard {
    const isQuote = data.meta.type === 'quote';

    return {
      id: data.record.id,
      header: {
        link: isQuote
          ? data.record.urls.url
          : addQueryString(data.record.urls.url, {
              sharePoll: '1'
            }),
        text: SingleCardBlockPresenter._cutText(
          isQuote ? data.card.quote : data.card.text
        )
      },
      type: data.meta.type,
      label: isQuote ? 'Цитата' : 'Опрос',
      author: isQuote
        ? SingleCardBlockPresenter._presentSingleCardAuthor(data.card)
        : null,
      statistic: {
        comments: guardZeroNumber(data.record.commentsCount)
          ? {
              link: data.record.urls.urlComments,
              text: data.record.commentsCount.toLocaleString('ru-RU')
            }
          : undefined,
        date: guardUnspecified(data.record.publishedAgo)
          ? {
              publishedAgo: data.record.publishedAgo
            }
          : undefined
      }
    };
  }
}
