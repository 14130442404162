export const CORE_ROUTING = [
  {
    path: '/__site/about',
    component: () => import('../views/about.view')
  },
  {
    path: '/__site/contacts',
    component: () => import('../views/contacts.view')
  },
  {
    path: '/__site/advert',
    component: () => import('../views/advert.view')
  },
  {
    path: '/__site/terms_of_use_blog',
    component: () => import('../views/terms_of_use_blog.view')
  },
  {
    path: '/__site/personal_data',
    component: () => import('../views/personal_data.view')
  },
  {
    path: '/__site/terms_of_use',
    component: () => import('../views/terms_of_use.view')
  },
  {
    path: '/__site/copyright',
    component: () => import('../views/copyright.view')
  },
  {
    path: '/__site/cookies',
    component: () => import('../views/cookies.view')
  },
  {
    path: '/__site/cookie-policy',
    component: () => import('../views/cookiePolicy.view')
  },
  {
    path: '/__site/recommender',
    component: () => import('../views/recommender.view')
  },
  {
    path: '/__site/terms_of_service',
    component: () => import('../views/terms_of_service.view')
  },
  {
    path: '/__site/price/',
    component: () => import('../views/price.view')
  },
  {
    path: '/__site/mediakit/',
    component: () => import('../views/mediakit.view')
  },
  {
    path: '/__site/action/',
    component: () => import('../views/action.view')
  },
  {
    path: '/__site/place/',
    component: () => import('../views/place.view')
  },
  {
    path: '/__site/events/',
    component: () => import('../views/events.view')
  },
  {
    path: '/__site/meeting/',
    component: () => import('../views/meeting.view')
  },
  {
    path: '/__site/special/',
    component: () => import('../views/special.view')
  },
  {
    path: '/__site/tech/',
    component: () => import('../views/tech.view')
  },
  {
    path: '/__site/schastlivyypyatak/',
    component: () => import('../views/schastlivyypyatak.view')
  },
  {
    path: '/__site/cases/',
    component: () => import('../views/cases.view')
  },
  {
    path: '/__site/fontankasup/',
    component: () => import('../views/fontankasup.view')
  },
  {
    path: '/__site/lotday/',
    component: () => import('../views/lotday.view')
  },
  {
    path: '/__site/partners/',
    component: () => import('../views/partners.view')
  },
  {
    path: '/__site/rules/',
    component: () => import('../views/rules.view')
  },
  {
    path: '/__site/public_offer/',
    component: () => import('../views/public_offer.view')
  },
  {
    path: '/__site/deyatelnost_v_sfere_it/',
    component: () => import('../views/activities_in_it.view')
  },
  {
    path: '/web-portal/',
    component: () => import('../views/web-portal.view')
  },
  {
    path: '/liv/',
    component: () => import('../views/living.view')
  }
];
