import type {
  CalendarArchivePageDTO,
  CalendarArchiveCalendarDTO
} from '@fontanka/news-api-client';

export type CalendarArchiveData = {
  calendar: CalendarArchiveCalendarDTO['data'] | null;
  blocks: {
    type: CalendarArchiveCalendarDTO['type'];
  }[];
};

export class CalendarArchiveMapper {
  public static toDO(pageDTO: CalendarArchivePageDTO): CalendarArchiveData {
    const initial = {
      calendar: null,
      blocks: []
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const { data } = pageDTO.data[current.path];

      if (current.type === 'calendar') {
        acc.blocks.push({
          type: current.type
        });

        acc.calendar = data as CalendarArchiveCalendarDTO['data'];
      }

      return acc;
    }, initial as CalendarArchiveData);
  }
}
