import type {
  Image,
  RecordCard,
  RecordPost,
  RecordText,
  RecordTextItem
} from '@fontanka/news';

import type { RecordPage } from '../domain';

type RecordDetailsItem = RecordCard | RecordPost | RecordTextItem;
type RecordDetails = RecordDetailsItem[];

const getImagesFromRecordData = (recordField: RecordDetails, type: string) =>
  recordField
    .filter((record: RecordDetailsItem) => record?.type === type)
    .map((record: RecordDetailsItem) => {
      const recordItem = record as RecordCard | RecordPost;
      return recordItem.data;
    })
    .reduce(
      (prevRecordData: RecordText, currentRecordData: RecordText) => [
        ...prevRecordData,
        ...currentRecordData
      ],
      []
    )
    .filter((recordText: RecordTextItem) => recordText.type === 'images')
    .map(({ value }: RecordTextItem) => value as Image);

export class RecordPageImagesMapper {
  private readonly _record: RecordPage['record'];

  constructor(recordData: RecordPage['record']) {
    this._record = recordData;
  }

  private _getTextImages(): Image[] {
    return this._record?.text
      ? this._record?.text
          .filter(
            item => item.type === 'images' && item.value?.isMainPhoto === false
          )
          .map(({ value }) => value as Image)
      : [];
  }

  private _getCardImages(): Image[] {
    return this._record?.isCardsTemplate
      ? getImagesFromRecordData(this._record?.cards, 'card')
      : [];
  }

  private _getPostImages(): Image[] {
    return this._record?.isBroadcast
      ? getImagesFromRecordData(this._record?.posts, 'post')
      : [];
  }

  public getRecordImages(): Image[] {
    return [
      ...this._getTextImages(),
      ...this._getCardImages(),
      ...this._getPostImages()
    ];
  }
}
