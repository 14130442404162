import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './default-page-header.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  render() {
    return <h1 class={styles.header}>{this.$slots.default}</h1>;
  }
});
