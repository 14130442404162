import { componentFactory } from 'vue-tsx-support';

export default componentFactory.create({
  render() {
    return (
      <svg
        width="31"
        height="31"
        viewBox="0 0 31 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.48246 8.38977H7.35833C6.52715 8.38977 6.12695 8.54399 6.12695 9.22257V9.99368C6.12695 10.6723 6.55794 10.8265 7.35833 10.8265H7.85089C8.15873 10.8265 8.28187 10.9807 8.28187 11.4742V21.5912C8.28187 22.424 8.43579 22.825 9.11305 22.825H9.91345C10.5907 22.825 10.7446 22.3931 10.7446 21.5912V9.22257C10.7446 8.54399 10.3136 8.38977 9.48246 8.38977Z"
          fill="currentColor"
        />
        <path
          d="M24.0735 1.35715C23.15 0.462667 22.0109 0 20.6256 0H10.2512C8.86594 0 7.69613 0.462667 6.80338 1.35715L1.32373 6.84746C0.461768 7.7111 0 8.85235 0 10.117V20.7274C0 22.1771 0.492552 23.3184 1.47766 24.2746L6.71102 29.5181C7.72691 30.536 8.89672 31.0295 10.3744 30.9986H20.6256C22.0417 30.9986 23.2115 30.5668 24.289 29.5181L29.5223 24.2746C30.4767 23.3184 31 22.1771 31 20.7274V10.1478C31 8.88319 30.5382 7.74195 29.6763 6.8783L24.0735 1.35715ZM28.8759 13.7566C28.8759 14.0959 28.7219 14.281 28.3833 14.281H26.8133V13.1706C26.8133 12.3378 26.6902 11.9368 25.9821 11.9368H25.3049C24.5968 11.9368 24.4737 12.3686 24.4737 13.1706V14.281H23.3654C22.5343 14.281 22.1341 14.4043 22.1341 15.1138V15.854C22.1341 16.5634 22.565 16.6868 23.3654 16.6868H24.4737V17.7972C24.4737 18.63 24.5968 19.031 25.3049 19.031H26.0129C26.721 19.031 26.8441 18.5992 26.8441 17.7972V16.6868H28.4141C28.7219 16.6868 28.8759 16.8411 28.9067 17.1495V20.7274C28.9067 21.5911 28.6604 22.2388 28.0447 22.8249L22.8113 28.0684C22.1956 28.6853 21.5184 28.9629 20.6872 28.9629H10.2205C9.38928 28.9629 8.71202 28.6853 8.09633 28.0684L2.86296 22.8249C2.24727 22.208 1.97021 21.5911 2.00099 20.7274V10.3637C2.00099 9.50008 2.27805 8.75981 2.92453 8.11208L8.25025 2.71431C8.77358 2.18995 9.48163 2.00489 10.2512 2.00489H20.6872C21.4568 2.00489 22.1341 2.15911 22.6882 2.71431L28.0139 8.11208C28.6296 8.75981 28.9374 9.50008 28.9374 10.3637V13.7566H28.8759Z"
          fill="currentColor"
        />
        <path
          d="M19.6719 15.3297C20.9956 14.3735 20.9956 13.3865 20.9956 12.5845V11.7825C20.8417 9.12993 19.9797 8.11206 16.9013 8.11206C13.8228 8.11206 12.9608 9.12993 12.8069 11.7825V12.5845C12.8069 13.3865 12.8377 14.3735 14.1306 15.3297C12.5606 15.9465 12.4375 17.2729 12.4375 19.031C12.4375 21.6219 13.361 22.9174 16.8705 22.9791C20.4107 22.9174 21.3034 21.6219 21.3034 19.031C21.365 17.3037 21.2419 15.9465 19.6719 15.3297ZM16.9013 10.302C18.1942 10.302 18.7175 10.7647 18.7175 12.3377C18.7175 13.8491 18.1942 14.3735 16.9013 14.3735C15.6083 14.3735 15.085 13.8491 15.085 12.3377C15.1158 10.7647 15.6083 10.302 16.9013 10.302ZM16.9013 20.7891C15.3928 20.7891 14.8695 20.2031 14.8695 18.7225C14.8695 17.242 15.3928 16.5634 16.9013 16.5634C18.4097 16.5634 18.933 17.242 18.933 18.7225C18.933 20.2031 18.4097 20.7891 16.9013 20.7891Z"
          fill="currentColor"
        />
      </svg>
    );
  }
});
