







import { Component, Prop, Vue } from 'vue-property-decorator';

import FntAdvLabel from '~icons/fnt-ui-common/adv-label';
import FntAdvLabelFrame from '~icons/fnt-ui-common/adv-label-frame';

@Component({
  name: 'UiAdvLabel',
  components: {
    FntAdvLabelFrame,
    FntAdvLabel
  }
})
export default class UiAdvLabel extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  hasFrame!: boolean;
}
