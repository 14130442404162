import { MentionsHttpClient } from '@fontanka/news-api-client';

import type { MentionRecord as MentionRecordAlias } from '../domain';
import { createMentionRecord } from '../domain';

type MentionRecord = MentionRecordAlias;

export class MentionRecordsDataMapper {
  private readonly _mentionsHttpClient: MentionsHttpClient;

  constructor(envType: string) {
    this._mentionsHttpClient = new MentionsHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getMentionRecords(
    page: number,
    pagesize: number
  ): Promise<MentionRecord[]> {
    let mentionRecords: MentionRecord[] = [];

    try {
      const { data } = await this._mentionsHttpClient.fetchMentionRecords({
        page,
        pagesize
      });

      mentionRecords = data?.map(record => createMentionRecord(record)) ?? [];
    } catch (err) {
      console.error(err);
    }

    return mentionRecords;
  }
}
