


















import { Component, Vue, Prop } from 'vue-property-decorator';

import AdvertLogoInformer from './advert-logo-informer.vue';

// Для утиной типизации без импортов из DTO
type HeaderAdvertLogoItem = {
  adInfo: string;
  adLabelEnabled: boolean;
  clickUrl: string;
  logoDesktop: string;
  logoMobile: string;
  signature: 'ADV' | 'INFO';
}

@Component({
  name: 'AdvertLogo',
  components: {
    AdvertLogoInformer
  }
})
export default class AdvertLogo extends Vue {
  @Prop({
    type: Object as () => HeaderAdvertLogoItem,
    required: true
  })
  data: HeaderAdvertLogoItem;

  @Prop({
    type: Boolean,
    required: true
  })
  isMobile: boolean;


  get clickUrl() {
    return this.data.clickUrl;
  }

  get logoUrl() {
    if (!this.isMobile && this.data.logoDesktop) {
      return this.data.logoDesktop;
    }

    return this.data.logoMobile;
  }

  get advLabelEnabled() {
    return this.data.adLabelEnabled;
  }

  get advInfo() {
    return this.data.adInfo;
  }

  get advMarkerText() {
    if (this.data.signature === 'ADV') {
      return 'Реклама';
    }

    if (this.data.signature === 'INFO') {
      return 'Информация';
    }

    return '';
  }

  handleAdvMarkClick(target: EventTarget | null) {
    const informer = (this.$refs.informer as InstanceType<typeof AdvertLogoInformer>);
    const parent = (target as HTMLElement)?.parentNode;
    if (informer && parent) {
      informer.toggleInformer(parent as HTMLElement);
    }
  };

  handleDocumentClick(e: MouseEvent) {
    const informer = (this.$refs.informer as InstanceType<typeof AdvertLogoInformer>);
    if (!informer?.isShown) {
      return;
    }

    const target = e.target as HTMLElement;

    if (!target.matches('[data-click-area]') && !target.closest('[data-informer]')) {
      informer.hideInformer();
    }
  };

  mounted() {
    document.addEventListener('mousedown', this.handleDocumentClick);
  }
  beforeDestroy() {
    document.removeEventListener('mousedown', this.handleDocumentClick);
  }
}
