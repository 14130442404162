import { Getter } from 'vuex-simple';

import { RootModule } from '../../../core';
import type { Header } from '../../domain';
import { HeaderMapper } from '../../services';

export const HEADER_MODULE_NAMESPACE = ['header_info_module'];

export class HeaderModule {
  // @Inject()
  // public headerMapper!: HeaderMapper;

  constructor(private _root: RootModule) {}

  @Getter()
  public get header(): Header {
    return HeaderMapper.toDO(
      this._root.pageSpec.config.header,
      this._root.pageSpec.data
    );
  }

  @Getter()
  public get rubrics() {
    return this.header.rubrics && this.header.rubrics.data
      ? this.header.rubrics.data
      : [];
  }

  @Getter()
  public get weatherInfo() {
    return this.header.weather && this.header.weather.data
      ? this.header.weather.data
      : null;
  }

  @Getter()
  public get weatherWidgetInfo() {
    return this.header.weatherWidget && this.header.weatherWidget.data
      ? this.header.weatherWidget.data
      : null;
  }

  @Getter()
  public get currenciesInfo() {
    return this.header.currency?.data || null;
  }

  @Getter()
  public get trafficWidget() {
    return this.header?.traffic?.data ?? null;
  }

  @Getter()
  public get socials() {
    return this.header?.socials?.data ?? [];
  }

  @Getter()
  public get mobileApps() {
    return this.header?.mobileApps?.data ?? [];
  }

  @Getter()
  public get advLogo() {
    return this.header?.advLogo?.data ?? null;
  }
}
