import type {
  DailyNewsPageDTO,
  DailyNewsPageDailyNewsDTO
} from '@fontanka/news-api-client';
import { SearchHttpClient } from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { BaseRecordMapper } from '../../../services';
import type { DailyNewsBlocks } from '../domain/daily-news';

type PageParams = {
  page: number;
  pagesize: number;
  period: number;
};

export class DailyNewsMapper {
  private readonly _searchHttpClient: SearchHttpClient;

  constructor(envType: string) {
    this._searchHttpClient = new SearchHttpClient({ envType, protocol: 'https' });
  }

  public static toDO(pageDTO: DailyNewsPageDTO): DailyNewsBlocks {
    const blocks: DailyNewsBlocks = [];

    return pageDTO.config.content.reduce((acc, current) => {
      const { data } = pageDTO.data[current.path];
      const { meta } = pageDTO.data[current.path];

      if (current.type === 'daily_news') {
        acc.push({
          type: current.type,
          total: meta?.total || 0,
          recordsCountPerPage:
            (data as DailyNewsPageDailyNewsDTO['data'])?.reduce(
              (count, archiveItem) => (count += archiveItem.records?.length || 0),
              0
            ) ?? 0,
          data:
            (data as DailyNewsPageDailyNewsDTO['data'])?.map(item => ({
              date: item.date,
              records:
                item.records?.map(record => BaseRecordMapper.toDO(record)) ?? []
            })) ?? []
        });
      }

      return acc;
    }, blocks);
  }

  public async getMoreNews(params: PageParams) {
    let newRecords: BaseRecord[] = [];
    const { page, pagesize, period } = params;

    try {
      const records = await this._searchHttpClient.searchRecords({
        page,
        pagesize,
        period
      });

      newRecords = records.data?.map(item => BaseRecordMapper.toDO(item)) ?? [];
    } catch (err) {
      console.error(err);
    }
    return newRecords;
  }
}
