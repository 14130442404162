import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './calendar.styles.scss';

type Day = {
  path?: string;
  day: string;
} | null;

type Week = Day[];

type Month = {
  name: string;
  weeks: Week[];
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    year: {
      type: String,
      required: true as const
    },
    data: {
      type: Array as () => Month[],
      required: true as const
    }
  },
  render() {
    const colspan = 7;

    return (
      <div class={styles.calendar}>
        <div class={styles.calendarTitle}>
          <span class={styles.year}>{this.year}</span>
        </div>

        <div class={styles.monthWrap}>
          {this.data.map(month => (
            <table class={styles.monthTable}>
              <tbody>
                <tr class={styles.monthRow}>
                  <th class={[styles.th, styles.monthName]} colspan={colspan}>
                    {month.name}
                  </th>
                </tr>

                <tr class={styles.monthRow}>
                  <th class={[styles.th, styles.weekDay]}>Пн</th>
                  <th class={[styles.th, styles.weekDay]}>Вт</th>
                  <th class={[styles.th, styles.weekDay]}>Ср</th>
                  <th class={[styles.th, styles.weekDay]}>Чт</th>
                  <th class={[styles.th, styles.weekDay]}>Пт</th>
                  <th class={[styles.th, styles.weekDay, styles.weekend]}>Сб</th>
                  <th class={[styles.th, styles.weekDay, styles.weekend]}>Вс</th>
                </tr>

                {month.weeks.map(week => (
                  <tr class={styles.monthRow}>
                    {week.map(day => {
                      if (day === null) {
                        return <td class={styles.td} domPropsInnerHTML="&nbsp;" />;
                      }
                      if (day.path) {
                        return (
                          <td class={[styles.td, styles.withLink]}>
                            <a class={styles.dayLink} href={day.path}>
                              {day.day}
                            </a>
                          </td>
                        );
                      }

                      return <td class={styles.td}>{day.day}</td>;
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
          ))}
        </div>
      </div>
    );
  }
});
