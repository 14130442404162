import {
  ImagesPollAttachDTO,
  ImagesPollResultsDTO
} from '@fontanka/news-api-client';
import { getSizeByRatioValues } from '@fontanka/cropping';
import { generateImageUrl, getCalculatedResult } from '@fontanka/news';
import {
  ImagesPollAnswer,
  ImagesPollAttach,
  ImagesPollResults
} from '../../../domain';
import { createImage } from '../image-mapper.service';

const MAX_IMAGE_WIDTH = 333;
const MAX_IMAGE_HEIGHT = 300;

export const createImagesPollAttach = (
  attach: ImagesPollAttachDTO
): ImagesPollAttach => ({
  type: 'imagesPoll',
  value: {
    pollId: attach.value.id,
    answers: attach.value.answers.map(answerDTO => {
      const image = createImage(answerDTO.image, {
        ...getSizeByRatioValues({
          imageWidth: answerDTO.image.width,
          imageHeight: answerDTO.image.height,
          maxImageWidth: MAX_IMAGE_WIDTH,
          maxImageHeight: MAX_IMAGE_HEIGHT,
          ratio: attach.value.settings.ratio.x / attach.value.settings.ratio.y
        }),
        crop: true
      });

      return {
        answer: answerDTO.id,
        content: answerDTO.resultHeader || '',
        calculatedResult: attach.value.settings.isNumber ? '0' : '0%',
        description: answerDTO.description,
        image: image
          ? {
              ...image,
              originUrl: generateImageUrl(answerDTO.image)
            }
          : null,
        lineProgressWidth: '0%',
        link: answerDTO.link || '',
        isUserAnswer: false,
        totalVotes: 0
      };
    }),
    dateEnd: attach.value.settings.dateEnd,
    hasResultsBlock: false,
    hasUserAnswers: false,
    isActive: attach.value.settings.isActive,
    isNumber: attach.value.settings.isNumber,
    isMultiple: attach.value.settings.multipleChoice,
    isResultsHidden: attach.value.settings.hideResults,
    needAuth: attach.value.settings.onlyAuthorized,
    needShowResults: false,
    needShowResultsBlock: attach.value.settings.showResultsBlock,
    title: attach.value.header
  }
});

export const createAttachImagesPollResults = (
  pollResultsDTO: ImagesPollResultsDTO['data']
): ImagesPollResults => ({
  answers: pollResultsDTO.answers,
  votersTotal: pollResultsDTO.votedUsersCount
});

export const createImagesPollAnswer = (
  answer: ImagesPollAnswer,
  isNumber: boolean,
  pollResults: ImagesPollResults,
  totalVotes: number
): ImagesPollAnswer => {
  const currentAnswer = pollResults.answers.find(
    resultsAnswer => resultsAnswer.id === answer.answer
  );

  const currentTotalVotes = currentAnswer?.votesCount || 0;

  const calculatedResult = currentTotalVotes
    ? getCalculatedResult(currentTotalVotes, totalVotes, isNumber)
    : {
        calculatedResult: answer.calculatedResult,
        lineProgressWidth: answer.lineProgressWidth
      };

  return {
    ...answer,
    ...calculatedResult,
    isUserAnswer: currentAnswer?.isVoted || false,
    totalVotes: currentTotalVotes
  };
};

export const createImagesPollValue = (
  imagesPollValue: ImagesPollAttach['value'],
  pollResults: ImagesPollResults
): ImagesPollAttach['value'] => {
  const hasUserAnswers = !!pollResults.answers.find(answer => answer.isVoted);

  const totalVotes =
    imagesPollValue.isMultiple && pollResults.votersTotal
      ? pollResults.answers.reduce((acc, { votesCount }) => acc + votesCount, 0)
      : pollResults.votersTotal;

  return {
    ...imagesPollValue,
    answers: imagesPollValue.answers.map(answer =>
      createImagesPollAnswer(
        answer,
        imagesPollValue.isNumber,
        pollResults,
        totalVotes
      )
    ),
    hasUserAnswers,
    hasResultsBlock:
      imagesPollValue.needShowResultsBlock &&
      ((!imagesPollValue.isResultsHidden && hasUserAnswers) ||
        !imagesPollValue.isActive),
    needShowResults:
      (!imagesPollValue.isResultsHidden &&
        (hasUserAnswers || imagesPollValue.isMultiple)) ||
      !imagesPollValue.isActive
  };
};
