

















































































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { UiAdvLabel } from '../ui-adv-label';
import { UiButton } from '../ui-button';
import { UiImage } from '../ui-image';
import { UiLabel } from '../ui-label';

import FntChevronLeftIcon from '~icons/fnt-ui-common/chevron-left';

import { UiObserveVisibility } from '../ui-observe-visibility';

import FntChevronRightIcon from '~icons/fnt-ui-common/chevron-right';

import { type SliderOptions, UiSlider, type UiSliderComponent } from '../ui-slider';
import { UiStatistic } from '../ui-statistic';

import type {
  UiNewsSliderMobileEmitValue,
  UiNewsSliderMobileRecords,
  UiNewsSliderRecord,
  UiNewsSliderMobileTheme
} from './ui-news-slider-mobile.contract';

@Component({
  name: 'UiNewsSliderMobile',
  components: {
    UiSlider,
    UiLabel,
    UiObserveVisibility,
    UiImage,
    UiStatistic,
    UiButton,
    FntChevronLeftIcon,
    FntChevronRightIcon,
    UiAdvLabel
  }
})
export default class UiNewsSliderMobile extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  title!: string;
  @Prop({
    type: String,
    default: ''
  })
  link!: string;

  @Prop({
    type: Array,
    required: true
  })
  records!: UiNewsSliderMobileRecords;

  @Prop({
    type: Object,
    required: true
  })
  mainNews!: UiNewsSliderRecord;

  @Prop({
    type: Boolean,
    default: false
  })
  isDark!: boolean;

  @Prop({
    type: Boolean,
    default: true
  })
  isMobile: boolean;

  @Prop({
    type: String as () => UiNewsSliderMobileTheme,
    default: 'fontankaPro'
  })
  theme: string;

  get sliderOptions(): SliderOptions {
    return {
      wrapAround: true,
      initialIndex: 0,
      cellAlign: this.isMobile ? 'left' : 'center'
    };
  }

  @Emit('commentClicked')
  commentClicked(event: UiNewsSliderMobileEmitValue): UiNewsSliderMobileEmitValue {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(event: UiNewsSliderMobileEmitValue): UiNewsSliderMobileEmitValue {
    return event;
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }

  @Emit('articleShown')
  articleShown(event: UiNewsSliderMobileEmitValue): UiNewsSliderMobileEmitValue {
    return event;
  }

  @Emit('headerClicked')
  headerClicked(event: UiNewsSliderMobileEmitValue): UiNewsSliderMobileEmitValue {
    return event;
  }

  prevSlide() {
    (this.$refs.slider as UiSliderComponent)?.prevSlide();
  }

  nextSlide() {
    (this.$refs.slider as UiSliderComponent)?.nextSlide();
  }
}
