import { SpoilerAttachDTO } from '@fontanka/news-api-client';
import { SpoilerAttach } from '../../../domain';

export const createSpoilerAttach = (attach: SpoilerAttachDTO): SpoilerAttach => ({
  type: 'spoiler',
  value: {
    title: attach.value.title,
    content: attach.value.content
  }
});
