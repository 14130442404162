import axios, { AxiosStatic, AxiosInstance } from 'axios';
import http from 'http';
import https from 'https';
import { Headers } from '@fontanka/data';
import { isBrowser } from '@fontanka/browser-or-node';
import { HttpStatusCode } from '@fontanka/http-status-code';

export type Config = {
  acceptEncoding?: string;
  enableKeepAlive?: boolean;
  envType?: string;
  protocol?: 'http' | 'https';
};

export type PageResult<R> = {
  result: R;
  status: HttpStatusCode;
};

export type Result<T> = {
  status?: HttpStatusCode;
  data: T;
  headers: Headers;
};

const DEFAULT_HOST = 'newsapi.fontanka.ru';

export class BaseHttpClient {
  public get envType(): string {
    if (this._config.envType !== undefined) {
      return this._config.envType;
    }

    return '';
  }

  public get protocol(): 'http' | 'https' {
    if (this._config.protocol !== undefined) {
      return this._config.protocol;
    }

    return 'http';
  }

  public get host() {
    return `${this.protocol}://${this._apiHost}${this.envType}/${this._version}`;
  }

  public get httpClient() {
    return this._httpClient;
  }

  public get regionId() {
    return this._regionId;
  }

  public get accept() {
    return this._accept;
  }

  private readonly _config: Config;

  private readonly _httpClient: AxiosStatic | AxiosInstance;

  private readonly _defaultAcceptEncoding = 'gzip';

  private readonly _regionId = 478;

  private readonly _apiHost: string = DEFAULT_HOST;

  private readonly _version = 'v1';

  private readonly _accept = 'vnd.news.v1.fontanka+json';

  /**
   * cap the maximum content length we'll accept to 50MBs, just in case
   */
  private readonly _maxContentLength: number = 50000000;

  /**
   * follow up to 10 HTTP 3xx redirects
   */
  private readonly _maxRedirects: number = 10;

  /**
   * 10 sec timeout
   */
  private readonly _timeout: number = 10000;

  constructor(config: Config, apiHost: string = DEFAULT_HOST) {
    this._config = config;
    this._apiHost = apiHost;
    this._httpClient = this._setupHttpClient();
  }

  public updateEnvType(envType: string) {
    this._config.envType = envType;
  }

  private _setupHttpClient(): AxiosStatic | AxiosInstance {
    if (this._config.acceptEncoding !== undefined) {
      axios.defaults.headers.get['Accept-Encoding'] = this._config.acceptEncoding;
    } else if (!isBrowser()) {
      axios.defaults.headers.get['Accept-Encoding'] = this._defaultAcceptEncoding;
    }

    if (this._config.enableKeepAlive !== false) {
      const client = axios.create({
        timeout: this._timeout,

        httpAgent: new http.Agent({ keepAlive: true }),
        httpsAgent: new https.Agent({ keepAlive: true }),

        maxRedirects: this._maxRedirects,

        maxContentLength: this._maxContentLength
      });

      return client;
    }

    return axios;
  }
}
