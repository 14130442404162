import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { ObserverVisibility } from '@fontanka/observe-visibility';
import rubricsImg from 'images/allrubrics.png';

import styles from './rubrics-list.styles.scss';

type Rubric = {
  readonly name: string;
  readonly path: string;
};

type Events = {
  onRubricListViewed: void;
  onRubricListClicked: string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      rubrics: {
        type: Array as () => Rubric[],
        required: true as const
      }
    },
    data() {
      return {
        rubricsListVisibility: new ObserverVisibility('half')
      };
    },
    mounted() {
      this.rubricsListVisibility.event.subscribe(state => {
        if (state.value === true) {
          this.$emit('rubricListViewed');
        }
      });
    },
    beforeDestroy() {
      this.rubricsListVisibility.destroy();
    },
    methods: {
      emitRubricClicked(rubricName: string) {
        this.$emit('rubricListClicked', rubricName);
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.rubricsListVisibility.getOptions('half')}
          class={styles.container}
        >
          <div class={[styles.item, styles.first]}>
            <img src={rubricsImg} alt="" />
          </div>

          <ul class={styles.list}>
            {this.rubrics.map(rubric => (
              <li class={styles.item}>
                <a
                  class={styles.rubricLink}
                  href={rubric.path}
                  onClick={(): void => this.emitRubricClicked(rubric.name)}
                >
                  {rubric.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  });
