import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { ActionResultPage } from '../domain';
import { ActionResultPageMapper } from '../services';

export const ACTION_RESULT_PAGE_MODULE_NAMESPACE = ['action_result_page_module'];

export class ActionResultPageModule {
  @State()
  public data: ActionResultPage['data'];

  constructor(private _root: RootModule) {
    const { data } = ActionResultPageMapper.toDO(this._root.pageSpec);

    this.data = data;
  }
}
