import { Service } from 'vue-typedi';

import { WidgetAutoHttpClient } from '@fontanka/news-api-client';

import type { WidgetAutoSliderOffer } from '../domain';

import { WidgetAutoMapper } from './widget-auto-mapper.service';

type Config = {
  envType?: string;
  protocol?: 'http' | 'https';
};

type WidgetAutoParams = {
  count: number;
};

@Service()
export class WidgetAutoDataMapperService {
  private _widgetAutoHttpClient: WidgetAutoHttpClient;

  constructor(private _config: Config) {
    this._widgetAutoHttpClient = new WidgetAutoHttpClient(this._config);
  }

  public async fetchWidgetAutoOffers(
    params: WidgetAutoParams
  ): Promise<WidgetAutoSliderOffer[]> {
    const offers = await this._widgetAutoHttpClient.fetchWidgetAutoOffers(params);
    return WidgetAutoMapper.toSliderDO(offers);
  }
}
