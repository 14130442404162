






























import { guardUnspecified } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiAdvLabel } from '../../ui-adv-label';
import { UiImage } from '../../ui-image';
import { UiLabel } from '../../ui-label';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  NewsVerticalImage,
  NewsVerticalStatistic,
  NewsVerticalLabel
} from './ui-news-vertical.contract';

@Component({
  components: {
    UiLabel,
    UiStatistic,
    UiObserveVisibility,
    UiImage,
    UiNewsCardLabels,
    UiAdvLabel
  },
  name: 'UiNewsVertical'
})
export default class UiNewsVertical extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsVerticalImage,
    required: true
  })
  image!: NewsVerticalImage;

  @Prop({
    type: Object as () => NewsVerticalStatistic,
    required: true
  })
  statistic!: NewsVerticalStatistic;

  @Prop({
    type: Object as () => NewsVerticalLabel,
    default: null
  })
  label: NewsVerticalLabel;

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasCommercialLabel: boolean;

  get hasLabel(): boolean {
    return guardUnspecified(this.label);
  }

  get hasImage() {
    return guardUnspecified(this.image);
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(place: string): string {
    return place;
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }
}
