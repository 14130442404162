/* eslint-disable import/exports-last */
export const CLASSIC_RATIO_WIDTH = 4;
export const CLASSIC_RATIO_HEIGHT = 3;
export const WIDE_RATIO_WIDTH = 16;
export const WIDE_RATIO_HEIGHT = 9;
export const FULL_FRAME_RATIO_WIDTH = 3;
export const FULL_FRAME_RATIO_HEIGHT = 2;

export const CLASSIC_ASPECT_RATIO = CLASSIC_RATIO_WIDTH / CLASSIC_RATIO_HEIGHT;
export const WIDE_ASPECT_RATIO = WIDE_RATIO_WIDTH / WIDE_RATIO_HEIGHT;
export const FULL_FRAME_ASPECT_RATIO =
  FULL_FRAME_RATIO_WIDTH / FULL_FRAME_RATIO_HEIGHT;
export const SQUARE_ASPECT_RATIO = 1;

export enum CropType {
  /**
   * 4 / 3
   */
  Classic = 1,
  /**
   * 16 / 9
   */
  Wide = 2,
  /**
   * 3 / 2
   */
  FullFrame = 3,
  /**
   * 1 / 1
   */
  Square = 4
}

const CROP_TYPE_TO_VALUE: Record<CropType, number> = {
  [CropType.Classic]: CLASSIC_ASPECT_RATIO,
  [CropType.Wide]: WIDE_ASPECT_RATIO,
  [CropType.FullFrame]: FULL_FRAME_ASPECT_RATIO,
  [CropType.Square]: SQUARE_ASPECT_RATIO
};

type SizeForCroppingParams = {
  cropType: CropType;
  /**
   * Оригинальная ширина изображения
   */
  width: number;
  /**
   * Оригинальная высота изображения
   */
  height: number;
};

type SizeForCroppingResult = {
  width: number;
  height: number;
};

export const getSizeForCropping = (
  params: SizeForCroppingParams
): SizeForCroppingResult => {
  const { cropType, width, height } = params;
  const aspectRatio = CROP_TYPE_TO_VALUE[cropType];
  const imageRatio = width / height;

  if (imageRatio > aspectRatio) {
    return {
      width: Math.round(height * aspectRatio),
      height
    };
  }

  return {
    width,
    height: Math.round(width / aspectRatio)
  };
};

type CroppedHeightByWidthParams = {
  cropType: CropType;
  width: number;
};

export const getCroppedHeightByWidth = (
  params: CroppedHeightByWidthParams
): number => {
  const { cropType, width } = params;
  const aspectRatio = CROP_TYPE_TO_VALUE[cropType];

  return Math.round(width / aspectRatio);
};

type SizeByRatioValuesParams = {
  ratio: number;
  imageWidth: number;
  imageHeight: number;
  maxImageWidth: number;
  maxImageHeight: number;
};

type SizeByRatioValuesResult = {
  width: number;
  height: number;
};

export const getSizeByRatioValues = (
  params: SizeByRatioValuesParams
): SizeByRatioValuesResult => {
  const { ratio, imageWidth, imageHeight, maxImageWidth, maxImageHeight } = params;
  let width = imageWidth;
  let height = imageHeight;
  let maxWidth = maxImageWidth;
  let maxHeight = maxImageHeight;

  if (ratio < width / height) {
    width = Math.round(height * ratio);
  } else {
    height = Math.round(width / ratio);
  }

  if (ratio < maxWidth / maxHeight) {
    maxWidth = Math.round(maxHeight * ratio);
  } else {
    maxHeight = Math.round(maxWidth / ratio);
  }

  return width > maxWidth && height > maxHeight
    ? { width: maxWidth, height: maxHeight }
    : { width, height };
};
