import { componentFactory } from 'vue-tsx-support';

import type { RubricRecord } from '../../_items';
import { RubricRecordItem } from '../../_items';

type RecordInfo = {
  recordId: number;
  isCommercial: boolean;
};

export default componentFactory.create({
  name: 'RubricRecordsList',
  props: {
    records: {
      type: Array as () => RubricRecord[],
      required: true as const
    }
  },
  methods: {
    emitCommentsClicked(recordInfo: RecordInfo, place: number): void {
      this.$emit('commentsClicked', {
        ...recordInfo,
        place
      });
    },
    emitHeaderClicked(recordInfo: RecordInfo, place: number): void {
      this.$emit('headerClicked', {
        ...recordInfo,
        place
      });
    },
    emitViewRecord(recordInfo: RecordInfo): void {
      this.$emit('viewRecord', recordInfo);
    }
  },
  render() {
    return (
      <div>
        {this.records.map((record, index) => (
          <RubricRecordItem
            record={record}
            onCommentsClicked={(event: RecordInfo) =>
              this.emitCommentsClicked(event, index + 1)
            }
            onHeaderClicked={(event: RecordInfo) =>
              this.emitHeaderClicked(event, index + 1)
            }
            onViewRecord={this.emitViewRecord}
          />
        ))}
      </div>
    );
  }
});
