import { guardUnspecified } from '@smh/utils/guards';
import { AxiosRequestConfig } from 'axios';

import { isBrowser } from '@fontanka/browser-or-node';

import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { ProfileDTO, ApiErrorDTO } from '../dtos';
import { BaseParams } from '../params';
import { SearchResult } from '../search-http-client';

import { EditProfilePageDTO } from './edit-profile-page-dto';
import { ProfilePageDTO, ProfilePageUserCommentDTO } from './profile-page-dto';

export type ProfilePageParams = BaseParams & {
  profileId: number;
  page: number;
  pagesize: number;
};

export type FetchUserCommentsParams = Pick<
  ProfilePageParams,
  Exclude<keyof ProfilePageParams, 'headers'>
>;

export type UpdateProfileParams = {
  nick: string;
  profileId: number;
  avatar?: string;
  newAvatarFile?: File;
  sex: 'male' | 'female' | 'unknown';
  birthday?: string;
  about?: string;
  captchaToken: string;
};

export type RegisterProfileParams = {
  nick: string;
  login: string;
  password: string;
  captchaToken: string;
};

export type RegisterResult = {
  confirmed: boolean;
  userId: number;
};

export type ConfirmRegisterProfileParams = {
  login: string;
  code: string;
};

export type RestorePasswordParams = {
  login: string;
};

export type ConfirmRestorePasswordParams = {
  login: string;
  password: string;
  code: string;
};

export type ChangeProfilePasswordParams = {
  oldPassword: string;
  newPassword: string;
};

export class ProfileHttpClient extends BaseHttpClient {
  private readonly _profilePageUrl = '/pages/fontanka/profile';

  private readonly _editProfilePageUrl = '/pages/fontanka/profile/edit';

  private readonly _fetchProfileCommentsUrl =
    '/public/fontanka/services/profile/comments/';

  private readonly _updateProfileUrl = '/public/users/';

  private readonly _registerProfileUrl = '/public/fontanka/users/account/register';

  private readonly _confirmRegisterProfileUrl = '/public/account/confirm';

  private readonly _restorePasswordUrl = '/public/account/restore/resend';

  private readonly _confirmRestorePasswordUrl = '/public/account/restore';

  private readonly _changePasswordUrl = '/public/account/password';

  constructor(config: Config) {
    super(config);
  }

  public async fetchProfilePageData(
    params: ProfilePageParams
  ): Promise<Result<ProfilePageDTO>> {
    const { headers, profileId, page, pagesize } = params;
    const url = `${this.host}${this._profilePageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<ProfilePageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        profileId,
        page,
        pagesize
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchUserComments(
    params: FetchUserCommentsParams
  ): Promise<SearchResult<ProfilePageUserCommentDTO[] | null>> {
    const { profileId, page, pagesize } = params;
    const url = `${this.host}${this._fetchProfileCommentsUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<
      SearchResult<ProfilePageUserCommentDTO[] | null>
    >(url, {
      withCredentials,
      params: {
        regionId: this.regionId,
        profileId,
        page,
        pagesize
      }
    });

    return response.data;
  }

  public async fetchEditProfilePageData(
    params: BaseParams
  ): Promise<Result<EditProfilePageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._editProfilePageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<EditProfilePageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async updateProfile(
    params: UpdateProfileParams
  ): Promise<ProfileDTO | null> {
    const {
      nick,
      profileId,
      sex,
      about,
      avatar,
      newAvatarFile,
      birthday,
      captchaToken
    } = params;
    const url = `${this.host}${this._updateProfileUrl}${profileId}/profile`;
    const withCredentials = true;
    let result = null;

    const formData = new FormData();
    formData.append('regionId', this.regionId.toString());
    formData.append('nick', nick);
    formData.append('sex', sex);
    formData.append('about', about ?? '');
    formData.append('avatar', avatar ?? '');
    if (guardUnspecified(newAvatarFile)) {
      formData.append('newAvatarFile', newAvatarFile);
    }
    formData.append('birthday', birthday ?? '');
    formData.append('captchaToken', captchaToken);

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'POST',
      headers: {
        accept: this.accept
      },
      url,
      params: {
        regionId: this.regionId
      },
      data: formData
    };

    try {
      const response = await this.httpClient(options);
      result = response.data;
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw ex;
    }

    return result;
  }

  public async registerProfile(
    params: RegisterProfileParams
  ): Promise<RegisterResult | null> {
    const { nick, login, password, captchaToken } = params;
    const url = `${this.host}${this._registerProfileUrl}`;
    const withCredentials = true;
    let result = null;

    const formData = new FormData();
    formData.append('regionId', this.regionId.toString());
    formData.append('nick', nick);
    formData.append('login', login);
    formData.append('password', password);
    formData.append('captchaToken', captchaToken);

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'POST',
      headers: {
        accept: this.accept
      },
      url,
      params: {
        regionId: this.regionId
      },
      data: formData
    };

    try {
      const response = await this.httpClient(options);
      result = response.data;
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw ex;
    }

    return result;
  }

  public async confirmRegistrationProfile(
    params: ConfirmRegisterProfileParams
  ): Promise<null> {
    const { login, code } = params;
    const url = `${this.host}${this._confirmRegisterProfileUrl}`;
    const withCredentials = true;

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'PATCH',
      headers: {
        accept: this.accept
      },
      url,
      params: {
        regionId: this.regionId
      },
      data: {
        login,
        code
      }
    };

    try {
      await this.httpClient(options);
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw new Error(ex.detail);
    }

    return null;
  }

  public async restorePassword(params: RestorePasswordParams): Promise<null> {
    const { login } = params;
    const url = `${this.host}${this._restorePasswordUrl}`;
    const withCredentials = true;

    const formData = new FormData();
    formData.append('login', login);

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'POST',
      headers: {
        accept: this.accept
      },
      url,
      params: {
        regionId: this.regionId
      },
      data: formData
    };

    try {
      await this.httpClient(options);
    } catch (err) {
      if (err.response && err.response.data) {
        const ex: ApiErrorDTO = err.response.data;
        throw new Error(ex.detail);
      }

      throw new Error(
        'При восстановлении пароля возникли проблемы. Попробуйте позже.'
      );
    }

    return null;
  }

  public async confirmRestorePassword(
    params: ConfirmRestorePasswordParams
  ): Promise<null> {
    const { code, login, password } = params;
    const url = `${this.host}${this._confirmRestorePasswordUrl}`;
    const withCredentials = true;

    const formData = new FormData();
    formData.append('login', login);
    formData.append('password', password);
    formData.append('code', code);

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'POST',
      headers: {
        accept: this.accept
      },
      url,
      params: {
        regionId: this.regionId
      },
      data: formData
    };

    try {
      await this.httpClient(options);
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw new Error(ex.detail);
    }

    return null;
  }

  public async changeProfilePassword(
    params: ChangeProfilePasswordParams
  ): Promise<null> {
    const { oldPassword, newPassword } = params;
    const url = `${this.host}${this._changePasswordUrl}`;
    const withCredentials = true;

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'PATCH',
      headers: {
        accept: this.accept
      },
      url,
      params: {
        regionId: this.regionId
      },
      data: {
        oldPassword,
        newPassword
      }
    };

    try {
      await this.httpClient(options);
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw new Error(ex.detail);
    }

    return null;
  }
}
