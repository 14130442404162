import fileSize from 'file-size';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import clipIcon from 'images/actual/clip.svg';
import removeIcon from 'images/actual/remove.svg';

import { ErrorMsg } from '../error-msg';

import styles from './file-uploader.styles.scss';

type Data = {
  files: File[];
};

type Events = {
  onErrorOccurred: boolean;
  onFilesChanged: File[];
};

const BYTES_IN_KB = 1024;
const KB_IN_MB = 1024;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      title: {
        type: String,
        default: ''
      },
      extensions: {
        type: Array as () => string[],
        required: true as const
      },
      accept: {
        type: String,
        default: 'image/*'
      },
      maxSize: {
        type: Number,
        // размер в мегабайтах
        default: 15
      },
      errorText: {
        type: String,
        required: true as const
      }
    },
    data(): Data {
      return {
        files: []
      };
    },
    computed: {
      humanExtensionsString(): string {
        return this.extensions.reduce((acc, current, index) => {
          if (index !== 0 && index < this.extensions.length) {
            acc = acc + ' / ';
          }

          acc = acc + current;

          return acc;
        }, '');
      },
      subtitle(): string {
        return `${this.humanExtensionsString}, ДО ${this.maxSize} МБ`;
      },
      maxSizeInBytes(): number {
        return this.maxSize * BYTES_IN_KB * KB_IN_MB;
      },
      totalFilesSize(): number {
        return this.files.reduce((acc, current) => (acc = acc + current.size), 0);
      },
      hasError(): boolean {
        return this.totalFilesSize > this.maxSizeInBytes;
      }
    },
    watch: {
      files() {
        this.$emit('filesChanged', this.files);
        this.$emit('errorOccurred', this.hasError);
      }
    },
    methods: {
      addFiles(event: MouseEvent) {
        event.preventDefault();
        (this.$refs['fileInput'] as HTMLInputElement).click();
      },
      changeFiles() {
        const fileInput = this.$refs['fileInput'] as HTMLInputElement;
        if (fileInput.files !== null) {
          this.files = [...this.files, ...Array.from(fileInput.files)];
        }
      },
      removeFile(fileIndex: number) {
        this.files = this.files.filter((file, index) => fileIndex !== index);
      },
      getHumanFileSize(size: number) {
        return fileSize(size)
          .human('jedec')
          .replace(
            /(KB)|(MB)/,
            m =>
              ({
                B: 'Б',
                KB: 'КБ',
                MB: 'МБ'
              }[m] as string)
          );
      }
    },
    render() {
      return (
        <div class={styles.fileUploader}>
          <input
            ref="fileInput"
            type="file"
            accept={this.accept}
            multiple
            hidden
            onChange={this.changeFiles}
          />

          {this.files.length > 0 ? (
            <ul class={styles.filesList}>
              {this.files.map((file, index) => (
                <li class={styles.fileItem}>
                  <span class={styles.fileName}>{file.name}</span>
                  <span class={styles.fileSize}>
                    {this.getHumanFileSize(file.size)}
                  </span>
                  <img
                    class={styles.removeIcon}
                    src={removeIcon}
                    onClick={() => this.removeFile(index)}
                  />
                </li>
              ))}
            </ul>
          ) : null}

          <a class={styles.link} onClick={this.addFiles}>
            <img class={styles.clipIcon} src={clipIcon} alt="" />

            <div class={styles.textWrapper}>
              {this.title ? <span class={styles.title}>{this.title}</span> : null}
              <span class={styles.subtitle}>{this.subtitle}</span>
            </div>
          </a>

          {this.hasError ? (
            <ErrorMsg class={styles.error}>{this.errorText}</ErrorMsg>
          ) : null}
        </div>
      );
    }
  });
