export const SUBSCRIPTION_ROUTING = [
  {
    path: '/subscription',
    name: 'subscription',
    component: () =>
      import(
        /* webpackChunkName: "subscription-page" */ './pages/subscription/subscription.page'
      )
  },
  {
    path: '/subscription_promo',
    component: () =>
      import(
        /* webpackChunkName: "subscription-promo-page" */ './pages/subscription-promo/subscription-promo.page'
      )
  }
];
