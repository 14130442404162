type MainNewsMap = {
  [key: string]: {
    text: string;
    position: number;
  };
};

export type MainNews = {
  id: string;
  text: string;
  position: number;
  data: {
    id: number;
    header: string;
    subheader: string;
    urls: {
      url: string;
    };
    hasMainPhotoCommercialLabel: boolean;
  }[];
}[];

export const MAIN_NEWS_IDS_MAP: MainNewsMap = {
  picture_of_the_day: {
    text: 'Картина дня',
    position: 0
  },
  readers_choice: {
    text: 'Выбор читателей',
    position: 1
  },
  suggestions: {
    text: 'Рекомендуем',
    position: 2
  }
};
