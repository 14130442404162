import { CustomWidgetAttachDTO } from '@fontanka/news-api-client';
import { CustomWidgetAttach } from '../../../domain';
import { extractScriptsInfoFromHtmlStr } from '@fontanka/html';
import { getSocialTypeByUrl } from '@fontanka/socials';

export const createCustomWidgetAttach = (
  attach: CustomWidgetAttachDTO
): CustomWidgetAttach => {
  const sourceScript = attach.value ? attach.value.script : '';

  let scriptsInfo = [];
  try {
    scriptsInfo = extractScriptsInfoFromHtmlStr(decodeURI(sourceScript));
  } catch (err) {
    scriptsInfo = extractScriptsInfoFromHtmlStr(sourceScript);
  }

  let src = '';
  let script = '';
  // TODO: исправить этот костыль
  scriptsInfo.map(scriptInfo => {
    if (scriptInfo.src !== '') {
      src = scriptInfo.src;
    }

    if (scriptInfo.content !== '') {
      script = scriptInfo.content;
    }
  });

  return {
    type: 'customWidget',
    value: {
      content: attach.media.content,
      script,
      src,
      socialType: getSocialTypeByUrl(src)
    }
  };
};
