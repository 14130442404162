import { guardUnspecified } from '@smh/utils/guards';
import { Service, Token } from 'vue-typedi';

type BrowserPermission = 'default' | 'granted' | 'denied';

type NewPermissionEvent = { to: BrowserPermission };

type OSEventParam = NewPermissionEvent | boolean | undefined;

type OneSignalCb<T = undefined> = (data: T) => void;

export enum PopoverActions {
  ALLOW = 'allow',
  DISALLOW = 'disallow'
}

export type OneSignalParams = {
  appId: string;
  segment: string;
};

export type OneSignalPromptConfig = {
  type: 'push';
  autoPrompt: boolean;
  text: {
    acceptButton: string;
    cancelButton: string;
    actionMessage: string;
  };
};

export type OneSignalInitConfig = {
  appId: string;
  welcomeNotification: {
    title: string;
    message: string;
  };
  promptOptions: {
    slidedown: {
      prompts: OneSignalPromptConfig[];
    };
  };
};

export const ONE_SIGNAL_SERVICE = new Token('one-signal-service');

@Service(ONE_SIGNAL_SERVICE)
export class OneSignalService {
  get oneSignal() {
    window.OneSignal = window.OneSignal || [];
    return window.OneSignal;
  }

  public init(
    params: OneSignalParams,
    isMobile: boolean,
    analiticsCb?: (e: string) => void
  ): void {
    const config: OneSignalInitConfig = {
      appId: params.appId,
      welcomeNotification: {
        title: 'Спасибо за подписку на новости!',
        message: 'Теперь вы не пропустите главное'
      },
      promptOptions: {
        slidedown: {
          prompts: [
            {
              type: 'push',
              autoPrompt: true,
              text: {
                acceptButton: 'Включить',
                cancelButton: 'Не сейчас',
                actionMessage:
                  'Включите уведомления, чтобы не пропустить важные новости'
              }
            }
          ]
        }
      }
    };

    this.oneSignal.push(() => {
      this.oneSignal.init(config);
      this._sendTag(params.segment);
    });

    this._subscribePopoverShown(() => {
      if (guardUnspecified(analiticsCb)) {
        analiticsCb('notificationPopoverShown');
      }

      const globalWrapperEl = document.querySelector('#onesignal-promt-target');

      if (!guardUnspecified(globalWrapperEl)) {
        return;
      }

      const topCoords = globalWrapperEl.getBoundingClientRect().top;

      const oneSignalPromptEl = document.querySelector(
        '#onesignal-slidedown-container'
      );
      (oneSignalPromptEl as HTMLElement).style.zIndex = '2147483644';

      if (!isMobile && topCoords > 0) {
        setTimeout(() => {
          if (guardUnspecified(oneSignalPromptEl)) {
            (oneSignalPromptEl as HTMLElement).style.top = `${topCoords}px`;
          }
        });
      }

      if (isMobile) {
        const NTIFICATION_CLOSE_TIMEOUT = 8000;

        setTimeout(() => {
          this._closePopover();
        }, NTIFICATION_CLOSE_TIMEOUT);
      }
    });

    if (guardUnspecified(analiticsCb)) {
      this._subscribePopoverActions(event => {
        switch (event) {
          case PopoverActions.ALLOW:
            analiticsCb('notificationPopoverAllowed');
            break;
          case PopoverActions.DISALLOW:
            analiticsCb('notificationPopoverDisallowed');
            break;
        }
      });

      this._subscribeToBrowserNotificationsDeniedChange((value?: boolean) => {
        analiticsCb(
          value
            ? 'browserNotificationPermissionDenied'
            : 'browserNotificationPermissionGranted'
        );
        this._unsubscribeToBrowserNotificationsDeniedChange();
      });
    }
  }

  private _subscribePopoverActions(callback: (event: PopoverActions) => void): void {
    this._subscribePopoverAllow(() => {
      callback(PopoverActions.ALLOW);
    });
    this._subscribePopoverDisallow(() => {
      callback(PopoverActions.DISALLOW);
    });
  }

  private _subscribePopoverShown(cb: OneSignalCb) {
    this.oneSignal.push(() => {
      this.oneSignal.on('popoverShown', cb);
    });
  }

  private _closePopover(): void {
    this.oneSignal.slidedown.close();
  }

  private _subscribeToBrowserNotificationsDeniedChange(
    cb: OneSignalCb<boolean>
  ): void {
    this.oneSignal.push(() => {
      this.oneSignal.on(
        'notificationPermissionChange',
        (newPermission: OSEventParam) => {
          const permissionValue =
            (newPermission as NewPermissionEvent)?.to || 'default';
          cb(permissionValue === 'denied');
        }
      );
    });
  }

  private _unsubscribeToBrowserNotificationsDeniedChange() {
    this.oneSignal.push(() => {
      this.oneSignal.off('notificationPermissionChange');
    });
  }

  private _subscribePopoverAllow(cb: OneSignalCb) {
    this.oneSignal.push(() => {
      this.oneSignal.on('popoverAllowClick', cb);
    });
  }

  private _subscribePopoverDisallow(cb: OneSignalCb) {
    this.oneSignal.push(() => {
      this.oneSignal.on('popoverCancelClick', cb);
    });
  }

  private _sendTag(key: string, value = '1'): void {
    this.oneSignal.sendTag(key, value);
  }
}
