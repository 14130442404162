






















import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../../ui-image';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type { NewsSImage, NewsSStatistic } from './ui-news-s.contract';

@Component({
  components: {
    UiNewsCardLabels,
    UiObserveVisibility,
    UiStatistic,
    UiImage
  },
  name: 'UiNewsS'
})
export default class UiNewsS extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsSImage,
    required: true
  })
  image!: NewsSImage;

  @Prop({
    type: Object as () => NewsSStatistic,
    required: true
  })
  statistic!: NewsSStatistic;

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  @Emit('blockShown')
  blockShown(): void {
    return;
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(place: string): string {
    return place;
  }
}
