import type {
  FormatPageDTO,
  FormatPageFormatNewsDTO
} from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { FormatRecordsMapper } from '../../../services';

export type FormatPage = {
  blocks: {
    type: FormatPageFormatNewsDTO['type'];
  }[];
  recordsInfo: {
    total: number;
    records: BaseRecord[];
  };
};

export class FormatPageMapper {
  public static toDO(pageDTO: FormatPageDTO): FormatPage {
    const INITIAL_DATA: FormatPage = {
      blocks: [],
      recordsInfo: {
        total: 0,
        records: []
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'format_news') {
        acc.recordsInfo = {
          total: block.meta?.total ?? 0,
          records: FormatRecordsMapper.toDO(
            block.data as FormatPageFormatNewsDTO['data']
          )
        };

        acc.blocks.push({
          type: current.type
        });
      }

      return acc;
    }, INITIAL_DATA);
  }
}
