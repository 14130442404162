import type { BasePageDTO, BaseRubricDTO } from '@fontanka/news-api-client';

import type { Rubric } from '../../../domain';

export class RubricsMapper {
  public static toDO(pageDTO: BasePageDTO): Rubric[] {
    const windowRubricsConfig = pageDTO.config.common.window_rubrics;
    if (!windowRubricsConfig) {
      return [];
    }

    const { path } = windowRubricsConfig;

    return (pageDTO.data[path]?.data as BaseRubricDTO[]) ?? [];
  }
}
