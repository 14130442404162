import {
  DATA_IMAGE_TYPE_TO_NAME_MAP,
  DoublePhotoAttachDTO
} from '@fontanka/news-api-client';
import { DoublePhotoAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

const MAX_IMAGE_WIDTH = 909;

export const createDoublePhotoAttach = (
  attach: DoublePhotoAttachDTO
): DoublePhotoAttach => ({
  type: 'doublePhoto',
  value: {
    description: attach.media.content || '',
    leftPhoto: createImage(attach.value.left, {
      crop: false,
      width: MAX_IMAGE_WIDTH
    }),
    position: attach.media.positionslider,
    rightPhoto: createImage(attach.value.right, {
      crop: false,
      width: MAX_IMAGE_WIDTH
    }),
    leftPhotoTypeName: attach.media['left-image-type']
      ? DATA_IMAGE_TYPE_TO_NAME_MAP[attach.media['left-image-type']]
      : DATA_IMAGE_TYPE_TO_NAME_MAP.photo,
    rightPhotoTypeName: attach.media['right-image-type']
      ? DATA_IMAGE_TYPE_TO_NAME_MAP[attach.media['right-image-type']]
      : DATA_IMAGE_TYPE_TO_NAME_MAP.photo
  }
});
