import { isAbsoluteUrl } from '@portal/utils/util-url';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';

import styles from './base-block.styles.scss';

type Events = {
  onHeaderClicked: Event;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'BaseBlock',
    props: {
      header: {
        type: String,
        default: ''
      },
      headerClass: {
        type: String,
        default: styles.headerDefaultColor
      },
      hasBorder: {
        type: Boolean,
        default: true
      },
      url: {
        type: String,
        default: ''
      },
      dataTestHeader: {
        type: String,
        default: ''
      }
    },
    methods: {
      getTargetLink(path: string) {
        return isAbsoluteUrl(path) ? '_blank' : '_self';
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs
      };

      return (
        <section
          class={[styles.baseBlock, this.hasBorder ? styles.outlined : '']}
          {...attributes}
        >
          {this.header &&
            (this.url ? (
              <a
                href={this.url}
                target={this.getTargetLink(this.url)}
                class={[
                  styles.header,
                  styles.link,
                  typography.secondary.label1.mobile,
                  this.headerClass
                ]}
                data-test={this.dataTestHeader}
                domPropsInnerHTML={this.header}
                onClick={(event: Event) => this.$emit('headerClicked', event)}
              />
            ) : (
              <div
                class={[
                  styles.header,
                  typography.secondary.label1.mobile,
                  this.headerClass
                ]}
                data-test={this.dataTestHeader}
                domPropsInnerHTML={this.header}
                onClick={(event: Event) => this.$emit('headerClicked', event)}
              />
            ))}
          {this.$slots.default}
        </section>
      );
    }
  });
