import * as tsx from 'vue-tsx-support';

import {
  injectStylesMixin,
  DATA_ATTR_REACH_GOAL_NAME,
  RecordPageGoals
} from '@apps/frontend';

import styles from './socials-list.styles.scss';

type SocialType = 'vk' | 'telegram' | 'twitter';

type Events = {
  onSocialClicked: SocialType;
};

const ID_TO_GOAL_MAP: { [key in SocialType]: RecordPageGoals } = {
  telegram: RecordPageGoals.TlgSharing,
  twitter: RecordPageGoals.TwitterSharing,
  vk: RecordPageGoals.VkSharing
};

export type SocialItem = {
  id: SocialType;
  title: string;
};

export default tsx
  .componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SocialsList',
    props: {
      socials: {
        type: Array as () => SocialItem[],
        required: true as const
      },
      theme: {
        type: String as () => 'default' | 'light',
        default: 'default'
      }
    },
    render() {
      return (
        <ul class={[styles.list, styles[this.theme]]}>
          {this.socials.map(social => (
            <li
              class={[styles.item, styles[social['id']], styles[this.theme]]}
              {...{
                attrs: {
                  [`data-${DATA_ATTR_REACH_GOAL_NAME}`]: ID_TO_GOAL_MAP[social.id]
                }
              }}
              onClick={() => this.$emit('socialClicked', social.id)}
            />
          ))}
        </ul>
      );
    }
  });
