





















import { guardUnspecified } from '@portal/utils/util-guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import type { ButtonSize, ButtonType, ButtonStyle } from './ui-button.contract';

@Component({
  name: 'UiButton'
})
export default class UiButton extends Vue {
  @Prop({
    type: String as () => ButtonStyle,
    default: 'filled'
  })
  readonly btnStyle: ButtonStyle;

  @Prop({
    type: String as () => ButtonType,
    default: 'primary'
  })
  readonly btnType: ButtonType;

  @Prop({
    type: String as () => ButtonSize,
    default: 'large'
  })
  readonly btnSize: ButtonSize;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isRounded: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  readonly isIconOnly: boolean;

  get tag(): 'a' | 'button' {
    return guardUnspecified(this.$attrs.href) ? 'a' : 'button';
  }

  get hasBadge(): boolean {
    return guardUnspecified(this.$slots.badge);
  }
}
