import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiButton } from '@fe/common/components';

import FntInviteLogoIcon from '~icons/fnt-common/invite-logo';

import styles from './mobile-app-invite.styles.scss';

type Events = {
  onAppInviteClicked: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'MobileAppInvite',
    props: {
      appUrl: {
        type: String,
        default: ''
      }
    },
    methods: {
      appInviteClicked(): void {
        this.$emit('appInviteClicked');
      }
    },
    render() {
      return (
        <div class={styles.wrap}>
          <FntInviteLogoIcon width={32} height={32} />

          <div class={styles.text}>
            <p class={styles.siteName}>Фонтанка.ру</p>
            <p class={styles.tagline}>Читать в приложении</p>
          </div>

          <UiButton
            class={styles.btn}
            href={this.appUrl}
            height="small"
            onClick={() => this.appInviteClicked()}
          >
            <template slot="text">установить</template>
          </UiButton>
        </div>
      );
    }
  });
