import type {
  CurrencyPageDTO,
  CurrencyPageCurrencyDTO
} from '@fontanka/news-api-client';

import type { CurrencyPageBlocks as CurrencyPageBlocksAlias } from '../domain';

import { CurrencyMapper } from './currency-mapper.service';

type CurrencyPageBlocks = CurrencyPageBlocksAlias;

export class CurrencyPageMapper {
  public static toDO(pageDTO: CurrencyPageDTO): CurrencyPageBlocks {
    const blocks: CurrencyPageBlocks = [];
    return pageDTO.config.content.reduce((acc, current) => {
      const { data } = pageDTO.data[current.path];

      if (current.type === 'currency_data') {
        acc.push({
          type: current.type,
          data: CurrencyMapper.toDO((data as CurrencyPageCurrencyDTO['data']) ?? [])
        });
      }
      return acc;
    }, blocks);
  }
}
