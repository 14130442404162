export const PHOTOS_VIDEOS_ROUTING = [
  {
    path: '/witness',
    name: 'witness',
    component: () =>
      import(
        /* webpackChunkName: "photos-videos-page" */ './pages/photos-videos/photos-videos.page'
      ),
    meta: {
      isPhoto: true
    }
  },
  {
    path: '/video',
    name: 'video',
    component: () =>
      import(
        /* webpackChunkName: "photos-videos-page" */ './pages/photos-videos/photos-videos.page'
      ),
    meta: {
      isVideo: true
    }
  }
];
