import type {
  SearchPageDTO,
  SearchPageSearchResultDTO
} from '@fontanka/news-api-client';

import { BaseRecordMapper, SearchRubricsMapper } from '../../../services';
import type { SearchPage } from '../domain';

export class SearchPageMapper {
  public static toDO(pageDTO: SearchPageDTO): SearchPage {
    const initialSearchPage: SearchPage = {
      rubrics: SearchRubricsMapper.toDO(pageDTO),
      blocks: []
    };

    return pageDTO.config.content.reduce((acc, current) => {
      if (current.type === 'search_result') {
        const block = pageDTO.data[current.path] as SearchPageSearchResultDTO;

        acc.blocks.push({
          type: 'search_result',
          data: block.data?.map(record => BaseRecordMapper.toDO(record)) ?? [],
          totalRecords: block.meta?.total ?? 0
        });
      }

      return acc;
    }, initialSearchPage);
  }
}
