import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './success-message.styles.scss';

import FntCloseIcon from '~icons/fnt-common/close';
import FntSuccessMessageIcon from '~icons/fnt-common/success-message';

type Events = {
  onClosed: void;
};

const ICON_SIZE = 48;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      hasCloseButton: {
        type: Boolean,
        default: true
      }
    },
    methods: {
      close() {
        this.$emit('closed');
      }
    },
    render() {
      return (
        <div class={styles.successMsg} data-test="success-message">
          <FntSuccessMessageIcon
            width={ICON_SIZE}
            height={ICON_SIZE}
            class={styles.successIcon}
          />

          <span class={styles.msg}>{this.$slots.default}</span>

          {this.$slots.button}

          {this.hasCloseButton && (
            <button
              class={styles.closeButton}
              data-test="success-message-close"
              onClick={this.close}
            >
              <FntCloseIcon />
            </button>
          )}
        </div>
      );
    }
  });
