import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

// eslint-disable-next-line import/no-unassigned-import
import 'flickity/dist/flickity.min.css';

import styles from './slider.styles.scss';

type Events = {
  onSlideChanged: number;
  onReady: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      isFullscreen: {
        type: Boolean,
        default: false
      },
      isDraggable: {
        type: Boolean,
        default: true
      },
      groupSize: {
        type: Number,
        default: 0
      },
      currentGroup: {
        type: Number,
        default: 0
      }
    },

    data() {
      return {
        slider: null
      };
    },

    watch: {
      currentGroup(val: number) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (!this.slider) {
          return;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.slider.select(val);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async mounted() {
      const { default: flickity } = await import('flickity');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await import('flickity-as-nav-for');

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.slider = new flickity(this.$refs['slider'] as HTMLElement, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        fullscreen: this.isFullscreen,
        lazyLoad: 1,
        pageDots: false,
        prevNextButtons: false,
        groupCells: this.groupSize || undefined,
        setGallerySize: false,
        cellAlign: 'left',
        contain: true,
        on: {
          ready: () => {
            this.$emit('ready');
          }
        }
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.slider.on('change', groupIndex => {
        this.$emit('slideChanged', groupIndex);
      });
    },

    render() {
      return (
        <div class={styles.sliderLayout} ref="slider">
          {this.$slots.default}
        </div>
      );
    }
  });
