import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './links-page-header.styles.scss';

type Link = {
  link: string;
  title: string;
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    data: {
      type: Array as () => Link[],
      required: true,
      default: []
    }
  },
  render() {
    return this.data && this.data.length > 0 ? (
      <div class={styles.dailyHeader}>
        {this.data.map((link, index) => (
          <a
            class={[styles.link, index !== 0 ? styles.notFirst : '']}
            href={link.link}
            title={link.title}
          >
            {link.title}
          </a>
        ))}
      </div>
    ) : (
      <div />
    );
  }
});
