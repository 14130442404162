import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiAdvLabel } from '@fe/common/ui';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import { RubricCommentsItem } from '../rubric-comments-item';

import styles from './archive-record-item.styles.scss';

type ComponentData = {
  recordItemVisibility: ObserverVisibility;
};

type Events = {
  onVisible: void;
  onClickTitle: void;
  onClickRubric: void;
  onClickComments: void;
};

export type ArchiveRecord = {
  id: number;
  publishDate: string;
  publishTime: string;
  hasPhoto: boolean;
  hasVideo: boolean;
  hasAudio: boolean;
  header: string;
  urls: {
    url: string;
    urlComments: string;
  };
  mainRubric: {
    id: string;
    path: string;
    name: string;
  } | null;
  isSpb: boolean;
  isCommentsAllowed: boolean;
  commentsCount: number;
  canShowComments: boolean;
  hasMainPhotoCommercialLabel: boolean;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'ArchiveRecordItem',
    props: {
      usePublishDate: {
        type: Boolean,
        default: false
      },
      record: {
        type: Object as () => ArchiveRecord,
        required: true
      },
      hide: {
        type: Boolean
      }
    },
    data(): ComponentData {
      return {
        recordItemVisibility: new ObserverVisibility('all')
      };
    },
    mounted(): void {
      this.recordItemVisibility.event.subscribe(state => {
        if (state.value) {
          this.$emit('visible');
        }
      });
    },
    methods: {
      clickTitle() {
        this.$emit('clickTitle');
      },
      clickRubric() {
        this.$emit('clickRubric');
      },
      clickComments() {
        this.$emit('clickComments');
      }
    },
    render() {
      return (
        <li
          v-observe-visibility={this.recordItemVisibility.getOptions('all')}
          class={[styles.recordItem, this.hide ? styles.hide : '']}
        >
          <div class={[styles.statsWrap, this.usePublishDate ? styles.big : '']}>
            <time class={styles.time}>
              <i class={styles.timeIcon}></i>
              <span>
                {this.usePublishDate
                  ? this.record.publishDate
                  : this.record.publishTime}
              </span>
            </time>

            {this.record.mainRubric !== null && (
              <a
                class={[styles.rubric, styles.top]}
                href={this.record.mainRubric.path}
                title={this.record.mainRubric.name}
                onClick={() => this.clickRubric()}
              >
                {this.record.mainRubric.name}
              </a>
            )}

            <div class={styles.icons}>
              {this.record.hasVideo && <i class={[styles.icon, styles.video]} />}

              {this.record.hasAudio && <i class={[styles.icon, styles.music]} />}

              {this.record.hasPhoto && <i class={[styles.icon, styles.photo]} />}
            </div>
          </div>

          <div class={styles.contentWrap}>
            <div>
              <a
                class={styles.recordLink}
                href={this.record.urls.url}
                onClick={() => this.clickTitle()}
                domPropsInnerHTML={this.record.header}
              />

              {this.record.canShowComments && (
                <span domPropsInnerHTML="&nbsp;"></span>
              )}

              {this.record.canShowComments && (
                <RubricCommentsItem
                  class={styles.commentsLink}
                  record={this.record}
                  onCommentsClicked={() => this.clickComments()}
                />
              )}
            </div>

            {this.record.mainRubric !== null && (
              <a
                class={[styles.rubric, styles.bottom]}
                href={this.record.mainRubric.path}
                title={this.record.mainRubric.name}
                onClick={() => this.clickRubric()}
              >
                {this.record.mainRubric.name}
              </a>
            )}

            {this.record.hasMainPhotoCommercialLabel && (
              <UiAdvLabel class={styles.advLabel} hasFrame={false} />
            )}
          </div>
        </li>
      );
    }
  });
