import { AxiosRequestConfig } from 'axios';

import { isBrowser } from '@fontanka/browser-or-node';

import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { ImagesPollResultsDTO, ApiErrorDTO, ActiveRecordVoteDTO } from '../dtos';
import { BaseParams } from '../params';

import { LongreadPageDTO } from './longread-page-dto';
import { RecordPageDTO } from './record-page-dto';

export type RecordPageParams = BaseParams & {
  day: number;
  id: string;
  month: number;
  year: number;
  isPreview?: 1 | undefined;
  testResult?: number;
  sharePost?: number;
  shareCard?: number;
  shareRecordImage?: string;
  from?: 'yanews';
};

export type LongreadPageParams = BaseParams & {
  id: string;
  isPreview?: 1 | undefined;
};

export type PollInfoParams = {
  recordId: number;
  pollId: number;
};

export type PollVotesParams = {
  recordId: number;
  pollId: number;
  answer: number | number[];
};

export type RecordVoteParams = {
  recordId: number;
  data: {
    vote: string;
  };
};

export class RecordsHttpClient extends BaseHttpClient {
  private readonly _recordPageUrl = '/pages/fontanka/record';

  private readonly _recordPublicUrl = '/public/fontanka/records';

  private readonly _longreadPageUrl = '/pages/fontanka/longreads';

  constructor(config: Config) {
    super(config);
  }

  public async fetchRecordPageData(
    params: RecordPageParams
  ): Promise<Result<RecordPageDTO | string>> {
    const {
      headers,
      year,
      month,
      day,
      id,
      isPreview,
      testResult,
      sharePost,
      shareCard,
      shareRecordImage,
      from
    } = params;
    const url = `${this.host}${this._recordPageUrl}/${year}/${month}/${day}/${id}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<RecordPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        isPreview,
        testResult,
        sharePost,
        shareCard,
        shareRecordImage,
        from
      }
    });

    return {
      status: response.data.status,
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchLongreadPageData(
    params: LongreadPageParams
  ): Promise<Result<LongreadPageDTO>> {
    const { headers, id, isPreview } = params;
    const url = `${this.host}${this._longreadPageUrl}/${id}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<LongreadPageDTO>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        isPreview
      }
    });

    return {
      data: response.data,
      headers: response.headers
    };
  }

  public async fetchPollResults(
    params: PollInfoParams
  ): Promise<ImagesPollResultsDTO['data']> {
    const { recordId, pollId } = params;
    const url = `${this.host}/public/fontanka/records/${recordId}/polls/${pollId}/vote`;

    try {
      const response = await this.httpClient.get<ImagesPollResultsDTO>(url, {
        withCredentials: true,
        headers: {
          accept: this.accept
        },
        params: {
          regionId: this.regionId
        }
      });

      return response.data.data;
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw new Error(ex.detail);
    }
  }

  public async voteForPoll(
    params: PollVotesParams
  ): Promise<ImagesPollResultsDTO['data']> {
    const { recordId, pollId, answer } = params;
    const url = `${this.host}/public/fontanka/records/${recordId}/polls/${pollId}/vote`;

    const options: AxiosRequestConfig = {
      withCredentials: true,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        accept: this.accept
      },
      params: {
        answerId: answer,
        regionId: this.regionId
      },
      url
    };

    try {
      const response = await this.httpClient(options);
      return response.data.data;
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw new Error(ex.detail);
    }
  }

  public async recordVote(params: RecordVoteParams) {
    const { recordId, data } = params;
    const url = `${this.host}${this._recordPublicUrl}/${recordId}/vote`;

    const options: AxiosRequestConfig = {
      withCredentials: true,
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        accept: this.accept
      },
      url,
      data: {
        vote: data.vote,
        regionId: this.regionId
      }
    };

    try {
      const response = await this.httpClient(options);
      return response.data;
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw new Error(ex.detail);
    }
  }

  public async getActiveRecordVote(recordId: number) {
    const url = `${this.host}${this._recordPublicUrl}/${recordId}/vote/?regionId=${this.regionId}`;

    try {
      const response = await this.httpClient.get<ActiveRecordVoteDTO>(url, {
        withCredentials: true,
        headers: {
          accept: this.accept
        }
      });

      return response.data;
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw new Error(ex.detail);
    }
  }
}
