export type Source = {
  srcset: string;
  type?: string;
  media?: string;
};

export enum AspectRatio {
  None = 0,
  Square = 1,
  Classic = 3 / 2,
  Wide = 16 / 9,
  VerticalClassic = 2 / 3,
  VerticalWide = 9 / 16
}

export enum ImageLoading {
  Lazy = 'lazy',
  Eager = 'eager'
}

export enum ImageDecoding {
  Auto = 'auto',
  Sync = 'sync',
  Async = 'async'
}

export type Image = {
  url: string;
  sources: Source[];
  loading?: ImageLoading;
  decoding?: ImageDecoding;
  aspectRatio?: AspectRatio;
};
