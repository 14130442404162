

import { Component, Vue, Prop } from 'vue-property-decorator';
import FntCrossBtnIcon from '~icons/fnt-common/cross-btn';

@Component({
  name: 'AdvLogoInformer',
  components: {
    FntCrossBtnIcon
  },
})
export default class AdvLogoInformer extends Vue {
  @Prop({
    type: String,
    required: true
  })
  text: string;

  informerX = 0;
  informerY = 0;
  pointerXOffset = 0;
  showInformer = false;

  POINTER_HEIGHT = 5;
  POINTER_WIDTH = 10;

  get bodyStyle() {
    return {
      '--body-left': `${this.informerX}px`,
      '--body-top': `${this.informerY}px`,
      '--pointer-translate-x': `${this.pointerXOffset}px`,
      '--pointer-height': `${this.POINTER_HEIGHT}px`,
      '--pointer-width': `${this.POINTER_WIDTH}px`,
    };
  };

  lastClickTarget: HTMLElement | null = null;

  async toggleInformer(target: HTMLElement) {
    if (target === this.lastClickTarget) {
      this.showInformer = !this.showInformer;
    } else {
      this.showInformer = true;
    }

    this.lastClickTarget = target;

    if (!this.showInformer) {
      return;
    }

    await this.$nextTick();

    if (this.$refs.root && this.$refs.body) {
      this.recalulatePosition(this.$refs.root as HTMLElement, this.$refs.body as HTMLElement, target);
    }
  };

  recalulatePosition(root: HTMLElement, body: HTMLElement, target: HTMLElement) {
    const rootRect = root.getBoundingClientRect();
    const targetRect = target.getBoundingClientRect();

    const offsetX = (target.offsetWidth - body.offsetWidth) / 2;
    const offsetY = target.offsetHeight + this.POINTER_HEIGHT;

    const minX = 0;
    const x = targetRect.x - rootRect.x + offsetX;
    const maxX = window.innerWidth - body.offsetWidth;

    this.informerX = Math.min(Math.max(x, minX), maxX);
    this.informerY = targetRect.y - rootRect.y + offsetY;

    const minPointerX = -(body.offsetWidth - this.POINTER_WIDTH) / 2;
    const pointerX = x - this.informerX;
    const maxPointerX = (body.offsetWidth - this.POINTER_WIDTH) / 2;

    this.pointerXOffset = Math.min(Math.max(pointerX, minPointerX), maxPointerX);
  };

  hideInformer() { this.showInformer = false };

  get isShown() {
    return this.showInformer
  };
};
