import type {
  HeaderConfigDTO,
  HeaderCurrencyDTO,
  HeaderRubricsDTO,
  HeaderWeatherDTO,
  HeaderTrafficDTO,
  BasePageDTO,
  HeaderWeatherWidgetDTO,
  HeaderSocialDTO,
  HeaderMobileAppsDTO,
  BaseMobileAppDTO,
  HeaderAdvLogoDTO
} from '@fontanka/news-api-client';

import type { Header } from '../domain';

export class HeaderMapper {
  public static toDO(
    config: HeaderConfigDTO,
    specData: BasePageDTO['data']
  ): Header {
    return Object.entries(config).reduce((acc, current) => {
      if (current[1]) {
        const blockConfig = current[1];
        const blockType = blockConfig.type;
        if (blockType === 'currency') {
          const { data } = specData[blockConfig.path] as HeaderCurrencyDTO;

          const result = {
            currency: [...data],
            link: '/currency.html'
          };

          return {
            ...acc,
            currency: {
              data: result
            }
          };
        }

        if (blockType === 'rubrics') {
          const { data } = specData[blockConfig.path] as HeaderRubricsDTO;
          return {
            ...acc,
            rubrics: {
              data
            }
          };
        }

        if (blockType === 'weather') {
          const { data } = specData[blockConfig.path] as HeaderWeatherDTO;
          return {
            ...acc,
            weather: {
              data
            }
          };
        }

        if (blockType === 'weather_widget') {
          const {
            data: {
              city_prepositional: cityPrepositional,
              cloud: { title: cloud },
              humidity,
              icon,
              precipitation: { title: precipitation },
              pressure,
              temperature,
              temperature_feel_like: temperatureFeelLike,
              temperature_trend: temperatureTrend,
              wind: {
                direction: {
                  title: direction,
                  title_short: directionShort,
                  title_letter: directionCode
                },
                speed
              }
            }
          } = specData[blockConfig.path] as HeaderWeatherWidgetDTO;

          return {
            ...acc,
            weatherWidget: {
              data: {
                cityPrepositional,
                cloud,
                humidity,
                icon,
                precipitation:
                  precipitation[0].toUpperCase() + precipitation.slice(1),
                pressure,
                temperature,
                temperatureFeelLike,
                temperatureTrend,
                wind: {
                  direction,
                  directionShort,
                  directionCode,
                  speed
                },
                link: '/weather.html'
              }
            }
          };
        }

        if (blockType === 'traffic') {
          const { data } = specData[blockConfig.path] as HeaderTrafficDTO;

          data.title = 'Пробки';

          return {
            ...acc,
            traffic: {
              data
            }
          };
        }

        if (blockType === 'socials') {
          const { data } = specData[blockConfig.path] as HeaderSocialDTO;
          return {
            ...acc,
            socials: {
              data
            }
          };
        }

        if (blockType === 'mobile_apps') {
          const { data } = specData[blockConfig.path] as HeaderMobileAppsDTO;
          return {
            ...acc,
            mobileApps: {
              data: this.addMobileAppName(data)
            }
          };
        }

        if (blockType === 'adv_logo') {
          const { data } = specData[blockConfig.path] as HeaderAdvLogoDTO;
          return {
            ...acc,
            advLogo: {
              data
            }
          };
        }
      }

      return acc;
    }, {} as Header);
  }

  public static addMobileAppName(arr: BaseMobileAppDTO[]): BaseMobileAppDTO[] {
    return arr.map(item => {
      if (item.name === 'ios') {
        item.title = 'App Store';
      }

      if (item.name === 'android') {
        item.title = 'Google Play';
      }

      if (item.name === 'appgallery') {
        item.title = 'AppGallery';
      }

      if (item.name === 'rustore') {
        item.title = 'RuStore';
      }

      return item;
    });
  }
}
