import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import type { ArchiveRecord } from '../../_items';
import { ArchiveRecordItem } from '../../_items';

import styles from './archive-records-section.styles.scss';

type ComponentData = {
  isClosed: boolean;
};

type EventParams = {
  recordId: number;
};

type Events = {
  onVisibleRecordItem: EventParams;
  onClickRecordItemTitle: EventParams;
  onClickRecordItemRubric: EventParams;
  onClickRecordItemComments: EventParams;
  onToggle: {
    isClosed: boolean;
  };
};

export type Info = {
  date: string;
  records: ArchiveRecord[];
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      showOnlySpbRecords: {
        type: Boolean,
        required: false,
        default: false
      },
      data: {
        type: Object as () => Info,
        required: true
      }
    },
    data(): ComponentData {
      return {
        isClosed: false
      };
    },
    computed: {
      toggleText(): string {
        return this.isClosed ? 'развернуть' : 'свернуть';
      }
    },
    methods: {
      toggle() {
        this.isClosed = !this.isClosed;
        this.$emit('toggle', { isClosed: this.isClosed });
      },
      visibleRecordItem(recordId: number) {
        this.$emit('visibleRecordItem', {
          recordId
        });
      },
      clickRecordItemTitle(recordId: number) {
        this.$emit('clickRecordItemTitle', { recordId });
      },
      clickRecordItemRubric(recordId: number) {
        this.$emit('clickRecordItemRubric', { recordId });
      },
      clickRecordItemComments(recordId: number) {
        this.$emit('clickRecordItemComments', { recordId });
      }
    },
    render() {
      return (
        <div class={[styles.recordsSection, this.isClosed ? styles.closed : '']}>
          <div class={styles.headerWrap}>
            <time class={styles.time}>{this.data.date}</time>
            <div
              class={[styles.toggle, this.isClosed ? styles.closed : '']}
              onClick={this.toggle}
            >
              {this.toggleText}
            </div>
          </div>

          <ul class={styles.list}>
            {this.data.records.map(record => (
              <ArchiveRecordItem
                record={record}
                hide={!record.isSpb && this.showOnlySpbRecords}
                onVisible={() => this.visibleRecordItem(record.id)}
                onClickTitle={() => this.clickRecordItemTitle(record.id)}
                onClickRubric={() => this.clickRecordItemRubric(record.id)}
                onClickComments={() => this.clickRecordItemComments(record.id)}
              />
            ))}
          </ul>
        </div>
      );
    }
  });
