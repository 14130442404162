import { PageNames } from '../page-names';

import { PageScheme } from './header-bidding-data';

export const HB_DESKTOP_PAGE_SCHEME: PageScheme = [
  {
    type: [PageNames.MAIN],
    ads: ['adv_hb-top-index', 'adv_vb-2-index', 'adv_vb-1-left']
  },
  {
    type: [PageNames.RECORD],
    ads: [
      'hb-top_d',
      'vb-right-1_d',
      'vb-left-1_d',
      'vb-left-2_d',
      'vb-right-2_d',
      'inread_d',
      'inread-2_d',
      'inline_d'
    ]
  },
  {
    type: [PageNames.COMMENTS, PageNames.ALL_COMMENTS],
    ads: ['hb-top_d', 'vb-right-1_d', 'vb-left-1_d', 'vb-left-2_d', 'vb-right-2_d']
  },
  {
    type: [
      PageNames.ARCHIVE,
      PageNames.ARCHIVE_BY_24HOURS,
      PageNames.ARCHIVE_BY_DATE,
      PageNames.ARCHIVE_CALENDAR,
      PageNames.AUTHOR,
      PageNames.DEFAULT,
      PageNames.FORMAT,
      PageNames.MENTION,
      PageNames.PROFILE,
      PageNames.PROFILE_SETTINGS,
      PageNames.SEARCH,
      PageNames.RUBRIC,
      PageNames.THEME,
      PageNames.VIDEO,
      PageNames.PROJECTS
    ],
    ads: ['hb-top_d']
  }
];

export const HB_MOBILE_PAGE_SCHEME: PageScheme = [
  {
    type: [PageNames.MAIN],
    ads: ['hb-top_m', 'sticky_m']
  },
  {
    type: [PageNames.RECORD],
    ads: [
      'hb-top_m',
      'inline-1_m',
      'inline-2_m',
      'inline-3_m',
      'inline-4_m',
      'sticky-rsya_m',
      'inread_m',
      'inread-2_m',
      'fullscreen'
    ]
  },
  {
    type: [PageNames.COMMENTS, PageNames.ALL_COMMENTS],
    ads: ['hb-top_m', 'sticky_m']
  },
  {
    type: [
      PageNames.ARCHIVE,
      PageNames.ARCHIVE_BY_24HOURS,
      PageNames.ARCHIVE_BY_DATE,
      PageNames.ARCHIVE_CALENDAR,
      PageNames.AUTHOR,
      PageNames.DEFAULT,
      PageNames.FORMAT,
      PageNames.MENTION,
      PageNames.PROFILE,
      PageNames.PROFILE_SETTINGS,
      PageNames.SEARCH,
      PageNames.RUBRIC,
      PageNames.THEME,
      PageNames.VIDEO,
      PageNames.PROJECTS
    ],
    ads: ['hb-top_m']
  }
];
