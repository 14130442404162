import { guardEmptyString, guardUnspecified } from '@smh/utils/guards';

import {
  CropType,
  getCroppedHeightByWidth,
  getSizeForCropping
} from '@fontanka/cropping';
import { ImageSource, imageSources } from '@fontanka/image-sources';

import { Image, SimpleImage } from '../../domain';
import { generateImageUrl } from '../image';

type ImageDTO = {
  readonly author: string;
  readonly description: string;
  readonly fileMask: string;
  readonly height: number;
  readonly id: string;
  readonly sourceName: 'NgsStaticImg' | 'E1Img' | 'fontanka';
  readonly url: string;
  readonly width: number;
};

const WEBP_EXT = '.webp-portal';
const WEBP_TYPE = 'image/webp';

const getWebpImageUrl = (url: string): string => `${url}${WEBP_EXT}`;

const getImageUrl = (
  url: string,
  width: number,
  height = 0,
  crop = true
): string => {
  const replacePattern = '##';

  if (!guardEmptyString(url)) {
    throw new Error('getImageUrl: url is empty');
  }

  if (!url.includes(replacePattern)) {
    throw new Error(`getImageUrl: replace pattern not found in ${url}`);
  }

  const endReplaceValue = height > 0 ? `_${height}${crop ? '_c' : ''}` : '';

  return url.replace(replacePattern, `_${width}${endReplaceValue}.`);
};

const getImageSources = (url: string, input: ImageMapperParams): ImageSource[] => {
  try {
    const { sources = [], cropType = CropType.FullFrame, crop = true } = input;

    return sources.map(val => {
      const { width, breakpoint, noMedia = false, hasRetina = true } = val;
      const media = !noMedia && breakpoint > 0 ? `(max-width: ${breakpoint}px)` : '';
      const height = getCroppedHeightByWidth({ cropType, width });

      const imageUrl = getImageUrl(url, width, height, crop);
      const webpImageUrl = getWebpImageUrl(imageUrl);
      let srcset = webpImageUrl;

      if (hasRetina) {
        const width2x = width * 2;
        const height2x = getCroppedHeightByWidth({ cropType, width: width2x });
        const image2x = getImageUrl(url, width2x, height2x, crop);
        const webpImage2x = getWebpImageUrl(image2x);

        srcset = `${webpImageUrl} 1x, ${webpImage2x} 2x`;
      }

      return {
        media,
        srcset,
        type: WEBP_TYPE
      };
    });
  } catch {
    return [];
  }
};

export type ResponsiveImageValues = {
  width: number;
  breakpoint: number;
  noMedia?: boolean;
  hasRetina?: boolean;
};

export type ImageMapperParams = {
  crop?: boolean;
  cropType?: CropType;
  width?: number;
  height?: number;
  sources?: ResponsiveImageValues[];
};

export const createImage = (
  imageDTO: ImageDTO | null,
  params: ImageMapperParams = { crop: false }
): Image | null => {
  if (imageDTO !== null) {
    let url = '';

    if (params.cropType) {
      const { width, height } = getSizeForCropping({
        cropType: params.cropType,
        width: imageDTO.width,
        height: imageDTO.height
      });

      url = generateImageUrl(imageDTO, params.crop, width, height);
    } else {
      url = generateImageUrl(imageDTO, params.crop, params.width, params.height);
    }

    return {
      author: imageDTO.author,
      description: imageDTO.description,
      fileMask: imageDTO.fileMask,
      height: imageDTO.height,
      width: imageDTO.width,
      id: imageDTO.id,
      sourceName: imageDTO.sourceName,
      sources: guardUnspecified(params.sources)
        ? getImageSources(imageDTO.url, params)
        : imageSources(url),
      url,
      originUrl: imageDTO.url
    };
  }

  return null;
};

export const createSimpleImage = (src: string): SimpleImage => ({
  url: src,
  sources: src !== '' ? imageSources(src) : []
});
