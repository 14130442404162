import { isBrowser } from '@fontanka/browser-or-node';

import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { AllAuthorsDTO } from '../dtos';
import { BaseParams } from '../params';
import { SearchParams } from '../search-http-client/search-http-client';

import type {
  AuthorPageAuthorRecordsDTO,
  AuthorPageAuthorRecordsItem,
  AuthorPageDTO
} from './author-page-dto';

export type AuthorPageParams = BaseParams & {
  id: string;
  pagesize: number;
};

export type AllAuthorPageParams = BaseParams & {
  pagesize: number;
};

export type FetchAuthorParams = {
  page?: number;
  pagesize?: number;
  text?: string;
  sort?: string;
};

export type AuthorRecordsParams = Pick<
  SearchParams,
  Exclude<keyof SearchParams, 'theme' | 'author'>
> & {
  id: number;
};

export class AuthorHttpClient extends BaseHttpClient {
  private readonly _authorPageUrl = '/pages/fontanka/author/';
  private readonly _recordAuthorPageUrl = '/pages/fontanka/records/authors/';
  private readonly _allAuthorsPageUrl = '/pages/fontanka/authors/';

  private readonly _fetchAuthorUrl = '/public/fontanka/services/authors/';

  constructor(config: Config) {
    super(config);
  }

  public async fetchAuthorPageData(
    params: AuthorPageParams
  ): Promise<Result<AuthorPageDTO>> {
    const { id, pagesize, headers } = params;
    const url = `${this.host}${this._authorPageUrl}${id}`;
    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<AuthorPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        pagesize
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchAuthorRecords(
    params: AuthorRecordsParams
  ): Promise<AuthorPageAuthorRecordsItem[]> {
    const { id, pagesize, page } = params;
    const url = `${this.host}${this._recordAuthorPageUrl}${id}`;
    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<AuthorPageAuthorRecordsDTO>(url, {
      withCredentials,
      params: {
        regionId: this.regionId,
        pagesize,
        page
      }
    });
    return response.data.data ?? [];
  }

  public async fetchAllAuthorsPageData(
    params: AllAuthorPageParams
  ): Promise<Result<AuthorPageDTO>> {
    const { headers, pagesize } = params;

    const url = `${this.host}${this._allAuthorsPageUrl}`;

    const response = await this.httpClient.get<PageResult<AuthorPageDTO>>(url, {
      headers,
      params: {
        regionId: this.regionId,
        pagesize
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchAuthors(
    params: FetchAuthorParams
  ): Promise<Result<AllAuthorsDTO>> {
    const url = `${this.host}${this._fetchAuthorUrl}`;

    const { page, pagesize, sort, text } = params;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<Result<AllAuthorsDTO>>(url, {
      withCredentials,
      params: {
        regionId: this.regionId,
        page,
        pagesize,
        text,
        sort
      }
    });

    return response.data;
  }
}
