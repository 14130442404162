import { guardUnspecified } from '@smh/utils/guards';

import { ArticleRotatedInsertsDTO, ArticleTextDTO } from '@fontanka/news-api-client';

import { RecordText } from '../../domain';

import { createAttach } from './attach-mapper';

type CreateRecordTextInput = {
  textDTO: ArticleTextDTO;
  recordId?: number;
  rotatedInserts?: ArticleRotatedInsertsDTO;
};

export const createRecordText = (input: CreateRecordTextInput): RecordText => {
  const { textDTO, recordId, rotatedInserts } = input;
  return textDTO.reduce((acc, current) => {
    const attach = createAttach({ attach: current, rotatedInserts, recordId });

    if (guardUnspecified(attach)) {
      acc.push(attach);
    }

    return acc;
  }, [] as RecordText);
};
