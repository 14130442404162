import { Action, State } from 'vuex-simple';

import type { RootModule } from '../../../../core';
import type { SearchRubrics } from '../../../../domain';
import { SearchRubricsMapper } from '../../../../services';
import type { FilterLink } from '../../domain/filter-link';
import type { LastNewsBlocks as LastNewsBlocksAlias } from '../../domain/last-news';
import { getPageUrl, getFilterLinks } from '../../domain/last-news';
import { LastNewsMapper } from '../../services';

type LastNewsBlocks = LastNewsBlocksAlias;

type NavigateParams = {
  type: FilterLink['type'];
  rubricId: 'all' | string;
};

export const LAST_NEWS_MODULE_NAMESPACE = ['last_news_module'];

export class LastNewsModule {
  @State()
  public filterLinks: FilterLink[];

  @State()
  public rubrics: SearchRubrics;

  @State()
  public blocks: LastNewsBlocks;

  constructor(private _root: RootModule, rubric: string) {
    this.rubrics = SearchRubricsMapper.toDO(this._root.pageSpec);
    this.filterLinks = getFilterLinks(rubric);

    this.blocks = LastNewsMapper.toDO(this._root.pageSpec);
  }

  @Action()
  navigate(params: NavigateParams) {
    location.href = getPageUrl(params.type, params.rubricId);
  }
}
