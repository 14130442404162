import { ImageDTO } from '@fontanka/news-api-client';
import { createImagePath } from './image-url.service';

export const generateImageUrl = (
  image?: ImageDTO | null,
  crop = false,
  width?: number,
  height?: number
): string => {
  if (!image || !image.url) {
    return '';
  }

  const { url, sourceName } = image;

  if (!sourceName || sourceName === 'fontanka') {
    return url;
  }

  const cropTemplate = image.sourceName === 'E1Img' ? '' : `${crop ? '_c' : ''}.`;

  return createImagePath({
    original: {
      ...image
    },
    cropTemplate,
    width,
    height
  });
};
