const browser =
  typeof window !== 'undefined' && typeof window.document !== 'undefined';

const node =
  typeof process !== 'undefined' &&
  process.versions != null &&
  process.versions.node != null;

export const isBrowser = () => browser;
export const isNode = () => node;
