import { toQueryString } from '@portal/utils/util-url';

export type MediascopeRequiredParams = {
  /**
   * Идентификатор каталога
   * */
  idc: string;
  /**
   * Идентификатор клиента
   * */
  cid: string;
  /**
   * Идентификатор тематического раздела
   * */
  tms: string;
};

export type MediascopeParams = MediascopeRequiredParams & {
  /**
   * ID контента в пределах вашего каталога (например, id материала)
   * */
  idlc?: string;
  /**
   * Таймстемп кадра потока. Для статичного материала = 0.
   * */
  fts: string;
  /**
   * Идентификатор типа источника. 1 - Web, 2 - App, 3 - OTT
   * */
  typ: string;
  /**
   * Номер версии ЕК. Версии могут отличаться содержимым ЕК. Значение по умолчанию: 0
   * */
  ver: string;
  /**
   * Тип контакта.
   * 0 - Не определено,
   * 1 - Live вещание,
   * 2 - VOD, 3 - Catch-up,
   * 4 - Контакт с текстовым материалом (статья),
   * 5 - пост в соцсети,
   * 6 - live аудио,
   * 7 - аудио по запросу
   * */
  type: string;
};

export class MediascopeAnalytics {
  private _baseParams: MediascopeParams;

  constructor(baseParams: MediascopeRequiredParams) {
    this._baseParams = {
      fts: '0',
      typ: '1',
      ver: '0',
      type: '4',
      ...baseParams
    };
  }

  public fetchCounter(recordId: number): void {
    const requestUrl = 'https://tns-counter.ru/e/ec01';

    const urlc =
      typeof window !== 'undefined' ? encodeURIComponent(window.location.href) : '';

    const query = toQueryString(
      {
        ...this._baseParams,
        idlc: recordId.toString(),
        urlc: urlc
      },
      '&'
    );
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open('GET', `${requestUrl}${query}`);
    xhr.send();
    xhr.onerror = err => {
      console.error(`Ошибка получения счетчика Mediascope:\n `, err);
    };
  }
}
