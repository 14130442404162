import { guardUnspecified } from '@portal/utils/util-guards';

import type { ApiErrorDTO, UpdateProfileParams } from '@fontanka/news-api-client';
import { ProfileHttpClient } from '@fontanka/news-api-client';

import type { ProfileInfo, ProfileEditErrorDO } from '../domain';

import { ProfileInfoMapper } from './profile-info-mapper.service';

export class EditProfileDataMapper {
  private readonly _profileHttpClient: ProfileHttpClient;

  constructor(envType: string) {
    this._profileHttpClient = new ProfileHttpClient({
      envType,
      protocol: 'https'
    });
  }

  private _extendError(error: ApiErrorDTO): ProfileEditErrorDO {
    const DEFAULT_ERROR = 'Что-то пошло не так. Попробуйте позднее';
    const TOO_LARGE_ERROR_STATUS = 413;
    const CAPTCHA_ERROR_STATUS = 403;

    try {
      if (error.status === TOO_LARGE_ERROR_STATUS) {
        return {
          ...error,
          errors: {
            general: `Слишком большой размер изображения. Загрузите изображение поменьше`
          }
        };
      }

      if (error.status === CAPTCHA_ERROR_STATUS) {
        return {
          ...error,
          errors: {
            general: error.detail
          }
        };
      }

      if (guardUnspecified(error.errors)) {
        const fieldErrorNames = ['nick', 'about', 'general'];

        return {
          ...error,
          errors: error.errors.reduce((acc, err) => {
            if (fieldErrorNames.includes(err.name)) {
              acc[err.name] = err.reason;
            } else {
              acc['general'] =
                err.name.toLowerCase() !== 'bad request'
                  ? err.reason
                  : DEFAULT_ERROR;
            }

            return acc;
          }, {})
        };
      }
    } catch (err) {
      throw {
        ...error,
        errors: {
          general: DEFAULT_ERROR
        }
      };
    }

    return {
      ...error,
      errors: {
        general: error.detail || DEFAULT_ERROR
      }
    };
  }

  public async updateProfile(
    profile: ProfileInfo,
    captchaToken: string
  ): Promise<ProfileInfo | null> {
    let data = null;

    const profileDTO = ProfileInfoMapper.fromDO(profile);

    const params: UpdateProfileParams = {
      nick: profileDTO.nick,
      profileId: profileDTO.id,
      sex: profileDTO.sex,
      about: profileDTO.about,
      avatar: profileDTO.avatar.url,
      newAvatarFile: profileDTO.newAvatarFile,
      birthday: profileDTO.birthday,
      captchaToken
    };

    try {
      const result = await this._profileHttpClient.updateProfile(params);
      data = result ? ProfileInfoMapper.toDO(result) : null;
    } catch (err) {
      const errorDO: ProfileEditErrorDO | null = this._extendError(
        err as ApiErrorDTO
      );
      throw errorDO;
    }

    return data;
  }
}
