export function capitalizeFirstLetter(initialString: string): string {
  return initialString.charAt(0).toUpperCase() + initialString.slice(1);
}

/**
 * plural(count, ['ответ', 'ответа', 'ответов']);
 * @param n
 * @param titles
 */
export const plural = (n: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]];
};
