




























































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiScrollable } from '../ui-draggable';
import { UiLabel } from '../ui-label';

import FntImageIcon from '~icons/fnt-ui-common/image';
import FntVideoIcon from '~icons/fnt-ui-common/video';

import type {
  TaxonomyType,
  TaxonomyFormat,
  TaxonomyRubric,
  TaxonomyTheme,
  AlignmentType,
  Theme
} from './ui-news-card-labels.contract';

@Component({
  components: {
    UiScrollable,
    FntImageIcon,
    FntVideoIcon,
    UiLabel
  },
  name: 'UiNewsCardLabels'
})
export default class UiNewsCardLabels extends Vue {
  @Prop({
    type: String as () => Theme,
    default: 'light'
  })
  theme: Theme;

  @Prop({
    type: String as () => AlignmentType,
    default: 'left'
  })
  alignment: AlignmentType;

  @Prop({
    type: Boolean,
    default: false
  })
  hasImageMark: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasVideoMark: boolean;

  @Prop({
    type: Array as () => TaxonomyFormat[],
    default: () => []
  })
  formats: TaxonomyFormat[];

  @Prop({
    type: Array as () => TaxonomyRubric[],
    default: () => []
  })
  rubrics: TaxonomyRubric[];

  @Prop({
    type: Array as () => TaxonomyTheme[],
    default: () => []
  })
  themes: TaxonomyTheme[];

  get hasPrimaryLabel(): boolean {
    return guardUnspecified(this.$slots.default);
  }

  @Emit('taxonomyClicked')
  taxonomyClicked(
    event: Event,
    taxonomyType: TaxonomyType,
    id: number,
    name: string
  ): { event: Event; taxonomyType: TaxonomyType; id: number; name: string } {
    return { event, taxonomyType, id, name };
  }
}
