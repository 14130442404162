import type {
  ActionResultPageDTO,
  ActionResultDataDTO
} from '@fontanka/news-api-client';

import type { ActionResultPage } from '../domain';

import { ActionResultMapper } from './action-result-mapper.service';

export class ActionResultPageMapper {
  public static toDO(pageDTO: ActionResultPageDTO): ActionResultPage {
    const initialPage: ActionResultPage = {
      data: {
        title: '',
        detail: '',
        isError: false
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      if (
        current.type === 'delete_subscription' ||
        current.type === 'delete_autosubscription' ||
        current.type === 'reactivation_subscriptions'
      ) {
        const { data, errors = [] } = pageDTO.data[current.path];

        acc.data = ActionResultMapper.getActionResult(
          data as ActionResultDataDTO,
          errors
        );
      }
      return acc;
    }, initialPage);
  }
}
