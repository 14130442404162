import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './radio-group.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  render() {
    return (
      <div class={styles.radioGroupWrap}>
        <p class={styles.caption}>{this.$slots.caption}</p>
        <div class={styles.radioGroup}>{this.$slots.items}</div>
      </div>
    );
  }
});
