import * as tsx from 'vue-tsx-support';

import type { BaseRecord } from '@apps/frontend';
import { injectStylesMixin } from '@apps/frontend';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import { SideSliderBlock } from '../_blocks';
import { SliderHorizontalItem } from '../_items';

import styles from './special-opinion.styles.scss';

type Events = {
  onChangedSlide: 'next' | 'prev';
  onClickedHeaderSlide: void;
  onClickedRecord: number;
  onClickedAuthor: {
    recordId: number;
    authorId: number;
  };
  onVisible: void;
};

export default tsx
  .componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      records: {
        type: Array as () => BaseRecord[],
        required: true as const
      }
    },
    data() {
      return {
        isSliderReady: false,
        currentSlideId: 0,
        specialOpinionVisibility: new ObserverVisibility('half')
      };
    },
    mounted() {
      this.specialOpinionVisibility.event.subscribe(state => {
        if (state.value) {
          this.$emit('visible');
        }
      });
    },
    beforeDestroy() {
      this.specialOpinionVisibility.destroy();
    },
    methods: {
      changedSlide(slideId: number) {
        if (this.currentSlideId !== slideId) {
          const emitValue = this.currentSlideId < slideId ? 'next' : 'prev';
          this.$emit('changedSlide', emitValue);
          this.currentSlideId = slideId;
        }
      },
      emitClickedHeaderSlide() {
        this.$emit('clickedHeaderSlide');
      },
      clickedRecord(recordId: number) {
        this.$emit('clickedRecord', recordId);
      },
      clickedAuthor(recordId: number, authorId: number) {
        this.$emit('clickedAuthor', { recordId, authorId });
      }
    },
    render() {
      return (
        <SideSliderBlock
          v-observe-visibility={this.specialOpinionVisibility.getOptions('half')}
          class={this.isSliderReady ? styles.ready : ''}
          header="Особое мнение"
          link="/mention"
          slidesCount={this.records.length}
          onReady={() => (this.isSliderReady = true)}
          onSlideChanged={this.changedSlide}
          onClickedHeader={this.emitClickedHeaderSlide}
        >
          {this.records.map(sliderItem => {
            const imageObj = sliderItem.author?.image || sliderItem?.image || null;
            const image = {
              url: imageObj?.url || '',
              sources: imageObj?.sources || []
            };
            return (
              <div class={styles.sliderItem}>
                <SliderHorizontalItem
                  url={sliderItem.urls?.url || ''}
                  header={sliderItem.header}
                  image={image}
                  publishAt={sliderItem.publishAt}
                  author={{
                    name: sliderItem.author?.name || '',
                    url: sliderItem.author?.url || ''
                  }}
                  hasCommercialLabel={sliderItem.hasMainPhotoCommercialLabel}
                  onClickedPhoto={() =>
                    this.clickedAuthor(sliderItem.id, sliderItem.author?.id || 0)
                  }
                  onClickedHeader={() => this.clickedRecord(sliderItem.id)}
                  onClickedAuthor={() =>
                    this.clickedAuthor(sliderItem.id, sliderItem.author?.id || 0)
                  }
                />
              </div>
            );
          })}
        </SideSliderBlock>
      );
    }
  });
