import { BidderName } from './header-bidding-data';

type PixelData = {
  bidder: BidderName;
  pixels: string[];
};

const HB_COMMON_PIXELS: PixelData[] = [
  {
    bidder: 'umg',
    pixels: ['https://a.utraff.com/sync?ssp=3452']
  },
  {
    bidder: 'sape',
    pixels: ['https://acint.net/cmatch/?dp=14&pi=1648506']
  }
];

const HB_MOBILE_PIXELS: PixelData[] = [
  {
    bidder: 'adfox_imho-video',
    pixels: [
      'https://www.tns-counter.ru/V13a****idsh_ad/ru/CP1251/tmsec=idsh_mob/',
      'https://www.tns-counter.ru/V13a****idsh_vid/ru/CP1251/tmsec=idsh_sid110096-mob/',
      'https://www.tns-counter.ru/V13a****idsh_vid/ru/CP1251/tmsec=idsh_sid116319-mob/',
      'https://mc.yandex.ru/watch/66716692?page-url=110096%3Futm_source=mob_default%26utm_medium=%26utm_campaign=%26utm_content=%26utm_term=&page-ref=[PAGE_URL]',
      'https://mc.yandex.ru/watch/66716692?page-url=116319%3Futm_source=mob_default%26utm_medium=%26utm_campaign=%26utm_content=%26utm_term=&page-ref=[PAGE_URL]'
    ]
  },
  ...HB_COMMON_PIXELS
];

const HB_DESKTOP_PIXELS: PixelData[] = [
  {
    bidder: 'adfox_imho-video',
    pixels: [
      'https://www.tns-counter.ru/V13a****idsh_ad/ru/CP1251/tmsec=idsh_dis/',
      'https://www.tns-counter.ru/V13a****idsh_vid/ru/CP1251/tmsec=idsh_sid109527-dis/',
      'https://mc.yandex.ru/watch/66716692?page-url=109527%3Futm_source=dis_default%26utm_medium=%26utm_campaign=%26utm_content=%26utm_term=&page-ref=[PAGE_URL]'
    ]
  },
  ...HB_COMMON_PIXELS
];

export const getSyncPixels = (isMobile: boolean, pageUrl: string) => {
  const syncPixels = isMobile ? HB_MOBILE_PIXELS : HB_DESKTOP_PIXELS;
  const baseUrl = new URL(`https://www.fontanka.ru${pageUrl}`);
  const totalPageUrl = baseUrl.origin + baseUrl.pathname;

  return syncPixels.map(item => {
    const pixels = item.pixels.map(pixel => {
      return pixel
        .replace('[PAGE_URL]', totalPageUrl)
        .replace('[MATH_RANDOM]', String(Math.random()));
    });

    return {
      bidder: item.bidder,
      pixels: pixels
    };
  });
};
