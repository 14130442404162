<template>
  <LazyHydrate ssr-only>
    <slot />
  </LazyHydrate>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';

export default {
  components: {
    LazyHydrate
  }
};
</script>
