import {
  TextAttach,
  CustomWidgetAttach,
  IframeAttach,
  ImageAttach,
  GifAttach,
  VideoAttach,
  ViqeoAttach,
  WQuoteAttach,
  GalleryAttach,
  LogotypeAttach,
  DocumentsAttach,
  AudiosAttach,
  AdvAttach,
  AppendAttach,
  PollAttach,
  ImagesPollAttach,
  DoublePhotoAttach,
  TestAttach,
  TestResultAttach,
  FeedbackAttach,
  SpoilerAttach,
  ForeignAgentAttach,
  RotatedInsertsAttachVM
} from './attaches';
import { Author } from './author';
import { Image, SimpleImage } from './image';
import { RecordVote } from './record-vote';
import { Rubric } from './rubric';
import { Theme } from './theme';

type BroadcastRecord = {
  updatesStatus?: '' | 'stoped' | 'started';
};

type Format = {
  id: number;
  name: string;
  url: string;
};

export type RecordTextItem =
  | TextAttach
  | CustomWidgetAttach
  | IframeAttach
  | ImageAttach
  | GifAttach
  | VideoAttach
  | ViqeoAttach
  | WQuoteAttach
  | GalleryAttach
  | LogotypeAttach
  | DocumentsAttach
  | AudiosAttach
  | AdvAttach
  | AppendAttach
  | PollAttach
  | ImagesPollAttach
  | DoublePhotoAttach
  | TestAttach
  | TestResultAttach
  | FeedbackAttach
  | SpoilerAttach
  | ForeignAgentAttach
  | RotatedInsertsAttachVM;

export type RecordText = RecordTextItem[];

export type RecordPost = {
  data: RecordText;
  date: string;
  id: number;
  type: 'post';
};

export type RecordCard = {
  data: RecordText;
  header: string;
  id: number;
  type: 'card';
};

export type RecordPosts = (RecordPost | RecordTextItem)[];

export type RecordCards = (RecordCard | RecordTextItem)[];

export type RecordParams = {
  inImageAds?: boolean;
  appUrl?: string;
};

export type Record = {
  id: number;
  commentsCount: number;
  originalHeader: string;
  header: string;
  headerKeywords: string[];
  isBroadcast: boolean;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
  isMatureContent: boolean;
  leadStr: string;
  mainPhoto: Image | null;
  isCardsTemplate: boolean;
  cards: RecordCards;
  posts: RecordPosts;
  publishAt: string;
  publishDateForRecordHeader: string;
  rubrics: Rubric[];
  mainRubric: Rubric | null;
  sourceName: string;
  subheader: string;
  text: RecordText;
  urls: {
    url: string;
    urlComments: string;
  };
  formats: Format[];
  isMention: boolean;
  authors: Author[];
  mentionAuthor: Author | null;
  copyright: boolean;
  commercialLabel: string;
  contraindications: SimpleImage;
  baa: SimpleImage; // biologically active additives - (БАД)
  themes: Theme[];
  yandexNewsHeader: string;
  voicedTextUrl?: string;
  votes?: RecordVote[];
  params?: RecordParams;
  viewsCount: number;
  socialShareImage: string;
  hasMainPhotoCommercialLabel: boolean;
};

export const MENTION_RUBRIC_ID = 'mention';

export const isPhotoRecord = (keywords?: string[]) =>
  keywords ? keywords.includes('фото') : false;

export const isVideoRecord = (keywords?: string[]) =>
  keywords ? keywords.includes('видео') : false;

export const isAudioRecord = (keywords?: string[]) =>
  keywords ? keywords.includes('аудио') : false;

export const isRecordBroadcast = (recordDTO: BroadcastRecord): boolean =>
  recordDTO.updatesStatus === 'started' || recordDTO.updatesStatus === 'stoped';

export const isMentionRecord = (rubrics?: Rubric[]) =>
  rubrics && rubrics.length > 0
    ? rubrics.some(rubric => rubric.id === MENTION_RUBRIC_ID)
    : false;
