import {
  BlockConfig,
  BlockInfo,
  BaseRubricDTO,
  BaseMobileAppDTO,
  BaseSocialDTO,
  BaseCityDTO,
  BaseMobileMenuDTO,
  BaseLogotypesDTO,
  BaseFooterTextDTO
} from '../../BaseDTOs';

export enum FooterBlockType {
  AboutCompany = 'about_company',
  FooterProjects = 'footer_projects',
  FooterRubrics = 'footer_rubrics',
  FooterRubricsList = 'footer_rubrics_list',
  FooterText = 'footer_text',
  MobileApps = 'mobile_apps',
  Socials = 'socials',
  FooterCities = 'footer_cities',
  FooterNotEditorialCities = 'footer_not_editorial_cities',
  Subscription = 'subscription',
  MobileMenu = 'mobile_menu',
  Logotypes = 'logotypes'
}

export type FooterConfigDTO = {
  about_company?: BlockConfig<FooterBlockType.AboutCompany>;
  footer_projects?: BlockConfig<FooterBlockType.FooterProjects>;
  footer_rubrics?: BlockConfig<FooterBlockType.FooterRubrics>;
  footer_rubrics_list?: BlockConfig<FooterBlockType.FooterRubricsList>;
  footer_text?: BlockConfig<FooterBlockType.FooterText>;
  mobile_apps?: BlockConfig<FooterBlockType.MobileApps>;
  socials?: BlockConfig<FooterBlockType.Socials>;
  footer_cities?: BlockConfig<FooterBlockType.FooterCities>;
  footer_not_editorial_cities?: BlockConfig<FooterBlockType.FooterNotEditorialCities>;
  subscription?: BlockConfig<FooterBlockType.Subscription>;
  mobile_menu?: BlockConfig<FooterBlockType.MobileMenu>;
  logotypes?: BlockConfig<FooterBlockType.Logotypes>;
};

export type FooterAboutCompanyItemDTO = {
  link: string;
  title: string;
  target?: '_self' | '_blank';
};

export type FooterAboutCompanyDTO = BlockInfo<
  FooterAboutCompanyItemDTO[] | null,
  FooterBlockType.AboutCompany
>;

export type FooterProjectItemDTO = {
  link: string;
  title: string;
  target?: '_self' | '_blank';
};

export type FooterProjectsDTO = BlockInfo<
  FooterProjectItemDTO[],
  FooterBlockType.FooterProjects
>;

export type FooterRubricsDTO = BlockInfo<
  BaseRubricDTO[],
  FooterBlockType.FooterRubrics
>;

export type FooterRubricsListDTO = BlockInfo<
  BaseRubricDTO[],
  FooterBlockType.FooterRubricsList
>;

export type FooterTextDTO = BlockInfo<BaseFooterTextDTO, FooterBlockType.FooterText>;

export type FooterTextPreparedDTO = {
  copyright: string;
  links: string[];
  text: string;
};

export type FooterMobileAppsDTO = BlockInfo<
  BaseMobileAppDTO[] | null,
  FooterBlockType.MobileApps
>;

export type FooterSocialsDTO = BlockInfo<
  BaseSocialDTO[] | null,
  FooterBlockType.Socials
>;

export type FooterCitiesDTO = BlockInfo<
  BaseCityDTO[] | null,
  FooterBlockType.FooterCities
>;

export type FooterNotEditorialCitiesDTO = BlockInfo<
  BaseCityDTO[] | null,
  FooterBlockType.FooterNotEditorialCities
>;

export type MobileMenuDTO = BlockInfo<
  BaseMobileMenuDTO | null,
  FooterBlockType.MobileMenu
>;

export type LogotypesDTO = BlockInfo<
  BaseLogotypesDTO | null,
  FooterBlockType.MobileMenu
>;

export type FooterBlocks =
  | FooterAboutCompanyDTO
  | FooterProjectsDTO
  | FooterRubricsDTO
  | FooterTextDTO
  | FooterMobileAppsDTO
  | FooterSocialsDTO;
