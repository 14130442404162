import { component } from 'vue-tsx-support';
import { useStore } from 'vuex-simple';

import type { RootModule } from '../core';

export const storeMixin = component({
  data() {
    return {
      store: useStore<RootModule>(this.$store)
    };
  }
});
