import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';
import { getDeviceByWindowWidth } from '@fontanka/device-by-width';

import { Slider } from '../../_sliders';

import FntSliderArrowIcon from '~icons/fnt-common/slider-arrow';

import styles from './base-slider-block.styles.scss';

enum SliderDirection {
  Prev = 'prev',
  Next = 'next'
}

type GroupSizeConfig = {
  desktop: number;
  laptop: number;
  tablet: number;
  mobile: number;
};

type ComponentData = {
  currentGroup: number;
  groupSize: number;
  hasScrolledByBtn: boolean;
  lastDirection: SliderDirection | null;
};

type DirectionSlide = 'prev' | 'next';

type Events = {
  onClickedHeader: void;
  onChangedSlide: DirectionSlide;
};

export type Record = {
  id: number;
  urls: {
    url: string;
  };
  image: {
    url: string;
  } | null;
  header: string;
  publishAt: string;
  leadStr: string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'BaseSliderBlock',
    props: {
      link: {
        type: String,
        required: true as const
      },
      header: {
        type: String,
        required: true as const
      },
      groupSizeConfig: {
        type: Object as () => GroupSizeConfig,
        default: () => ({
          desktop: 2,
          laptop: 2,
          tablet: 2,
          mobile: 1
        })
      },
      slidesCount: {
        type: Number,
        required: true as const
      }
    },
    data(): ComponentData {
      return {
        currentGroup: 0,
        groupSize: 0,
        lastDirection: null,
        hasScrolledByBtn: false
      };
    },
    mounted() {
      this.groupSize = this.getGroupSize();

      window.onresize = () => {
        this.groupSize = 0;
        this.groupSize = this.getGroupSize();
      };
    },
    computed: {
      isLastGroup(): boolean {
        return (
          this.currentGroup === Math.floor(this.slidesCount / this.groupSize) - 1
        );
      }
    },
    methods: {
      getGroupSize(): number {
        const windowWidth = window.innerWidth;
        return this.groupSizeConfig[getDeviceByWindowWidth(windowWidth)];
      },
      prevSlide() {
        this.hasScrolledByBtn = true;

        if (this.currentGroup !== 0) {
          this.lastDirection = SliderDirection.Prev;
          this.currentGroup = this.currentGroup - 1;
        }
      },
      nextSlide() {
        this.hasScrolledByBtn = true;

        if (!this.isLastGroup) {
          this.lastDirection = SliderDirection.Next;
          this.currentGroup = this.currentGroup + 1;
        }
      },
      onChangeSlide(groupIndex: number) {
        if (!this.hasScrolledByBtn) {
          this.lastDirection =
            this.currentGroup <= groupIndex
              ? SliderDirection.Next
              : SliderDirection.Prev;
        }

        this.currentGroup = groupIndex;
        this.$emit('changedSlide', this.lastDirection);
        this.hasScrolledByBtn = false;
      },
      emitClickedHeader() {
        this.$emit('clickedHeader');
      }
    },
    render() {
      return (
        <div class={styles.companyNews}>
          <div class={styles.headerWrap}>
            <a
              class={[styles.headerLink, typography.secondary.label1.mobile]}
              href={this.link}
              domPropsInnerHTML={this.header}
              onClick={() => this.emitClickedHeader()}
            />
            <div class={styles.nav}>
              <button
                class={[
                  styles.button,
                  this.currentGroup === 0 ? styles.disabled : ''
                ]}
                onClick={this.prevSlide}
              >
                <FntSliderArrowIcon />
              </button>
              <button
                class={[styles.button, this.isLastGroup ? styles.disabled : '']}
                onClick={this.nextSlide}
              >
                <FntSliderArrowIcon />
              </button>
            </div>
          </div>

          {
            // условие для переинициализации слайдера при переходе между планшетной и десктопной версткой
            this.groupSize ? (
              <div class={styles.slider}>
                <Slider
                  groupSize={this.groupSize}
                  currentGroup={this.currentGroup}
                  onSlideChanged={this.onChangeSlide}
                >
                  {this.$slots.default}
                </Slider>
              </div>
            ) : null
          }
        </div>
      );
    }
  });
