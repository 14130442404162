import { Regions } from '@portal/regions';
import { State, Getter, Action, Mutation } from 'vuex-simple';

import type { Context } from '@fontanka/data';
import { MailHttpClient } from '@fontanka/news-api-client';
import type {
  BasePageDTO,
  NewBasePageDTO,
  MailParams
} from '@fontanka/news-api-client';

import type { ReachGoalsParamsType } from '../services';
import { AnalyticsService } from '../services';

type LoyaltyRole = 'Disloyal' | 'Average' | 'Loyal';

export {
  DATA_ATTR_REACH_GOAL_NAME,
  ArchiveRecordlistGoals,
  AuthFormGoals,
  BaseReachGoalsValues,
  CompaniesNewsGoals,
  DiscussionsGoals,
  FontankaPROGoals,
  HeaderGoals,
  InImageAdvGoals,
  NewsFeedGoals,
  NewsHourGoals,
  OurCollectionGoals,
  ProfileGoals,
  RecordCommentsPageGoals,
  RecordPageGoals,
  RubricListGoals,
  RubricNewsGoals,
  ShowcaseTileGoals,
  SpecialOpinionGoals,
  OpinionGoals,
  SwitcherGoals,
  ToGoTodayGoals,
  TopNewsGoals,
  UserAdBlockReachGoals,
  WeSocialsGoals,
  WidgetAutoGoals
} from '../services';

export type YaParamsEvent = {
  goalName: string;
  params: ReachGoalsParamsType;
};

export type ClickHouseEventParams = {
  goalName: string;
  params: string | ReachGoalsParamsType;
};

export type ApiReachGoals = {
  name: string;
  params?: string | ReachGoalsParamsType;
};

export type GoalsEnumType = {
  FieldType: string;
  ViewBlock: string;
  ClickBlock: string;
  BlockTitle?: string;
};

export class RootModule<T extends BasePageDTO | NewBasePageDTO = BasePageDTO> {
  @State()
  public isModalOpened = false;

  /**
   * @deprecated - поддержка webp всегда по умолчанию
   */
  @State()
  public hasWebpSupport = true;

  @State()
  public readonly baseInfo: Context['baseInfo'];

  @State()
  public readonly pageInfo: Context['pageInfo'];

  @State()
  public readonly serverDate: Context['serverDate'] = '';

  @State()
  public readonly geoRegionId: Context['geoRegionId'] = Regions.Fontanka;

  @State()
  public readonly pageSpec: Context<T>['pageSpec'];

  @State()
  public readonly pageName: Context['pageName'];

  @State()
  public isAdblock = false;

  @State()
  public isMainRedesign = false;

  @State()
  public loyaltySegment: LoyaltyRole | null = null;

  @State()
  public isViqeoCbReady = false;

  @State()
  public isScroogeTest = false;

  @Mutation()
  public setIsAdblock(value: boolean) {
    this.isAdblock = value;
  }

  @Mutation()
  public setIsViqeoCbReady(value: boolean) {
    this.isViqeoCbReady = value;
  }

  @Mutation()
  public setIsMainRedesign(value: boolean) {
    this.isMainRedesign = value;
  }

  @Mutation()
  public updateUserLoyalty(state: LoyaltyRole) {
    this.loyaltySegment = state;
  }

  @Mutation()
  public setIsScroogeTest(value: boolean) {
    this.isScroogeTest = value;
  }

  @Getter()
  public get recaptchaKey(): string {
    return this.pageSpec.reCaptchaSettings?.publicKey || '';
  }

  @Getter()
  public get envType(): string {
    return this.baseInfo?.envType ?? '';
  }

  @Getter()
  public get headers(): unknown {
    return this.baseInfo?.headers;
  }

  @Getter()
  public get globalReachGoalNames(): string[] {
    const apiReachGoals: ApiReachGoals[] = this.pageSpec.data.reach_goal?.data || [];
    return apiReachGoals.map(item => item.name);
  }

  private _mailHttpClient: MailHttpClient;

  private _analyticsService: AnalyticsService;

  constructor() {
    this._mailHttpClient = new MailHttpClient({
      envType: this.envType,
      protocol: 'https'
    });

    this._analyticsService = new AnalyticsService({
      yandexCounterId: 6544318,
      domain: 'https://analytics.fontanka.ru/v1/',
      envType: this.envType,
      mediascopeParams: {
        idc: '158',
        cid: 'fontanka_ru',
        tms: 'fontanka_ru'
      }
    });
  }

  @Action()
  public async sendMail(params: MailParams) {
    this._mailHttpClient.updateEnvType(this.envType);
    return await this._mailHttpClient.sendMail(params);
  }

  @Action()
  public changeModalState(state: boolean) {
    this.isModalOpened = state;
  }

  @Action()
  public sendClickEventMetrics(event: MouseEvent) {
    this._analyticsService.sendClickEventMetrics(event, this.globalReachGoalNames);
  }

  @Action()
  public initLoyaltyServices() {
    this._analyticsService.initLoyaltyServices(this.geoRegionId, this.serverDate);

    this.updateUserLoyalty(this._analyticsService.loyaltyUserStatus);
  }

  @Action()
  public sendPageAnalyticsData() {
    const apiReachGoals: ApiReachGoals[] = this.pageSpec.data.reach_goal?.data || [];

    if (apiReachGoals.length > 0) {
      apiReachGoals.forEach(reachgoal => {
        const { name, params } = reachgoal;

        this._analyticsService.sendYMReachGoal(name, params);
        this.sendClickHouseEvent({
          goalName: name,
          params: params || {}
        });
      });
    }

    this._analyticsService.sendLoyaltyYMReachGoal(this.globalReachGoalNames);
    this._analyticsService.sendViewsLoyaltyYMReachGoal();
  }

  @Action()
  public sendMediascopeAnalytics(recordId: number) {
    this._analyticsService.getMediascopeCounter(recordId);
  }

  @Action()
  public sendYMWithProductParams(data: YaParamsEvent) {
    const { goalName, params: goalParams } = data;
    const params = {
      ['Продукт']: {
        [this.pageName || 'default']: goalParams
      }
    };
    this._analyticsService.sendYMParams(goalName, params);
    this.sendClickHouseEvent({ goalName, params });
  }

  @Action()
  public sendYMWithAdvParams(data: YaParamsEvent) {
    const { goalName, params: goalParams } = data;
    const params = {
      ['Реклама']: {
        [this.pageName || 'default']: goalParams
      }
    };
    this._analyticsService.sendYMParams(goalName, params);
    this.sendClickHouseEvent({ goalName, params });
  }

  @Action()
  public sendYMReachGoal(name: string) {
    this._analyticsService.sendYMReachGoal(name);
    this.sendClickHouseEvent({
      goalName: name,
      params: {}
    });
  }

  @Action()
  public sendClickHouseEvent({ params, goalName }: ClickHouseEventParams) {
    this._analyticsService.sendClickHouseEvent(
      params,
      goalName,
      this.globalReachGoalNames
    );
  }
}
