import { getISODay } from 'date-fns';

import type {
  CalendarArchiveMonth,
  CalendarArchiveCalendar,
  CalendarArchiveCalendarMonthItemDTO
} from '@fontanka/news-api-client';
import { leadZeroPad } from '@fontanka/numbers';

import type { MonthItem, WeekDay } from '../domain/calendar-archive';
import { getDayPath } from '../domain/calendar-archive';
import type { FilterLink } from '../domain/filter-link';

const MONTHS_MAP: { [key in CalendarArchiveMonth]: MonthItem } = {
  april: {
    index: 3,
    name: 'апрель'
  },
  august: {
    index: 7,
    name: 'август'
  },
  december: {
    index: 11,
    name: 'декабрь'
  },
  february: {
    index: 1,
    name: 'февраль'
  },
  january: {
    index: 0,
    name: 'январь'
  },
  july: {
    index: 6,
    name: 'июль'
  },
  june: {
    index: 5,
    name: 'июнь'
  },
  march: {
    index: 2,
    name: 'март'
  },
  may: {
    index: 4,
    name: 'май'
  },
  november: {
    index: 10,
    name: 'ноябрь'
  },
  october: {
    index: 9,
    name: 'октябрь'
  },
  september: {
    index: 8,
    name: 'сентябрь'
  }
};

type CalendarParams = {
  calendarDTO: CalendarArchiveCalendar;
  rubric?: string;
  type: FilterLink['type'];
  year: string;
};

type WeeksParams = {
  monthDTO: CalendarArchiveCalendarMonthItemDTO[];
  monthIndex: number;
  rubric?: string;
  type: FilterLink['type'];
  year: number;
};

const FIRST_WEEK_DAY_INDEX = 1;
const DAYS_IN_WEEK = 7;
const FIRST_WEEK_INDEX = 0;

type WeekDayParams = {
  day: number;
  monthIndex: number;
  recordsCount: number;
  rubric?: string;
  type: FilterLink['type'];
  year: number;
};

export class CalendarMapper {
  public static toDO({ calendarDTO, year, rubric, type }: CalendarParams) {
    return Object.entries(calendarDTO)
      .map(entry => ({
        name: MONTHS_MAP[entry[0] as CalendarArchiveMonth].name,
        index: MONTHS_MAP[entry[0] as CalendarArchiveMonth].index,
        weeks: CalendarMapper.weeksToDO({
          year: Number(year),
          monthIndex: MONTHS_MAP[entry[0] as CalendarArchiveMonth].index,
          monthDTO: entry[1],
          rubric,
          type
        })
      }))
      .sort((a, b) => a.index - b.index);
  }

  public static weeksToDO({
    year,
    monthIndex,
    monthDTO,
    rubric,
    type
  }: WeeksParams) {
    const weeks: WeekDay[][] = [];

    const dayInWeek = getISODay(new Date(year, monthIndex, monthDTO[0].day));
    const startDaysOffset = dayInWeek - FIRST_WEEK_DAY_INDEX;

    if (startDaysOffset !== 0) {
      weeks[FIRST_WEEK_INDEX] = [];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      weeks[FIRST_WEEK_INDEX].push(...Array(startDaysOffset).fill(null));
    }

    for (let i = 0; i <= monthDTO.length - 1; i++) {
      const day = monthDTO[i];

      const weekNumber = Math.trunc((i + startDaysOffset) / DAYS_IN_WEEK);

      if (!weeks[weekNumber]) {
        weeks[weekNumber] = [];
      }

      weeks[weekNumber].push(
        CalendarMapper.dayToDO({
          year,
          monthIndex,
          day: day.day,
          recordsCount: day.count,
          rubric,
          type
        })
      );
    }

    const lastWeek = weeks[weeks.length - 1];
    if (lastWeek.length < DAYS_IN_WEEK) {
      const endDaysOffset = DAYS_IN_WEEK - lastWeek.length;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      lastWeek.push(...Array(endDaysOffset).fill(null));
    }

    return weeks;
  }

  public static dayToDO({
    year,
    monthIndex,
    day,
    rubric,
    recordsCount,
    type
  }: WeekDayParams) {
    const dayStr = leadZeroPad(day);
    const monthStr = leadZeroPad(monthIndex + 1);

    return {
      day: dayStr,
      path:
        recordsCount > 0
          ? getDayPath(year, monthStr, dayStr, type, rubric)
          : undefined
    };
  }
}
