export type Social = {
  id: 'vk' | 'telegram' | 'twitter';
  title: string;
};

export const getRecordSocials = (): Social[] => [
  {
    id: 'vk',
    title: 'Поделиться ВКонтакте'
  },
  {
    id: 'telegram',
    title: 'Поделиться в Telegram'
  },
  {
    id: 'twitter',
    title: 'Поделиться в Twitter'
  }
];
