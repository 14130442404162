import { getSpbDateInfo } from '@fontanka/dates';

export const TAG_INFO = {
  PAGE_URL: '/text/tags/',
  DEFAULT_TAG: '',
  DEFAULT_START_PAGE: 1,
  PAGE_SIZE: 30,
  DEFAULT_RUBRIC: 'all',
  getDefaultDateTo: () => {
    const { day, month, year } = getSpbDateInfo();
    return `${day}.${month}.${year}`;
  }
};
