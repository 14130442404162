import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiAdvLabel } from '@fe/common/ui';
import { stripTags } from '@fontanka/news';

import { TabBtn } from '../../_buttons';

import styles from './switcher.styles.scss';

type TabInfo = {
  id: string;
  text: string;
  data: {
    id: number;
    header: string;
    urls: {
      url: string;
    };
    hasMainPhotoCommercialLabel: boolean;
  }[];
};

type Events = {
  onVisible: void;
  onTurnedTab: string;
  onClickedRecord: { recordId: number; activeTab: string };
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'Switcher',
    props: {
      tabs: {
        type: Array as () => TabInfo[],
        required: true as const
      }
    },
    data() {
      return {
        activeId: '',
        activeText: ''
      };
    },
    computed: {
      activeList(): TabInfo['data'] {
        return this.tabs.filter(item => item.id === this.activeId)?.[0]?.data;
      }
    },
    mounted() {
      this.$emit('visible');
    },
    methods: {
      turnTab(id: string, title: string) {
        this.$emit('turnedTab', title);
        this.activeId = id;
        this.activeText = title;
      },
      clickRecord(recordId: number) {
        this.$emit('clickedRecord', { recordId, activeTab: this.activeText });
      }
    },
    render() {
      return (
        <div class={styles.switcher}>
          <div class={styles.tabs}>
            {this.tabs.map((item, index) => {
              if (!this.activeId) {
                this.activeId = item.id;
                this.activeText = item.text;
              }

              // TODO: ДИЧАЙШИЙ КОСТЫЛЬ!!!
              // потому что switcher нужно отделить от доменной области
              if (index === 1) {
                return (
                  <div class={[styles.tabBtn, styles.wrapper]}>
                    <TabBtn
                      class={[styles.tabBtn, styles.desk]}
                      id={item.id}
                      text={item.text}
                      isActive={this.activeId === item.id}
                      onClicked={id => this.turnTab(id, item.text)}
                    />

                    <TabBtn
                      class={[styles.tabBtn, styles.mobile]}
                      id={item.id}
                      text="Читают"
                      isActive={this.activeId === item.id}
                      onClicked={id => this.turnTab(id, item.text)}
                    />
                  </div>
                );
              }

              return (
                <TabBtn
                  class={styles.tabBtn}
                  id={item.id}
                  text={item.text}
                  isActive={this.activeId === item.id}
                  onClicked={id => this.turnTab(id, item.text)}
                />
              );
            })}
          </div>

          <div class={styles.tab}>
            <ul class={[styles.list]}>
              {this.activeList?.map(record => (
                <li class={styles.item}>
                  <a
                    href={record.urls.url}
                    title={stripTags(record.header)}
                    domPropsInnerHTML={record.header}
                    onClick={() => {
                      this.clickRecord(record.id);
                    }}
                  />
                  {record.hasMainPhotoCommercialLabel && (
                    <UiAdvLabel hasFrame={false} class={styles.advMarker} />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  });
