import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './weather-forecast.styles.scss';

type ForecastType = {
  cloudTitle: string;
  date: string;
  dayTemperature: string;
  icon: string;
  iconSrc: string;
  nightTemperature: string;
  precipitationTitle: string;
  title: string;
  isWeekend: boolean;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    forecast: {
      type: Array as () => ForecastType[],
      required: true as const
    }
  },
  render() {
    return (
      <div class={styles.weatherForecast}>
        {this.forecast.map(weekday => (
          <div class={styles.weekdayBlock}>
            <div class={styles.titleBlock}>
              <span class={[styles.title, weekday.isWeekend ? styles.weekend : '']}>
                {weekday.title}
              </span>
              <span class={styles.subtitle}>{weekday.date}</span>
            </div>
            <div class={styles.weatherIcon}>
              <img src={weekday.iconSrc} alt={weekday.icon} />
            </div>
            <div class={styles.temperatureBlock}>
              <div class={styles.dayTemperature}>
                <span>{weekday.dayTemperature}</span>
                <span domPropsInnerHTML="&deg;"></span>
              </div>
              <div class={styles.nightTemperature}>
                <span>{weekday.nightTemperature}</span>
                <span domPropsInnerHTML="&deg;"></span>
              </div>
            </div>
            <span class={styles.description}>
              <span>{weekday.cloudTitle},</span>
              <span class={styles.whitespace} domPropsInnerHTML="&nbsp;"></span>
              <span>{weekday.precipitationTitle}</span>
            </span>
          </div>
        ))}
      </div>
    );
  }
});
