import {
  guardUnspecified,
  guardZeroNumber,
  guardEmptyString,
  guardEmptyArray
} from '@smh/utils/guards';

import { CropType } from '@fontanka/cropping';
import { isPhotoRecord, isVideoRecord, createImage } from '@fontanka/news';
import type {
  HomePageWindowItemDTO,
  HomePageWindowDTO,
  BaseRubricDTO,
  BaseThemeDTO
} from '@fontanka/news-api-client';

import type {
  NewWindow,
  WindowItemVM,
  WindowRubricVM,
  WindowThemeVM,
  WindowStatisticVM,
  WindowNewsTypeVM,
  WindowTaxonomyVM
} from '../domain';

const WINDOW_RECORD_IMAGE_WIDTH = 800;

const presentWindowItem = (
  data: HomePageWindowItemDTO,
  index: number,
  isMobile = false,
  isOurCollection = false
): WindowItemVM => {
  const type = isOurCollection
    ? isMobile
      ? 'ui-news-l'
      : 'ui-news-m'
    : presentWindowType(index, isMobile);

  return {
    index,
    type,
    id: data.id,
    title: data.header,
    isCommercial: data.isCommercial,
    isOnline: data.isActiveTranslation,
    subtitle: data.subheader,
    label: presentWindowLabel(data),
    url: data.urls?.url || '',
    hasImageMark: isPhotoRecord(data?.headerKeywords ?? []),
    hasVideoMark: data.hasVideoMark ?? isVideoRecord(data?.headerKeywords ?? []),
    themes:
      guardUnspecified(data.themes) && guardEmptyArray(data.themes)
        ? [presentWindowTheme(data.themes[0])]
        : [],
    rubrics:
      guardUnspecified(data.rubrics) && guardEmptyArray(data.rubrics)
        ? [presentWindowRubric(data.rubrics[0])]
        : [],
    formats: [],
    statistic: presentStatistic(data),
    image: createImage(data.mainPhoto ?? null, {
      width: WINDOW_RECORD_IMAGE_WIDTH,
      cropType: presentWindowImageType(type, isMobile),
      crop: true
    }),
    hasMainPhotoCommercialLabel: data.hasMainPhotoCommercialLabel ?? false
  };
};

const presentWindowImageType = (
  type: WindowNewsTypeVM,
  isMobile: boolean
): CropType => {
  switch (type) {
    case 'ui-news-main': {
      return isMobile ? CropType.FullFrame : CropType.Square;
    }
    case 'ui-news-vertical': {
      return CropType.Square;
    }
    default: {
      return CropType.FullFrame;
    }
  }
};

const presentWindowLabel = (
  item: HomePageWindowItemDTO
): WindowTaxonomyVM | null => {
  if (guardUnspecified(item.rubrics) && guardUnspecified(item.rubrics[0])) {
    return {
      id: item.rubrics[0].id,
      title: item.rubrics[0].name,
      url: item.rubrics[0].path
    };
  }

  if (guardUnspecified(item.themes) && guardUnspecified(item.themes[0])) {
    return {
      id: item.themes[0].id.toString(),
      title: item.themes[0].name,
      url: item.themes[0].path
    };
  }

  return null;
};

const presentWindowTheme = (item: BaseThemeDTO): WindowThemeVM => {
  return {
    id: item.id.toString(),
    title: item.name,
    url: item.path
  };
};

const presentWindowRubric = (item: BaseRubricDTO): WindowRubricVM => {
  return {
    id: item.id,
    title: item.name,
    url: item.path
  };
};

const presentComments = (
  data: HomePageWindowItemDTO
): WindowStatisticVM['comments'] | undefined => {
  const commentsCount = data.commentsCount ?? 0;
  const isDiscuss = !guardZeroNumber(commentsCount);
  const commentsUrl = data.urls?.urlComments ?? '';
  const discussUrl = `${commentsUrl}?discuss=1`;

  return data.isCommentsAllowed
    ? {
        text: isDiscuss ? 'Обсудить' : `${commentsCount}`,
        link: isDiscuss ? discussUrl : commentsUrl
      }
    : undefined;
};

const presentDate = (data: HomePageWindowItemDTO): WindowStatisticVM['date'] => {
  return guardUnspecified(data?.publishedAgo) && guardEmptyString(data.publishedAgo)
    ? {
        publishedAgo: data.publishedAgo
      }
    : undefined;
};

const presentStatistic = (data: HomePageWindowItemDTO): WindowStatisticVM => {
  return {
    comments: presentComments(data),
    date: presentDate(data)
  };
};

const presentWindowType = (index: number, isMobile: boolean): WindowNewsTypeVM => {
  switch (index) {
    case 0: {
      return 'ui-news-main';
    }
    case 1: {
      return isMobile ? 'ui-news-m' : 'ui-news-horizontal';
    }
    case 2: {
      return isMobile ? 'ui-news-m' : 'ui-news-horizontal';
    }
    case 3: {
      return isMobile ? 'ui-news-m' : 'ui-news-horizontal';
    }
    case 4: {
      return isMobile ? 'ui-news-very' : 'ui-news-horizontal';
    }
    case 8: {
      return isMobile ? 'ui-news-m' : 'ui-news-horizontal';
    }
    case 9: {
      return isMobile ? 'ui-news-vertical' : 'ui-news-horizontal';
    }
    default: {
      return 'ui-news-m';
    }
  }
};

export class WindowMapper {
  public static present(
    windowDTO: HomePageWindowDTO['data'],
    isMobile: boolean,
    isOurCollection: boolean
  ): NewWindow | null {
    return guardUnspecified(windowDTO)
      ? windowDTO.map((windowItemDTO, index) =>
          presentWindowItem(windowItemDTO, index, isMobile, isOurCollection)
        )
      : null;
  }
}
