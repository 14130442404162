import { getYandexCounters, getYandexRotorSettings } from '@smh/analytics/core';

const YANDEX_COUNTER_SETTINGS = {
  webvisor: true,
  clickmap: true,
  trackLinks: true,
  accurateTrackBounce: true,
  trackHash: true,
  triggerEvent: true
};

const ROTOR_SETTINGS = {
  waiterEnabled: true,
  failOnTimeout: false,
  noJsRedirectsToMain: true
};

const GOOGLE_UNIVERSAL_COUNTER = `<script>
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','//www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-28412007-5', 'auto', {'sampleRate':25});
ga('require', 'displayfeatures');
ga('send', 'pageview');

setTimeout(function() {
  ga('send', 'event', {
    eventCategory: location.pathname,
    eventAction: 'reading'
  });
}, 7000);
</script>`;

const GOOGLE_TAG = `<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-L3X39F5J22"></script>
<script>
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-L3X39F5J22');
</script>`;

export const LONGREADS_LI_COUNTER = `<br/><center><a href="//www.liveinternet.ru/click" target="_blank"><img src="//counter.yadro.ru/logo?14.5" title="LiveInternet: number of pageviews for 24 hours, of visitors for 24 hours and for today is shown" alt="" border="0" width="88" height="31"></a></center>`;

export const LIVE_INTERNET_COUNTER = `<!--LiveInternet counter--><script type="text/javascript">
new Image().src = "//counter.yadro.ru/hit;hsdigital/rn/smi/178?"+
escape(document.referrer)+((typeof(screen)=="undefined")?"":
";s"+screen.width+"*"+screen.height+"*"+(screen.colorDepth?
screen.colorDepth:screen.pixelDepth))+";u"+escape(document.URL)+
";h"+escape(document.title.substring(0,150))+
";"+Math.random();</script><!--/LiveInternet-->`;

export const MAIL_COUNTER = `<!-- Rating@Mail.ru counter -->
<script>
  var _tmr = window._tmr || (window._tmr = []);
  _tmr.push({
    id    : "104245",
    type  : "pageView",
    start : (new Date()).getTime()
  });
  (function (d, w, id) {
    if (d.getElementById(id)) return;
    var ts   = d.createElement("script");
    ts.type  = "text/javascript";
    ts.async = true;
    ts.id    = id;
    ts.src   = (d.location.protocol === "https:" ? "https:" : "http:") + "//top-fwz1.mail.ru/js/code.js";
    var f    = function () {
      var s = d.getElementsByTagName("script")[ 0 ];
      s.parentNode.insertBefore(ts, s);
    };
    if (w.opera === "[object Opera]") {
      d.addEventListener("DOMContentLoaded", f, false);
    } else {
      f();
    }
  })(document, window, "topmailru-code");
</script>
<noscript>
  <div>
    <img src="//top-fwz1.mail.ru/counter?id=104245;js=na" style="border:0;position:absolute;left:-9999px;" alt=""/>
  </div>
</noscript>
<!-- //Rating@Mail.ru counter -->`;

export const AJUR_COUNTER = `<!--AJUR counter-->
<script>
  // используется в плеерах и прочих интерактивах на странице
  var counter_url = 'https://ac.ajur.info/cgi-bin/ac.fcgi';

  (new Image()).src = counter_url +
                      '?site=fontanka' +
                      '&referrer=' + escape(document.referrer) +
                      '&url=' + escape(document.URL) +
                      '&advtopic=' + escape("FP") +
                      '&r=' + Math.random();

  /*
  ** Функция-счетчик показа заголовков
  ** @since 14.0.0-major.FONTANKARU-425.0
  **/
  function sendTitles() {
    // AJAX-запрос количества показов
    var xmlHttp;
    var titles = document.getElementsByClassName('count_show_title');
    var data   = '';

    for	(var i = 0; i < titles.length; i++) {
      var article  = titles[i].getAttribute('data-count-show-id');
      data = data + '&showtitle=' + encodeURIComponent(article);
    }

    var params = 'site=fontanka&url=' + encodeURIComponent(window.location.href) + data;

    xmlHttp = GetXmlHttpObject();

    if (xmlHttp == null) {
      alert('Ваш браузер не поддерживает AJAX!');
      return;
    }

    xmlHttp.open('POST', counter_url, true);
    xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xmlHttp.send(params);

    function GetXmlHttpObject() {
      var xmlHttp = null;

      try {
        xmlHttp = new XMLHttpRequest();
      }
      catch (e) {
        try {
          xmlHttp = new ActiveXObject('Msxml2.XMLHTTP');
        }
        catch (e) {
          xmlHttp = new ActiveXObject('Microsoft.XMLHTTP');
        }
      }

      return xmlHttp;
    }
  }

  document.addEventListener('DOMContentLoaded', sendTitles);
</script>
<!--/AJUR counter-->`;

export const TNS_COUNTER = `<!-- tns-counter.ru -->
<script>
  (new Image()).src = '//www.tns-counter.ru/V13a***R>' + document.referrer.replace(/\\*/g, '%2a') +
                      '*fontanka_ru/ru/CP1251/tmsec=fontanka_total/' + Math.round(Math.random() * 1E9);
</script>
<noscript>
  <img src="//www.tns-counter.ru/V13a****fontanka_ru/ru/CP1251/tmsec=fontanka_total/" width="1" height="1" alt=""/>
</noscript>
<!--/ tns-counter.ru -->`;

export const AUDITORY_TNS_COUNTER = `<!-- auditory tns-counter.ru -->
<script>
    ((counterHostname) => {
        window.MSCounter = {
            counterHostname: counterHostname
        };
        window.mscounterCallbacks = window.mscounterCallbacks || [];
        window.mscounterCallbacks.push(() => {
            msCounterExampleCom = new MSCounter.counter({
                    'account': 'fontanka_ru',
                    'tmsec': 'mx3_fontanka_ru',
                    'autohit' : true
                });
        });

        const newScript = document.createElement('script');
        newScript.async = true;
        newScript.src = counterHostname + '/ncc/counter.js';

        const referenceNode = document.querySelector('script');
        if (referenceNode) {
            referenceNode.parentNode.insertBefore(newScript, referenceNode);
        } else {
            document.firstElementChild.appendChild(newScript);
        }
    })('https://tns-counter.ru/');
</script>
<!--/ auditory tns-counter.ru -->`;

export const WEBORAMA_COUNTER = `<!-- Weborama -->
<script>
	var wamid = '3575';
	var typ   = '3';
	var Wvar  = [];
	Wvar.push("domain", location.host);
	(function () {
		var w    = document.createElement("script");
		w.type   = "text/javascript";
		w.src    = document.location.protocol + "//cstatic.weborama.fr/js/wam/customers/wamfactory_dpm.wildcard.min.js?rnd=" +
							 new Date().getTime();
		w.async  = true;
		var body = document.getElementsByTagName('script')[ 0 ];
		body.parentNode.insertBefore(w, body);
	})();
</script>
<!-- /Weborama -->


<!-- Default DMP Tag -->
<script type='text/javascript'>
	var wamid = '4671'; /* Hearst Shkulev WAM Account ID */
	var typ = '1'; /* Event Type default 1 */
	var Wvar = [];
	Wvar.push('crm_id','12345hftd');
	(function(){
	var w = document.createElement("script");
	w.type = "text/javascript";
	w.src = document.location.protocol + "//cstatic.weborama.fr/js/wam/customers/wamfactory_dpm.wildcard.min.js?rnd="+new Date().getTime();
	w.async = true;
	var body = document.getElementsByTagName('script')[0];
	body.parentNode.insertBefore(w,body);
	})();
</script>`;

export const ON_THE_IO_COUNTER = `<!-- Код onthe.io -->
<script async src="//cdn.onthe.io/io.js?U94cnXEDwOkF"></script>
<script>
	document.addEventListener('DOMContentLoaded', function() {
		var $dataIOContainer = document.querySelector('[data-io-article-url]');

    $dataIOContainer &&
    $dataIOContainer.setAttribute('data-io-article-url', window.location.href);
	});

	function genConfOntheIO() {
		var $pageType         = document.querySelector('meta[property="og:type"]'),
				$pageCanonicalUrl = document.querySelector('link[rel="canonical"]') || document.querySelector('meta[property="og:url"]');

		var ontheio_conf = {
			page_type          : $pageType ? $pageType.content : 'default',
			page_url           : window.location.href,
			page_url_canonical : $pageCanonicalUrl ? $pageCanonicalUrl.content ? $pageCanonicalUrl.content : $pageCanonicalUrl.href : '',
			// <meta og:title> не везде есть, используем содержание тега <title>
			page_title         : document.querySelector('title').textContent,
			page_language      : document.querySelector('html').lang || 'ru'
		};

		if (ontheio_conf.page_type === 'article') {
			var $metaAuthorsAJUR  = document.querySelector('meta[property="ajur:article:authors"]'),
					$metaRubricsAJUR  = document.querySelector('meta[property="ajur:article:rubrics"]'),
					$metaItemTypeAJUR = document.querySelector('meta[property="ajur:article:item_type"]'),
					$metaWordCount   	= document.querySelector('meta[property="ajur:article:word_count"]'),
					$metaPubTime      = document.querySelector('meta[property="ajur:article:published_time"]');

			ontheio_conf.article_authors          = $metaAuthorsAJUR && $metaAuthorsAJUR.content
				? $metaAuthorsAJUR.content.split(', ')
				: [];
			ontheio_conf.article_categories       = $metaRubricsAJUR && $metaRubricsAJUR.content
				? $metaRubricsAJUR.content.split(', ')
				: [];
			ontheio_conf.article_type             = $metaItemTypeAJUR ? $metaItemTypeAJUR.content : 'no_type';
			ontheio_conf.article_word_count       = $metaWordCount ? $metaWordCount.content : 0;
			ontheio_conf.article_publication_date = $metaPubTime ? new Date($metaPubTime.content).toUTCString() : '';
		}

		return ontheio_conf;
  }

  window._io_config            = window._io_config || {};
  window._io_config[ "0.2.0" ] = window._io_config[ "0.2.0" ] || [];
  window._io_config[ "0.2.0" ].push(genConfOntheIO());
</script>`;

export const getCounters = (params?: Record<string, unknown> & { host: string }) => {
  let GOOGLE_COUNTER = GOOGLE_TAG;

  if (params?.host?.match(/^liv\./)) {
    GOOGLE_COUNTER = GOOGLE_UNIVERSAL_COUNTER;
  }

  return (
    getYandexRotorSettings(ROTOR_SETTINGS) +
    getYandexCounters([6544318], {
      ...YANDEX_COUNTER_SETTINGS,
      params: params?.visitParams
    }) +
    GOOGLE_COUNTER +
    LIVE_INTERNET_COUNTER +
    MAIL_COUNTER +
    AJUR_COUNTER +
    TNS_COUNTER +
    AUDITORY_TNS_COUNTER +
    WEBORAMA_COUNTER
  );
};
