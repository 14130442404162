import { Subject } from 'rxjs';
import {
  ObsVisibilityHook,
  ObsVisibilityOptions,
  ObsVisibilityEvent
} from './observerVisibility.types';
import { OBSERVER_VISIBILITY_CONFIG } from './config';

export class ObserverVisibility {
  private readonly _hooks: ObsVisibilityHook | ObsVisibilityHook[];
  private readonly _customOptions: ObsVisibilityOptions | undefined;
  private readonly _event: Subject<ObsVisibilityEvent>;
  private readonly _options: ObsVisibilityOptions = {};

  private get _normalizeHooks(): ObsVisibilityHook[] {
    return typeof this._hooks === 'string' ? [this._hooks] : [...this._hooks];
  }

  get event() {
    return this._event;
  }

  constructor(
    hooks: ObsVisibilityHook | ObsVisibilityHook[],
    customOption?: ObsVisibilityOptions
  ) {
    this._hooks = hooks;
    this._customOptions = customOption;
    this._event = new Subject<ObsVisibilityEvent>();
    this._configureOptions();
  }

  getOptions(hook: ObsVisibilityHook) {
    try {
      this._checkHook(hook);

      return this._options[hook];
    } catch (e) {
      console.error(e);
    }
  }

  destroy() {
    this._event.complete();
    this._event.unsubscribe();
  }

  private _configureOptions() {
    this._normalizeHooks.forEach(hook => {
      const customOption = this._customOptions?.[hook] ?? {};
      this._options[hook] = {
        ...OBSERVER_VISIBILITY_CONFIG[hook],
        ...customOption,
        callback: this._getCallback(hook, this.event)
      };
    });
  }

  private _getCallback(
    hook: ObsVisibilityHook | string,
    event: Subject<ObsVisibilityEvent>
  ): Function {
    return function (value: boolean, entry: IntersectionObserverEntry) {
      event.next({
        hook,
        value,
        target: entry.target,
        isIntersecting: entry.isIntersecting
      });
    };
  }

  private _checkHook(hook: ObsVisibilityHook) {
    if (!this._options[hook]) {
      throw new Error(
        `Hook \'${hook}\' for observer visibility options is not defined`
      );
    }
  }
}
