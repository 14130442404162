import { getSpbDateInfo } from '@fontanka/dates';

export const SEARCH_INFO = {
  PAGE_URL: '/cgi-bin/search.scgi',
  DEFAULT_TEXT: '',
  DEFAULT_START_PAGE: 1,
  PAGE_SIZE: 30,
  DEFAULT_RUBRIC: '',
  DEFAULT_DATE_FROM: '2000-01-01',
  getDefaultDateTo: () => {
    const { day, month, year } = getSpbDateInfo();
    return `${year}-${month}-${day}`;
  }
};
