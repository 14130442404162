export type Params = {
  button?: Node;
  event: MouseEvent;
  popup: Node;
};

export const isOutsideClick = ({ popup, button, event }: Params) => {
  if (!event?.target) {
    return true;
  }

  const elements =
    (event as MouseEvent & { path: Node[] }).path ||
    (event.composedPath && event.composedPath());

  if (!elements) {
    return true;
  }

  if (button && (elements[0] === button || button.contains(<Node>event.target))) {
    return false;
  }

  if (popup && popup.contains(<Node>event.target)) {
    return false;
  }

  return true;
};
