import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './orange-form-wrapper.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    header: {
      type: String,
      default: ''
    }
  },
  render() {
    return (
      <div class={styles.wrapper}>
        {this.header ? <h1 class={styles.header}>{this.header}</h1> : null}

        {this.$slots.default}
      </div>
    );
  }
});
