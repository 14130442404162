import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './rubric-record-header-item.styles.scss';

type Record = {
  header: string;
  urls: {
    url: string;
  };
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'RubricRecordHeaderItem',
  props: {
    record: {
      type: Object as () => Record,
      required: true as const
    }
  },
  methods: {
    emitHeaderClicked(): void {
      this.$emit('headerClicked');
    }
  },
  render() {
    return (
      <a
        class={styles.link}
        href={this.record.urls.url}
        onClick={this.emitHeaderClicked}
      >
        <h3 class={styles.header} domPropsInnerHTML={this.record.header} />
      </a>
    );
  }
});
