import { isRegionIdMatchedWithGeoId, Regions } from '@portal/regions';
import {
  LoyaltyService,
  PageViewsLoyaltyService
} from '@portal/user-loyalty-service';
import { JtClickHouse } from '@smh/analytics/clickhouse';

import type { MediascopeRequiredParams } from '../mediascope-analytics';
import { MediascopeAnalytics } from '../mediascope-analytics';
import type { ReachGoalsParamsType } from '../yandex-analytics';
import { YandexAnalytics } from '../yandex-analytics';

enum UserLoyaltyGoals {
  BlockType = 'Пользователь',
  LoyaltyByLastVisitFieldType = 'Лояльность',
  LoyaltyByViewsFieldType = 'Лояльность (просмотры за 30 дней)',
  LoyaltyGroups = 'userLoyaltyGroups'
}

type ConfigParams = {
  yandexCounterId: number;
  domain: string;
  envType: string;
  mediascopeParams?: MediascopeRequiredParams;
};

type Config = {
  yandexCounterId: number;
  serverDate: string;
  domain: string;
  envType: string;
  mediascopeParams?: MediascopeRequiredParams;
};

type CustomMouseEvent = MouseEvent & {
  path: Node[];
};

type ClickHouseParams = string | ReachGoalsParamsType;

export const DATA_ATTR_REACH_GOAL_NAME = 'rg-name';

export enum RecordPageGoals {
  ReadComments = 'comment_read',
  WriteComment = 'comment_write',
  FbSharing = 'fb',
  VkSharing = 'vk',
  LjSharing = 'lj',
  TwitterSharing = 'twi',
  TlgSharing = 'tlg',
  ViberSharing = 'vbr',
  WhatsappSharing = 'wtsp',
  ViewRecordFeedback = 'ViewConnactionForm',
  ClickRecordFeedbackBtn = 'ClickConnactionFormText',
  ClickRecordFeedbackPhone = 'ClickConnactionFormPhone',
  ClickRecordFeedbackVK = 'ClickConnactionFormVK',
  ClickRecordFeedbackTG = 'ClickConnactionFormTG',
  ClickRecordFeedbackWA = 'ClickConnactionFormWA',
  ClickRecordFeedbackVB = 'ClickConnactionFormVB',
  ClickRecordFeedbackIG = 'ClickConnactionFormIG',
  ClickRecordFeedbackYT = 'ClickConnactionFormYT',
  SentUserToConnactionForm = 'SentUserToConnactionForm',

  ShareImageFB = 'SharePhotoBoxFB',
  ShareImageVK = 'SharePhotoBoxVK',
  ShareImageTW = 'SharePhotoBoxTW',
  ShareImageTG = 'SharePhotoBoxTG',
  ShareImageOK = 'SharePhotoBoxOK',
  ShareImageWA = 'SharePhotoBoxWA',
  ShareImageLink = 'SharePhotoBoxLink',

  ViewFancyBox = 'viewFancyBox',
  OpenFancyBox = 'openFancyBox',
  FancyBoxNavigation = 'navFancyBox',
  CloseFancyBox = 'closeFancyBox',

  ShareFancyBox = 'shareFancyBox',
  LogoFancyBox = 'logoFancyBox',

  GalleryNavigation = 'navGalleryBox',
  DoublePhotoChange = 'navChangeBox',

  AudioView = 'AudioView',
  AudioPause = 'AudioPause',
  AudioPlay = 'AudioPlay',
  AudioEnd = 'AudioEnd',

  VotesView = 'VotesView',
  VotesDone = 'VotesDone',
  VotesMore = 'VotesMore',

  VideoView = 'VideoView',
  VideoStart = 'VideoStart',
  VideoPause = 'VideoPause',
  VideoPlay = 'VideoPlay',
  VideoEnd = 'VideoEnd',
  VideoRePlay = 'VideoRePlay',
  VideoAdvViewed = 'VideoAdvViewed',

  ViewJoin = 'ViewJoin',
  ClickJoin = 'ClickJoin',
  ViewReadnow = 'ViewReadnow',
  ClickReadnow = 'ClickReadnow'
}

export enum BaseReachGoalsValues {
  View = 'Просмотр',
  Load = 'Загрузилось',
  ToGo = 'Переход',
  ToRecord = 'Переход на материал',
  ToAuthor = 'Переход на профиль автора',
  ToComments = 'Переход на комментарии'
}

export enum RecordAuthorReachGoalsNames {
  FieldType = 'Блок автора',
  View = 'viewAuthor',
  Click = 'clickAuthor'
}

export enum RecordTagsReachGoalsNames {
  FieldType = 'Теги',
  View = 'viewTags',
  Click = 'clickTags'
}

export enum RecordVotesReachGoalsNames {
  FieldType = 'Оценка материала',
  View = 'viewRating',
  Vote = 'clickRating',
  Revote = 'clickRe_Rating'
}

export enum RecordVotesReachGoalsValues {
  Vote = 'Проголосовал',
  Revote = 'Переголосовал'
}

export enum StickyPanelReachGoalsValues {
  ToComments = 'Клик комментарии',
  SharePage = 'Клик шаринг'
}

export enum StickyPanelReachGoalsNames {
  FieldType = 'Залипающая плашка',
  ToComments = 'clickcomment',
  SharePage = 'clicksharing'
}

export enum HeaderGoals {
  ClickMenu = 'clickMenu',
  InformerBlock = 'Информер',
  Weather = 'Погода',
  Currency = 'Курсы',
  AddNewsBtnBlock = 'Пришлите свою новость',
  AddNewsBtnClicked = 'Открыл форму',
  Projects = 'Проекты',
  Themes = 'Темы',
  Rubrics = 'Рубрики',
  Socials = 'Соц сети',
  Apps = 'Приложения',
  Logo = 'Логотип',
  Opened = 'Открыл',
  Closed = 'Закрыл',
  SearchBlock = 'Поиск',
  SearchOpenForm = 'Развернул форму',
  SearchClosedForm = 'Закрыл поиск',
  SearchSubmitForm = 'Обычный поиск'
}

export enum RecordCommentsPageGoals {
  WriteComment = 'comments_write1',
  ReadMoreComments = 'read_more_comments',
  ClickToRecord = 'viewArticle',
  ViewAddComment = 'viewAddComment',
  ClickAddComment = 'addComment',
  ViewDiscussions = 'viewAllDiscussions',
  ClickDiscussions = 'clickAllDiscussions',
  ClickReplyBtn = 'answerComment',
  ClickComment = 'clickOnComment',
  ClickCommentAnswers = 'clickCommentAnswers',
  ClickVoteComment = 'voteComment',
  ViewSorting = 'viewCommentsSorting',
  ClickSorting = 'sortComments',
  ViewNextComments = 'viewLoadComments',
  ClickNextComments = 'clickLoadComments',
  ViewCommentForm = 'viewCommentForm',
  ToRecord = 'Вернуться к публикации (кнопка)',
  AddCommentTop = 'Добавить комментарий (Вверху)',
  AddCommentBottom = 'Добавить комментарий (Внизу)',
  AllDiscussionsTop = 'Все дискуссии (Вверху)',
  AllDiscussionsBottom = 'Все дискуссии (Внизу)',
  Comment = 'Комментарий',
  Reply = 'Ответить',
  CommentsSorting = 'Сортировка комментариев',
  CurrentSorting = 'Текущая',
  ChangeSorting = 'Смена сортировки',
  AscSorting = 'Новые внизу',
  DescSorting = 'Новые вверху',
  ShowNextComments = 'Показать следующие комментарии',
  Click = 'Клик',
  CommentForm = 'Форма добавления комментария',
  AddComment = 'Отправить',
  CloseForm = 'Закрыть',
  SignOut = 'Разлогиниться'
}

export enum AuthFormGoals {
  ViewAuthForm = 'viewAuthorisationForm',
  EnterAuthForm = 'enterAuthorisationForm',
  RegisterAuthForm = 'registerFromAuthorisationForm',
  EnterViaSocial = 'enterViaSocial',
  ClickAuthForm = 'clickAuthorisationForm',
  AuthForm = 'Форма авторизации',
  SignIn = 'Вход',
  Register = 'Регистрация',
  LoginPassword = 'Логин/пароль',
  ForgotPassword = 'Забыли пароль?',
  FB = 'fb',
  VK = 'vk'
}

export enum SwitcherGoals {
  FieldType = 'Картина дня (блок)',
  ViewBlock = 'viewDayPicBlock',
  ClickBlock = 'clickDayPicBlock'
}

export enum FontankaPROGoals {
  BlockTitle = 'Фонтанка PRO (заголовок)',
  FieldType = 'Фонтанка PRO',
  FieldTypeTiles = 'Фонтанка PRO',
  ViewBlock = 'viewFtPRO',
  ClickBlock = 'clickFtPRO'
}

export enum ToGoTodayGoals {
  BlockTitle = 'Куда пойдем сегодня (заголовок)',
  FieldType = 'Куда пойдем сегодня (блок)',
  ViewBlock = 'viewToGoToday',
  ClickBlock = 'clickToGoToday'
}

export enum FeedbackGoals {
  BlockTitle = 'Форма связи с редакцией (в материале)',
  BlockTitleMainPage = 'Форма связи с редакцией',
  Click = 'ContactInreadclick',
  View = 'ContactInreadview',
  Sent = 'ContactInreadsent'
}

export enum CompaniesNewsGoals {
  BlockTitle = 'Новости компаний (заголовок)',
  FieldType = 'Новости компаний (блок)',
  SlideNext = 'листание вправо',
  SlidePrev = 'листание влево',
  ViewBlock = 'viewCompNews',
  ClickBlock = 'clickCompNews'
}

export enum NewsHourGoals {
  FieldType = 'Новость часа',
  ViewBlock = 'viewNewsHour',
  ClickBlock = 'clickNewsHourClick'
}

export enum SpecialOpinionGoals {
  BlockTitle = 'Особое мнение (заголовок)',
  FieldType = 'Особое мнение',
  ViewBlock = 'viewSpecialOpinion',
  ClickBlock = 'clickSpecialOpinion'
}

export enum OpinionGoals {
  BlockTitle = 'Мнение (заголовок)',
  FieldType = 'Мнение',
  ViewBlock = 'viewOpinion',
  ClickBlock = 'clickOpinion'
}

export enum OpinionsSliderGoals {
  ClickBlockValue = 'Переход',
  ClickBlock = 'clickOpinion',
  ClickHeaderValue = 'Мнение (заголовок)',
  FieldType = 'Мнение (блок)',
  ViewBlockValue = 'Просмотр',
  ViewBlock = 'viewOpinion'
}

export enum ShowcaseTileGoals {
  FieldType = 'Витрина',
  ViewBlock = 'viewShowcase',
  ClickBlock = 'clickShowcase',
  Commercial = 'Рекламный',
  Editorial = 'Редакционный',
  BigImage = 'Большая картинка',
  SmallImage = 'Маленькая картинка',
  WithoutImage = 'Без картинки'
}

export enum DiscussionsGoals {
  FieldType = 'Обсуждения (блок)',
  BlockTitle = 'Обсуждения (заголовок)',
  ViewBlock = 'viewDiscussions',
  ClickBlock = 'clickDiscussions'
}

export enum WeSocialsGoals {
  FieldType = 'Мы в соцсетях',
  VkWidget = 'Виджет Вконтакте',
  ViewBlock = 'viewSMM',
  ClickBlock = 'clickSMM'
}

export enum WidgetAutoGoals {
  FieldType = 'Авто.ру (блок)',
  ToOffer = 'Переход на объявление',
  ToSite = 'Переход на сайт',
  ClickSellAuto = 'Кнопка Продать автомобиль',
  ClickAllAuto = 'Все автомобили (кнопка)',
  ScrollOffers = 'Скроллинг объявлений',
  ViewBlock = 'viewAutoRuBlock',
  ClickBlock = 'clickAutoRuBlock',
  ScrollBlock = 'clickAutoRuScroll'
}

export enum OurCollectionGoals {
  FieldType = 'Наша коллекция (блок)',
  FieldHeaderClickValue = 'Наша коллекция (заголовок)',
  ViewBlock = 'viewOurCollection',
  ClickBlock = 'clickOurCollection'
}

export enum QuotationGoals {
  ClickBlockValue = 'Переход',
  ClickBlock = 'clickQuote',
  ClickCommentsValue = 'Переход на комментарии',
  FieldType = 'Цитата',
  ViewBlockValue = 'Просмотр',
  ViewBlock = 'viewQuote'
}

export enum RubricListGoals {
  FieldType = 'Все рубрики (блок)',
  FieldValue = 'Переход на новости по рубрике',
  ViewBlock = 'viewAllRub',
  ClickBlock = 'clickAllRub'
}

export enum OpinionBlockGoals {
  FieldType = 'Мнение (карточка)',

  ArticleClickedValue = 'Переход',
  ViewValue = 'Просмотр',
  TitleClickedValue = 'Мнение (заголовок)',
  CommentsClickedValue = 'Переход на комментарии',

  ViewBlock = 'viewOpinion',
  ClickBlock = 'clickOpinion'
}

export enum ProfileGoals {
  ClickBlock = 'clickProfile'
}

export enum UserAdBlockReachGoals {
  Enabled = 'userAdBlockTrue',
  Disabled = 'userAdBlockFalse'
}

export enum NewsFeedGoals {
  AllNewsBtn = 'Все новости (кнопка)',
  ArchiveBtn = 'Архив новостей (кнопка)',
  ClickBlock = 'clickNewsFeed',
  Commercial = 'Рекламный',
  DefaultRecord = 'Обычная новость',
  Editorial = 'Редакционный',
  FirstFieldType = 'Лента новостей',
  HoursBtn = 'За 24 часа (кнопка)',
  PhotoIcon = 'Фото',
  PictureOfTheDay = 'Картина дня',
  PrevNewsBtn = 'Предыдущие новости (кнопка)',
  SecondFieldType = 'Спб',
  VideoIcon = 'Видео',
  ViewBlock = 'viewNewsFeed',
  WithoutIcon = 'Без значков'
}

export enum PictureOfTheDayGoals {
  View = 'viewDayPicBlock',
  Click = 'clickDayPicBlock'
}

export enum RecordSocialsGoals {
  JoinFieldType = 'Подписка SMM (блок)',
  SocialsFieldType = 'Шаринг(Внизу)',
  JoinClickedGoal = 'clickSMMSubscribeBlock',
  JoinViewGoal = 'viewSMMSubscribeBlock',
  SocialsViewGoal = 'viewShareBlockBot',
  SocialsClickedGoal = 'clickShareBlockBot',
  SocialsView = 'Просмотр',
  SocialsClick = 'Клик на',
  JoinView = 'Просмотр',
  JoinClick = 'Клик'
}

export enum RecordHeaderGoals {
  FieldType = 'Шапка материала',
  ViewBlock = 'viewHeadArt',
  ClickPrint = 'clickHeadPrint',
  ClickTheme = 'clickHeadTheme',
  ClickRubric = 'clickHeadRub',
  ClickComments = 'clickHeadDiscussTxt',
  Text = 'Текст'
}

export enum RubricNewsGoals {
  FieldType = 'Материалы рубрики',
  ViewBlock = 'viewSameRubricItemsBlock',
  ClickBlock = 'clickSameRubricItemsBlock'
}

export enum InImageAdvGoals {
  FieldType = 'Баннер на фото',
  ShowBlock = 'InimageView',
  View = 'Просмотр'
}

export enum TagPageReachGoalsNames {
  SubmitForm = 'filterapply',
  ClickArticle = 'clickarticlepagetags',
  ChangePage = 'clicknextpagetags'
}

export enum TagPageReachGoals {
  BlockType = 'Центральная колонка',
  FilterField = 'Фильтр',
  FilterApply = 'Применить',
  ListField = 'Выдача',
  GoToArticle = 'Переход',
  ChangePage = 'Переход на другую страницу'
}

export enum AuthorPageReachGoals {
  BlockType = 'Центральная колонка',
  FieldType = 'Материалы автора',
  ViewRecord = 'Просмотр',
  GoToRecord = 'Переход на материал',
  GoToComments = 'Переход в комментарии'
}

export enum AuthorPageReachGoalsNames {
  ViewAuthors = 'viewAuthors',
  ClickAuthors = 'clickAuthors'
}

export const STORIES_EVENT_MAP = {
  slideOpened: {
    value: 'Открыл',
    goalName: 'OpenStories'
  },
  slideViewed: {
    value: 'Просмотрел',
    goalName: 'ViewStories'
  },
  sliderClosed: {
    value: 'Закрыл',
    goalName: 'CloseStories'
  },
  swipePrev: {
    value: 'Свайп назад',
    goalName: 'SwypeBackStories'
  },
  swipeNext: {
    value: 'Свайп вперед',
    goalName: 'SwypeForwardStories'
  },
  clickNext: {
    value: 'Клик вперед',
    goalName: 'ClickForwardStories'
  },
  clickPrev: {
    value: 'Клик назад',
    goalName: 'ClickBackStories'
  },
  longPressed: {
    value: 'Удержание',
    goalName: 'HoldingStories'
  },
  slideLinkClicked: {
    value: 'Переход',
    goalName: 'LinkStories'
  },
  scrollStoriesLeft: {
    value: 'Скролл ленты',
    goalName: 'ScrollStoriesLeft'
  },
  scrollStoriesRight: {
    value: 'Скролл ленты',
    goalName: 'ScrollStoriesRight'
  },
  seenStories: {
    value: 'Увидел блок',
    goalName: 'SeenStories'
  }
};

export type RecordHeaderBlockGoals =
  | RecordHeaderGoals.ViewBlock
  | RecordHeaderGoals.ClickComments
  | RecordHeaderGoals.ClickRubric
  | RecordHeaderGoals.ClickTheme
  | RecordHeaderGoals.ClickPrint;

export const ACTIONS_BY_RG_HEADER: Record<RecordHeaderBlockGoals, string> = {
  [RecordHeaderGoals.ViewBlock]: 'Просмотр',
  [RecordHeaderGoals.ClickComments]: 'Клик Обсудить',
  [RecordHeaderGoals.ClickRubric]: 'Клик Рубрика',
  [RecordHeaderGoals.ClickTheme]: 'Клик Тема',
  [RecordHeaderGoals.ClickPrint]: 'Клик Печать'
};

export type CommentsBlockGoalsTypes =
  | CommentsBlockGoals.ViewBlock
  | CommentsBlockGoals.ClickAdd
  | CommentsBlockGoals.ClickCommentAuthor
  | CommentsBlockGoals.ClickViewAll;

export enum CommentsBlockGoals {
  FieldType = 'Комментарии к материалу (блок)',
  ViewBlock = 'viewCommentsBlock',
  ClickAdd = 'addComment',
  ClickViewAll = 'viewAllComments',
  ClickCommentAuthor = 'clickOnComment',
  ClickCommentText = 'clickOnComment',
  ClickCommentsHead = 'clickCommentsHead',
  VoteComment = 'voteComment'
}

export enum ReadAlsoBlockGoals {
  FieldType = 'Читайте также (блок)',
  GoalName = 'viewAlsoReadBlock'
}

export enum ArchiveRecordlistGoals {
  FieldType = 'Материал',
  ViewValueType = 'Просмотр',
  ViewGoalName = 'viewArticle',
  ClickArticleValueType = 'Переход на материал',
  ClickArticleGoalName = 'clickArticle',
  ClickArticleCategoryValueType = 'Переход на новости по рубрике',
  ClickArticleCategoryGoalName = 'clickArticleCategory',
  ViewAllCommentsValueType = 'Переход на комментарии',
  ViewAllCommentsGoalName = 'viewAllComments',
  RollDayArticlesFieldType = 'Статьи за день',
  RollDayArticlesGoalName = 'rollDayArticles'
}

export enum TopNewsGoals {
  FieldType = 'ТОП5 (блок)',
  ViewGoalName = 'viewTopPopularBlock',
  ViewValueType = 'Просмотр',
  ClickGoalName = 'clickTopPopularBlock',
  ClickRecordValueType = 'Переход на материал',
  ClickCommentsValueType = 'Переход на комментарии'
}

export enum AllAuthorsPageGoals {
  FieldType = 'Список авторов',
  ViewGoalName = 'viewAuthorsList',
  ViewValueType = 'Просмотр',
  ClickGoalName = 'clickAuthorsList',
  ClickValueType = 'Клик'
}

export const ACTIONS_BY_RG_COMMENTS: Record<
  Exclude<keyof typeof CommentsBlockGoals, 'FieldType'>,
  string
> = {
  ViewBlock: 'Просмотр',
  ClickAdd: 'Добавить комментарий (кнопка)',
  ClickViewAll: 'Читать все комментарии',
  ClickCommentAuthor: 'Переход на профиль автора',
  ClickCommentText: 'Текст комментария',
  ClickCommentsHead: 'Комментарии (заголовок)',
  VoteComment: 'Голосование'
};

export const enum AppInviteAnalytics {
  ViewGoal = 'AppInviteView',
  ClickGoal = 'AppInviteClick',
  Place = 'Центральная колонка',
  Block = 'Приглашение в приложение',
  ActionView = 'Просмотр',
  ActionClick = 'Клик'
}

export const enum RecordTrafficBlockReachGoalsNames {
  ViewSmi2Block = 'ViewSmi2Block',
  ClickSmi2Block = 'ClickSmi2Block',
  ViewSmi24Block = 'View24smiBlock',
  ClickSmi24Block = 'Click24smiBlock',
  ViewSparrowBlock = 'ViewSparrowBlock',
  ClickSparrowBlock = 'ClickSparrowBlock'
}

export const enum RecordTrafficBlockReachGoalsActions {
  Click = 'Клик',
  View = 'Просмотр'
}

export const enum RecordTrafficBlockReachGoalsValues {
  Smi2 = 'СМИ2 (блок)',
  Smi24 = '24СМИ (блок)',
  Sparrow = 'Sparrow (блок)'
}

export class AnalyticsService {
  private _config: Config = {
    yandexCounterId: 0,
    serverDate: '',
    domain: '',
    envType: ''
  };

  private _yandexAnalytics: YandexAnalytics;

  private _loyaltyService: LoyaltyService;

  private _jtClickHouse: JtClickHouse;

  private _mediascopeAnalytics: MediascopeAnalytics;

  private _pageViewsLoyaltyService: PageViewsLoyaltyService;

  constructor(config: ConfigParams) {
    this._config = {
      ...this._config,
      ...config
    };

    this._yandexAnalytics = new YandexAnalytics(this._config.yandexCounterId);

    if (this._config.envType === '') {
      this._jtClickHouse = new JtClickHouse(this._config.domain);
    }

    if (this._config.mediascopeParams !== undefined) {
      this._mediascopeAnalytics = new MediascopeAnalytics(
        this._config.mediascopeParams
      );
    }
  }

  public get loyaltyUserStatus() {
    const { loyalty } = this._loyaltyService;

    return loyalty;
  }

  public initLoyaltyServices(geoRegionId: number, serverDate = '') {
    this._config.serverDate = serverDate;

    const isUserRegion = isRegionIdMatchedWithGeoId(Regions.Fontanka, geoRegionId);

    this._loyaltyService = new LoyaltyService(isUserRegion, serverDate);
    this._pageViewsLoyaltyService = new PageViewsLoyaltyService();
  }

  public sendLoyaltyYMReachGoal(experiments: string[]) {
    const { loyalty, daysFromLastVisit } = this._loyaltyService;
    const params = {
      [UserLoyaltyGoals.BlockType]: {
        [UserLoyaltyGoals.LoyaltyByLastVisitFieldType]: {
          [loyalty]: daysFromLastVisit.toString()
        }
      }
    };

    this.sendYMReachGoal(UserLoyaltyGoals.LoyaltyGroups, params);
    this.sendClickHouseEvent(params, UserLoyaltyGoals.LoyaltyGroups, experiments);
  }

  public sendViewsLoyaltyYMReachGoal() {
    const { loyalty: pageViewsLoyalty, views } =
      this._pageViewsLoyaltyService.getPageViewsLoyalty(this._config.serverDate);

    const params = {
      [UserLoyaltyGoals.BlockType]: {
        [UserLoyaltyGoals.LoyaltyByViewsFieldType]: {
          [pageViewsLoyalty]: views.toString()
        }
      }
    };

    this.sendYMUserParams(params);
  }

  public sendClickEventMetrics(event: MouseEvent, experiments: string[]) {
    if (!event) {
      return;
    }

    const customEvent = event as CustomMouseEvent;
    const elements =
      customEvent.path || (customEvent.composedPath && customEvent.composedPath());

    if (elements) {
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i] as HTMLElement;
        if (typeof element.getAttribute === 'function') {
          const reachGoalName = element.getAttribute(
            `data-${DATA_ATTR_REACH_GOAL_NAME}`
          );
          if (reachGoalName) {
            this._yandexAnalytics.sendReachGoal(reachGoalName);
            this.sendClickHouseEvent({}, reachGoalName, experiments);
            break;
          }
        }
      }
    }
  }

  public sendYMReachGoal(
    reachGoalName: string,
    params?: string | ReachGoalsParamsType
  ) {
    this._yandexAnalytics.sendReachGoal(reachGoalName, params);
  }

  public sendYMUserParams(params?: string | ReachGoalsParamsType) {
    this._yandexAnalytics.sendUserParams(params);
  }

  public sendYMParams(reachGoalName: string, goalParams: ReachGoalsParamsType) {
    this._yandexAnalytics.sendReachGoal(reachGoalName, goalParams);
  }

  public sendClickHouseEvent(
    params: ClickHouseParams,
    eventName: string,
    experiments: string[]
  ) {
    if (this._config.envType === '') {
      this._jtClickHouse.sendEvent({
        url: window.location.href,
        referrer: document.referrer,
        eventName,
        experiments,
        params
      });
    }
  }

  public getMediascopeCounter(recordId: number) {
    this._mediascopeAnalytics.fetchCounter(recordId);
  }
}
