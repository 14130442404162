import type { WidgetAutoSliderOffer } from '@fe/common/features/widget-auto/domain';
import type { WidgetAutoOfferDTO } from '@fontanka/news-api-client';

export class WidgetAutoMapper {
  public static toSliderDO(
    autoOffersDTO: WidgetAutoOfferDTO[]
  ): WidgetAutoSliderOffer[] {
    return autoOffersDTO.map(offer => ({
      url: offer.url,
      imageUrl: offer.image['320x240'] || offer.image.small || '',
      title: `${offer.mark} ${offer.model}`,
      description: `${offer.year}${offer.mileage ? `, ${offer.mileage} км` : ''}`,
      price: `${offer.price.toLocaleString('ru')} ₽`
    }));
  }
}
