import { componentFactoryOf } from 'vue-tsx-support';
import { useModule } from 'vuex-simple';

import type { AdvModule } from '@apps/frontend';
import { ADV_MODULE_NAMESPACE, injectStylesMixin, storeMixin } from '@apps/frontend';

import { LazyAdv } from '../adv';

import FntCrossBtnIcon from '~icons/fnt-common/cross-btn';

import styles from './overlay-modal.styles.scss';

type Events = {
  onClose: (value: string) => void;
};

type ComponentData = {
  isRender: boolean;
};

const CLOSE_ICON_SIZE = 24;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(storeMixin)
  .create({
    name: 'OverlayModal',
    props: {
      modalId: {
        type: String,
        required: true
      },
      shouldReserveAdvArea: {
        type: Boolean,
        default: false
      }
    },
    data(): ComponentData {
      return {
        isRender: false
      };
    },
    computed: {
      advModule(): AdvModule | undefined {
        return useModule<AdvModule>(this.$store, ADV_MODULE_NAMESPACE);
      },
      isOpened() {
        return this.store.isModalOpened;
      }
    },
    mounted() {
      this.isRender = true;

      document.addEventListener('keyup', this.pressKey);
    },
    beforeDestroy() {
      document.removeEventListener('keyup', this.pressKey);
    },
    methods: {
      pressKey(event: KeyboardEvent) {
        const ESC = 27;
        const { keyCode } = event;
        if (keyCode === ESC) {
          this.hideModal();
        }
      },
      hideModal() {
        this.$emit('close', this.modalId);
      }
    },
    render() {
      return (
        <div class={styles.overlay}>
          {!this.isOpened && this.$slots.default}

          {this.isRender && (
            <modal
              name={this.modalId}
              adaptive={true}
              height="100%"
              width="100%"
              onClosed={() => this.hideModal()}
            >
              <div class={styles.content}>
                <div class={styles.wrapper}>{this.$slots.default}</div>
                {this.advModule?.fancyBoxAdv && (
                  <LazyAdv
                    class={[
                      styles.adv,
                      this.shouldReserveAdvArea ? styles.advContainer : ''
                    ]}
                    adv={this.advModule.fancyBoxAdv}
                    clientRender={true}
                  />
                )}
              </div>
              <div onClick={() => this.hideModal()} class={styles.closeIcon}>
                <FntCrossBtnIcon width={CLOSE_ICON_SIZE} height={CLOSE_ICON_SIZE} />
              </div>
            </modal>
          )}
        </div>
      );
    }
  });
