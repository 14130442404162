import { VideoAttachDTO } from '@fontanka/news-api-client';
import { VideoAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createVideoAttach = (attach: VideoAttachDTO): VideoAttach => ({
  type: 'videos',
  value: {
    ...attach.value,
    url: attach.value.urls.big ? attach.value.urls.big : attach.value.url,
    previewImage: createImage(attach.value.previewImage),
    ratio:
      attach.value.width / attach.value.height > 1
        ? `${attach.value.width}:${attach.value.height}`
        : '1:1'
  }
});
