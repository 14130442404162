import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './weather-day.styles.scss';

type DayType = {
  sunrise: string;
  sunset: string;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    day: {
      type: Object as () => DayType,
      required: true as const
    }
  },
  render() {
    return (
      <div class={styles.weatherDay}>
        <p class={styles.daytimeGroup}>
          <span class={styles.label}>Восход: </span>
          <span>{this.day.sunrise}</span>
        </p>
        <p class={styles.daytimeGroup}>
          <span class={styles.label}>Закат: </span>
          <span>{this.day.sunset}</span>
        </p>
      </div>
    );
  }
});
