import type { CovidWidget } from '@fe/common/domain';
import type { CovidWidgetDTO } from '@fontanka/news-api-client';

export class CovidWidgetMapper {
  public static toDO(covidWidgetDTO: CovidWidgetDTO['data']): CovidWidget | null {
    if (covidWidgetDTO === null) {
      return null;
    }

    return {
      link: covidWidgetDTO.link,
      covidInfo: covidWidgetDTO.covid19Info.map(info => ({
        city: info.city,
        infected: this._numberToLocal(info.infected),
        infectedDiff: this._numberToLocal(info.infectedDiff),
        cured: this._numberToLocal(info.cured),
        curedDiff: this._numberToLocal(info.curedDiff),
        died: this._numberToLocal(info.died),
        diedDiff: info.diedDiff ? this._numberToLocal(info.diedDiff) : ''
      })),
      record:
        covidWidgetDTO.record !== null
          ? {
              url: covidWidgetDTO.record.urls.url,
              id: covidWidgetDTO.record.id,
              header: covidWidgetDTO.record.header
            }
          : null
    };
  }

  private static _numberToLocal(n: number): string {
    return n.toLocaleString('ru-RU').replace(/,/, '.');
  }
}
