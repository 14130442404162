import { isBrowser } from '@fontanka/browser-or-node';

import { BaseHttpClient, Config, Result, PageResult } from '../base-http-client';
import { BaseRecordDTO, KeywordsDTO, LeadDTO, ImageDTO, AuthorDTO } from '../dtos';
import { BaseParams } from '../params';

import { SearchPageDTO } from './search-page-dto';

export type SearchParams = {
  format?: string;
  theme?: string;
  author?: string;
  page: number;
  pagesize: number;
  date?: string;
  period?: number;
  rubricId?: 'all' | string;
};

export enum SearchSortType {
  Date = 'date',
  Weight = 'weight'
}

export type SeachPageParams = BaseParams & {
  text: string;
  page: number;
  pagesize: number;
  rubric?: string;
  sort?: SearchSortType;
  dateFrom?: string;
  dateTo?: string;
};

export type SearchResult<T> = {
  data: T;
  meta?: {
    total: number;
  };
};

export type SearchRecordDTO = BaseRecordDTO & {
  commentsCount: number;
  headerKeywords: KeywordsDTO;
  isCommentsAllowed: boolean;
  lead: LeadDTO;
  mainPhoto: ImageDTO | null;
  subheader: string;
  authors: AuthorDTO[];
};

export class SearchHttpClient extends BaseHttpClient {
  private readonly _searchPageUrl = '/pages/fontanka/search';

  private readonly _searchUrl = '/public/fontanka/services/archive/';

  constructor(config: Config) {
    super(config);
  }

  public async fetchSearchPageData(
    params: SeachPageParams
  ): Promise<Result<SearchPageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._searchPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<SearchPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        ...params,
        headers: undefined
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async searchRecords(
    params: SearchParams
  ): Promise<SearchResult<SearchRecordDTO[] | null>> {
    const { author, format, theme, page, pagesize, date, period, rubricId } = params;
    const url = `${this.host}${this._searchUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<
      SearchResult<SearchRecordDTO[] | null>
    >(url, {
      withCredentials,
      params: {
        regionId: this.regionId,
        author,
        format,
        theme,
        page,
        pagesize,
        date,
        period,
        rubricId
      }
    });

    return response.data;
  }
}
