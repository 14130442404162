import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './error-msg.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  render() {
    return <div class={styles.errorMsg}>{this.$slots.default}</div>;
  }
});
