












import { Component, Prop, Vue } from 'vue-property-decorator';
import { useStore } from 'vuex-simple';

import type { RootModule } from '../../core';
import { AdvSlot } from '../adv-slot';
import { LazyHydrateSsr } from '../lazy-hydrate-ssr';

type AdvData = {
  sticky: boolean;
  types?: {
    desktop?: boolean;
    laptop?: boolean;
    tablet?: boolean;
    mobile?: boolean;
  };
  data: string;
} | null;

const RECORD_WITHOUT_ADV = [71983220, 73014437, 73014698, 73015250];

const RELOAD_TIME = 60000;

@Component({
  name: 'AdvMobileStickyRsyaBody',
  components: {
    LazyHydrateSsr,
    AdvSlot
  }
})
export default class AdvMobileStickyRsya extends Vue {
  @Prop({
    default: null,
    type: Object as () => AdvData
  })
  adv: AdvData | null;
  @Prop({
    default: false,
    type: Boolean
  })
  isReload: boolean;

  isClosed = false;

  store = useStore<RootModule>(this.$store);

  get isMobile() {
    return this.store.baseInfo.isMobile;
  }

  get advData() {
    return this.adv?.data || '';
  }

  get isAdblock() {
    return this.store.isAdblock;
  }

  get recordId() {
    return this.$route.name === 'record' ? Number(this.$route.params.id) : 0;
  }

  get isRender(): boolean {
    if (RECORD_WITHOUT_ADV.includes(this.recordId)) {
      return false;
    }

    if (this.isAdblock) {
      return false;
    }

    return true;
  }

  onClose() {
    this.isClosed = true;

    if (this.isReload) {
      this.reload();
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRender(event: any) {
    const { stickyAdNode } = event.detail;
    const stickyParent = stickyAdNode.parentElement;
    if (stickyParent) {
      stickyParent.style.bottom = '80px';
      stickyParent.style.zIndex = '1000';
    }
  }

  reload() {
    setTimeout(() => {
      this.isClosed = false;
    }, RELOAD_TIME);
  }
}
