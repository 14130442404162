import {
  ArticleRotatedInsertsDTO,
  ArticleTextItemDTO
} from '@fontanka/news-api-client';

import { RecordTextItem } from '../../domain';

import {
  createCustomWidgetAttach,
  createIframeAttach,
  createImageAttach,
  createGifAttach,
  createTextAttach,
  createVideoAttach,
  createViqeoAttach,
  createWQuoteAttach,
  createGalleryAttach,
  createLogotypeAttach,
  createDocumentsAttach,
  createAudiosAttach,
  createAdvAttach,
  createAppendAttach,
  createPollAttach,
  createImagesPollAttach,
  createDoublePhotoAttach,
  createTestAttach,
  createTestResultAttach,
  createFeedbackAttach,
  createSpoilerAttach,
  createForeignAgentAttach
  // RotateAttachesMapper
} from './attaches';

type CreateAttachInput = {
  attach: ArticleTextItemDTO;
  recordId?: number;
  rotatedInserts?: ArticleRotatedInsertsDTO;
};

export const createAttach = (input: CreateAttachInput): RecordTextItem | null => {
  const { attach } = input;

  switch (attach.type) {
    case 'customWidget':
      return createCustomWidgetAttach(attach);
    case 'iframe':
      return createIframeAttach(attach);
    case 'images':
      return createImageAttach(attach);
    case 'gif':
      return createGifAttach(attach);
    case 'text':
      return createTextAttach(attach);
    case 'videos':
      return createVideoAttach(attach);
    case 'viqeoVideo':
      return createViqeoAttach(attach);
    case 'wquote':
      return createWQuoteAttach(attach);
    case 'gallery':
      return createGalleryAttach(attach);
    case 'logotype':
      return createLogotypeAttach(attach);
    case 'documents':
      return createDocumentsAttach(attach);
    case 'audios':
      return createAudiosAttach(attach);
    case 'append':
      return createAppendAttach(attach);
    case 'poll':
      return createPollAttach(attach);
    case 'imagesPoll':
      return createImagesPollAttach(attach);
    case 'doublePhoto':
      return createDoublePhotoAttach(attach);
    case 'test':
      return createTestAttach(attach);
    case 'testResult':
      return createTestResultAttach(attach);
    case 'feedback':
      return createFeedbackAttach(attach);
    case 'spoiler':
      return createSpoilerAttach(attach);
    case 'adv':
    case 'desktop_record_inread_advert':
    case 'desktop_record_inread_advert_2':
    case 'desktop_record_inread_advert_3':
    case 'mobile_record_inread_advert':
    case 'mobile_record_inread_advert_2':
    case 'mobile_record_inread_advert_3':
    case 'mobile_record_inread_advert_4':
    case 'mobile_record_inread_advert_5':
    case 'mobile_record_inread_advert_6':
      return createAdvAttach(attach, 1);
    case 'foreignAgent':
      return createForeignAgentAttach(attach);
    // case 'rotatedInserts':
    //   if (guardUnspecified(rotatedInserts)) {
    //     return RotateAttachesMapper.execute(attach, rotatedInserts, recordId);
    //   }
    //   return null;
    default:
      return null;
  }
};
