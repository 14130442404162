import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import styles from './our-collection-item.styles.scss';

type ImageData = {
  url: string;
  sources: {
    srcset: string;
    type: string;
  }[];
} | null;

type Events = {
  onRecordClicked: number;
  onRecordViewed: number;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'OurCollectionItem',
    props: {
      recordId: {
        type: Number,
        required: true as const
      },
      header: {
        type: String,
        required: true as const
      },
      recordUrl: {
        type: String,
        required: true as const
      },
      image: {
        type: Object as () => ImageData,
        default: null
      }
    },
    data() {
      return {
        ourCollectionVisibility: new ObserverVisibility('half')
      };
    },
    mounted() {
      this.ourCollectionVisibility.event.subscribe(state => {
        if (state.value === true) {
          this.ourCollectionRecordViewed();
        }
      });
    },
    beforeDestroy() {
      this.ourCollectionVisibility.destroy();
    },
    methods: {
      ourCollectionRecordClicked() {
        this.$emit('recordClicked', this.recordId);
      },
      ourCollectionRecordViewed() {
        this.$emit('recordViewed', this.recordId);
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.ourCollectionVisibility.getOptions('half')}
          class={styles.container}
          onClick={this.ourCollectionRecordClicked}
        >
          <a class={styles.link} href={this.recordUrl}>
            {this.image !== null && (
              <UiImage class={styles.image} sources={this.image.sources}>
                <img src={this.image.url} alt="" />
              </UiImage>
            )}
            <h5 class={styles.header} domPropsInnerHTML={this.header} />
          </a>
        </div>
      );
    }
  });
