













import { Component, Prop, Vue } from 'vue-property-decorator';

import type { Color, Theme, ColorScheme } from './ui-label.contract';

@Component({
  name: 'UiLabel'
})
export default class UiLabel extends Vue {
  @Prop({
    type: String as () => Theme,
    default: 'smallText'
  })
  theme: Theme;

  @Prop({
    type: String as () => ColorScheme,
    default: 'none'
  })
  colorScheme: ColorScheme;

  @Prop({
    type: String as () => Color,
    default: 'none'
  })
  color: Color;

  @Prop({
    type: Boolean,
    default: false
  })
  hasBorder: boolean;
}
