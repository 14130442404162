import { Getter, State } from 'vuex-simple';

import { type AdvData } from '@fe/common/domain';
import { AdvDataMapper } from '@fe/common/services';

import { type RootModule } from '../../../core';

export const NEW_ADV_MODULE_NAMESPACE = ['new_adv_module'];

export class NewAdvModule {
  @State()
  public adv: AdvData;

  constructor(private _root: RootModule) {
    this.adv = AdvDataMapper.toDO(
      this._root.pageSpec.config.advert,
      this._root.pageSpec.data
    );
  }

  @Getter()
  public get mobileFullscreenAdv() {
    return this.adv.mobile_fullscreen_advert ?? null;
  }

  @Getter()
  public get headerAdvert() {
    return this.adv.header_advert ?? null;
  }

  @Getter()
  public get mobileTopAdv() {
    return this.adv[11161] ?? null;
  }

  @Getter()
  public get newsFeed1Adv() {
    return this.adv[11624] ?? null;
  }

  @Getter()
  public get newsFeed2Adv() {
    return this.adv[11627] ?? null;
  }

  @Getter()
  public get mobileStickyAdv() {
    return this.adv.mobile_sticky_advert ?? null;
  }
}
