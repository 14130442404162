import { AudiosAttachDTO } from '@fontanka/news-api-client';
import { AudiosAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createAudiosAttach = (attach: AudiosAttachDTO): AudiosAttach => ({
  type: 'audios',
  value: {
    author: attach.value.author,
    description: attach.value.description,
    url: attach.value.url,
    previewImage: createImage(attach.value.previewImage)
  }
});
