






import AmazeIcon from '~icons/fnt-ui-reactions/amaze';
import AngryIcon from '~icons/fnt-ui-reactions/angry';
import AnxiousIcon from '~icons/fnt-ui-reactions/anxious';
import CoolIcon from '~icons/fnt-ui-reactions/cool';
import CryIcon from '~icons/fnt-ui-reactions/cry';
import ExpressiolessIcon from '~icons/fnt-ui-reactions/expressioless';
import FireIcon from '~icons/fnt-ui-reactions/fire';
import FlushedIcon from '~icons/fnt-ui-reactions/flushed';
import FrowningIcon from '~icons/fnt-ui-reactions/frowning';
import HeartIcon from '~icons/fnt-ui-reactions/heart';
import LoudlyCryingIcon from '~icons/fnt-ui-reactions/loudly-crying';
import PooIcon from '~icons/fnt-ui-reactions/pile-of-poo';
import SmileIcon from '~icons/fnt-ui-reactions/smile';
import SmilingEyesIcon from '~icons/fnt-ui-reactions/smiling-eyes';
import SunglassesIcon from '~icons/fnt-ui-reactions/sunglasses';
import ThinkingIcon from '~icons/fnt-ui-reactions/thinking';
import TongueIcon from '~icons/fnt-ui-reactions/tongue';
import WinkingFaceIcon from '~icons/fnt-ui-reactions/winking-face';
import WithoutMouthIcon from '~icons/fnt-ui-reactions/without-mouth';

import { Component, Prop, Vue } from 'vue-property-decorator'

import type { EmojiType } from './ui-emoji.contract';

@Component({
  name: 'Emoji',
})
export default class UiEmoji extends Vue {
  @Prop({
    type: String as () => EmojiType,
    required: true,
  })
  type: EmojiType;

  emojiIcons = {
    cool: CoolIcon,
    smile: SmileIcon,
    amaze: AmazeIcon,
    angry: AngryIcon,
    cry: CryIcon,
    smilingEyes: SmilingEyesIcon,
    tongue: TongueIcon,
    sunglasses: SunglassesIcon,
    winkingFace: WinkingFaceIcon,
    expressioless: ExpressiolessIcon,
    heart: HeartIcon,
    fire: FireIcon,
    frowning: FrowningIcon,
    anxious: AnxiousIcon,
    pileOfPoo: PooIcon,
    flushed: FlushedIcon,
    loudlyCrying: LoudlyCryingIcon,
    withoutMouth: WithoutMouthIcon,
    thinking: ThinkingIcon,
    default: SmileIcon,
  };

  get icon() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.emojiIcons[this.type] ?? this.emojiIcons.default;
  }
}
