import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { SearchRubrics } from '../../../domain';
import type { SearchPage } from '../domain';
import { SearchPageMapper } from '../services';

export const SEARCH_PAGE_MODULE_NAMESPACE = ['search_page_module'];

export class SearchPageModule {
  @State()
  public blocks: SearchPage['blocks'];

  @State()
  public rubrics: SearchRubrics;

  constructor(private _root: RootModule) {
    const { rubrics, blocks } = SearchPageMapper.toDO(this._root.pageSpec);

    this.rubrics = rubrics;
    this.blocks = blocks;
  }
}
