import { format } from 'date-fns';
import type DatePicker from 'tui-date-picker';
import { componentFactoryOf } from 'vue-tsx-support';
import type {
  InputHTMLAttributes,
  SyntheticEvent,
  FormHTMLAttributes
} from 'vue-tsx-support/types/dom';

import { injectStylesMixin } from '@apps/frontend';

import { OrangeBtn } from '../../_buttons';
import { FormSelect } from '../../_forms';

// eslint-disable-next-line import/no-unassigned-import
import 'tui-date-picker/dist/tui-date-picker.css';
import styles from './search-filter.styles.scss';

type ComponentData = {
  currentText: string;
  currentOptionId: string;
  currentDateFrom: string;
  currentDateTo: string;
  dateFromPicker: DatePicker | null;
  dateToPicker: DatePicker | null;
};

type ParamsMap = {
  text: string;
  option: string;
  sort: string;
  dateFrom: string;
  dateTo: string;
};

type Option = {
  id: string;
  name: string;
};

type DateRange = {
  dateFrom: string;
  dateTo: string;
};

enum SortType {
  Date = 'date',
  Weight = 'weight'
}

type SortTab = {
  id: SortType;
  text: string;
  isActive: boolean;
  link: string;
};

type AllOptionId = 'all';

type Events = {
  onSearchClicked: void;
  onTextChanged: string;
  onOptionChanged: 'all' | string;
  onDateChanged: DateRange;
};

const MIN_TEXT_LENGTH = 3;
const SORT_TABS_INFO: SortTab[] = [
  {
    id: SortType.Date,
    text: 'Дате',
    isActive: false,
    link: ''
  },
  {
    id: SortType.Weight,
    text: 'Релевантности',
    isActive: false,
    link: ''
  }
];

const DATE_PICKER_LOCALE = {
  titles: {
    // days
    DD: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота'
    ],
    // // daysShort
    D: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    // months
    MMMM: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    // monthsShort
    MMM: [
      'Янв',
      'Фев',
      'Мар',
      'Апр',
      'Май',
      'Июн',
      'Июл',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек'
    ]
  },
  titleFormat: 'MMMM yyyy',
  todayFormat: 'MMMM dd, yyyy',
  date: 'Date',
  time: 'Time'
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      paramsMap: {
        type: Object as () => ParamsMap,
        required: true as const
      },
      searchUrl: {
        type: String,
        required: true as const
      },
      searchUrlWithParams: {
        type: String,
        required: true as const
      },
      text: {
        type: String,
        required: false,
        default: ''
      },
      optionId: {
        type: String as () => AllOptionId | string,
        required: true as const,
        default: 'all'
      },
      sortType: {
        type: String as () => SortType,
        required: false,
        default: 'date'
      },
      options: {
        type: Array as () => Option[],
        required: true as const,
        default: []
      },
      // yyyy-MM-dd
      dateFrom: {
        type: String,
        required: true as const
      },
      // yyyy-MM-dd
      dateTo: {
        type: String,
        required: true as const
      },
      userDateFormat: {
        type: String,
        required: false,
        default: 'dd.MM.yyyy'
      },
      requestDateFormat: {
        type: String,
        required: false,
        default: 'yyyy-MM-dd'
      }
    },
    data(): ComponentData {
      return {
        currentText: this.text,
        currentOptionId: this.optionId,
        currentDateFrom: this.dateFrom,
        currentDateTo: this.dateTo,
        dateFromPicker: null,
        dateToPicker: null
      };
    },
    computed: {
      userDate(): DateRange {
        return {
          dateFrom: this.formatDate(new Date(this.currentDateFrom), 'user'),
          dateTo: this.formatDate(new Date(this.currentDateTo), 'user')
        };
      },
      currentOptionIdForRequest(): string {
        return this.currentOptionId !== 'all' ? this.currentOptionId : '';
      },
      sortTabs(): SortTab[] {
        return SORT_TABS_INFO.map(tab =>
          tab.id === this.sortType
            ? { ...tab, isActive: true }
            : { ...tab, link: this.makeSortTabsUrl() }
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async mounted() {
      const { default: datePicker } = await import('tui-date-picker');

      datePicker.localeTexts['ru'] = DATE_PICKER_LOCALE;

      this.dateFromPicker = new datePicker(
        this.$refs['dateFromCalendar'] as HTMLElement,
        {
          date: new Date(this.dateFrom),
          language: 'ru',
          input: {
            element: this.$refs['dateFromPicker'] as HTMLElement
          }
        }
      );

      this.dateFromPicker.on('change', this.changeDateFrom);

      this.dateToPicker = new datePicker(
        this.$refs['dateToCalendar'] as HTMLElement,
        {
          date: new Date(this.dateTo),
          language: 'ru',
          input: {
            element: this.$refs['dateToPicker'] as HTMLElement
          }
        }
      );

      this.dateToPicker.on('change', this.changeDateTo);
    },
    methods: {
      submitForm(event: SyntheticEvent<FormHTMLAttributes, Event>) {
        if (this.currentText.length < MIN_TEXT_LENGTH) {
          event.preventDefault();
          this.showTextError();
        }
      },
      showTextError() {
        alert('Поисковая фраза должна быть длиннее двух символов');
      },
      changeText(event: SyntheticEvent<InputHTMLAttributes, Event>) {
        this.currentText = event.target.value?.toString() ?? '';
      },
      changeOption(optionId: AllOptionId | string) {
        this.currentOptionId = optionId;
      },
      makeSortTabsUrl() {
        return `${this.searchUrlWithParams}&${this.paramsMap.sort}=${
          this.sortType === SortType.Date ? SortType.Weight : SortType.Date
        }`;
      },
      formatDate(date: Date, formatType: 'user' | 'request') {
        return format(
          date,
          formatType === 'user' ? this.userDateFormat : this.requestDateFormat
        );
      },
      changeDateFrom() {
        this.currentDateFrom = this.formatDate(
          this.dateFromPicker?.getDate() ?? new Date(),
          'request'
        );
      },
      changeDateTo() {
        this.currentDateTo = this.formatDate(
          this.dateToPicker?.getDate() ?? new Date(),
          'request'
        );
      }
    },
    render() {
      return (
        <form
          class={styles.searchFilter}
          action={this.searchUrl}
          method="get"
          accept-charset="utf-8"
          onSubmit={this.submitForm}
        >
          <div class={styles.paramsWrapper}>
            <div class={styles.topLine}>
              <input
                class={styles.text}
                name={this.paramsMap.text}
                type="text"
                value={this.currentText}
                placeholder="Введите текст для поиска"
                onInput={this.changeText}
              />

              <div class={styles.selectWrapper}>
                <input
                  name={this.paramsMap.option}
                  type="hidden"
                  value={this.currentOptionIdForRequest}
                />
                <FormSelect
                  currentOptionId={this.currentOptionId}
                  options={this.options}
                  onOptionChanged={this.changeOption}
                />
              </div>

              <OrangeBtn class={styles.searchBtn} type="submit">
                Найти
              </OrangeBtn>
            </div>

            <div class={styles.bottomLine}>
              <input
                name={this.paramsMap.dateFrom}
                type="hidden"
                value={this.currentDateFrom}
              />

              <input
                name={this.paramsMap.dateTo}
                type="hidden"
                value={this.currentDateTo}
              />

              <span class={styles.dateRange}>Период: </span>

              <span ref="dateFromPicker" class={[styles.dateRange, styles.date]}>
                {this.userDate.dateFrom}
              </span>
              <span class={styles.calendarItem} ref="dateFromCalendar"></span>

              <span class={styles.dateRange}> – </span>

              <span ref="dateToPicker" class={[styles.dateRange, styles.date]}>
                {this.userDate.dateTo}
              </span>
              <span class={styles.calendarItem} ref="dateToCalendar"></span>
            </div>
          </div>

          <div class={styles.sortWrapper}>
            <div class={styles.sort}>
              <input
                name={this.paramsMap.sort}
                type="hidden"
                value={this.sortType}
              />

              <span class={styles.sortTitle}>Сортировать по:</span>

              {this.sortTabs.map(tab => (
                <span class={[styles.sortTab, tab.isActive ? styles.active : '']}>
                  {!tab.isActive ? (
                    <a class={styles.sortLink} href={tab.link}>
                      {tab.text}
                    </a>
                  ) : (
                    tab.text
                  )}
                </span>
              ))}
            </div>
          </div>
        </form>
      );
    }
  });
