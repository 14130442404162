import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './ui-image.styles.scss';

type Sources = { srcset: string; type: string; media?: string };

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'UiImage',
  props: {
    sources: {
      type: Array as () => Sources[],
      required: true as const
    }
  },
  render() {
    return (
      <picture class={styles.picture}>
        {this.sources.map(source => (
          <source srcset={source.srcset} type={source.type} media={source.media} />
        ))}
        {this.$slots.default}
      </picture>
    );
  }
});
