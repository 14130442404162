import { getCalculatedResult } from '@fontanka/news';
import { PollAttachDTO, PollResultsDTO } from '@fontanka/news-api-client';
import { PollAnswer, PollAttach, PollResults } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createPollAttach = (attach: PollAttachDTO): PollAttach => ({
  type: 'poll',
  value: {
    pollId: attach.value.id,
    title: attach.value?.header || '',
    image: attach.value.image ? createImage(attach.value.image) : null,
    answers: attach.value.answers.map(answerDTO => ({
      answer: answerDTO.id,
      content: answerDTO.description,
      isUserAnswer: false,
      totalVotes: 0,
      calculatedResult: '0',
      lineProgressWidth: '0%'
    })),
    isMultiple: attach.value.settings.multipleChoice,
    needAuth: attach.value.settings.onlyAuthorized,
    isResultsHidden: attach.value.settings.hideResults,
    isFinished: !attach.value.settings.isActive,
    isNumber: attach.value.settings.isNumber,
    hasUserAnswers: false,
    needShowHiddenResults: false,
    needShowCalculatedResults: false
  }
});

export const createAttachPollResults = (
  pollResultsDTO: PollResultsDTO['data']
): PollResults => ({
  answers: pollResultsDTO.answers,
  votersTotal: pollResultsDTO.votedUsersCount
});

export const createPollAnswer = (
  answer: PollAnswer,
  isNumber: boolean,
  pollResults: PollResults,
  totalVotes: number
): PollAnswer => {
  const currentTotalVotes =
    pollResults.answers?.find(el => el.id === answer.answer)?.votesCount ?? 0;

  const calculatedResult = currentTotalVotes
    ? getCalculatedResult(currentTotalVotes, totalVotes, isNumber)
    : {
        calculatedResult: answer.calculatedResult,
        lineProgressWidth: answer.lineProgressWidth
      };

  return {
    ...answer,
    ...calculatedResult,
    isUserAnswer:
      pollResults.answers?.find(el => el.id === answer.answer)?.isVoted ?? false,
    totalVotes: currentTotalVotes
  };
};

export const createPollValue = (
  pollValue: PollAttach['value'],
  pollResults: PollResults
): PollAttach['value'] => {
  const hasUserAnswers = pollResults.answers.some(el => el.isVoted);
  const canShowResults = hasUserAnswers && !pollValue.isResultsHidden;
  const totalVotes =
    pollValue.isMultiple && pollResults.answers
      ? pollResults.answers.reduce((acc, current) => acc + current.votesCount, 0)
      : pollResults.votersTotal;

  return {
    ...pollValue,
    answers: pollValue.answers.map(answer =>
      createPollAnswer(answer, pollValue.isNumber, pollResults, totalVotes)
    ),
    hasUserAnswers,
    needShowHiddenResults:
      !pollValue.isFinished && pollValue.isResultsHidden && hasUserAnswers,
    needShowCalculatedResults: pollValue.isFinished || canShowResults
  };
};
