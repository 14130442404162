import { format } from 'date-fns';
import * as locale from 'date-fns/locale';

import type { DayTimeDTO, WeatherPageContentDTO } from '@fontanka/news-api-client';
import { capitalizeFirstLetter } from '@fontanka/strings';

import type { WeatherData } from '../domain';
import { WEATHER_DATE_FORMAT, getWeatherIconByType } from '../domain';

const getFormattedTodayHeader = (): string => {
  const today = new Date();
  return `${format(today, WEATHER_DATE_FORMAT, { locale: locale.ru })}, сегодня`;
};

const getColumnFormattedDate = (date: string): string => {
  // 'dd MM' -> 'MM dd'
  const reversedDate = date.split('.').reverse().join('-');

  const currentYear = new Date().getFullYear();
  return `${format(new Date(`${currentYear}-${reversedDate}`), WEATHER_DATE_FORMAT, {
    locale: locale.ru
  })}`;
};

export class WeatherMapper {
  public static readonly DAY_TIME_MAP = {
    morning: 'Утром',
    day: 'Днём',
    evening: 'Вечером',
    night: 'Ночью'
  };

  public static readonly WIND_DIRECTION_MAP = {
    south: 'Ю',
    east: 'В',
    west: 'З',
    north: 'С',
    south_east: 'ЮВ',
    south_west: 'ЮЗ',
    north_east: 'СВ',
    north_west: 'СЗ'
  };

  public static readonly WIND_DIRECTION_STYLES_MAP = {
    south: 'south',
    east: 'east',
    west: 'west',
    north: 'north',
    south_east: 'southEast',
    south_west: 'southWest',
    north_east: 'northEast',
    north_west: 'northWest'
  };

  public static readonly DAY_TIME_KEYS_SORTED = [
    'morning',
    'day',
    'evening',
    'night'
  ];

  public static readonly WEEK_LENGTH = 7;

  public static toDO(weatherDTO: WeatherPageContentDTO): WeatherData {
    const forecast = weatherDTO.forecast.map(weekday => {
      const date = getColumnFormattedDate(weekday.date);
      const title = capitalizeFirstLetter(weekday.title);
      const isWeekend = ['сб', 'вс'].includes(weekday.title.toLowerCase());
      const cloudTitle = capitalizeFirstLetter(weekday.cloudTitle);
      const iconSrc = getWeatherIconByType(weekday.icon);

      return { ...weekday, date, title, cloudTitle, iconSrc, isWeekend };
    });

    if (forecast.length > WeatherMapper.WEEK_LENGTH) {
      forecast.length = WeatherMapper.WEEK_LENGTH;
    }

    const today = WeatherMapper.DAY_TIME_KEYS_SORTED.map(dayTimeKey => {
      const dayTime = WeatherMapper.DAY_TIME_MAP[dayTimeKey];
      const day = weatherDTO.today[dayTimeKey] as DayTimeDTO;
      const iconSrc = getWeatherIconByType(day.icon);
      const windDirectionStyle = WeatherMapper.WIND_DIRECTION_STYLES_MAP[
        day.windDirection
      ] as string;
      const windDirectionName = WeatherMapper.WIND_DIRECTION_MAP[
        day.windDirection
      ] as string;
      const cloudTitle = capitalizeFirstLetter(day.cloudTitle);

      const mappedDay = {
        ...day,
        iconSrc,
        windDirection: {
          style: windDirectionStyle,
          name: windDirectionName
        },
        name: dayTime,
        cloudTitle
      };

      return mappedDay;
    });

    const todayHeader = getFormattedTodayHeader();

    return {
      day: weatherDTO.day,
      forecast,
      today,
      todayHeader
    };
  }
}
