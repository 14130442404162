import { guardUnspecified, guardZeroNumber } from '@smh/utils/guards';
import { isAbsoluteUrl } from '@smh/utils/url';

import { CropType } from '@fontanka/cropping';
import { createImage, type Image } from '@fontanka/news';
import type {
  HomePageRecommendationDTO,
  HomePageRecommendationItemDTO
} from '@fontanka/news-api-client';

import type {
  RecommendationItem,
  RecommendationBlockData,
  RecommendationMainPhoto
} from '../domain';

import { BaseArticlePresenter } from './base-article';

const IMAGE_WIDTH = 295;

const baseArticlePresenter = new BaseArticlePresenter();

export class RecommendationMapper {
  static readonly recordUpLimit = 5;

  public static toDO(
    blockData: HomePageRecommendationDTO['data']
  ): RecommendationBlockData {
    return {
      title: 'Другие СМИ',
      records: blockData
        .map(this._presentRecommendation)
        .slice(0, this.recordUpLimit)
    };
  }

  private static _presentRecommendation(
    data: HomePageRecommendationItemDTO,
    index: number
  ): RecommendationItem {
    const isPrimary = !guardZeroNumber(index);

    const link: string = data.urls?.url ?? '';

    const image: Image | null = isPrimary
      ? createImage(data.mainPhoto, {
          width: IMAGE_WIDTH,
          crop: true,
          cropType: CropType.FullFrame
        })
      : null;

    const mainPhoto: RecommendationMainPhoto = guardUnspecified(image)
      ? { ...image, isShow: true }
      : { isShow: false };

    const statistic = {
      ...baseArticlePresenter.presentStatistic(data),
      isShow: isPrimary
    };

    return {
      id: data.id,
      place: `Place${index + 1}`,
      title: {
        text: data.header,
        link,
        target: isAbsoluteUrl(link) ? '_blank' : '_self'
      },
      isPrimary,
      mainPhoto,
      statistic
    };
  }
}
