import { Action, Getter, State, Mutation } from 'vuex-simple';

import type { AuthorInList } from '@fontanka/news';

import { RootModule } from '../../../core';
import type { MetaAuthors, AuthorsData } from '../domain';
import { AllAuthorsPageMapper, AuthorsDataMapper } from '../services';

type FetchAuthorsParams = {
  pagesize?: number;
  text?: string;
  sort?: string;
};

export const ALL_AUTHORS_PAGE_MODULE_NAMESPACE = ['all-authors_page_module'];

export class AllAuthorsPageModule {
  private readonly _authorsPageSize = 30;

  private _authorsDataMapper: AuthorsDataMapper;

  constructor(private _root: RootModule) {
    this._authorsDataMapper = new AuthorsDataMapper(this._root.envType);

    const { authors, meta } = AllAuthorsPageMapper.toDO(this._root.pageSpec);

    this._authors = authors;
    this._metaAuthors = meta;
  }

  @State()
  private _authors: AuthorInList[];

  @State()
  private _searchedAuthors: AuthorInList[];

  @State()
  private _metaAuthors: MetaAuthors;

  /**
   * Начинаем со 2-ой страницы, первая приходит в спеке
   */
  @State()
  private _currentAuthorsPage = 2;

  @Getter()
  public get authors(): AuthorInList[] {
    return this._authors ?? [];
  }

  @Getter()
  public get searchedAuthors(): AuthorInList[] {
    return this._searchedAuthors ?? [];
  }

  @Getter()
  public get hasMoreAuthors(): boolean {
    return this._metaAuthors.total > this._authors.length;
  }

  @Action()
  public async searchAuthors(searchQuery: string): Promise<void> {
    this.setSearchedAuthors([]);

    const params = {
      text: searchQuery,
      sort: 'alphabetAsc'
    };

    try {
      const { authors } = await this._fetchAuthors(params);

      this.setSearchedAuthors(authors);
    } catch (err) {
      console.error(err);
    }
  }

  @Action()
  public clearSearchedList(): void {
    this._searchedAuthors = [];
  }

  @Action()
  public async fetchAuthorsByPage(): Promise<void> {
    const params = {
      page: this._currentAuthorsPage,
      pagesize: this._authorsPageSize,
      sort: 'alphabetAsc'
    };

    try {
      const { authors, meta } = await this._fetchAuthors(params);

      this.setAuthors(authors);
      this.setMetaAuthors(meta);

      this._currentAuthorsPage++;
    } catch (err) {
      console.error(err);
    }
  }

  @Mutation()
  public setMetaAuthors(meta: MetaAuthors): void {
    this._metaAuthors = {
      ...this._metaAuthors,
      ...meta
    };
  }

  @Mutation()
  public setAuthors(authors: AuthorInList[]): void {
    this._authors = [...this._authors, ...authors];
  }

  @Mutation()
  public setSearchedAuthors(authors: AuthorInList[]): void {
    this._searchedAuthors = authors;
  }

  private _fetchAuthors(params: FetchAuthorsParams): Promise<AuthorsData> {
    return this._authorsDataMapper.getAuthors(params);
  }
}
