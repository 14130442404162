import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './orange-btn.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    theme: {
      type: String as () => 'default' | 'simple' | 'big',
      default: 'default'
    },
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    link: {
      type: String,
      default: ''
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  render() {
    return this.link ? (
      <a
        class={[styles.orangeBtn, styles[this.theme]]}
        href={this.link}
        target={this.target}
      >
        {this.$slots.default}
      </a>
    ) : (
      <button
        class={[styles.orangeBtn, styles[this.theme]]}
        type={this.type}
        disabled={this.disabled ? this.disabled : undefined}
      >
        {this.$slots.default}
      </button>
    );
  }
});
