import { guardUnspecified } from '@smh/utils/guards';

import { createImage } from '@fontanka/news';
import type {
  HomePageCompanyNewsDTO,
  HomePageCompanyNewsItemDTO
} from '@fontanka/news-api-client';

import type { CompanyNews, CompanyNewsRecord } from '../domain';

const COMPANY_NEWS_IMAGE_WIDTH = 220;

export class CompanyNewsMapper {
  public static toDo(blockNews: HomePageCompanyNewsDTO['data']): CompanyNews | null {
    if (guardUnspecified(blockNews)) {
      return {
        title: 'Новости компаний',
        url: '/pressrelease',
        records: blockNews.map(this._presentRecord)
      };
    }
    return null;
  }

  private static _presentRecord(
    item: HomePageCompanyNewsItemDTO
  ): CompanyNewsRecord {
    return {
      id: item.id,
      title: item.header,
      lead: item.leadForNews,
      image: guardUnspecified(item.mainPhoto)
        ? createImage(item.mainPhoto, { width: COMPANY_NEWS_IMAGE_WIDTH })
        : null,
      url: item.urls.url,
      statistic: {
        date: {
          publishedAgo: item.publishedAgo ?? '5 минут назад'
        }
      },
      hasMainPhotoCommercialLabel: item.hasMainPhotoCommercialLabel ?? false
    };
  }
}
