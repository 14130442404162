import { truncate } from '@smh/utils/strings';

import type { CropType } from '@fontanka/cropping';
import {
  leadToString,
  isAudioRecord,
  isPhotoRecord,
  isVideoRecord,
  createImage
} from '@fontanka/news';
import type {
  FontankaProArticleVM,
  CommonFontankaProItemDTO
} from '@fontanka/news-api-client';

type Params = {
  crop?: boolean;
  cropType?: CropType;
  width?: number;
  height?: number;
};

export class FontankaProArticleMapper {
  public static characterLimit = 90;

  public static execute(
    recordDTO: CommonFontankaProItemDTO,
    params?: Params
  ): FontankaProArticleVM {
    let { header, commentsCount, isCommentsAllowed, headerKeywords, publishAt } =
      recordDTO;
    const {
      id,
      lead,
      mainPhoto,
      urls,
      hasVideoMark,
      isCommercial,
      hasMainPhotoCommercialLabel
    } = recordDTO;

    header = truncate({
      length: FontankaProArticleMapper.characterLimit,
      postfix: '...',
      subject: header
    });

    isCommentsAllowed = isCommentsAllowed ?? false;
    commentsCount = commentsCount ?? 0;
    headerKeywords = headerKeywords ?? [];
    publishAt = publishAt ?? '';

    return {
      id,
      header,
      isCommentsAllowed,
      commentsCount,
      publishAt,
      isCommercial,
      canShowComments: isCommentsAllowed && commentsCount > 0,
      leadStr: leadToString(lead ?? []),
      image: createImage(mainPhoto ?? null, params),
      urls: urls ?? {
        url: '',
        urlComments: ''
      },
      hasAudio: isAudioRecord(headerKeywords),
      hasPhoto: isPhotoRecord(headerKeywords),
      hasVideo: hasVideoMark ?? isVideoRecord(headerKeywords),
      hasMainPhotoCommercialLabel: hasMainPhotoCommercialLabel ?? false
    };
  }
}
