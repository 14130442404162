import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { UiButton } from '@fe/common/components';
import type { AnswersCommentItem, RecordComment } from '@fe/common/domain';
import { injectStylesMixin } from '@fe/common/mixins';
import { typography } from '@fe/common/styles/typography';

import FntActionPlusIcon from '~icons/fnt-common/action-plus';

import { plural } from '@fontanka/strings';

import FntRepliesToCommentsIcon from '~icons/fnt-common/replies-to-comments';

import { VuePortalTarget } from '@fontanka/vue-portal';

import FntReplyToCommentIcon from '~icons/fnt-common/reply-to-comment';

import { UiAvatar } from '../../ui-avatar';
import { CommentHeader } from '../comment-header';
import { CommentsVote } from '../comments-vote';
import { DirectComment } from '../direct-comment';

import styles from './record-comment.styles.scss';

type VoteEvent = { id: number; parentId?: number };

type Events = {
  onReplyLinkClicked: number;
  onDirectCommentClicked: number;
  onRepliesToggled: number;
  onCommentAuthorClicked: number;
  onCommentTextClicked: number;
  onShowMoreAnswersClicked: { id: number; date: number };
  onVoteLikeClicked: VoteEvent;
  onVoteDislikeClicked: VoteEvent;
};

const ACTION_ICON_SIZE = 16;
const COMMENT_REPLY_FORM_PREFIX = 'reply-form-';
const REPLIES_ARRAY = ['ответ', 'ответа', 'ответов'];

// eslint-disable-next-line @typescript-eslint/naming-convention
const CommentCard = componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'CommentCard',
    props: {
      comment: {
        type: Object as () => RecordComment | AnswersCommentItem,
        required: true as const
      },
      idPrefix: {
        type: String,
        default: ''
      },
      needAllCommentsLink: {
        type: Boolean,
        default: false
      },
      isShowReplyBtn: {
        type: Boolean,
        default: false
      },
      isShowAnswers: {
        type: Boolean,
        default: false
      },
      isShowVotes: {
        type: Boolean,
        default: false
      },
      isSmallMobile: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isRepliesVisible: false
      };
    },
    computed: {
      gotoCommentLink(): string {
        return this.comment.links.thread;
      },
      hasChildren(): boolean {
        return this.comment.childrenCount > 0 && this.isShowAnswers;
      },
      hasMoreAnswers(): boolean {
        return this.comment.children?.hasAfter ?? false;
      },
      replyFormId(): string {
        return `${COMMENT_REPLY_FORM_PREFIX}${this.comment.id}`;
      },
      commentIdAttr(): string {
        return `${this.idPrefix}${this.comment.id}`;
      },
      replyBtnText(): string {
        return `${this.comment.childrenCount} ${
          this.isSmallMobile
            ? ''
            : this.pluralReplyCounter(this.comment.childrenCount)
        }`.trim();
      }
    },
    watch: {
      childrenCount() {
        this.toggleReplies(true);
      }
    },
    methods: {
      pluralReplyCounter(n: number) {
        return plural(n, REPLIES_ARRAY);
      },
      getAvatar() {
        const hasUrl = guardEmptyString(this.comment.user.link);

        const AVATAR_COMPONENT = (
          <UiAvatar
            url={this.comment.user.avatar.url}
            isRounded={true}
            sex={this.comment.user.sex}
            size="sm"
          />
        );

        if (!hasUrl) {
          return AVATAR_COMPONENT;
        }

        return (
          <a
            href={this.comment.user.link}
            onClick={() => this.emitAuthorClicked(this.comment.id)}
          >
            {AVATAR_COMPONENT}
          </a>
        );
      },
      toggleReplies(isNewComment = false) {
        this.isRepliesVisible = isNewComment || !this.isRepliesVisible;

        if (this.isRepliesVisible) {
          this.emitRepliesToggling(this.comment.id);
        }
      },
      emitReplyLinkEvent(id: number) {
        this.$emit('replyLinkClicked', id);
      },
      emitDirectCommentClick(directParentId: number) {
        this.$emit('directCommentClicked', directParentId);
      },
      emitRepliesToggling(id: number) {
        this.$emit('repliesToggled', id);
      },
      emitAuthorClicked(id: number) {
        this.$emit('commentAuthorClicked', id);
      },
      emitCommentTextClicked(id: number) {
        this.$emit('commentTextClicked', id);
      },
      emitShowMoreAnswersClicked() {
        this.$emit('showMoreAnswersClicked', {
          id: this.comment.id,
          date: this.comment.children.searchDate
        });
      },
      emitRepliesLinkClicked() {
        this.toggleReplies();
      },
      emitVoteLikeClicked(id: number, parentId?: number) {
        this.$emit('voteLikeClicked', { id, parentId });
      },
      emitVoteDislikeClicked(id: number, parentId?: number) {
        this.$emit('voteDislikeClicked', { id, parentId });
      }
    },
    render() {
      return (
        <div
          id={this.commentIdAttr}
          itemscope
          itemtype="http://schema.org/UserComments"
          class={styles.commentWrapper}
          data-test="comment"
        >
          {this.getAvatar()}
          <div class={styles.commentBlock}>
            <div class={styles.comment}>
              <CommentHeader
                user={this.comment.user}
                date={this.comment.date}
                onCommentAuthorClicked={() =>
                  this.emitAuthorClicked(this.comment.id)
                }
              />

              {guardUnspecified(this.comment.directParent) && (
                <DirectComment
                  class={styles.directComment}
                  comment={this.comment.directParent}
                  onCommentAuthorClicked={(id: number) => this.emitAuthorClicked(id)}
                />
              )}
              {this.needAllCommentsLink ? (
                <a
                  href={this.gotoCommentLink}
                  class={[
                    styles.text,
                    typography.primary.body2.mobile,
                    typography.primary.body1.tablet
                  ]}
                  onClick={() => this.emitCommentTextClicked(this.comment.id)}
                  domPropsInnerHTML={this.comment.text}
                />
              ) : (
                <p
                  class={[
                    styles.text,
                    typography.primary.body2.mobile,
                    typography.primary.body1.tablet
                  ]}
                  domPropsInnerHTML={this.comment.text}
                />
              )}
            </div>
            <div class={styles.actions}>
              {this.isShowReplyBtn && (
                <button
                  class={[styles.actionItem, styles.actionBtn]}
                  onClick={() => this.emitReplyLinkEvent(this.comment.id)}
                  data-test="comment-reply"
                >
                  <FntReplyToCommentIcon
                    class={styles.actionBtnIcon}
                    width={ACTION_ICON_SIZE}
                    height={ACTION_ICON_SIZE}
                  />
                  ответить
                </button>
              )}

              {this.hasChildren && (
                <button
                  class={[styles.actionItem, styles.actionBtn]}
                  onClick={() => this.emitRepliesLinkClicked()}
                  data-test="comment-answers-count"
                >
                  <FntRepliesToCommentsIcon
                    class={styles.actionBtnIcon}
                    width={ACTION_ICON_SIZE}
                    height={ACTION_ICON_SIZE}
                  />
                  {this.replyBtnText}
                </button>
              )}

              {this.isShowVotes && (
                <CommentsVote
                  comment={this.comment}
                  gotoCommentLink={
                    this.needAllCommentsLink ? this.gotoCommentLink : ''
                  }
                  onLikeClicked={({ id, parentId }: VoteEvent) =>
                    this.emitVoteLikeClicked(id, parentId)
                  }
                  onDislikeClicked={({ id, parentId }: VoteEvent) =>
                    this.emitVoteDislikeClicked(id, parentId)
                  }
                />
              )}
            </div>
          </div>

          <div class={styles.answersWrap}>
            <VuePortalTarget name={this.replyFormId} />

            <div
              class={[styles.answers, this.isRepliesVisible ? '' : styles.hidden]}
            >
              {this.comment.children?.data.map((comment: AnswersCommentItem) => (
                <CommentCard
                  key={comment.id}
                  idPrefix={this.idPrefix}
                  comment={comment}
                  isShowReplyBtn={true}
                  isShowVotes={true}
                  onReplyLinkClicked={(e: number) => this.emitReplyLinkEvent(e)}
                  onRepliesToggled={(e: number) => this.emitRepliesToggling(e)}
                  onDirectCommentClicked={(id: number) =>
                    this.emitDirectCommentClick(id)
                  }
                  onCommentAuthorClicked={() =>
                    this.emitAuthorClicked(this.comment.id)
                  }
                  onCommentTextClicked={(e: number) =>
                    this.emitCommentTextClicked(e)
                  }
                  onVoteLikeClicked={({ id, parentId }) =>
                    this.emitVoteLikeClicked(id, parentId)
                  }
                  onVoteDislikeClicked={({ id, parentId }) =>
                    this.emitVoteDislikeClicked(id, parentId)
                  }
                />
              ))}
              {this.hasMoreAnswers && (
                <UiButton
                  class={styles.moreAnswersBtn}
                  theme="white"
                  nativeOnClick={() => this.emitShowMoreAnswersClicked()}
                >
                  <template slot="icon">
                    <FntActionPlusIcon />
                  </template>
                  <template slot="text">Показать еще ответы</template>
                </UiButton>
              )}
            </div>
          </div>
        </div>
      );
    }
  });

export { CommentCard, COMMENT_REPLY_FORM_PREFIX };
