import { guardEmptyString, guardUnspecified } from '@smh/utils/guards';

import type {
  SuperCoverBlockData,
  SuperCoverLink,
  SuperCoverThemeArticle
} from '@fe/common/features/new-home/domain';
import { createImage, type Image } from '@fontanka/news';
import type {
  SuperCoverArticleDTO,
  SuperCoverLinkDTO,
  SupercoverDTO
} from '@fontanka/news-api-client';

export class SupercoverMapper {
  private static readonly _maxThemeArticlesCount = 3;

  public static present(data: SupercoverDTO['data']) {
    if (!guardUnspecified(data)) {
      return null;
    }

    const articlesList = data.theme.records.slice(0, this._maxThemeArticlesCount);

    const result: SuperCoverBlockData = {
      themeButtonTitle: 'Показать еще',
      main: {
        id: data.record.id,
        title: {
          text: data.record.header,
          link: data.record.urls.url
        },
        links: data.links.map(item => this._presentSuperCoverLink(item)),
        image: createImage(data.image, {}) as Image,
        label: {
          isShow: guardEmptyString(data.labelTitle),
          text: data.labelTitle
        }
      },
      theme: {
        id: data.theme.id,
        title: {
          text: data.theme.title,
          link: data.theme.link
        },
        articles: articlesList.map(item => this._presentSuperCoverThemeArticle(item))
      }
    };

    return result;
  }

  private static _presentSuperCoverLink(data: SuperCoverLinkDTO): SuperCoverLink {
    return {
      text: data.title,
      link: data.path
    };
  }

  private static _presentSuperCoverThemeArticle(
    data: SuperCoverArticleDTO
  ): SuperCoverThemeArticle {
    return {
      id: data.id,
      title: {
        text: data.header,
        link: data.urls.url
      },
      publishAt: data.publishAt
    };
  }
}
