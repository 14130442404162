import Vue from 'vue';
import Vuex from 'vuex';
import { createVuexStore } from 'vuex-simple';

import { RootModule } from './root.store';

Vue.use(Vuex);

export const createStore = () => {
  const instance = new RootModule();
  return createVuexStore(instance);
};

export * from './root.store';
export * from './user';
export * from './adv';
