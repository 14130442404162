import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './tab-btn.styles.scss';

type Events = {
  onClicked: string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      isActive: {
        type: Boolean,
        required: false,
        default: false
      },
      id: {
        type: String,
        required: true as const
      },
      text: {
        type: String,
        required: true as const
      }
    },
    render() {
      return (
        <div
          class={[styles.btn, this.isActive ? styles.active : '']}
          onClick={() => this.$emit('clicked', this.id)}
        >
          {this.text}
        </div>
      );
    }
  });
