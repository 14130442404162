import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import { UiAdvLabel } from '@fe/common/ui';

import styles from './slider-vertical-item.styles.scss';

type Events = {
  onClickedRecord: void;
};

type Image = {
  url: string;
  sources: {
    type: string;
    srcset: string;
  }[];
} | null;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SliderVerticalItem',
    props: {
      url: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: true
      },
      image: {
        type: (Object as () => Image) || null,
        required: true
      },
      publishAt: {
        type: String,
        required: true
      },
      text: {
        type: String,
        default: ''
      },
      hasCommercialLabel: {
        type: Boolean,
        default: false
      }
    },
    render() {
      return (
        <div class={styles.sliderItem}>
          <div class={styles.sliderItemTop}>
            <a
              class={styles.imageWrapper}
              href={this.url}
              onClick={() => this.$emit('clickedRecord')}
            >
              <UiImage sources={this.image?.sources ?? []}>
                <img class={styles.img} src={this.image?.url ?? ''} alt="" />
              </UiImage>
            </a>

            <div class={styles.dateWrapper}>
              <span class={styles.date}>{this.publishAt}</span>
            </div>

            <a
              class={styles.titleWrapper}
              href={this.url}
              onClick={() => this.$emit('clickedRecord')}
            >
              <h3 class={styles.title} domPropsInnerHTML={this.header} />

              {this.hasCommercialLabel && (
                <UiAdvLabel hasFrame={false} class={styles.advMarker} />
              )}
            </a>
          </div>

          <a
            class={styles.previewWrapper}
            href={this.url}
            onClick={() => this.$emit('clickedRecord')}
          >
            <div class={styles.preview} domPropsInnerHTML={this.text} />
          </a>
        </div>
      );
    }
  });
