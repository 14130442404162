







































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiAdvLabel } from '../../ui-adv-label';
import { UiImage } from '../../ui-image';
import { UiLabel } from '../../ui-label';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  TaxonomyClickEvent,
  NewsHorizontalImage,
  NewsHorizontalStatistic,
  NewsHorizontalFormat,
  NewsHorizontalRubric,
  NewsHorizontalTheme
} from './ui-news-horizontal.contract';

@Component({
  components: {
    UiNewsCardLabels,
    UiObserveVisibility,
    UiStatistic,
    UiImage,
    UiLabel,
    UiAdvLabel
  },
  name: 'UiNewsHorizontal'
})
export default class UiNewsHorizontal extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsHorizontalImage,
    required: true
  })
  image!: NewsHorizontalImage;

  @Prop({
    type: Object as () => NewsHorizontalStatistic,
    required: true
  })
  statistic!: NewsHorizontalStatistic;

  @Prop({
    type: Boolean,
    default: false
  })
  isOnline: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasImageMark: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasVideoMark: boolean;

  @Prop({
    type: Array as () => NewsHorizontalFormat[],
    default: () => []
  })
  formats: NewsHorizontalFormat[];

  @Prop({
    type: Array as () => NewsHorizontalRubric[],
    default: () => []
  })
  rubrics: NewsHorizontalRubric[];

  @Prop({
    type: Array as () => NewsHorizontalTheme[],
    default: () => []
  })
  themes: NewsHorizontalTheme[];

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasCommercialLabel: boolean;

  get hasImage() {
    return guardUnspecified(this.image);
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(place: string): string {
    return place;
  }

  @Emit('taxonomyClicked')
  taxonomyClicked(event: TaxonomyClickEvent): TaxonomyClickEvent {
    return event;
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }
}
