import { componentFactory } from 'vue-tsx-support';
import { useModule } from 'vuex-simple';

import { injectStylesMixin, storeMixin } from '@apps/frontend';
import successIcon from 'images/actual/success.svg';

import { DefaultPageHeader } from '../../../../components';
import type { ActionResultPage } from '../../domain';
import type { ActionResultPageModule } from '../../store';
import { ACTION_RESULT_PAGE_MODULE_NAMESPACE } from '../../store';

import styles from './action-result-content.styles.scss';

export default componentFactory
  .mixin(injectStylesMixin(styles))
  .mixin(storeMixin)
  .create({
    name: 'ActionResultContent',
    computed: {
      actionResultPageModule(): ActionResultPageModule | undefined {
        return useModule<ActionResultPageModule>(
          this.$store,
          ACTION_RESULT_PAGE_MODULE_NAMESPACE
        );
      },
      data(): ActionResultPage['data'] {
        return (
          this.actionResultPageModule?.data || {
            title: '',
            detail: '',
            isError: false
          }
        );
      }
    },
    render() {
      return (
        <section>
          <DefaultPageHeader>Подписка на рассылку</DefaultPageHeader>
          <div class={styles.wrapper}>
            {!this.data.isError && (
              <img
                class={styles.icon}
                src={successIcon}
                alt=""
                width="60"
                height="60"
              />
            )}
            <div class={styles.title} domPropsInnerHTML={this.data.title} />
            {this.data.detail !== '' && (
              <div class={styles.text} domPropsInnerHTML={this.data.detail} />
            )}
          </div>
        </section>
      );
    }
  });
