import { guardUnspecified } from '@smh/utils/guards';
import { State } from 'vuex-simple';

import type { NewHomePageDTO } from '@fontanka/news-api-client';

import type { RootModule } from '../../../core';
import type { NewMainPageGrid } from '../application';
import { getNewMainPageGrid } from '../application';
import type { NewHomePage } from '../domain';
import { NewHomePageMapper } from '../services';

export const NEW_HOME_PAGE_MODULE_NAMESPACE = ['new_home_page_module'];

export class NewHomePageModule<T extends NewHomePageDTO = NewHomePageDTO> {
  @State()
  public window: NewHomePage['window'];

  @State()
  public mainNews: NewHomePage['mainNews'];

  @State()
  public businessNews: NewHomePage['businessNews'];

  @State()
  public newestOpinion: NewHomePage['newestOpinion'];

  @State()
  public recentOpinions: NewHomePage['recentOpinions'];

  @State()
  public rubrics: NewHomePage['rubrics'];

  @State()
  public ourCollection: NewHomePage['ourCollection'];

  @State()
  public socials: NewHomePage['socials'];

  @State()
  public grid: NewMainPageGrid;

  @State()
  public fontankaPro: NewHomePage['fontankaPro'];

  @State()
  public companyNews: NewHomePage['companyNews'];

  @State()
  public weekend: NewHomePage['weekend'];

  @State()
  public recommendation: NewHomePage['recommendation'];

  @State()
  public calendar: NewHomePage['calendar'];

  @State()
  public supercover: NewHomePage['supercover'];

  @State()
  public singleCard: NewHomePage['singleCard'];

  @State()
  public vkWidgetCode = `
    <div id="vk_groups"></div>
    <script>
    function loadScript( url, callback ) {
      var script = document.createElement( 'script' );
      script.async = true;
      script.type = 'text/javascript';
      script.onload = function() {
        callback();
      };
      script.src = url;
      document.getElementsByTagName( 'head' )[0].appendChild( script );
    }

    loadScript('https://vk.com/js/api/openapi.js?169', function() {
      if (VK && VK.Widgets) {
        VK.Widgets.Group('vk_groups', {mode: 1, width:'236'}, 18901857);
      }
    });
    </script>`;

  @State()
  public discussions: NewHomePage['discussions'];

  @State()
  public newsFeed;

  constructor(private _root: RootModule<T>) {
    const { isDesktop } = this._root.baseInfo;
    const isMobile = !isDesktop;
    const {
      businessNews,
      calendar,
      companyNews,
      discussions,
      fontankaPro,
      mainNews,
      newestOpinion,
      newsFeed,
      ourCollection,
      recentOpinions,
      recommendation,
      rubrics,
      socials,
      supercover,
      weekend,
      window,
      singleCard
    } = NewHomePageMapper.toDO(this._root.pageSpec, isMobile);

    this.businessNews = businessNews;
    this.calendar = calendar;
    this.companyNews = companyNews;
    this.discussions = discussions;
    this.fontankaPro = fontankaPro;
    this.mainNews = mainNews;
    this.newestOpinion = newestOpinion;
    this.newsFeed = newsFeed;
    this.ourCollection = ourCollection;
    this.recentOpinions = recentOpinions;
    this.recommendation = recommendation;
    this.rubrics = rubrics;
    this.socials = socials;
    this.supercover = supercover;
    this.weekend = weekend;
    this.window = window;
    this.singleCard = singleCard;
    this.grid = getNewMainPageGrid({
      isMobile,
      hasSupercover: guardUnspecified(supercover)
    });
  }
}
