import type {
  ActionResultDataDTO,
  ActionResultErrorDTO
} from '@fontanka/news-api-client';

import type { ActionResultPage } from '../domain';

export class ActionResultMapper {
  public static getActionResult(
    data: ActionResultDataDTO | null,
    errors: ActionResultErrorDTO[]
  ): ActionResultPage['data'] {
    if (data === null) {
      return {
        title: errors[0]?.name || 'Ошибка!',
        detail:
          errors[0]?.reason || 'Что-то пошло не так. Попробуйте обновить страницу',
        isError: true
      };
    }

    return {
      title: data.title,
      detail: data.detail,
      isError: errors.length > 0
    };
  }
}
