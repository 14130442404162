import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiAdvLabel } from '@fe/common/ui';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import { RubricCommentsItem } from '../rubric-comments-item';
import { RubricRecordHeaderItem } from '../rubric-record-header-item';
import { RubricRecordStatsItem } from '../rubric-record-stats-item';

import styles from './rubric-record-item.styles.scss';

type ComponentData = {
  recordVisibility: ObserverVisibility;
};

export type RubricRecord = {
  hasVideo: boolean;
  hasPhoto: boolean;
  hasAudio: boolean;
  publishDate: string;
  header: string;
  subheader: string;
  urls: {
    url: string;
    urlComments: string;
  };
  commentsCount: number;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
  canShowComments: boolean;
  id: number;
  hasMainPhotoCommercialLabel?: boolean;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'RubricRecordItem',
  props: {
    record: {
      type: Object as () => RubricRecord,
      required: true as const
    }
  },
  data(): ComponentData {
    return {
      recordVisibility: new ObserverVisibility('half')
    };
  },
  mounted() {
    this.recordVisibility.event.subscribe(state => {
      if (state.value) {
        this.$emit('viewRecord', {
          recordId: this.record.id,
          isCommercial: this.record.isCommercial
        });
      }
    });
  },
  beforeDestroy() {
    this.recordVisibility.destroy();
  },
  methods: {
    emitCommentsClicked(): void {
      this.$emit('commentsClicked', {
        recordId: this.record.id,
        isCommercial: this.record.isCommercial
      });
    },
    emitHeaderClicked(): void {
      this.$emit('headerClicked', {
        recordId: this.record.id,
        isCommercial: this.record.isCommercial
      });
    }
  },
  render() {
    return (
      <article
        v-observe-visibility={this.recordVisibility.getOptions('half')}
        class={styles.record}
      >
        <RubricRecordStatsItem record={this.record} />

        <div class={styles.headerGroup}>
          <RubricRecordHeaderItem
            record={this.record}
            onHeaderClicked={this.emitHeaderClicked}
          />
          {this.record.canShowComments && (
            <RubricCommentsItem
              class={styles.comments}
              record={this.record}
              onCommentsClicked={this.emitCommentsClicked}
            />
          )}
        </div>

        {this.record.hasMainPhotoCommercialLabel && (
          <UiAdvLabel hasFrame={false} class={styles.advMarker} />
        )}
      </article>
    );
  }
});
