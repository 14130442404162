import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { ProjectsBlocks as ProjectsBlocksAlias } from '../services/projects-mapper.service';
import { ProjectsMapper } from '../services/projects-mapper.service';

type ProjectsBlocks = ProjectsBlocksAlias;

export const PROJECTS_MODULE_NAMESPACE = ['projects_module'];

export class ProjectsModule {
  @State()
  public blocks: ProjectsBlocks;

  constructor(private _root: RootModule) {
    this.blocks = ProjectsMapper.toDO(this._root.pageSpec);
  }
}
