import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import styles from './discussions.styles.scss';

type Record = {
  id: number;
  commentsCount: number;
  header: string;
  urls: {
    url: string;
    urlComments: string;
  };
};

type ComponentData = {
  blockVisibility: ObserverVisibility;
};

type Events = {
  onClickedHeader: void;
  onViewRecord: number;
  onClickedRecord: number;
  onClickedComments: number;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      records: {
        type: Array as () => Record[] | null,
        required: false,
        default() {
          return [];
        }
      }
    },
    data(): ComponentData {
      return {
        blockVisibility: new ObserverVisibility('all')
      };
    },
    mounted() {
      this.blockVisibility.event.subscribe(({ value, target }) => {
        if (value) {
          const targetElement = target as HTMLElement;
          const recordId = targetElement.dataset.recordId || '';
          this.emitViewRecord(Number(recordId));
        }
      });
    },
    beforeDestroy() {
      this.blockVisibility.destroy();
    },
    methods: {
      emitClickedHeader() {
        this.$emit('clickedHeader');
      },
      emitViewRecord(recordId: number) {
        this.$emit('viewRecord', recordId);
      },
      emitClickedRecord(recordId: number) {
        this.$emit('clickedRecord', recordId);
      },
      emitClickedComments(recordId: number) {
        this.$emit('clickedComments', recordId);
      }
    },
    render() {
      return (
        <div class={styles.discussions}>
          <a href="/allcomments.html" onClick={() => this.emitClickedHeader()}>
            <h4 class={styles.header}>Обсуждения</h4>
          </a>

          <ul class={styles.list}>
            {this.records?.map(record => (
              <li
                v-observe-visibility={this.blockVisibility.getOptions('all')}
                class={styles.item}
                data-record-id={record.id}
              >
                <a
                  class={[styles.link, styles.pageLink]}
                  href={record.urls.url}
                  domPropsInnerHTML={record.header}
                  onClick={() => this.emitClickedRecord(record.id)}
                ></a>

                <a
                  class={[styles.link, styles.commentsLink]}
                  href={record.urls.urlComments}
                  title={`количество комментариев ${record.commentsCount}`}
                  onClick={() => this.emitClickedComments(record.id)}
                >
                  {`(${record.commentsCount})`}
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  });
