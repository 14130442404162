import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './age-restriction-logo.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  render() {
    return <div class={styles.ageRestrictionLogo}>18+</div>;
  }
});
