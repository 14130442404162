import type {
  ProjectsPageProjectNewsDTO,
  ProjectsPageDTO,
  BlockConfig
} from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { FormatRecordsMapper } from '../../../services';

export type ProjectsBlocks = {
  params: BlockConfig<ProjectsPageProjectNewsDTO['type']>['params'];
  type: ProjectsPageProjectNewsDTO['type'];
  data: BaseRecord[];
}[];

export class ProjectsMapper {
  public static toDO(pageDTO: ProjectsPageDTO): ProjectsBlocks {
    const blocks: ProjectsBlocks = [];

    return pageDTO.config.content.reduce((acc, current) => {
      const { data } = pageDTO.data[current.path];

      if (current.type === 'project_news') {
        acc.push({
          params: current.params,
          type: current.type,
          data: FormatRecordsMapper.toDO(
            (data as ProjectsPageProjectNewsDTO['data']) ?? []
          )
        });
      }

      return acc;
    }, blocks);
  }
}
