import Vue from 'vue';
import type { RouteConfig } from 'vue-router';
import Router from 'vue-router';

import { CORE_ROUTING } from '../core';
import { ACTION_RESULT_ROUTING } from '../features/action-result';
import { ARCHIVE_ROUTING } from '../features/archive';
import { AUTHORS_ROUTING, ALL_AUTHORS_ROUTING } from '../features/authors';
import { COMMENTS_ROUTING } from '../features/comments';
import { CURRENCY_ROUTING } from '../features/currency';
import { FORMATS_ROUTING } from '../features/formats';
import { HOME_ROUTING } from '../features/home';
import { MENTIONS_ROUTING } from '../features/mentions';
import { PHOTOS_VIDEOS_ROUTING } from '../features/photos-videos';
import { PROFILE_ROUTING } from '../features/profile';
import { PROJECTS_ROUTING } from '../features/projects';
import { RECORDS_ROUTING } from '../features/records';
import { RUBRICS_ROUTING } from '../features/rubric';
import { SEARCH_ROUTING } from '../features/search';
import { SUBSCRIPTION_ROUTING } from '../features/subscription';
import { TAGS_ROUTING } from '../features/tags';
import { THEMES_ROUTING } from '../features/themes';
import { WEATHER_ROUTING } from '../features/weather';

Vue.use(Router);

const createCoreRouter = (routes: RouteConfig[]) =>
  new Router({
    mode: 'history',
    routes
  });

export const createRouter = () =>
  createCoreRouter([
    ...HOME_ROUTING,
    ...CORE_ROUTING,
    ...ACTION_RESULT_ROUTING,
    ...SUBSCRIPTION_ROUTING,
    ...PROFILE_ROUTING,
    ...SEARCH_ROUTING,
    ...TAGS_ROUTING,
    ...PHOTOS_VIDEOS_ROUTING,
    ...COMMENTS_ROUTING,
    ...WEATHER_ROUTING,
    ...AUTHORS_ROUTING,
    ...ALL_AUTHORS_ROUTING,
    ...MENTIONS_ROUTING,
    ...ARCHIVE_ROUTING,
    ...PROJECTS_ROUTING,
    ...FORMATS_ROUTING,
    ...THEMES_ROUTING,
    ...CURRENCY_ROUTING,
    ...RECORDS_ROUTING,
    ...RUBRICS_ROUTING
  ]);
