import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import closeIcon from 'images/actual/close.svg';

import styles from './adv-mobile-sticky-bottom.styles.scss';

const CLOSE_DELAY_TIME = 4000;
const RELOAD_TIME = 60000;
const REFRESH_TIME = 40000;

const RECORD_WITHOUT_ADV = [71983220];
const CONTAINER_ID = 'adv_mob_sticky-mobile-smi';
const UNALLOWED_ADV_REFERRERS = [
  'iframe-toloka.com',
  'raterhub.com',
  'iframe-tasks.yandex',
  'iframe-yang.yandex',
  'yandex-team.ru'
];

type ComponentData = {
  isVisibleAdv: boolean;
  isVisibleCloseBtn: boolean;
  isClosed: boolean;
  refreshInterval: NodeJS.Timer | undefined;
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'AdvMobileStickyBottom',
  props: {
    isBottomIntend: {
      type: Boolean,
      default: false
    }
  },
  data(): ComponentData {
    return {
      isVisibleAdv: false,
      isVisibleCloseBtn: false,
      isClosed: false,
      refreshInterval: undefined
    };
  },
  computed: {
    recordId(): number {
      return this.$route.name === 'record' ? Number(this.$route.params.id) : 0;
    },
    isRender(): boolean {
      if (RECORD_WITHOUT_ADV.includes(this.recordId)) {
        return false;
      }

      return !this.isClosed;
    }
  },
  methods: {
    onDisplay() {
      this.isVisibleAdv = true;

      setTimeout(() => {
        if (this.isVisibleAdv) {
          this.isVisibleCloseBtn = true;
        }
      }, CLOSE_DELAY_TIME);
    },
    onStub() {
      this.isVisibleAdv = false;
    },
    onClose() {
      this.isClosed = true;
      this.isVisibleCloseBtn = false;
      this.destroyAdv();

      setTimeout(() => {
        this.isClosed = false;
        this.initAdv();
      }, RELOAD_TIME);
    },
    initAdv() {
      if (this.isUnallowedAdvReferrer()) {
        return;
      }

      window.yaContextCb = window.yaContextCb || [];

      const adfoxSettings = window.SDK_SETTINGS?.adfoxParams ?? {};
      const puid40 =
        (document.querySelector('meta[property="fnt:rubric"]') as HTMLMetaElement)
          ?.content || '';
      const puid53 =
        (document.querySelector('meta[property="fnt:page-type"]') as HTMLMetaElement)
          ?.content || '';

      window.yaContextCb.push(() => {
        window?.Ya?.adfoxCode?.create({
          ownerId: 320882,
          containerId: CONTAINER_ID,
          params: {
            p1: 'cgtvv',
            p2: 'gndu',
            puid40,
            puid53,
            ...adfoxSettings
          },
          onRender: this.onDisplay,
          onStub: this.onStub
        });
      });

      this.refreshInterval = setInterval(() => {
        window?.Ya?.adfoxCode?.reload(CONTAINER_ID);
      }, REFRESH_TIME);
    },
    destroyAdv() {
      window?.Ya?.adfoxCode?.destroy(CONTAINER_ID);
      clearInterval(this.refreshInterval);
      this.refreshInterval = undefined;
    },
    isUnallowedAdvReferrer() {
      const referrer =
        new URLSearchParams(window.location.search).get('_testReferrer') ||
        document.referrer;

      return UNALLOWED_ADV_REFERRERS.some(domain => referrer.includes(domain));
    }
  },
  mounted() {
    this.initAdv();
  },
  beforeDestroy() {
    this.destroyAdv();
  },
  render() {
    return this.isRender ? (
      <div
        class={[
          styles.wrapper,
          {
            [styles.bottomIntend]: this.isBottomIntend,
            [styles.hidden]: !this.isVisibleAdv
          }
        ]}
      >
        <div id={CONTAINER_ID} />
        {this.isVisibleCloseBtn && (
          <div class={styles.closeBtnWrap}>
            <img
              class={styles.closeBtn}
              src={closeIcon}
              onClick={this.onClose}
              alt="close"
            />
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  }
});
