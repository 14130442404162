import { TestResultAttachDTO } from '@fontanka/news-api-client';
import { TestResultAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createTestResultAttach = (
  attach: TestResultAttachDTO
): TestResultAttach => {
  const max = attach.media.max ? Number(attach.media.max) : 0;

  return {
    type: 'testResult',
    value: {
      id: `testResult${max}`,
      content: attach.media.content || '',
      image: attach.value ? createImage(attach.value) : null,
      max
    }
  };
};
