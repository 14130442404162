import type { BaseRubricDTO } from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';

import type { FilterLink } from './filter-link';

const ALL_PAGE = 'all.html';
const NEWS_PAGE = 'news.html';
const ARTICLES_PAGE = 'article.html';
const COMMERCIAL_PAGE = 'businews.html';

export type DayArchiveBlocks = {
  type: 'last_news';
  total: number;
  recordsCountPerPage: number;
  data: {
    date: string;
    records: BaseRecord[];
  }[];
}[];

export const getFilterLinks = (
  year: string,
  month: string,
  day: string,
  rubricId?: 'all' | string
): FilterLink[] => [
  {
    url: getPageUrl({ type: 'all', year, month, day, rubricId }),
    text: 'Все',
    type: 'all'
  },
  {
    url: getPageUrl({ type: 'news', year, month, day, rubricId }),
    text: 'Новости',
    type: 'news'
  },
  {
    url: getPageUrl({ type: 'articles', year, month, day, rubricId }),
    text: 'Статьи',
    type: 'articles'
  },
  {
    url: getPageUrl({ type: 'commercial', year, month, day, rubricId }),
    text: 'Новости компаний',
    type: 'commercial'
  }
];

export const getFilterRubrics = (rubrics: BaseRubricDTO[]) => [
  {
    id: 'all',
    name: 'Все рубрики'
  },
  ...rubrics
];

export type PageUrlParams = {
  day: string;
  month: string;
  rubricId?: 'all' | string;
  type: FilterLink['type'];
  year: string;
};

export const getPageUrl = ({ type, year, month, day, rubricId }: PageUrlParams) => {
  const rubricPath = rubricId && rubricId !== 'all' ? `/${rubricId}` : '';
  const baseUrl = `${rubricPath}/${year}/${month}/${day}/`;

  if (type === 'all') {
    return `${baseUrl}${ALL_PAGE}`;
  }

  if (type === 'news') {
    return `${baseUrl}${NEWS_PAGE}`;
  }

  if (type === 'articles') {
    return `${baseUrl}${ARTICLES_PAGE}`;
  }

  if (type === 'commercial') {
    return `${baseUrl}${COMMERCIAL_PAGE}`;
  }

  return '';
};
