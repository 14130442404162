/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Buffer } from 'buffer';

import { getCookie, removeCookie } from 'tiny-cookie';

export const adblockCookieMatched = (): void => {
  const COOKIE_NAME = 'crookie';
  const USER_MATCHED_NAME = 'cmtchd';
  const RESET_COOKIE = 'fontanka_crookie_reset';

  const TTL = 14 * 24 * 60 * 60 * 1000;

  const currentDomain = document.location.hostname;

  if (getCookie(COOKIE_NAME) !== null && getCookie(USER_MATCHED_NAME) !== null) {
    if (getCookie(RESET_COOKIE) === null) {
      removeCookie(COOKIE_NAME);
      removeCookie(USER_MATCHED_NAME);
    } else {
      return;
    }
  }

  const xhr = new XMLHttpRequest();
  xhr.open('GET', 'https://http-check-headers.yandex.ru');
  xhr.withCredentials = true;
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== 4) return;
    if (xhr.status !== 200) {
      return;
    }
    if (xhr.response) {
      let expires = new Date(new Date().valueOf() + TTL).toUTCString();

      document.cookie = `${RESET_COOKIE}=true;path=/;domain=${currentDomain};Secure;SameSite=None;expires=${expires}`;

      document.cookie = `${COOKIE_NAME}=${String(
        xhr.response
      )};path=/;domain=${currentDomain};Secure;SameSite=None;expires=${expires}`;

      expires = new Date(new Date().valueOf() + TTL / 2).toUTCString();
      const value = Buffer.from(String(Number(new Date())), 'binary')
        .toString('base64')
        .replace(/=/gi, '');
      document.cookie = `${USER_MATCHED_NAME}=${value};path=/;domain=${currentDomain};Secure;SameSite=None;expires=${expires}`;
    }
  };
  xhr.send();
};
