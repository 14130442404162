import { guardEmptyArray, guardUnspecified } from '@smh/utils/guards';
import { isAbsoluteUrl } from '@smh/utils/url';

import {
  ArticlePopularNewsInsertDTO,
  ArticlePopularNewsItemDTO,
  ArticleRotatedInsertsDTO,
  ArticleTextItemDTO
} from '@fontanka/news-api-client';

import type {
  PopularNewsAttachItemVM,
  PopularNewsAttachVM,
  RotatedInsertsAttachVM
} from '../../../domain';

export class RotateAttachesMapper {
  static editorialsAmount = 2;
  static advertorialsAmount = 1;

  public static execute(
    attach: ArticleTextItemDTO,
    rotatedInserts: ArticleRotatedInsertsDTO,
    recordId = 0
  ): RotatedInsertsAttachVM {
    const popularData = rotatedInserts.data?.['popular_data'] || null;
    let popular = null;

    if (guardUnspecified(popularData)) {
      popular = this.presentPopularNews(popularData, recordId);
    }

    return {
      type: 'rotatedInserts',
      value: {
        popular
      }
    };
  }

  public static presentPopularNews(
    popularNewsDTO: ArticlePopularNewsInsertDTO,
    recordId: number
  ): PopularNewsAttachVM | null {
    const { data } = popularNewsDTO;

    try {
      const { editorial = [], advertorial = [] } = data;

      const presentedEditorials = editorial.map(item =>
        this.presentPopularNewsItem(item)
      );
      const presentedAdvertorials = advertorial.map(item =>
        this.presentPopularNewsItem(item)
      );
      const editorialsWithoutCurrent = this.excludeCurrentArticle(
        presentedEditorials,
        recordId
      );

      return this.getPopularNewsList(
        editorialsWithoutCurrent,
        presentedAdvertorials
      );
    } catch (e) {
      console.error(e);

      return null;
    }
  }

  private static presentPopularNewsItem(
    newsItem: ArticlePopularNewsItemDTO
  ): PopularNewsAttachItemVM {
    return {
      id: newsItem.id,
      header: newsItem.header,
      type: newsItem.isCommercial ? 'commercial' : 'editorial',
      url: newsItem.urls.url,
      target: isAbsoluteUrl(newsItem.urls.url) ? '_blank' : '_self'
    };
  }

  private static excludeCurrentArticle(
    articles: PopularNewsAttachVM,
    currentId: number
  ) {
    return articles.filter(item => item.id !== currentId);
  }

  private static getPopularNewsList(
    editorials: PopularNewsAttachVM,
    advertorials: PopularNewsAttachVM
  ) {
    const totalAmount = this.editorialsAmount + this.advertorialsAmount;

    // если редакционных/рекламных материалов нет, то все новости будут одного типа
    if (!guardEmptyArray(editorials)) {
      return this.getShuffledArray(advertorials, totalAmount);
    } else if (!guardEmptyArray(advertorials)) {
      return this.getShuffledArray(editorials, totalAmount);
    } else {
      return [
        ...this.getShuffledArray(editorials, this.editorialsAmount),
        ...this.getShuffledArray(advertorials, this.advertorialsAmount)
      ];
    }
  }

  private static getShuffledArray(array: PopularNewsAttachVM, length: number) {
    return array
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
      .slice(0, length);
  }
}
