import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import { Logo } from '../logo';

import styles from './simple-page-wrapper.styles.scss';

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  render() {
    return (
      <div>
        <div class={styles.topWrapper}>
          <Logo />

          {this.$slots.top}
        </div>
        {this.$slots.default}
        <div class={styles.bottomWrapper}>{this.$slots.bottom}</div>
      </div>
    );
  }
});
