import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import { typography } from '@fe/common/styles/typography';
import { UiAdvLabel } from '@fe/common/ui';

import styles from './slider-vertical-item.styles.scss';

type Events = {
  onClickedRecord: void;
};

type Image = {
  url: string;
  sources: {
    type: string;
    srcset: string;
  }[];
} | null;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SliderVerticalItem',
    props: {
      url: {
        type: String,
        required: true
      },
      header: {
        type: String,
        required: true
      },
      image: {
        type: (Object as () => Image) || null,
        default: null
      },
      publishAt: {
        type: String,
        required: true
      },
      text: {
        type: String,
        default: ''
      },
      hasCommercialLabel: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      clearedText() {
        return this.text.replace(new RegExp('<[^>]*>', 'g'), '');
      }
    },
    render() {
      return (
        <div class={styles.sliderItem}>
          <a
            class={styles.slideContent}
            href={this.url}
            onClick={() => this.$emit('clickedRecord')}
          >
            <div class={styles.imageWrapper}>
              <UiImage sources={this.image?.sources ?? []}>
                <img class={styles.img} src={this.image?.url ?? ''} alt="" />
              </UiImage>
              {this.hasCommercialLabel && <UiAdvLabel class={styles.advMarker} />}
            </div>

            <div class={styles.content}>
              <div
                class={[styles.title, typography.secondary.heading5.mobile]}
                domPropsInnerHTML={this.header}
              />

              <div
                class={[styles.description, typography.secondary.bodyMain2.mobile]}
                domPropsInnerHTML={this.clearedText}
              />
            </div>

            <div class={[styles.date, typography.primary.overline.mobile]}>
              {this.publishAt}
            </div>
          </a>
        </div>
      );
    }
  });
