import type {
  MentionsPageDTO,
  MentionsPageMentionsDTO
} from '@fontanka/news-api-client';

import type { MentionsPageData as MentionsPageDataAlias } from '../domain';
import { createMentionRecord } from '../domain';

type MentionsPageData = MentionsPageDataAlias;

export class MentionsPageMapper {
  public static toDO(pageDTO: MentionsPageDTO): MentionsPageData {
    const INITIAL = {
      blocks: [],
      recordsInfo: {
        total: 0,
        records: []
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'mentions') {
        acc.blocks.push({
          type: current.type
        });

        const data = (block.data as MentionsPageMentionsDTO['data']) ?? [];

        acc.recordsInfo = {
          total: block.meta?.total ?? 0,
          records: data.map(record => createMentionRecord(record))
        };

        return acc;
      }

      return acc;
    }, INITIAL as MentionsPageData);
  }
}
