type Params = {
  original: {
    url: string;
    width: number;
    height: number;
    originUrl?: string;
  };
  cropTemplate: string;
  width?: number;
  height?: number;
};

const REPLACE_PATTERN = '##';

export const createImagePath = (params: Params): string => {
  const { original, cropTemplate, width, height } = params;

  const imgUrl = original.originUrl || original.url;

  if (imgUrl.indexOf(REPLACE_PATTERN) === -1) {
    return '';
  }

  if (!width) {
    return imgUrl.replace(
      REPLACE_PATTERN,
      `_${original.width}_${original.height}${cropTemplate}`
    );
  }

  if (!height) {
    return imgUrl.replace(REPLACE_PATTERN, `_${width}${cropTemplate}`);
  }

  return imgUrl.replace(REPLACE_PATTERN, `_${width}_${height}${cropTemplate}`);
};
