









































import { guardUnspecified, guardEmptyString } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiAdvLabel } from '../../ui-adv-label';
import { UiImage } from '../../ui-image';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  TaxonomyClickEvent,
  NewsMainImage,
  NewsMainStatistic,
  NewsMainFormat,
  NewsMainRubric,
  NewsMainTheme
} from './ui-news-main.contract';

@Component({
  components: {
    UiNewsCardLabels,
    UiObserveVisibility,
    UiStatistic,
    UiImage,
    UiAdvLabel
  },
  name: 'UiNewsMain'
})
export default class UiNewsMain extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  subtitle!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsMainImage,
    required: true
  })
  image!: NewsMainImage;

  @Prop({
    type: Object as () => NewsMainStatistic,
    required: true
  })
  statistic!: NewsMainStatistic;

  @Prop({
    type: Boolean,
    default: false
  })
  isOnline: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasImageMark: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasVideoMark: boolean;

  @Prop({
    type: Array as () => NewsMainFormat[],
    default: () => []
  })
  formats: NewsMainFormat[];

  @Prop({
    type: Array as () => NewsMainRubric[],
    default: () => []
  })
  rubrics: NewsMainRubric[];

  @Prop({
    type: Array as () => NewsMainTheme[],
    default: () => []
  })
  themes: NewsMainTheme[];

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasCommercialLabel: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  isMobile: boolean;

  get hasImage() {
    return guardUnspecified(this.image);
  }

  get hasSubtitle() {
    return guardEmptyString(this.subtitle);
  }

  get labelsAlignment() {
    return this.isMobile ? 'left' : 'center';
  }

  @Emit('taxonomyClicked')
  taxonomyClicked(event: TaxonomyClickEvent): TaxonomyClickEvent {
    return event;
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(event: Event): Event {
    return event;
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }
}
