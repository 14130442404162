






import { Portal, setSelector } from '@linusborg/vue-simple-portal';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'UiPortal',
  components: {
    Portal
  }
})
export default class UiPortal extends Vue {
  @Prop({
    default: true,
    type: Boolean
  })
  isOpen!: boolean;

  @Prop({
    default: 'body',
    type: String
  })
  appendTo!: string;

  @Prop({
    default: 'modal',
    type: String
  })
  selector!: string;

  @Watch('isOpen')
  onOpen(value: boolean) {
    if (value) {
      setSelector(this.selector);

      this.$nextTick(() => {
        this.$emit('opened');
      });
    } else {
      this.$nextTick(() => {
        this.$emit('closed');
      });
    }
  }
}
