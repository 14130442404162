


























import { guardUnspecified } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../../ui-image';
import { UiLabel } from '../../ui-label';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  NewsVeryImage,
  NewsVeryStatistic,
  NewsVeryLabel
} from './ui-news-very.contract';

@Component({
  components: {
    UiLabel,
    UiStatistic,
    UiObserveVisibility,
    UiImage,
    UiNewsCardLabels
  },
  name: 'UiNewsVery'
})
export default class UiNewsVery extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsVeryImage,
    required: true
  })
  image!: NewsVeryImage;

  @Prop({
    type: Object as () => NewsVeryStatistic,
    required: true
  })
  statistic!: NewsVeryStatistic;

  @Prop({
    type: Object as () => NewsVeryLabel,
    default: null
  })
  label: NewsVeryLabel;

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  get hasLabel(): boolean {
    return guardUnspecified(this.label);
  }

  get hasImage() {
    return guardUnspecified(this.image);
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(place: string): string {
    return place;
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }
}
