import { guardEmptyArray } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import type { BaseRecord } from '@apps/frontend';
import { injectStylesMixin } from '@apps/frontend';
import { OpinionItem } from '@fe/common/components/_items/opinion-item';
import { typography } from '@fe/common/styles/typography';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import FntSmallArrowIcon from '~icons/fnt-common/small-arrow';

import styles from './opinion-block.styles.scss';

type Events = {
  onClickedHeaderSlide: void;
  onClickedRecord: number;
  onClickedAuthor: {
    recordId: number;
    authorId: number;
  };
  onClickedComments: number;
  onVisible: void;
};

type ComponentData = {
  opinionVisibility: ObserverVisibility;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'OpinionBlock',
    props: {
      records: {
        type: Array as () => BaseRecord[],
        required: true
      }
    },
    data(): ComponentData {
      return { opinionVisibility: new ObserverVisibility('half') };
    },
    mounted() {
      this.opinionVisibility.event.subscribe(state => {
        if (state.value) {
          this.$emit('visible');
        }
      });
    },
    beforeDestroy() {
      this.opinionVisibility.destroy();
    },
    computed: {
      isRecord(): boolean {
        return guardEmptyArray(this.records);
      }
    },
    methods: {
      clickedRecord(recordId: number): void {
        this.$emit('clickedRecord', recordId);
      },
      clickedComments(recordId: number): void {
        this.$emit('clickedComments', recordId);
      },
      clickedAuthor(recordId: number, authorId: number): void {
        this.$emit('clickedAuthor', { recordId, authorId });
      },
      clickedHeaderSlide(): void {
        this.$emit('clickedHeaderSlide');
      }
    },
    render() {
      return (
        <div
          class={styles.opinion}
          v-observe-visibility={this.opinionVisibility.getOptions('half')}
        >
          <a
            href="/mention"
            class={styles.header}
            title="Мнения"
            onClick={this.clickedHeaderSlide}
          >
            <span class={[styles.title, typography.secondary.label1.mobile]}>
              Мнения
            </span>
            <FntSmallArrowIcon class={styles.icon} />
          </a>
          {this.isRecord && (
            <ul class={styles.list}>
              {this.records?.map(item => (
                <li class={styles.item}>
                  <OpinionItem
                    item={item}
                    onClickedPhoto={(): void =>
                      this.clickedAuthor(item.id, item.author?.id || 0)
                    }
                    onClickedHeader={(): void => this.clickedRecord(item.id)}
                    onClickedComments={(): void => this.clickedComments(item.id)}
                    onClickedAuthor={(): void =>
                      this.clickedAuthor(item.id, item.author?.id || 0)
                    }
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      );
    }
  });
