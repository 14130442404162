import { componentFactory } from 'vue-tsx-support';

import { storeMixin } from '@apps/frontend';
import { pretendNormalComponent } from '@fontanka/tsx-helpers';

import { AdvCreative } from '../adv-creative';
import { LazyHydrateSsr } from '../lazy-hydrate-ssr';

import type { AdvData as _AdvData } from './adv.component';

// eslint-disable-next-line @typescript-eslint/naming-convention
const Adv = pretendNormalComponent(() =>
  import('./adv.component').then(m => m.default)
);

type AdvData = _AdvData;

export default componentFactory.mixin(storeMixin).create({
  props: {
    adv: {
      type: Object as () => AdvData,
      required: true as const
    },
    type: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: undefined
    },
    hasPlaceholder: {
      type: Boolean,
      default: false
    },
    clientRender: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isScroogeTest(): boolean {
      return this.store.isScroogeTest;
    }
  },
  render() {
    return this.isScroogeTest ? (
      <AdvCreative
        adv={this.adv}
        index={this.index}
        type={this.type}
        clientRender={this.clientRender}
      />
    ) : (
      <LazyHydrateSsr>
        <Adv
          adv={this.adv}
          type={this.type}
          index={this.index}
          hasPlaceholder={this.hasPlaceholder}
        />
      </LazyHydrateSsr>
    );
  }
});
