import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './rubric-record-stats-item.styles.scss';

type Record = {
  hasPhoto: boolean;
  hasVideo: boolean;
  hasAudio: boolean;
  publishDate: string;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    record: {
      type: Object as () => Record,
      required: true as const
    }
  },
  render() {
    return (
      <div class={styles.stats}>
        <time class={styles.time}>{this.record.publishDate}</time>

        <div class={styles.icons}>
          {this.record.hasVideo ? <i class={[styles.icon, styles.video]} /> : null}
          {this.record.hasAudio ? <i class={[styles.icon, styles.music]} /> : null}
          {this.record.hasPhoto ? <i class={[styles.icon, styles.photo]} /> : null}
        </div>
      </div>
    );
  }
});
