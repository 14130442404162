import { imageSources } from '@fontanka/image-sources';
import { AuthorImage } from '../../domain';
import { createImagePath } from '../image-url.service';

export type AuthorImageDTO = {
  fileName: string;
  height: number;
  width: number;
};

export type AuthorImageParams = {
  crop?: boolean;
  width?: number;
  height?: number;
};

export const createAuthorImage = (
  authorImageDTO: AuthorImageDTO | null,
  params: AuthorImageParams = { crop: false }
): AuthorImage | null => {
  if (!authorImageDTO) {
    return null;
  }

  if (!params) {
    return {
      url: authorImageDTO.fileName,
      sources: imageSources(authorImageDTO.fileName),
      width: authorImageDTO.width,
      height: authorImageDTO.height
    };
  }

  const { crop = false, width, height } = params;

  const cropTemplate = `${crop ? '_c' : ''}.`;

  const url = createImagePath({
    original: {
      ...authorImageDTO,
      url: authorImageDTO.fileName
    },
    cropTemplate,
    width,
    height
  });

  return {
    url,
    sources: imageSources(url),
    width: authorImageDTO.width,
    height: authorImageDTO.height
  };
};
