import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { RubricPageBlocks as RubricPageBlocksAlias } from '../services/rubric-page-mapper.service';
import { RubricPageMapper } from '../services/rubric-page-mapper.service';

export type RubricPageBlocks = RubricPageBlocksAlias;

export const RUBRIC_MODULE_NAMESPACE = ['rubric_module'];

export class RubricModule {
  @State()
  public blocks: RubricPageBlocks;

  constructor(private _root: RootModule) {
    this.blocks = RubricPageMapper.toDO(this._root.pageSpec);
  }
}
