








import { guardUnspecified } from '@smh/utils/guards';
import { getCookie, setCookie } from 'tiny-cookie';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { useModule, useStore } from 'vuex-simple';

import { AdvSlot } from '../adv-slot';
import type { Creative } from '../adv-slot/creative';
import { PLACEMENTS } from '../adv-slot/creative';
import { ADV_MODULE_NAMESPACE, AdvModule, RootModule } from '@fe/common/core';
import { MobileBrandingCreatives } from '@fe/common/domain';

type AdvData = {
  data: Creative;
  path: string;
  settings: {
    place: string;
    placeId: number;
    sticky: boolean;
    subPlace: string;
    types: {
      desktop: boolean;
      laptop: boolean;
      mobile: boolean;
      tablet: boolean;
    };
  };
  type: string;
} | null;

const RESPONSIVE_BLOCKS = [PLACEMENTS.INLINE_1, PLACEMENTS.INLINE_3];

const RECORD_WITHOUT_ADV = [71983220, 73014437, 73014698, 73015250];

@Component({
  name: 'AdvCreative',
  components: {
    AdvSlot
  }
})
export default class AdvCreative extends Vue {
  @Prop({
    required: true,
    type: Object
  })
  adv: AdvData;
  @Prop({
    type: Number,
    default: undefined
  })
  index: number;
  @Prop({
    type: Boolean,
    default: false
  })
  clientRender: boolean;
  @Prop({
    type: String,
    default: undefined
  })
  type: string;

  store = useStore<RootModule>(this.$store);

  disabledFullscreenCookie = 'fontanka_fullscreen_disabled';
  counterFullscreenCookie = 'fontanka_fullscreen_counter';
  maxDisplayCount = 2;
  timeOutFullscreen = 10800000; // 3h
  canReplaceMobileBrandingCreative = false;

  get advModule(): AdvModule | undefined {
    return useModule<AdvModule>(this.$store, ADV_MODULE_NAMESPACE);
  }

  get isMobile() {
    return this.store.baseInfo.isMobile;
  }

  get isDesktopTopAdv(): boolean {
    return this.type === 'headerAdvert' && !this.isAdblock;
  }

  get isMobileTopAdv(): boolean {
    return this.type === 'mobileTopAdv' && !this.isAdblock;
  }

  get isAdblock() {
    return this.store.isAdblock;
  }

  get recordId() {
    return this.$route.name === 'record' ? Number(this.$route.params.id) : 0;
  }

  get isRender(): boolean {
    if (RECORD_WITHOUT_ADV.includes(this.recordId)) {
      return false;
    }

    if (this.isAdblock) {
      return false;
    }

    return true;
  }

  get isResponsivePlaceholder() {
    return (
      this.isMobile &&
      RESPONSIVE_BLOCKS.includes(this.advPlacement as PLACEMENTS) &&
      this.$route.name === 'record'
    );
  }

  get isInread() {
    return this.type === 'inread';
  }

  get advData() {
    return this.adv?.data || null;
  }

  get isMobileAdv() {
    return this.advData?.isMobile;
  }

  get advPlacement() {
    return this.advData?.placement || '';
  }

  get isPlaceholderEnabled() {
    return this.advData?.placeholderEnabled || false;
  }

  get placeholderSize(): string {
    if (!this.isPlaceholderEnabled || this.isResponsivePlaceholder) {
      return '';
    }

    const isInread2 = this.advPlacement === PLACEMENTS.INREAD_2;
    const shouldChangeInreadMaxHeight = isInread2 && this.verticalMobileBrandingBlock;

    const placeholderHeight = shouldChangeInreadMaxHeight ? 600 :
      this.advData?.placeholderConfig.height || 0;
    /*
     * ВАЖНО! Нужно оставить min-height и max-height вместо height,
     * так работают магические респонсивные плейсхолдеры яндекса
     */
    return placeholderHeight
      ? `min-height: ${placeholderHeight}px; max-height: ${placeholderHeight}px; --creative-height: ${placeholderHeight}px;`
      : '';
  }

  get isPlaceholderBackground() {
    return (
      (this.isPlaceholderEnabled &&
        this.advData?.placeholderConfig.backgroundEnabled) ||
      false
    );
  }

  get isPlaceholderMark() {
    return (
      (this.isPlaceholderEnabled &&
        this.advData?.placeholderConfig.advMarkEnabled) ||
      false
    );
  }

  get blockerCookie() {
    if (this.advData?.placement?.includes('fullscreen')) {
      return this.disabledFullscreenCookie;
    }

    return undefined;
  }

  get mobileBrandingCreatives(): MobileBrandingCreatives | undefined {
    return this.advModule?.mobileBrandingCreatives;
  }

  get horizontalMobileBrandingBlock(): string | undefined {
    return this.mobileBrandingCreatives?.horizontalImage ||
      this.mobileBrandingCreatives?.horizontalHtml;
  }

  get verticalMobileBrandingBlock(): string | undefined {
    return this.mobileBrandingCreatives?.verticalImage ||
      this.mobileBrandingCreatives?.verticalHtml;
  }

  get brandingHtmlMap(): Partial<Record<PLACEMENTS, string | undefined>> {
    return {
      [PLACEMENTS.INREAD]: this.horizontalMobileBrandingBlock,
      [PLACEMENTS.INREAD_2]: this.verticalMobileBrandingBlock,
    };
  }

  get brandingHtml(): string | false {
    if (!this.mobileBrandingCreatives) {
      return false;
    }

    return this.brandingHtmlMap[this.advPlacement as PLACEMENTS] || false;
  }

  get shouldRenderBrandingHtml(): boolean {
    return this.canReplaceMobileBrandingCreative && Boolean(this.brandingHtml);
  }

  get classes() {
    return [
      this.$style.adv,
      this.isMobileAdv ? this.$style.mobile : '',
      this.isPlaceholderEnabled ? this.$style.placeholderEnabled : '',
      this.isPlaceholderBackground ? this.$style.placeholderBackground : '',
      this.isPlaceholderMark ? this.$style.placeholderMark : '',
      this.isResponsivePlaceholder ? this.$style.responsivePlaceholder : '',
      this.isDesktopTopAdv ? this.$style.desktopTopAdv : '',
      this.isMobileTopAdv ? this.$style.mobileTopAdv : '',
      this.isInread ? this.$style.inread : '',
      this.isResponsivePlaceholder ? this.$style.responsive : ''
    ];
  }

  setDisabledFullscreenCookie() {
    const date = new Date(Date.now() + this.timeOutFullscreen);
    setCookie(this.disabledFullscreenCookie, 'true', {
      expires: date.toUTCString()
    });
  }

  @Emit('display')
  onDisplay() {
    return;
  }

  mounted() {
    this.canReplaceMobileBrandingCreative = true;
    if (this.advData?.placement?.includes('fullscreen')) {
      const disabledFullscreen = getCookie(this.disabledFullscreenCookie);
      const fullscreenCounter = getCookie(this.counterFullscreenCookie);

      if (!guardUnspecified(disabledFullscreen)) {
        const counter = Number(fullscreenCounter) + 1;
        const date = new Date(Date.now() + this.timeOutFullscreen);
        setCookie(this.counterFullscreenCookie, String(counter), {
          expires: date.toUTCString()
        });

        if (counter >= this.maxDisplayCount) {
          this.setDisabledFullscreenCookie();
        }
      }
    }
  }
}
