import { guardEmptyArray, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';
import DatePicker from 'vue2-datepicker';
// eslint-disable-next-line import/no-unassigned-import
import 'vue2-datepicker/locale/ru';

import { injectStylesMixin } from '@apps/frontend';

import FntCalendarIcon from '~icons/fnt-common/calendar';
import FntCloseIcon from '~icons/fnt-common/close';

import styles from './ui-datepicker.styles.scss';

type ChangedPeriod = (null | string)[];
type DateValue = null | string | ChangedPeriod;

type ComponentData = {
  value: DateValue;
  disabled: boolean;
};

type Events = {
  onChangedPeriod: ChangedPeriod;
  onChangedValue: null | string;
  onMounted: void;
};

const ICON_WIDTH = 24;
const TIMEOUT_MS = 500;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'UiDatepicker',
    props: {
      date: {
        type: String,
        default: ''
      },
      dates: {
        type: Array as () => ChangedPeriod,
        default: (): [] => []
      },
      disabledAfterToday: {
        type: Boolean,
        default: false
      },
      isRange: {
        type: Boolean,
        default: false
      },
      placeholder: {
        type: String,
        default: ''
      },
      valueToken: {
        type: String,
        default: 'DD.MM.YYYY'
      },
      format: {
        type: String,
        default: 'DD.MM.YYYY'
      }
    },
    data(): ComponentData {
      return {
        value: null,
        disabled: false
      };
    },
    computed: {
      needToMoveMonthBack(): boolean {
        return (
          guardUnspecified(this.value) &&
          Array.isArray(this.value) &&
          (guardEmptyArray(this.value) || !guardUnspecified(this.value[0]))
        );
      }
    },
    mounted() {
      this.setDefaultValue();
      this.$emit('mounted');
    },
    methods: {
      inputEventListener(value: DateValue): void {
        this.value = value;
        this.$emit(this.isRange ? 'changedPeriod' : 'changedValue', value);
      },
      disableDateRange(date: Date): boolean {
        if (this.disabledAfterToday) {
          const today = new Date();
          return date > today;
        }
        return false;
      },
      openEventListener(): void {
        this.disableClicks();
        if (this.isRange) {
          setTimeout(() => {
            this.addClassToBtns();
            this.moveMonthTrigger();
          }, 0);
        }
      },
      // костыль, добавляем классы для начала и конца выбранного периода
      addClassToBtns(): void {
        const activeCells = document.querySelectorAll(
          '.vue2-datepicker-popup .cell.active'
        );
        const minActiveCellsCount = 2;

        if (activeCells.length === minActiveCellsCount) {
          activeCells[0].classList.add('start');
          activeCells[1].classList.add('end');
        }
      },

      // костыль, переключаем обе панели пикера на 1 месяц назад
      moveMonthTrigger(): void {
        if (this.needToMoveMonthBack) {
          const buttons = document.querySelectorAll(
            '.vue2-datepicker-popup .vue2-btn-icon-left'
          );
          buttons.forEach(btn => (btn as HTMLElement).click());
        }
      },
      setDefaultValue(): void {
        this.value = this.isRange ? this.dates : this.date;
      },
      disableClicks(): void {
        this.disabled = true;

        setTimeout(() => {
          this.disabled = false;
        }, TIMEOUT_MS);
      },
      clearEventListener(): void {
        this.disableClicks();

        this.value = this.isRange ? [] : '';
      }
    },
    render() {
      return (
        <div class={[styles.datepickerWrap, this.disabled ? styles.disabled : '']}>
          <DatePicker
            value={this.value}
            editable={false}
            range={this.isRange}
            placeholder={this.placeholder}
            valueType={this.valueToken}
            format={this.format}
            prefixClass="vue2"
            rangeSeparator=" — "
            titleFormat={this.valueToken}
            disabledDate={this.disableDateRange}
            onInput={(value: string) => this.inputEventListener(value)}
            onOpen={() => this.openEventListener()}
            onClear={() => this.clearEventListener()}
          >
            <FntCalendarIcon
              slot="icon-calendar"
              width={ICON_WIDTH}
              height={ICON_WIDTH}
            />
            <FntCloseIcon slot="icon-clear" width={ICON_WIDTH} height={ICON_WIDTH} />
          </DatePicker>
        </div>
      );
    }
  });
