import type {
  LastNewsPageDTO,
  LastNewsPageLastNewsDTO
} from '@fontanka/news-api-client';

import { BaseRecordMapper } from '../../../services';
import type { LastNewsBlocks } from '../domain/last-news';

export class LastNewsMapper {
  public static toDO(pageDTO: LastNewsPageDTO): LastNewsBlocks {
    const blocks: LastNewsBlocks = [];

    return pageDTO.config.content.reduce((acc, current) => {
      const { data } = pageDTO.data[current.path];

      if (current.type === 'last_news') {
        acc.push({
          type: current.type,
          data:
            (data as LastNewsPageLastNewsDTO['data'])?.map(item => ({
              date: item.date,
              records:
                item.records?.map(record => BaseRecordMapper.toDO(record)) ?? []
            })) ?? []
        });
      }

      return acc;
    }, blocks);
  }
}
