import type { FormatNewsItemDTO } from '@fontanka/news-api-client';

import type { BaseRecord } from '../domain';

import { BaseRecordMapper } from './base-record-mapper.service';

export class FormatRecordsMapper {
  public static toDO(formatsDTO?: FormatNewsItemDTO[]): BaseRecord[] {
    return formatsDTO?.map(r => BaseRecordMapper.toDO(r)) ?? [];
  }
}
