import {
  BlockConfig,
  BaseRecordDTO,
  KeywordsDTO,
  BlockInfo,
  CompanyNewsItemDTO,
  AuthorDTO,
  BaseSocialDTO,
  TopNewsItemDTO,
  ImageDTO
} from '../../BaseDTOs';

export enum LeftAndRightColsBlockType {
  Authors = 'authors',
  BusinessNews = 'business_news',
  Calendar = 'calendar',
  CompanyNews = 'company_news',
  CompanyNewsBottom = 'company_news_bottom',
  Discussions = 'discussions',
  FontankaPro = 'fontanka_pro',
  HourNews = 'hour_news',
  MainNews = 'main_news',
  NavigationLinks = 'navigation_links',
  MainPageCustomTextBlock = 'main_page_custom_text_block',
  News = 'news',
  Opinions = 'opinions',
  OurCollection = 'our_collection',
  PictureOfTheDay = 'picture_of_the_day',
  ReadersChoice = 'readers_choice',
  Recommendation = 'recommendation',
  RubricNews = 'rubric_news',
  RubricRecords = 'rubric_records',
  Socials = 'socials',
  Suggestions = 'suggestions',
  Supercover = 'supercover',
  TopNews = 'top_news',
  Weekend = 'network_content',
  Window = 'window',
  WindowRubrics = 'window_rubrics'
}

export type HourNewsItemDTO = BaseRecordDTO & {
  headerKeywords: KeywordsDTO;
  publishTime: string;
  publishDateForWindow: string;
};

export type HourNewsDTO = BlockInfo<
  HourNewsItemDTO,
  LeftAndRightColsBlockType.HourNews
>;

export type NewsItemDTO = BaseRecordDTO & {
  headerKeywords: KeywordsDTO;
  publishTime: string;
  publishDateForWindow: string;
  isPictureOfTheDay: boolean;
  isActiveTranslation: boolean;
  hasVideoMark?: boolean;
  hasMainPhotoCommercialLabel?: boolean;
};

export type NavigationLinkDTO = {
  path: string;
  title: string;
};

export type FontankaProItemDTO = BaseRecordDTO & {
  leadForNews: string;
  viewType: 'business_tribune' | 'fontanka_pro';
  mainPhoto: ImageDTO;
  publishedAgo?: string;
};

export type NewsDTO = BlockInfo<
  {
    all: NewsItemDTO[] | null;
    spb: NewsItemDTO[] | null;
  },
  LeftAndRightColsBlockType.News
>;

export type CompanyNewsDTO = BlockInfo<
  CompanyNewsItemDTO[],
  LeftAndRightColsBlockType.CompanyNews
>;

export type NewCompanyNewsDTO = BlockInfo<
  CompanyNewsItemDTO[],
  LeftAndRightColsBlockType.CompanyNewsBottom
>;

export type RubricRecordsDTO = BlockInfo<
  {
    news: BaseRecordDTO[];
    articles: BaseRecordDTO[];
  },
  LeftAndRightColsBlockType.RubricRecords
>;

export type RubricNewsDTO = BlockInfo<
  BaseRecordDTO[],
  LeftAndRightColsBlockType.RubricNews
>;

export type PictureOfTheDayDTO = BlockInfo<
  BaseRecordDTO[] | null,
  LeftAndRightColsBlockType.PictureOfTheDay
>;

export type SuggestionsDTO = BlockInfo<
  BaseRecordDTO[] | null,
  LeftAndRightColsBlockType.Suggestions
>;

export type ReadersChoiceDTO = BlockInfo<
  BaseRecordDTO[] | null,
  LeftAndRightColsBlockType.ReadersChoice
>;

export type FontankaProDTO = BlockInfo<
  FontankaProItemDTO[],
  LeftAndRightColsBlockType.FontankaPro
>;

export type DiscussionsItemDTO = BaseRecordDTO & {
  commentsCount: number;
};

export type DiscussionsDTO = BlockInfo<
  DiscussionsItemDTO[],
  LeftAndRightColsBlockType.Discussions
>;

export type OpinionItemDTO = BaseRecordDTO & {
  author: AuthorDTO;
};

export type OpinionsDTO = BlockInfo<
  OpinionItemDTO[] | null,
  LeftAndRightColsBlockType.Opinions
>;

export type OpinionAvatarDTO = {
  fileName: string;
  width: number;
  height: number;
};

export type OpinionAuthorDTO = {
  id: number;
  image: OpinionAvatarDTO;
  jobPosition: string;
  name: string;
  url: string;
  sex?: 'female' | 'male' | 'unknown';
};

export type OpinionUrlsDTO = {
  url: string;
  urlComments: string;
};

export type OpinionDTO = {
  id: number;
  header: string;
  authors: OpinionAuthorDTO[];
  urls?: OpinionUrlsDTO;
  commentsCount?: number;
  viewsCount?: number;
  isCommentsAllowed?: boolean;
  publishedAgo: string;
};

export type OpinionsBlockDTO = BlockInfo<
  OpinionDTO[] | null,
  LeftAndRightColsBlockType.Opinions
>;

export type PictureDayTabDTO =
  | 'picture_of_the_day'
  | 'readers_choice'
  | 'suggestions';

export type PictureDayArticleDTO = {
  header: string;
  id: number;
  isCommercial: boolean;
  subheader: string;
  urls: {
    url: string;
    urlComments: string;
  };
  hasMainPhotoCommercialLabel?: boolean;
};

export type PictureDayBlockDTO = BlockInfo<
  Record<PictureDayTabDTO, PictureDayArticleDTO[]>,
  LeftAndRightColsBlockType.MainNews
>;

export type SpecialNewsDTO = {
  id: number;
  hasVideoMark: boolean;
  isCommercial: boolean;
  header: string;
  headerKeywords: string[];
  isPictureOfTheDay: boolean;
  publishDateForRecordHeader: string;
  publishDateForWindow: string;
  publishDateForWindowDotFormat: string;
  subheader: string;
  urls: {
    url: string;
    urlComments: string;
  };
  hasMainPhotoCommercialLabel?: boolean;
};

export type SpecialNewsBlockDTO = BlockInfo<
  SpecialNewsDTO,
  LeftAndRightColsBlockType.HourNews
>;

export type SingleCardUrlsDTO = {
  url: string;
  urlCanonical: string;
  urlComments: string;
};

export type SingleCardDTO = {
  card: {
    authorDescription: string;
    authorName: string;
    quote: string;
    text: string;
  };
  meta: {
    type: 'quote' | 'poll';
  };
  record: {
    id: number;
    commentsCount: number;
    publishedAgo: string;
    urls: SingleCardUrlsDTO;
    viewsCount: number;
  };
};

export type SingleCardBlockDataDTO = SingleCardDTO;

export type SingleCardBlockDTO = BlockInfo<
  SingleCardBlockDataDTO,
  LeftAndRightColsBlockType.MainPageCustomTextBlock
>;

export type SocialsDTO = BlockInfo<
  BaseSocialDTO[],
  LeftAndRightColsBlockType.Socials
>;

export type NavigationLinksDTO = BlockInfo<
  NavigationLinkDTO[] | null,
  LeftAndRightColsBlockType.NavigationLinks
>;

export type AuthorsItem = AuthorDTO & {
  url: string;
  isActiveAuthor: boolean;
};

export type AuthorsDTO = BlockInfo<AuthorsItem[], LeftAndRightColsBlockType.Authors>;

export type AuthorRecordsItemDTO = BaseRecordDTO & {
  publishDateForWindow: string;
};

export type SuperCoverUrlsDTO = {
  url: string;
  urlCanonical: string;
  urlComments: string;
};

export type SuperCoverLinkDTO = {
  title: string;
  path: string;
};

export type SuperCoverArticleDTO = {
  id: number;
  hasVideoMark: boolean;
  header: string;
  urls: SuperCoverUrlsDTO;
  publishAt?: string;
};

export type SuperCoverMainArticleDTO = SuperCoverArticleDTO & {
  mainPhoto: ImageDTO;
};

export type SuperCoverThemeDTO = {
  id: number;
  link: string;
  records: SuperCoverArticleDTO[];
  title: string;
};

export type SuperCoverDTO = {
  image: ImageDTO;
  labelTitle: string;
  links: SuperCoverLinkDTO[];
  record: SuperCoverMainArticleDTO;
  theme: SuperCoverThemeDTO;
};

export type SupercoverDTO = BlockInfo<
  SuperCoverDTO | null,
  LeftAndRightColsBlockType.Supercover
>;

export type TopNewsDTO = BlockInfo<
  TopNewsItemDTO[],
  LeftAndRightColsBlockType.TopNews
>;

export type LeftColConfigs =
  | BlockConfig<LeftAndRightColsBlockType.HourNews>
  | BlockConfig<LeftAndRightColsBlockType.News>
  | BlockConfig<LeftAndRightColsBlockType.Socials>
  | BlockConfig<LeftAndRightColsBlockType.PictureOfTheDay>
  | BlockConfig<LeftAndRightColsBlockType.Suggestions>
  | BlockConfig<LeftAndRightColsBlockType.NavigationLinks>
  | BlockConfig<LeftAndRightColsBlockType.Authors>;

export type RightColConfigs =
  | BlockConfig<LeftAndRightColsBlockType.HourNews>
  | BlockConfig<LeftAndRightColsBlockType.News>
  | BlockConfig<LeftAndRightColsBlockType.Socials>
  | BlockConfig<LeftAndRightColsBlockType.CompanyNews>
  | BlockConfig<LeftAndRightColsBlockType.CompanyNewsBottom>
  | BlockConfig<LeftAndRightColsBlockType.RubricRecords>
  | BlockConfig<LeftAndRightColsBlockType.RubricNews>
  | BlockConfig<LeftAndRightColsBlockType.Discussions>
  | BlockConfig<LeftAndRightColsBlockType.Opinions>
  | BlockConfig<LeftAndRightColsBlockType.ReadersChoice>
  | BlockConfig<LeftAndRightColsBlockType.TopNews>;

export type NewBlockConfigs = {
  supercover: BlockConfig<LeftAndRightColsBlockType.Supercover>;
  business_news: BlockConfig<LeftAndRightColsBlockType.BusinessNews>;
  company_news: BlockConfig<LeftAndRightColsBlockType.CompanyNews>;
  company_news_bottom: BlockConfig<LeftAndRightColsBlockType.CompanyNewsBottom>;
  discussions: BlockConfig<LeftAndRightColsBlockType.Discussions>;
  fontanka_pro: BlockConfig<LeftAndRightColsBlockType.FontankaPro>;
  hour_news: BlockConfig<LeftAndRightColsBlockType.HourNews>;
  main_news: BlockConfig<LeftAndRightColsBlockType.MainNews>;
  main_page_custom_text_block: BlockConfig<LeftAndRightColsBlockType.MainPageCustomTextBlock>;
  news: BlockConfig<LeftAndRightColsBlockType.News>;
  opinions: BlockConfig<LeftAndRightColsBlockType.Opinions>;
  our_collection: BlockConfig<LeftAndRightColsBlockType.OurCollection>;
  readers_choice: BlockConfig<LeftAndRightColsBlockType.ReadersChoice>;
  recommendation: BlockConfig<LeftAndRightColsBlockType.Recommendation>;
  rubric_news: BlockConfig<LeftAndRightColsBlockType.RubricNews>;
  rubric_records: BlockConfig<LeftAndRightColsBlockType.RubricRecords>;
  socials: BlockConfig<LeftAndRightColsBlockType.Socials>;
  top_news: BlockConfig<LeftAndRightColsBlockType.TopNews>;
  weekend: BlockConfig<LeftAndRightColsBlockType.Weekend>;
  window: BlockConfig<LeftAndRightColsBlockType.Window>;
  window_rubrics: BlockConfig<LeftAndRightColsBlockType.WindowRubrics>;
  calendar: BlockConfig<LeftAndRightColsBlockType.Calendar>;
};

export type LeftColContent =
  | HourNewsDTO
  | NewsDTO
  | SocialsDTO
  | PictureOfTheDayDTO
  | SuggestionsDTO
  | NavigationLinksDTO
  | AuthorsDTO;

export type RightColContent =
  | HourNewsDTO
  | NewsDTO
  | SocialsDTO
  | CompanyNewsDTO
  | RubricRecordsDTO
  | RubricNewsDTO
  | ReadersChoiceDTO
  | DiscussionsDTO
  | OpinionsDTO
  | TopNewsDTO;
