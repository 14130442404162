






















import { guardUnspecified } from '@portal/utils/util-guards';
import { Component, Vue } from 'vue-property-decorator';

import { grid } from '../../../styles/redesign/grid/grid';

@Component({
  name: 'UiLayout'
})
export default class UiLayout extends Vue {
  get isRenderLeftCol() {
    return guardUnspecified(this.$slots['left-col']);
  }

  get isRenderTopBlock() {
    return guardUnspecified(this.$slots['top-block']);
  }

  get classes() {
    return grid;
  }
}
