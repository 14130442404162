import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './radio-btn.styles.scss';

type Events = {
  onChange: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      value: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      checked: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      emitChangeEvent() {
        this.$emit('change');
      }
    },
    render() {
      return (
        <label class={styles.radioBtn} title={this.title}>
          <input
            value={this.value}
            name={this.name}
            class={styles.inputHidden}
            type="radio"
            checked={this.checked}
            onChange={this.emitChangeEvent}
          />
          <span class={styles.inner}>{this.$slots.default}</span>
        </label>
      );
    }
  });
