import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import type { FormatRecord } from '../../_items';
import { FormatRecordItem } from '../../_items';

import styles from './formats-list.styles.scss';

const WIDE_BLOCKS_COUNT = 6;

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    records: {
      type: Array as () => FormatRecord[],
      required: true
    }
  },
  render() {
    return (
      <div class={styles.formatsList}>
        {this.records.map((record, index) => (
          <FormatRecordItem
            record={record}
            type={index < WIDE_BLOCKS_COUNT ? 'wide' : 'normal'}
            isFirst={index === 0}
          />
        ))}
      </div>
    );
  }
});
