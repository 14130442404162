




















import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import { UiNewsGroupItem } from './ui-news-group-item';
import type { NewsGroupItem, NewsGroupTheme } from './ui-news-group.contract';

@Component({
  name: 'UiNewsGroup',
  components: { UiNewsGroupItem }
})
export default class UiNewsGroup extends Vue {
  @Prop({
    required: true,
    type: Array
  })
  items!: NewsGroupItem[];

  @Prop({
    default: 'light',
    type: String
  })
  theme: NewsGroupTheme;

  @Prop({
    default: '',
    type: String
  })
  dataTest: string;

  @Prop({
    default: false,
    type: Boolean
  })
  hasBookmarks: boolean;

  @Emit('articleClicked')
  emitClickToArticle(article: NewsGroupItem, place: number) {
    return { id: article.id, path: article.title.link, place };
  }

  @Emit('commentClicked')
  emitClickToComment(article: NewsGroupItem, place: number) {
    return { id: article.id, path: article.title.link, place };
  }

  @Emit('bookmarkClicked')
  emitClickToBookmark(article: NewsGroupItem, place: number) {
    return { id: article.id, path: article.title.link, place };
  }

  @Emit('articleShown')
  emitArticleShown(article: NewsGroupItem, place: number) {
    return { id: article.id, path: article.title.link, place };
  }
}
