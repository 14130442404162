import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './rubric-comments-item.styles.scss';

type Record = {
  urls: {
    urlComments: string;
  };
  commentsCount: number;
  isCommentsAllowed: boolean;
};

type Events = {
  onCommentsClicked: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'RubricCommentsItem',
    props: {
      record: {
        type: Object as () => Record,
        required: true as const
      },
      color: {
        type: String as () => 'orange' | 'white',
        default: 'orange'
      }
    },
    methods: {
      emitCommentsClicked(): void {
        this.$emit('commentsClicked');
      }
    },
    render() {
      return (
        <a
          class={[styles.commentsLink, styles[this.color]]}
          href={this.record.urls.urlComments}
          title={`Количество комментариев ${this.record.commentsCount}`}
          onClick={this.emitCommentsClicked}
        >
          <i class={[styles.commentsIcon, styles[this.color]]} />
          <span>{this.record.commentsCount}</span>
        </a>
      );
    }
  });
