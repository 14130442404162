type ForecastByDate = {
  cloudTitle: string;
  date: string;
  dayTemperature: string;
  icon: string;
  iconSrc: string;
  nightTemperature: string;
  precipitationTitle: string;
  title: string;
  isWeekend: boolean;
};

type DayTime = {
  name: string;
  cloudTitle: string;
  humidity: number;
  icon: string;
  iconSrc: string;
  precipitationTitle: string;
  pressure: number;
  temperature: string;
  windDirection: {
    style: string;
    name: string;
  };
  windSpeed: number;
};

export type WeatherData = {
  day: {
    sunrise: string;
    sunset: string;
  };
  forecast: ForecastByDate[];
  today: DayTime[];
  todayHeader: string;
};

export type WeatherPage = {
  blocks: {
    type: 'weather_data';
    data: WeatherData;
  }[];
};

export const WEATHER_DATE_FORMAT = 'd MMMM';
