import { guardUnspecified } from '@smh/utils/guards';

import type {
  NewsfeedItemVM,
  NewsfeedVM
} from '@fe/common/features/new-home/domain';
import { isPhotoRecord, isVideoRecord } from '@fontanka/news';
import type { NewsDTO, NewsItemDTO } from '@fontanka/news-api-client';

const COUNT_NEWS_MOBILE = 8;

const getNewsfeedList = (list: NewsItemDTO[], isMobile: boolean) => {
  return isMobile ? [...list.slice(0, COUNT_NEWS_MOBILE)] : list;
};

const presentNewsfeedItem = (data: NewsItemDTO, index: number): NewsfeedItemVM => {
  return {
    index,
    id: data.id,
    title: data.header,
    isActiveTranslation: data.isActiveTranslation ?? false,
    url: data.urls?.url || '',
    hasImageMark: isPhotoRecord(data?.headerKeywords ?? []),
    hasVideoMark: data.hasVideoMark ?? isVideoRecord(data?.headerKeywords ?? []),
    time: data.publishDateForWindow,
    isCommercial: data.isCommercial,
    isPictureOfTheDay: data.isPictureOfTheDay,
    hasMainPhotoCommercialLabel: data.hasMainPhotoCommercialLabel ?? false
  };
};

export class NewsfeedMapper {
  public static present(data: NewsDTO['data'], isMobile: boolean) {
    const result: NewsfeedVM = {
      data: {
        all: [],
        spb: []
      },
      params: {
        tabs: [
          {
            id: 'all',
            title: 'Все'
          },
          {
            id: 'spb',
            title: 'СПБ'
          }
        ],
        link24hours: {
          link: '/24hours.html',
          title: '24 часа'
        },
        linkArc: {
          link: '/arc/all.html',
          title: 'Архив новостей'
        },
        linkAllNews: {
          link: '/24hours_news.html',
          title: 'Все новости'
        }
      }
    };

    if (guardUnspecified(data?.all)) {
      result.data.all = getNewsfeedList(data.all, isMobile).map(
        (item: NewsItemDTO, index: number) => presentNewsfeedItem(item, index)
      );
    }

    if (guardUnspecified(data?.spb)) {
      result.data.spb = getNewsfeedList(data.spb, isMobile).map(
        (item: NewsItemDTO, index: number) => presentNewsfeedItem(item, index)
      );
    }

    return result;
  }
}
