






import { Component, Vue } from 'vue-property-decorator';
import { LazyHydrateSsr } from '../lazy-hydrate-ssr';

// TODO при раскатке фонтанки на скрудж утащить на бек! Там данные летят для блока smi2_video

@Component({
  name: 'AdvSmi2Video',
  components: {
    LazyHydrateSsr,
  }
})
export default class AdvSmi2Video extends Vue {
  getHTML(pixel: string) {
    return `<div id="unit_101573"><a href="https://smi2.ru/"></a></div>
      <script type="text/javascript" charset="utf-8">
        (function() { var sc = document.createElement('script'); sc.type = 'text/javascript'; sc.async = true; sc.src = '//smi2.ru/data/js/101573.js'; sc.charset = 'utf-8'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sc, s); const trackingPixels = '${pixel}'; new Image().src = trackingPixels; })();
      <\/script>`;
  }

  get pixel() {
    return `https://yandex.ru/ads/adfox/320882/getCode?p1=cgvnk&p2=frfe&pfc=fptje&pfb=qksip&puid1=&puid2=&puid3=&puid4=&puid5=&puid6=&puid7=&puid8=&puid9=&puid10=&puid11=&puid12=&puid13=&puid14=&puid15=&puid16=&puid17=&puid18=&puid19=&puid20=&puid21=&puid22=&puid23=&puid24=&puid25=&puid26=&puid27=&puid28=&puid29=&puid30=&puid31=&puid32=&puid33=&puid34=&puid35=&puid36=&puid37=&puid38=&puid39=&puid40=&puid41=&puid42=&puid43=&puid44=&puid45=&puid46=&puid47=&puid48=&puid49=&puid50=&puid51=&puid52=&puid53=&puid54=&puid55=&puid56=&puid57=&puid58=&puid59=&puid60=&puid61=&puid62=&pr=%random%&ptrc=b`;
  }

  get html() {
    return this.getHTML(this.pixel);
  }
}
