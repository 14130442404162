import type { Author } from '@fontanka/news';
import { createAuthor } from '@fontanka/news';
import type {
  MentionsPageMentionRecordItem,
  MentionsPageMentionsDTO
} from '@fontanka/news-api-client';

export type MentionsPageData = {
  blocks: {
    type: MentionsPageMentionsDTO['type'];
  }[];
  recordsInfo: {
    total: number;
    records: MentionRecord[];
  };
};

export type MentionRecord = {
  header: string;
  subheader: string;
  author: Author | null;
  urls: {
    url: string;
  };
};

export const createMentionRecord = (
  recordDTO: MentionsPageMentionRecordItem
): MentionRecord => {
  const author = recordDTO.authors[0] ?? null;

  return {
    header: recordDTO.header,
    subheader: recordDTO.subheader,
    author: createAuthor(author, { crop: true }),
    urls: {
      url: recordDTO.urls.url
    }
  };
};
