




















import { Component, Prop, Vue } from 'vue-property-decorator';

import { AspectRatio } from './ui-image.contract';
import type { Image } from './ui-image.contract';

@Component({
  name: 'UiImage'
})
export default class UiImage extends Vue {
  @Prop({
    required: true,
    type: Object as () => Image
  })
  image: Image;

  @Prop({
    type: String,
    default: ''
  })
  alt: string;

  @Prop({
    type: Number
  })
  width: number;

  @Prop({
    type: Number
  })
  height: number;

  @Prop({
    type: String,
    default: ''
  })
  imgClass: string;

  get aspectRatioClass() {
    switch (this.image.aspectRatio) {
      case AspectRatio.Square:
        return 'aspectRatioSquare';
      case AspectRatio.Classic:
        return 'aspectRatioClassic';
      case AspectRatio.Wide:
        return 'aspectRatioWide';
      case AspectRatio.VerticalClassic:
        return 'aspectRatioVerticalClassic';
      case AspectRatio.VerticalWide:
        return 'aspectRatioVerticalWide';
      default:
        return '';
    }
  }

  get imgWidth() {
    return this.width ?? '100%';
  }

  get wrapperStyles() {
    const styles: { width: string; height?: string } = {
      width: this.width ? `${this.width}px` : '100%'
    };

    if (this.height) {
      styles.height = `${this.height}px`;
    }

    return styles;
  }
}
