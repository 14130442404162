import type { TagPageDTO, TagPageBlocksDTO } from '@fontanka/news-api-client';

import { BaseRecordMapper, SearchRubricsMapper } from '../../../services';
import type { TagPage } from '../domain';

export class TagPageMapper {
  public static toDO(pageDTO: TagPageDTO): TagPage {
    const initialTagPage: TagPage = {
      rubrics: SearchRubricsMapper.toDO(pageDTO),
      blocks: [],
      title: pageDTO.metatags?.h1 ?? ''
    };

    return pageDTO.config.content.reduce((acc, current) => {
      if (current.type === 'search_result') {
        const block = pageDTO.data[current.path] as TagPageBlocksDTO;

        acc.blocks.push({
          type: 'tag_records_list',
          data: block.data?.map(record => BaseRecordMapper.toDO(record)) ?? [],
          totalRecords: block.meta?.total ?? 0
        });
      }

      return acc;
    }, initialTagPage);
  }
}
