import { SEARCH_INFO } from '@fontanka/data';

export const SEARCH_ROUTING = [
  {
    path: SEARCH_INFO.PAGE_URL,
    name: 'search',
    component: () =>
      import(/* webpackChunkName: "search-page" */ './pages/search.page')
  }
];
