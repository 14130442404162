import { componentFactoryOf } from 'vue-tsx-support';

import type { Info } from './archive-records-section';

import { ArchiveRecordsSection } from '.';

type Events = {
  onVisibleRecordItem: {
    recordId: number;
  };
  onClickRecordItemTitle: {
    recordId: number;
  };
  onClickRecordItemRubric: {
    recordId: number;
  };
  onClickRecordItemComments: {
    recordId: number;
  };
  onToggleSection: {
    isClosed: boolean;
  };
};

export default componentFactoryOf<Events>().create({
  props: {
    showOnlySpbRecords: {
      type: Boolean,
      required: false,
      default: false
    },
    data: {
      type: Array as () => Info[],
      required: true
    }
  },
  methods: {
    toggleSection(isClosed: boolean) {
      this.$emit('toggleSection', { isClosed });
    },
    visibleRecordItem(recordId: number) {
      this.$emit('visibleRecordItem', { recordId });
    },
    clickRecordItemTitle(recordId: number) {
      this.$emit('clickRecordItemTitle', { recordId });
    },
    clickRecordItemRubric(recordId: number) {
      this.$emit('clickRecordItemRubric', { recordId });
    },
    clickRecordItemComments(recordId: number) {
      this.$emit('clickRecordItemComments', { recordId });
    }
  },
  render() {
    return (
      <div>
        {this.data.length > 0 &&
          this.data.map(info => {
            if (info.records.length > 0) {
              return (
                <ArchiveRecordsSection
                  data={info}
                  showOnlySpbRecords={this.showOnlySpbRecords}
                  onToggle={params => this.toggleSection(params.isClosed)}
                  onVisibleRecordItem={params =>
                    this.visibleRecordItem(params.recordId)
                  }
                  onClickRecordItemTitle={params =>
                    this.clickRecordItemTitle(params.recordId)
                  }
                  onClickRecordItemRubric={params =>
                    this.clickRecordItemRubric(params.recordId)
                  }
                  onClickRecordItemComments={params =>
                    this.clickRecordItemComments(params.recordId)
                  }
                />
              );
            }
          })}
      </div>
    );
  }
});
