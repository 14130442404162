import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { NoSSR } from '@fe/common/ui';
import closeIcon from 'images/actual/close.svg';

// eslint-disable-next-line import/no-useless-path-segments
import { AdvCreative } from '../../components/adv-creative';
import type { AdvData as _AdvData } from '../adv/adv.component';

import styles from './adv-mobile-sticky-bottom.styles.scss';

const CLOSE_DELAY_TIME = 4000;

const RELOAD_TIME = 60000;

const RECORD_WITHOUT_ADV = [71983220];

type ComponentData = {
  isVisibleAdv: boolean;
  isVisibleCloseBtn: boolean;
  isClosed: boolean;
};

type AdvData = _AdvData;

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'AdvMobileStickyBottom',
  props: {
    adv: {
      type: Object as () => AdvData,
      required: true as const
    },
    isBottomIntend: {
      type: Boolean,
      default: false
    }
  },
  data(): ComponentData {
    return {
      isVisibleAdv: false,
      isVisibleCloseBtn: false,
      isClosed: false
    };
  },
  computed: {
    recordId(): number {
      return this.$route.name === 'record' ? Number(this.$route.params.id) : 0;
    },
    isRender(): boolean {
      if (RECORD_WITHOUT_ADV.includes(this.recordId)) {
        return false;
      }

      return !this.isClosed;
    }
  },
  methods: {
    onDisplay() {
      this.isVisibleAdv = true;

      setTimeout(() => {
        this.isVisibleCloseBtn = true;
      }, CLOSE_DELAY_TIME);
    },
    onClose() {
      this.isClosed = true;
      this.isVisibleAdv = false;
      this.isVisibleCloseBtn = false;

      setTimeout(() => {
        this.isClosed = false;
      }, RELOAD_TIME);
    }
  },
  render() {
    return this.isRender ? (
      <div
        class={[
          styles.wrapper,
          {
            [styles.bottomIntend]: this.isBottomIntend,
            [styles.hidden]: !this.isVisibleAdv
          }
        ]}
      >
        <NoSSR>
          <AdvCreative
            adv={this.adv}
            onDisplay={this.onDisplay}
            clientRender={true}
          />
        </NoSSR>
        {this.isVisibleCloseBtn && (
          <div class={styles.closeBtnWrap}>
            <img
              class={styles.closeBtn}
              src={closeIcon}
              onClick={this.onClose}
              alt="close"
            />
          </div>
        )}
      </div>
    ) : (
      <div />
    );
  }
});
