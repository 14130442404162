import { ThemesHttpClient } from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { FormatRecordsMapper } from '../../../services';

export class ThemeRecordsDataMapper {
  private readonly _themesHttpClient: ThemesHttpClient;

  constructor(envType: string) {
    this._themesHttpClient = new ThemesHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getThemeRecords(
    theme: string,
    page: number,
    pagesize: number
  ): Promise<BaseRecord[]> {
    let formatRecords: BaseRecord[] = [];

    try {
      const { data } = await this._themesHttpClient.fetchThemeRecords({
        theme,
        page,
        pagesize
      });

      formatRecords = FormatRecordsMapper.toDO(data ?? []);
    } catch (err) {
      console.error(err);
    }

    return formatRecords;
  }
}
