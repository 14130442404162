import { Action, Mutation, State } from 'vuex-simple';

import type { RootModule } from '../../../../core';
import type { BaseRecord, SearchRubrics } from '../../../../domain';
import { SearchRubricsMapper } from '../../../../services';
import type { DailyNewsBlocks as DailyNewsBlocksAlias } from '../../domain/daily-news';
import { getPageUrl, getFilterLinks } from '../../domain/daily-news';
import type { FilterLink } from '../../domain/filter-link';
import { DailyNewsMapper } from '../../services';

type DailyNewsBlocks = DailyNewsBlocksAlias;

type NavigateParams = {
  type: FilterLink['type'];
  rubricId: 'all' | string;
};

type PageParams = {
  page: number;
  pagesize: number;
  period: number;
};

export const DAILY_NEWS_MODULE_NAMESPACE = ['daily_news_module'];

export class DailyNewsModule {
  @State()
  public filterLinks: FilterLink[];

  @State()
  public rubrics: SearchRubrics;

  @State()
  blocks: DailyNewsBlocks;

  private readonly _dailyNewsMapper: DailyNewsMapper;

  constructor(private _root: RootModule, rubric: string) {
    this._dailyNewsMapper = new DailyNewsMapper(this._root.envType);

    this.rubrics = SearchRubricsMapper.toDO(this._root.pageSpec);
    this.filterLinks = getFilterLinks(rubric);

    this.blocks = DailyNewsMapper.toDO(this._root.pageSpec);
  }

  @Action()
  navigate(params: NavigateParams) {
    location.href = getPageUrl(params.type, params.rubricId);
  }

  @Action()
  async fetchMoreNews(params: PageParams) {
    const records = await this._dailyNewsMapper.getMoreNews(params);
    this.addRecords(records);
  }

  @Mutation()
  addRecords(newRecords: BaseRecord[]) {
    this.blocks
      .filter(dayArchiveBlock => dayArchiveBlock.type === 'daily_news')
      .map(dayArchiveBlock => {
        dayArchiveBlock.recordsCountPerPage += newRecords.length;
        dayArchiveBlock.data.map(blockData => {
          blockData.records.push(...newRecords);
        });
      });
  }
}
