import { init as initSentry } from '@sentry/browser';
import { Vue as SentryVueIntegrations } from '@sentry/integrations';
import Vue from 'vue';
import type { PluginFunction, PluginObject } from 'vue';
import VueObserveVisibility from 'vue-observe-visibility';
import { sync } from 'vuex-router-sync';

import { createStore } from '@apps/frontend';

import App from './app';
import { createRouter } from './router';

Vue.config.ignoredElements = ['noindex', 'portal-stories'];

if (typeof window !== 'undefined') {
  window.onerror = function (message, source, lineno, colno, error) {
    console.error('window.onerror: ', error);
  };
}

const enableClientSentry =
  process.env.VUE_ENV !== 'server' &&
  process.env.NODE_ENV === 'production' &&
  typeof window !== 'undefined' &&
  !window.envType;

if (enableClientSentry) {
  initSentry({
    ignoreErrors: [
      'Failed to load because no supported source was found',
      'The play() request was interrupted by a call to pause()',
      '<IFRAME name=',
      'The media resource indicated by the src attribute or assigned media provider object was not suitable',
      // https://sentry.portal.s/portal/fontanka-frontend/issues/212108
      'src/helpers in arg',
      // https://sentry.portal.s/portal/fontanka-frontend/issues/211178
      "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
      // http://sentry.portal.s/portal/fontanka-frontend/issues/212108
      "Can't execute code from a freed script",
      // https://sentry.portal.s/portal/fontanka-frontend/issues/212130
      "Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules",
      // https://sentry.portal.s/portal/fontanka-frontend/issues/212005
      'Non-Error promise rejection captured with value: Timeout',
      // https://sentry.portal.s/portal/fontanka-frontend/issues/211405
      'Blocked a frame with origin',
      // https://sentry.portal.s/portal/fontanka-frontend/issues/211122
      'AdSDK Error',
      // https://sentry.portal.s/portal/fontanka-frontend/issues/212067
      'InvalidStateError',
      // https://sentry.portal.s/portal/fontanka-frontend/issues/212146
      'The operation was aborted',
      // https://sentry.portal.s/portal/fontanka-frontend/issues/212168
      'pcode/media'
    ],
    whitelistUrls: ['https://static.ngs.ru/fontanka/'],
    dsn: `https://9e57db1939f046fab8e2b0a1549eb484@${window.location.host}/_report/20`,
    integrations: [new SentryVueIntegrations({ Vue, attachProps: true })],
    release: window.fntRelease,
    enabled: false
  });
} else {
  Vue.config.errorHandler = function (err, vm, info) {
    console.error('Vue.errorHandler: ', err);
  };
}

if (process.env.VUE_ENV !== 'server') {
  Vue.use(
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    require('vue-js-modal').default as
      | PluginObject<unknown>
      | PluginFunction<unknown>
  );
}

Vue.use(VueObserveVisibility);

export function createApp() {
  const router = createRouter();

  router.beforeEach((to, from, next) => {
    const cacheUrlsRegex = new RegExp(
      /(webcache\.googleusercontent\.com|yandexwebcache\.net)/,
      'gi'
    );
    if (
      typeof window !== 'undefined' &&
      window.location.href.match(cacheUrlsRegex) !== null
    ) {
      return false;
    }
    next();
  });

  const store = createStore();

  sync(store, router);

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  });

  return { app, router, store };
}
