export enum PageNames {
  RECORD = 'Материал',
  MAIN = 'Главная',
  COMMENTS = 'Комментарии',
  ALL_COMMENTS = 'Все дискуссии',
  ARCHIVE = 'Архив',
  ARCHIVE_CALENDAR = 'Архив (календарь)',
  ARCHIVE_BY_DATE = 'Архив за день',
  ARCHIVE_BY_24HOURS = 'Архив за 24 часа',
  RUBRIC = 'Рубрика',
  PROFILE = 'Профиль',
  SEARCH = 'Поиск',
  TAG = 'Тег',
  THEME = 'Тема',
  PROJECTS = 'Проекты',
  FORMAT = 'Форматы',
  WITNESS = 'Фото',
  VIDEO = 'Видео',
  MENTION = 'Мнения',
  AUTHOR = 'Страница автора',
  AUTHORS = 'Все авторы',
  PROFILE_SETTINGS = 'Настройки профиля',
  REGISTER = 'Регистрация',
  SUBSCRIPTION = 'Подписка',
  SUBSCRIPTION_REACTIVATE = 'Реактивация подписки',
  SUBSCRIPTIONS_UNSUBSCRIBE = 'Подтверждение отписки',
  DEFAULT = 'Прочее'
}
