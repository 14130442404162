/* eslint-disable @typescript-eslint/naming-convention */
type CreateSlotParams = {
  container: HTMLElement;
  adunit: string;
  params?: CustomSlotParams;
};

type CustomSlotParams = {
  type?: 'fullscreen' | 'floorAd';
  priority?: number;
  fullscreenBannerBlacklist?: string[];
};

type Targeting = {
  key: string;
  value: string | string[];
};

type SlotSizes = {
  x: number;
  y: number;
};

type SlotEvents =
  | 'render'
  | 'stub'
  | 'error'
  | 'request'
  | 'beforeDisplay'
  | 'display'
  | 'beforeRefresh'
  | 'refresh'
  | 'beforeDestroy'
  | 'closed'
  | 'destroy';

export enum SlotStatus {
  CREATED,
  READY,
  IN_PROGRESS,
  DESTROYED,
  STUB,
  ERROR
}

export interface ISlot {
  readonly $el: HTMLElement;
  readonly config: Creative;
  readonly bannerSize: SlotSizes;
  isDestroyed: boolean;
  status: SlotStatus;
  display(): Promise<unknown>;
  refresh(): Promise<unknown>;
  destroy(): Promise<unknown>;
  setTargeting(targeting: Targeting): void;
  on(eventName: SlotEvents, callback: () => void): void;
  off(eventName: SlotEvents, callback?: () => void): void;
}

export type Creative = {
  adserver: string;
  placement: string;
  layout: string;
  isMobile: boolean;
  placeholderEnabled: boolean;
  placeholderConfig: {
    height: number;
    backgroundEnabled: boolean;
    bordersEnabled: boolean;
    advMarkEnabled: boolean;
  };
  refreshEnabled: boolean;
  refreshConfig: {
    refreshInterval: number;
    viewportTime: number;
  };
  adfoxConfig: {
    p1: string;
    p2: string;
  };
};

export interface IStickyRsyaManager {
  setzIndex(zIndex: number): void;
  setBottomOffset(offset: number): void;
  setLeftOffset(offset: number): void;
}

export interface ICreativeManager {
  ready: Promise<void>;
  stickyRsya: IStickyRsyaManager;
  createSlot(params: CreateSlotParams): Promise<ISlot>;
  destroySlot(slot: ISlot): void;
  setTargeting(...targetings: Targeting[]): void;
  findSlots(adunit: string): ISlot[];
}

export enum PLACEMENTS {
  INREAD = 'inread',
  INREAD_2 = 'inread-2',
  HB_MID_1 = 'hb-mid-1',
  INLINE = 'inline',
  INLINE_1 = 'inline-1',
  INLINE_3 = 'inline-3',
  VB_RIGHT_5 = 'vb-right-5',
  STICKY_RSYA = 'sticky-rsya',
  STICKY = 'sticky',
  FLYROLL = 'flyroll'
}
