import type {
  AllCommentsPageDTO,
  AllCommentsPageCommentsNewsDTO
} from '@fontanka/news-api-client';

import { BaseRecordMapper } from '../../../services';
import type { AllCommentsPage } from '../domain';
import { AllCommentsRecords } from '../domain';

export class AllCommentsPageMapper {
  public static toDO(pageDTO: AllCommentsPageDTO): AllCommentsPage {
    const blocks: AllCommentsPage['blocks'] = [];

    return pageDTO.config.content.reduce(
      (acc, current) => {
        const { data } = pageDTO.data[current.path];

        if (current.type === 'comments_news') {
          acc.blocks.push({
            type: current.type,
            data:
              (data as AllCommentsPageCommentsNewsDTO['data'])?.map(item => ({
                date: item.date,
                records: new AllCommentsRecords(
                  item.records?.map(record => BaseRecordMapper.toDO(record)) ?? []
                ).records
              })) ?? []
          });
        }

        return acc;
      },
      {
        blocks
      }
    );
  }
}
