import { isBrowser } from '@fontanka/browser-or-node';
import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { BaseParams } from '../params';
import { FormatPageDTO } from './format-page-dto';
import {
  SearchHttpClient,
  SearchParams
} from '../search-http-client/search-http-client';

export type FormatPageParams = BaseParams & {
  format: string;
  page: number;
  pagesize: number;
};

export type FormatsParams = Pick<
  SearchParams,
  Exclude<keyof SearchParams, 'theme' | 'format'>
> & {
  format: string;
};

export class FormatsHttpClient extends BaseHttpClient {
  private readonly _searchHttpClient: SearchHttpClient;

  private readonly _formatPageUrl = '/pages/fontanka/formats/';

  constructor(config: Config) {
    super(config);

    this._searchHttpClient = new SearchHttpClient(config);
  }

  public async fetchFormatPageData(
    params: FormatPageParams
  ): Promise<Result<FormatPageDTO>> {
    const { format, page, pagesize, headers } = params;
    const url = `${this.host}${this._formatPageUrl}${format}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<FormatPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        format,
        page,
        pagesize
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchFormatRecords(params: FormatsParams) {
    return this._searchHttpClient.searchRecords(params);
  }
}
