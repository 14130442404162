import { State, Action } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type {
  MentionsPageData as MentionsPageDataAlias,
  MentionRecord as MentionRecordAlias
} from '../domain';
import { MentionsPageMapper, MentionRecordsDataMapper } from '../services';

type MentionsPageData = MentionsPageDataAlias;
type MentionRecord = MentionRecordAlias;

export const MENTIONS_PAGE_MODULE_NAMESPACE = ['mentions_page_module'];

export class MentionsPageModule {
  @State()
  public blocks: MentionsPageData['blocks'];

  @State()
  public records: MentionsPageData['recordsInfo']['records'];

  @State()
  public totalRecords: MentionsPageData['recordsInfo']['total'];

  private readonly _mentionRecordsDataMapper: MentionRecordsDataMapper;

  private readonly _recordsPageSize = 20;

  /**
   * Начинаем со 2-ой страницы, потому что с сервера придет первая
   */
  private _currentRecordsPage = 2;

  constructor(private _root: RootModule) {
    this._mentionRecordsDataMapper = new MentionRecordsDataMapper(
      this._root.envType
    );

    const { blocks, recordsInfo } = MentionsPageMapper.toDO(this._root.pageSpec);

    this.totalRecords = recordsInfo.total;
    this.records = recordsInfo.records;
    this.blocks = blocks;
  }

  @Action()
  async fetchRecords() {
    const records = await this._mentionRecordsDataMapper.getMentionRecords(
      this._currentRecordsPage,
      this._recordsPageSize
    );

    this._addRecords(records);

    this._currentRecordsPage = this._currentRecordsPage + 1;
  }

  private _addRecords(records: MentionRecord[]) {
    this.records = [...this.records, ...records];
  }
}
