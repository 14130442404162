import { Action } from 'vuex-simple';

import { MistakesHttpClient } from '@fontanka/news-api-client';
import type { MistakesParams } from '@fontanka/news-api-client';

export const MISTAKES_MODULE_NAMESPACE = ['mistakes_module'];

export class MistakesModule {
  private _mistakesHttpClient: MistakesHttpClient;

  constructor(envType: string) {
    this._mistakesHttpClient = new MistakesHttpClient({
      envType,
      protocol: 'https'
    });
  }

  @Action()
  public async sendMistakes(params: MistakesParams) {
    return await this._mistakesHttpClient.sendMistakes(params);
  }
}
