import { AxiosRequestConfig } from 'axios';

import { Config, BaseHttpClient } from '../base-http-client';

export type MistakesParams = {
  errorType: 'typo' | 'fact';
  selectedText: string;
  prevText: string;
  nextText: string;
  message: string;
  recordHeader: string;
  recordId: number;
  captchaToken: string;
};

export type MistakesResultDTO = {
  detail: string;
  status: number;
  title: string;
};

export class MistakesHttpClient extends BaseHttpClient {
  private readonly _mistakesUrl = '/public/fontanka/services/mistakes';

  constructor(config: Config) {
    super(config);
  }

  public async sendMistakes(
    params: MistakesParams
  ): Promise<MistakesResultDTO | null> {
    const url = `${this.host}${this._mistakesUrl}`;

    const formData = new FormData();
    formData.append('type', params.errorType);
    formData.append('text', params.selectedText);
    formData.append('prev', params.prevText);
    formData.append('next', params.nextText);
    formData.append('description', params.message);
    formData.append('url', window.location.href);
    formData.append('title', params.recordHeader);
    formData.append('entity', params.recordId.toString());
    formData.append('region', this.regionId.toString());
    formData.append('captchaToken', params.captchaToken);

    const options: AxiosRequestConfig = {
      withCredentials: true,
      method: 'POST',
      params: {
        regionId: this.regionId
      },
      url,
      data: formData
    };

    try {
      const response = await this.httpClient(options);
      return response.data.data;
    } catch (err) {
      return null;
    }
  }
}
