import { guardUnspecified, guardZeroNumber } from '@smh/utils/guards';
import { isAbsoluteUrl } from '@smh/utils/url';

import { createImage } from '@fontanka/news';
import type { CommonCalendarItemDTO } from '@fontanka/news-api-client';

import type { PageCommonCalendar, PageCommonCalendarRecord } from '../domain';

const IMAGE_WIDTH = 460;

export class CalendarMapper {
  public static toDo(
    blockSpecData: CommonCalendarItemDTO[] | null,
    isMobile: boolean
  ): PageCommonCalendar | null {
    if (!guardUnspecified(blockSpecData)) {
      return null;
    }

    return {
      title: 'Куда пойдем сегодня',
      url: '/theme/today/',
      records:
        blockSpecData.map((item, index) =>
          this._presentRecord(item, index, isMobile)
        ) ?? []
    };
  }

  private static _presentRecord(
    item: CommonCalendarItemDTO,
    index: number,
    isMobile: boolean
  ): PageCommonCalendarRecord {
    const commentsCount = item.commentsCount ?? 0;
    const isDiscuss = !guardZeroNumber(commentsCount);
    const commentsUrl = item.urls?.urlComments ?? '';
    const discussUrl = `${commentsUrl}?discuss=1`;
    const link = isDiscuss ? discussUrl : commentsUrl;

    const comments = item.isCommentsAllowed
      ? {
          text: isDiscuss ? 'Обсудить' : `${commentsCount}`,
          link,
          target: isAbsoluteUrl(link) ? '_blank' : '_self'
        }
      : undefined;
    return {
      isMain: index === 0 || index === 1,
      id: item.id,
      index,
      title: item.header,
      image: guardUnspecified(item.mainPhoto)
        ? createImage(item.mainPhoto, { width: IMAGE_WIDTH })
        : null,
      url: item.urls.url,
      statistic: {
        comments,
        date: {
          publishedAgo: item.publishedAgo ?? '5 минут назад'
        }
      }
    };
  }
}
