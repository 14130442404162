









































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

import FntBookmarkIcon from '~icons/fnt-ui-common/bookmark';
import FntBookmarkAddedIcon from '~icons/fnt-ui-common/bookmark-added';

import type { StatisticTheme, Statistic } from './ui-statistic.contract';

import FntCommentsIcon from '~icons/fnt-ui-common/comments';
import FntEyeIcon from '~icons/fnt-ui-common/eye';

@Component({
  name: 'UiStatistic',
  components: {
    FntBookmarkAddedIcon,
    FntBookmarkIcon,
    FntCommentsIcon,
    FntEyeIcon
  }
})
export default class UiStatistic extends Vue {
  @Prop({
    required: true,
    type: Object as () => Statistic
  })
  statistic!: Statistic;

  @Prop({
    type: String as () => StatisticTheme,
    default: 'light'
  })
  theme: StatisticTheme;

  @Prop({
    type: Boolean,
    default: false
  })
  hasBtn: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  isCenterPosition: boolean;

  get views() {
    return guardUnspecified(this.statistic.views)
      ? {
          isExist: true,
          count: this.statistic.views?.count
        }
      : { isExist: false };
  }

  get comments() {
    return guardUnspecified(this.statistic.comments)
      ? {
          isExist: true,
          link: this.statistic.comments.link,
          text: this.statistic.comments.text,
          target: this.statistic.comments.target
        }
      : { isExist: false };
  }

  get date() {
    return guardUnspecified(this.statistic.date)
      ? {
          isExist: true,
          publishedAgo: this.statistic.date.publishedAgo
        }
      : { isExist: false };
  }

  get isCustomBtnIcon() {
    return guardUnspecified(this.$slots.default);
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('btnClicked')
  btnClicked(event: Event): Event {
    return event;
  }
}
