import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './default-btn.styles.scss';

type Events = {
  onClicked: MouseEvent;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      link: {
        type: String,
        default: ''
      },
      type: {
        type: String as () => 'default' | 'big',
        default: 'default'
      },
      color: {
        type: String as () => 'grey' | 'orange' | 'green' | 'white' | 'whiteGray',
        default: 'grey'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      styles(): string[] {
        return [
          styles.btn,
          this.type === 'big' ? styles.big : '',
          styles[this.color]
        ];
      }
    },
    render() {
      return this.link && this.link !== '' ? (
        <a
          class={this.styles}
          href={this.link}
          title=""
          onClick={e => this.$emit('clicked', e)}
        >
          {this.$slots.default}
        </a>
      ) : (
        <button
          class={this.styles}
          disabled={this.disabled ? this.disabled : undefined}
          onClick={e => this.$emit('clicked', e)}
        >
          {this.$slots.default}
        </button>
      );
    }
  });
