




import { Component, Prop, Vue } from 'vue-property-decorator';

import type { SpinSizes } from './ui-spin.contract';

@Component({
  name: 'UiSpin',
})
export default class UiSpin extends Vue {
  @Prop({
    type: String,
    default: 'small',
  })
  size: SpinSizes;
}
