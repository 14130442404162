import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './our-collection.styles.scss';

type Events = {
  onHeaderClicked: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'OurCollection',
    methods: {
      ourCollectionHeaderClicked() {
        this.$emit('headerClicked');
      }
    },
    render() {
      return (
        <div class={styles.container}>
          <a
            class={styles.headerLink}
            href="/theme/best"
            onClick={this.ourCollectionHeaderClicked}
          >
            Наша коллекция
          </a>

          <ul class={styles.list}>{this.$slots.default}</ul>
        </div>
      );
    }
  });
