import { AxiosRequestConfig } from 'axios';

import { Config, BaseHttpClient } from '../base-http-client';

export type AuthUserInfoDTO = {
  avatar: string;
  editProfileLink: string;
  email: string;
  hasMailAccount: boolean;
  hasProfile: boolean;
  isBlocked: boolean;
  link: string;
  login: string;
  newMailsCount: number;
  sex: 'female' | 'male' | 'unknown' | '';
  trusted: boolean;
  userId: number;
};

export type LogoutInfoDTO = {
  detail: null;
  status: number;
  title: string;
};

export class AuthHttpClient extends BaseHttpClient {
  private readonly _authUrl = '/public/fontanka/users/account/';
  private readonly _loginUrl = '/public/fontanka/users/account/login/';
  private readonly _logoutUrl = '/public/fontanka/users/account/logout/';

  constructor(config: Config) {
    super(config);
  }

  public async checkAuthorization(): Promise<AuthUserInfoDTO | null> {
    const url = `${this.host}${this._authUrl}`;
    const withCredentials = true;
    let result;

    const options = {
      withCredentials,
      headers: {
        accept: this.accept
      },
      params: {
        regionId: this.regionId
      }
    };

    try {
      const response = await this.httpClient.get<{ data: AuthUserInfoDTO }>(
        url,
        options
      );
      result = response.data.data;
    } catch (err) {
      result = null;
    }

    return result;
  }

  public async login(
    username: string,
    password: string,
    captchaToken: string
  ): Promise<AuthUserInfoDTO | null> {
    const url = `${this.host}${this._loginUrl}`;
    const withCredentials = true;
    let result = null;

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'POST',
      headers: {
        accept: this.accept
      },
      auth: {
        username,
        password
      },
      data: {
        captchaToken
      },
      params: {
        regionId: this.regionId
      },
      url
    };

    try {
      const response = await this.httpClient(options);
      result = response.data.data;
    } catch (err) {
      result = null;
      throw err;
    }

    return result;
  }

  public async logout(): Promise<LogoutInfoDTO | null> {
    const url = `${this.host}${this._logoutUrl}`;
    const withCredentials = true;
    let result;

    const options: AxiosRequestConfig = {
      withCredentials,
      method: 'POST',
      headers: {
        accept: this.accept
      },
      url,
      params: {
        regionId: this.regionId
      }
    };

    try {
      const response = await this.httpClient(options);
      result = response.data.data;
    } catch (err) {
      result = null;
    }

    return result;
  }
}
