import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './border-btn.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    link: {
      type: String,
      required: true as const
    }
  },
  render() {
    return (
      <a class={styles.btn} href={this.link}>
        {this.$slots.default}
      </a>
    );
  }
});
