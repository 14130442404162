export const CURRENCY_ROUTING = [
  {
    path: '/currency.html',
    component: () =>
      import(
        /* webpackChunkName: "currency-page" */ './pages/currency/currency.page'
      ),
    alias: []
  }
];
