import type { NewBasePageDTO, BaseRubricDTO } from '@fontanka/news-api-client';

import type { Rubric } from '../../../domain';

export class RubricsMapper {
  public static toDO(pageDTO: NewBasePageDTO): Rubric[] {
    const windowRubricsConfig = pageDTO.config.blocks.window_rubrics;
    if (!windowRubricsConfig) {
      return [];
    }

    const { path } = windowRubricsConfig;

    return (pageDTO.data[path]?.data as BaseRubricDTO[]) ?? [];
  }
}
