import type { BaseRecord } from '../../../domain';

import type { FilterLink } from './filter-link';

const ALL_URL = '/24hours.html';
const NEWS_URL = '/24hours_news.html';
const ARTICLES_URL = '/24hours_articles.html';
const ARTICLES_URL_WITH_RUBRIC = '/24hours_article.html';

export type DailyNewsBlocks = {
  type: 'daily_news';
  total: number;
  recordsCountPerPage: number;
  data: {
    date: string;
    records: BaseRecord[];
  }[];
}[];

export const getFilterLinks = (rubricId?: 'all' | string): FilterLink[] => [
  {
    url: getPageUrl('all', rubricId),
    text: 'Все',
    type: 'all'
  },
  {
    url: getPageUrl('news', rubricId),
    text: 'Новости',
    type: 'news'
  },
  {
    url: getPageUrl('articles', rubricId),
    text: 'Статьи',
    type: 'articles'
  }
];

export const getPageUrl = (type: FilterLink['type'], rubricId?: 'all' | string) => {
  if (!rubricId || rubricId === 'all') {
    if (type === 'all') {
      return ALL_URL;
    }

    if (type === 'news') {
      return NEWS_URL;
    }

    return ARTICLES_URL;
  }

  if (type === 'all') {
    return `/${rubricId}/last${ALL_URL}`;
  }

  if (type === 'news') {
    return `/${rubricId}/last${NEWS_URL}`;
  }

  return `/${rubricId}/last${ARTICLES_URL_WITH_RUBRIC}`;
};
