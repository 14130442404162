







import { Component, Prop, Vue } from 'vue-property-decorator';

import FntAndroidIcon from '~icons/fnt-common/app-android';

import type { StoreIconName } from './ui-mobile-app-icon.contract';

import FntAppgalleryIcon from '~icons/fnt-common/app-appgallery';
import FntIosIcon from '~icons/fnt-common/app-ios';
import FntRuStoreIcon from '~icons/fnt-common/app-rustore';

@Component({
  name: 'UiMobileAppIcon',
  components: {
    FntAndroidIcon,
    FntIosIcon,
    FntRuStoreIcon,
    FntAppgalleryIcon
  }
})
export default class UiMobileAppIcon extends Vue {
  @Prop({
    required: true,
    type: String as () => StoreIconName
  })
  name: StoreIconName;
}
