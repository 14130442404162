import { PhotosVideosHttpClient } from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { FormatRecordsMapper } from '../../../services';

export class PhotosVideosRecordsDataMapper {
  private readonly _photosVideosHttpClient: PhotosVideosHttpClient;

  constructor(envType: string) {
    this._photosVideosHttpClient = new PhotosVideosHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getPhotosRecords(page: number, pagesize: number) {
    return this._getRecords(page, pagesize, 'photo');
  }

  public async getVideosRecords(page: number, pagesize: number) {
    return this._getRecords(page, pagesize, 'video');
  }

  private async _getRecords(
    page: number,
    pagesize: number,
    type: 'photo' | 'video'
  ): Promise<BaseRecord[]> {
    let formatRecords: BaseRecord[] = [];

    try {
      let result;

      if (type === 'photo') {
        result = await this._photosVideosHttpClient.searchPhotosRecords({
          page,
          pagesize
        });
      } else {
        result = await this._photosVideosHttpClient.searchVideosRecords({
          page,
          pagesize
        });
      }

      formatRecords = FormatRecordsMapper.toDO(result.data ?? []);
    } catch (err) {
      console.error(err);
    }

    return formatRecords;
  }
}
