

























import { guardEmptyString, guardZeroNumber } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'UiTextarea',
})
export default class UiTextarea extends Vue {
  @Prop({
    type: String,
    default: '',
  })
  id: string;

  @Prop({
    type: String,
    default: '',
  })
  value: string;

  @Prop({
    type: String,
    default: '',
  })
  label: string;

  @Prop({
    type: Number,
    default: null,
  })
  maxlength: number;

  @Prop({
    type: Boolean,
    default: false,
  })
  hasError: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  resize: boolean;

  get hasLabel() {
    return guardEmptyString(this.label);
  }

  get hasCounter() {
    return guardZeroNumber(this.maxlength);
  }

  get textareaClasses() {
    return [
      this.$style.textarea,
      this.hasError ? this.$style.error : '',
      this.resize ? '' : this.$style.noResize,
    ];
  }
}
