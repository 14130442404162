import type { MetaCommentsDTO } from '@fontanka/news-api-client';
import { CommentsHttpClient } from '@fontanka/news-api-client';

import type {
  RecordComment,
  RecordCommentAdv,
  AnswersData,
  MetaComments
} from '../../../domain';
import { RecordCommentMapper } from '../../../services';

type GetCommentsParams = {
  day: string;
  id: string;
  month: string;
  year: string;
  sort: 'asc' | 'desc';
  dateFrom: number;
};

type VoteCommentParams = {
  commentId: string;
  vote: 1 | -1;
};

type SendCommentParams = {
  recordId: number;
  nick: string;
  profileId: number;
  text: string;
  parentId?: number;
  captchaToken: string;
};

type CommentsData = {
  comments: (RecordComment | RecordCommentAdv)[];
  meta: MetaComments;
};

type GetAnswersParams = {
  parentId: number;
  dateFrom?: number;
};

export class CommentsDataMapper {
  private readonly _commentsHttpClient: CommentsHttpClient;

  constructor(envType: string) {
    this._commentsHttpClient = new CommentsHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getComments(params: GetCommentsParams) {
    const data: CommentsData = {
      comments: [],
      meta: {
        commentsCount: 0,
        hasAfter: false,
        searchDate: 0,
        total: 0,
        totalCaption: 0
      }
    };

    try {
      const result = await this._commentsHttpClient.fetchRecordComments(params);
      data.comments =
        result.data?.map(commentDTO =>
          RecordCommentMapper.getParentComment(commentDTO)
        ) ?? [];
      data.meta = RecordCommentMapper.getMetaCommentsData(
        result.meta as MetaCommentsDTO
      );
    } catch (err) {
      console.error(err);
    }

    return data;
  }

  public async getCommentAnswers(params: GetAnswersParams) {
    const answersData: AnswersData = {
      data: [],
      searchDate: 0,
      hasAfter: false
    };

    try {
      const result = await this._commentsHttpClient.fetchCommentAnswers(params);
      answersData.data = result.data
        ? result.data.map(child =>
            RecordCommentMapper.getAnswer({
              commentDTO: child,
              parents: result.data,
              parentId: params.parentId
            })
          )
        : [];

      answersData.searchDate = result.meta?.searchDate ?? 0;
      answersData.hasAfter = result.meta?.hasAfter ?? false;
    } catch (err) {
      console.error(err);
    }

    return answersData;
  }

  public async sendComment(params: SendCommentParams, parent?: RecordComment) {
    // eslint-disable-next-line no-useless-catch
    try {
      const comment = await this._commentsHttpClient.sendComment(params);
      return RecordCommentMapper.getSentCommentData(comment, parent);
    } catch (err) {
      throw err;
    }
  }

  public async voteComment(
    recordId: string,
    data: VoteCommentParams
  ): Promise<void> {
    await this._commentsHttpClient.voteComment(recordId, data);
  }
}
