import {
  BlockConfig,
  BlockInfo,
  BaseRubricDTO,
  SocialsTypeDTO,
  BaseMobileAppDTO
} from '../../BaseDTOs';

export enum HeaderBlockType {
  Currency = 'currency',
  Rubrics = 'rubrics',
  Weather = 'weather',
  WeatherWidget = 'weather_widget',
  Traffic = 'traffic',
  Socials = 'socials',
  MobileApps = 'mobile_apps',
  AdvLogo = 'adv_logo'
}

export type HeaderConfigDTO = {
  currency?: BlockConfig<HeaderBlockType.Currency>;
  rubrics?: BlockConfig<HeaderBlockType.Rubrics>;
  weather?: BlockConfig<HeaderBlockType.Weather>;
  weather_widget?: BlockConfig<HeaderBlockType.WeatherWidget>;
  traffic?: BlockConfig<HeaderBlockType.Traffic>;
  socials?: BlockConfig<HeaderBlockType.Socials>;
  mobile_apps?: BlockConfig<HeaderBlockType.MobileApps>;
  adv_logo?: BlockConfig<HeaderBlockType.AdvLogo>;
};

export type HeaderCurrencyItemDTO = {
  delta: number;
  name: 'usd' | 'eur';
  nominal: number;
  title: string;
  value: number;
};

export type HeaderCurrency = {
  link: string;
  currency: HeaderCurrencyItemDTO[];
};

export type HeaderCurrencyDTO = BlockInfo<
  HeaderCurrencyItemDTO[],
  HeaderBlockType.Currency
>;

export type HeaderRubricsDTO = BlockInfo<BaseRubricDTO[], HeaderBlockType.Rubrics>;

export type HeaderWeatherItemDTO = {
  now: string;
  tomorrow: string;
};

export type HeaderWeatherDTO = BlockInfo<
  HeaderWeatherItemDTO,
  HeaderBlockType.Weather
>;

export type HeaderWeatherWidgetIconDTO =
  | 'cloudy_none_day'
  | 'cloudy_none_night'
  | 'cloudy_light_rain_day'
  | 'cloudy_light_rain_night'
  | 'cloudy_rain_day'
  | 'cloudy_rain_night'
  | 'cloudy_light_snow_day'
  | 'cloudy_light_snow_night'
  | 'cloudy_snow_day'
  | 'cloudy_snow_night'
  | 'cloudy_thunderstorm_day'
  | 'cloudy_thunderstorm_night'
  | 'mostly_cloudy_none_day'
  | 'mostly_cloudy_none_night'
  | 'mostly_cloudy_light_rain_day'
  | 'mostly_cloudy_light_rain_night'
  | 'mostly_cloudy_rain_day'
  | 'mostly_cloudy_rain_night'
  | 'mostly_cloudy_light_snow_day'
  | 'mostly_cloudy_light_snow_night'
  | 'mostly_cloudy_snow_day'
  | 'mostly_cloudy_snow_night'
  | 'mostly_cloudy_thunderstorm_day'
  | 'mostly_cloudy_thunderstorm_night'
  | 'sunshine_none_day'
  | 'partly_cloudy_none_day'
  | 'partly_cloudy_light_rain_day'
  | 'sunshine_rain_day'
  | 'sunshine_light_rain_day'
  | 'partly_cloudy_rain_day'
  | 'partly_cloudy_light_snow_day'
  | 'partly_cloudy_snow_day'
  | 'sunshine_light_snow_day'
  | 'sunshine_snow_day'
  | 'partly_cloudy_thunderstorm_day'
  | 'sunshine_none_night'
  | 'partly_cloudy_none_night'
  | 'partly_cloudy_light_rain_night'
  | 'partly_cloudy_rain_night'
  | 'sunshine_light_rain_night'
  | 'sunshine_rain_night'
  | 'partly_cloudy_light_snow_night'
  | 'partly_cloudy_snow_night'
  | 'sunshine_light_snow_night'
  | 'sunshine_snow_night'
  | 'partly_cloudy_thunderstorm_night';

export type HeaderWeatherWidgetItemDTO = {
  city: string;
  city_prepositional: string;
  cloud: {
    title: string;
  };
  humidity: number;
  icon: HeaderWeatherWidgetIconDTO;
  precipitation: {
    title: string;
  };
  pressure: number;
  temperature: number;
  temperature_feel_like: number;
  temperature_trend: number;
  wind: {
    direction: {
      title: string;
      title_letter: string;
      title_short: string;
    };
    speed: number;
  };
};

export type HeaderWeatherWidgetDTO = BlockInfo<
  HeaderWeatherWidgetItemDTO,
  HeaderBlockType.WeatherWidget
>;

export type HeaderTrafficItemDTO = {
  hint: string;
  icon: 'green' | 'yellow' | 'red';
  level: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
  link: string;
  title: 'Пробки';
};

export type HeaderTrafficDTO = BlockInfo<
  HeaderTrafficItemDTO,
  HeaderBlockType.Traffic
>;

export type HeaderSocialItemDTO = {
  id: SocialsTypeDTO;
  link: string;
  text: string;
};

export type HeaderSocialDTO = BlockInfo<
  HeaderSocialItemDTO[],
  HeaderBlockType.Socials
>;

export type HeaderMobileAppsDTO = BlockInfo<
  BaseMobileAppDTO[],
  HeaderBlockType.MobileApps
>;

export type HeaderAdvLogoItemDTO = {
  adInfo: string;
  adLabelEnabled: boolean;
  clickUrl: string;
  logoDesktop: string;
  logoMobile: string;
  signature: 'ADV' | 'INFO';
} | null;

export type HeaderAdvLogoDTO = BlockInfo<
  HeaderAdvLogoItemDTO,
  HeaderBlockType.AdvLogo
>;

export type HeaderBlocks =
  | HeaderCurrencyDTO
  | HeaderRubricsDTO
  | HeaderWeatherDTO
  | HeaderWeatherWidgetDTO
  | HeaderSocialDTO
  | HeaderMobileAppsDTO
  | HeaderAdvLogoDTO;
