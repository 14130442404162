import { ProfileHttpClient } from '@fontanka/news-api-client';

import type { UserComment } from '../domain';

import { UserCommentsMapper } from './user-comments-mapper.service';

type Params = {
  profileId: number;
  page: number;
  pagesize: number;
};

export class UserCommentsDataMapper {
  private readonly _profileHttpClient: ProfileHttpClient;

  constructor(envType: string) {
    this._profileHttpClient = new ProfileHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getUserComments(params: Params): Promise<UserComment[]> {
    let userComments: UserComment[] = [];

    try {
      const result = await this._profileHttpClient.fetchUserComments(params);

      userComments = UserCommentsMapper.toDO(result.data ?? []);
    } catch (err) {
      console.error(err);
    }

    return userComments;
  }
}
