import type {
  AnswerCommentDTO,
  CommentAdvDTO,
  CommentItemDTO,
  MetaCommentsDTO,
  SentCommentDTO
} from '@fontanka/news-api-client';

import type {
  RecordComment,
  RecordCommentAdv,
  MetaComments,
  AnswersCommentItem
} from '../domain';

import { CommentUserMapper } from './comment-user-mapper.service';

type DirectParentComment = {
  id: number;
  text: string;
  date: string;
  user: {
    nick: string;
    link: string;
    trusted: boolean;
  };
};

type AnswerDataParams = {
  commentDTO: AnswerCommentDTO;
  parents: AnswerCommentDTO[];
  parentId: number;
};

export class RecordCommentMapper {
  public static getParentComment(
    commentDTO: CommentItemDTO | CommentAdvDTO
  ): RecordComment | RecordCommentAdv {
    if (commentDTO.type === 'adv') {
      return commentDTO;
    }

    const userDTO = commentDTO.user;

    return {
      id: commentDTO.id,
      date: commentDTO.date,
      links: commentDTO.links,
      parentId: commentDTO.parentId,
      directParentId: commentDTO.directParentId,
      directParent: null,
      text: commentDTO.decoratedText,
      decoratedText: commentDTO.decoratedText,
      isRoot: commentDTO.parentId === 0,
      user: CommentUserMapper.toDO(userDTO),
      children: {
        data: [] as AnswersCommentItem[],
        searchDate: 0,
        hasAfter: false
      },
      childrenCount: commentDTO.childrenCount,
      type: commentDTO.type,
      searchDate: commentDTO.searchDate,
      userVote: commentDTO.userVote,
      votesDislike: commentDTO.votesMinus,
      votesLike: commentDTO.votesPlus,
      isShowOnDesktop: true
    };
  }

  public static getMetaCommentsData(metaDTO: MetaCommentsDTO | null): MetaComments {
    return {
      commentsCount: metaDTO?.commentsCount || 0,
      hasAfter: metaDTO?.hasAfter || false,
      searchDate: metaDTO?.searchDate || 0,
      total: metaDTO?.total || 0,
      totalCaption: metaDTO?.totalCaption || 0
    };
  }

  public static getAnswer({
    commentDTO,
    parents,
    parentId
  }: AnswerDataParams): AnswersCommentItem {
    const userDTO = commentDTO.user;

    const directComment =
      commentDTO.directParentId === parentId
        ? null
        : parents.find(child => child.id === commentDTO.directParentId) ?? null;

    return {
      id: commentDTO.id,
      date: commentDTO.date,
      links: commentDTO.links,
      parentId: commentDTO.parentId,
      text: commentDTO.decoratedText,
      decoratedText: commentDTO.decoratedText,
      isRoot: commentDTO.parentId === 0,
      user: CommentUserMapper.toDO(userDTO),
      children: {
        data: [] as AnswersCommentItem[],
        searchDate: 0,
        hasAfter: false
      },
      childrenCount: commentDTO.childrenCount,
      searchDate: commentDTO.searchDate,
      directParentId: commentDTO.directParentId,
      directParent: directComment ? this._mapDirectParentData(directComment) : null,
      userVote: commentDTO.userVote,
      votesDislike: commentDTO.votesMinus,
      votesLike: commentDTO.votesPlus,
      isShowOnDesktop: true
    };
  }

  public static getSentCommentData(
    commentDTO: SentCommentDTO,
    parent?: RecordComment
  ): RecordComment {
    const directComment =
      commentDTO.directParentId === parent?.id
        ? null
        : parent?.children.data.find(
            (child): child is AnswersCommentItem =>
              child.id === commentDTO.directParentId
          ) ?? null;

    const userDTO = commentDTO.user;

    return {
      id: commentDTO.id,
      date: commentDTO.date,
      links: commentDTO.links,
      parentId: commentDTO.parentId,
      text: commentDTO.decoratedText,
      decoratedText: commentDTO.decoratedText,
      isRoot: commentDTO.parentId === 0,
      user: CommentUserMapper.toDO(userDTO),
      children: {
        data: [] as AnswersCommentItem[],
        searchDate: 0,
        hasAfter: false
      },
      childrenCount: commentDTO.childrenCount,
      searchDate: commentDTO.searchDate,
      directParentId: commentDTO.directParentId,
      directParent: directComment ? this._mapDirectParentData(directComment) : null,
      type: 'item',
      votesDislike: commentDTO.votesMinus,
      votesLike: commentDTO.votesPlus,
      userVote: commentDTO.userVote,
      isShowOnDesktop: true
    };
  }

  private static _mapDirectParentData(
    directComment: AnswerCommentDTO | AnswersCommentItem
  ): DirectParentComment {
    return {
      id: directComment.user.id,
      text: directComment.text.replace(/<\/?[^>]+>/g, ''),
      date: directComment.date,
      user: {
        nick: directComment.user.nick,
        link: directComment.user.link,
        trusted: directComment.user.trusted
      }
    };
  }
}
