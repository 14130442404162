import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { WeatherPage } from '../domain';
import { WeatherPageMapper } from '../services/weather-page-mapper.service';

export const WEATHER_PAGE_MODULE_NAMESPACE = ['weather_page_module'];

export class WeatherPageModule {
  @State()
  public blocks: WeatherPage['blocks'];

  constructor(private _root: RootModule) {
    const { blocks } = WeatherPageMapper.toDO(this._root.pageSpec);
    this.blocks = blocks;
  }
}
