import type {
  DayArchivePageLastNewsDTO,
  DayArchivePageDTO
} from '@fontanka/news-api-client';
import { SearchHttpClient } from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { BaseRecordMapper } from '../../../services';
import type { DayArchiveBlocks } from '../domain/day-archive';

type PageParams = {
  year: string;
  month: string;
  day: string;
  rubricId?: 'all' | string;
  page: number;
  pagesize: number;
};

export class DayArchiveMapper {
  private readonly _searchHttpClient: SearchHttpClient;

  constructor(envType: string) {
    this._searchHttpClient = new SearchHttpClient({ envType, protocol: 'https' });
  }

  public static toDO(pageDTO: DayArchivePageDTO): DayArchiveBlocks {
    const blocks: DayArchiveBlocks = [];

    return pageDTO.config.content.reduce((acc, current) => {
      const { data } = pageDTO.data[current.path];
      const { meta } = pageDTO.data[current.path];

      if (current.type === 'last_news') {
        acc.push({
          type: current.type,
          total: meta?.total || 0,
          recordsCountPerPage:
            (data as DayArchivePageLastNewsDTO['data'])?.reduce(
              (count, archiveItem) => (count += archiveItem.records?.length || 0),
              0
            ) ?? 0,
          data:
            (data as DayArchivePageLastNewsDTO['data'])?.map(item => ({
              date: item.date,
              records:
                item.records?.map(record => BaseRecordMapper.toDO(record)) ?? []
            })) ?? []
        });
      }

      return acc;
    }, blocks);
  }

  public async getMoreNews(params: PageParams) {
    let newRecords: BaseRecord[] = [];
    const { day, month, year, rubricId, page, pagesize } = params;

    let date;
    if (year && month && day) {
      date = `${day}.${month}.${year}`;
    }

    try {
      const records = await this._searchHttpClient.searchRecords({
        page,
        pagesize,
        date,
        rubricId
      });

      newRecords = records.data?.map(item => BaseRecordMapper.toDO(item)) ?? [];
    } catch (err) {
      console.error(err);
    }
    return newRecords;
  }
}
