import { parse, format } from 'date-fns';

import { PROFILE_INFO } from '@fontanka/data';
import { createSimpleImage } from '@fontanka/news';
import type { ProfilePageProfileDTO } from '@fontanka/news-api-client';

import type { ProfileInfo } from '../domain';

export class ProfileInfoMapper {
  public static toDO(profileDTO: ProfilePageProfileDTO['data']): ProfileInfo {
    return {
      ...profileDTO,
      avatar: createSimpleImage(profileDTO.avatar),
      birthday: ProfileInfoMapper.birthdayToDO(profileDTO.birthday),
      sex:
        profileDTO.sex === 'female' || profileDTO.sex === 'male'
          ? profileDTO.sex
          : 'unknown'
    };
  }

  public static fromDO(profile: ProfileInfo): ProfileInfo {
    return {
      ...profile,
      avatar:
        profile.newAvatarFile !== undefined ? createSimpleImage('') : profile.avatar,
      birthday: ProfileInfoMapper.birthdayFromDO(profile.birthday)
    };
  }

  public static birthdayToDO(birthday: string) {
    return birthday
      ? format(
          parse(birthday, PROFILE_INFO.SOURCE_BIRTHDAY_DATE_FORMAT, new Date()),
          PROFILE_INFO.TARGET_BIRTHDAY_DATE_FORMAT
        )
      : birthday;
  }

  public static birthdayFromDO(birthday: string) {
    return birthday
      ? format(
          parse(birthday, PROFILE_INFO.TARGET_BIRTHDAY_DATE_FORMAT, new Date()),
          PROFILE_INFO.SOURCE_BIRTHDAY_DATE_FORMAT
        )
      : birthday;
  }
}
