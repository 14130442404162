import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';

import styles from './pagination.styles.scss';

import FntSmallArrowIcon from '~icons/fnt-common/small-arrow';

type Events = {
  onPageChanged: { pageBefore: number; pageAfter: number };
};

type Page = {
  n: number;
  text: string | number;
  active: boolean;
};

const ARROW_SIZE = 16;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'Pagination',
    props: {
      url: {
        type: String,
        required: true
      },
      page: {
        type: Number,
        required: true
      },
      pagesize: {
        type: Number,
        required: true
      },
      totalItems: {
        type: Number,
        required: true
      },
      maxSize: {
        type: Number,
        required: false,
        default: 3
      },
      pageFieldName: {
        type: String,
        required: true
      }
    },
    computed: {
      noPrevious(): boolean {
        return this.page === 1;
      },
      noNext(): boolean {
        return this.page === this.totalPages;
      },
      totalPages(): number {
        const totalPages =
          this.pagesize < 1 ? 1 : Math.ceil(this.totalItems / this.pagesize);
        return Math.max(totalPages || 0, 1);
      },
      pages(): Page[] {
        const pages: Array<Page> = [];

        if (this.page <= 0 || this.page > this.totalPages) {
          return pages;
        }

        // Default page limits
        let startPage = 1;
        let endPage = this.totalPages;
        const isMaxSized = this.maxSize && this.maxSize < this.totalPages;

        // recompute if maxSize
        if (isMaxSized) {
          // Current page is displayed in the middle of the visible ones
          startPage = Math.max(this.page - Math.floor(this.maxSize / 2), 1);
          endPage = startPage + this.maxSize - 1;

          // Adjust if limit is exceeded
          if (endPage > this.totalPages) {
            endPage = this.totalPages;
            startPage = endPage - this.maxSize + 1;
          }
        }

        // Add page number links
        for (let num = startPage; num <= endPage; num++) {
          const page = this.makePage(num, num, num === this.page);
          pages.push(page);
        }

        if (isMaxSized && this.maxSize > 0) {
          if (startPage > 1) {
            if (startPage > this.maxSize) {
              // need ellipsis for all options unless range is too close to beginning
              const previousPageSet = this.makePage(startPage - 1, '...', false);
              pages.unshift(previousPageSet);
            }

            if (startPage === this.maxSize) {
              // need to replace ellipsis when the buttons would be sequential
              const secondPageLink = this.makePage(2, '2', false);
              pages.unshift(secondPageLink);
            }
            // add the first page
            const firstPageLink = this.makePage(1, '1', false);
            pages.unshift(firstPageLink);
          }

          if (endPage < this.totalPages) {
            if (endPage < this.totalPages - 2) {
              // need ellipsis for all options unless range is too close to end
              const nextPageSet = this.makePage(endPage + 1, '...', false);
              pages.push(nextPageSet);
            }

            if (endPage === this.totalPages - 2) {
              // need to replace ellipsis when the buttons would be sequential
              const secondToLastPageLink = this.makePage(
                this.totalPages - 1,
                this.totalPages - 1,
                false
              );
              pages.push(secondToLastPageLink);
            }
            // add the last page
            const lastPageLink = this.makePage(
              this.totalPages,
              this.totalPages,
              false
            );
            pages.push(lastPageLink);
          }
        }
        return pages;
      }
    },
    methods: {
      makePage(n: number, text: string | number, isActive: boolean): Page {
        return { n, text, active: isActive };
      },
      makePageUrl(page: number) {
        return `${this.url}&${this.pageFieldName}=${page}`;
      },
      emitPrevPageClicked() {
        if (this.noPrevious) return;

        this.emitPageClicked(this.page - 1);
      },
      emitNextPageClicked() {
        if (this.noNext) return;

        this.emitPageClicked(this.page + 1);
      },
      emitPageClicked(page: number) {
        this.$emit('pageChanged', { pageBefore: this.page, pageAfter: page });
      }
    },
    render() {
      return (
        <div class={styles.pagination}>
          <div class={styles.prevNextWrapper}>
            <a
              class={[
                styles.arrow,
                styles.leftArrow,
                this.noPrevious ? styles.disabled : ''
              ]}
              href={this.makePageUrl(this.page - 1)}
              onClick={() => this.emitPrevPageClicked()}
              title="Предыдущая"
            >
              <FntSmallArrowIcon width={ARROW_SIZE} height={ARROW_SIZE} />
            </a>
          </div>

          {this.pages.map(page => (
            <a
              class={[
                styles.item,
                page.active ? styles.active : '',
                typography.primary.button.mobile
              ]}
              href={this.makePageUrl(page.n)}
              onClick={() => this.emitPageClicked(page.n)}
            >
              {page.text}
            </a>
          ))}

          <div class={[styles.prevNextWrapper, styles.next]}>
            <a
              class={[styles.arrow, this.noNext ? styles.disabled : '']}
              href={this.makePageUrl(this.page + 1)}
              onClick={() => this.emitNextPageClicked()}
              title="Следующая"
            >
              <FntSmallArrowIcon width={ARROW_SIZE} height={ARROW_SIZE} />
            </a>
          </div>
        </div>
      );
    }
  });
