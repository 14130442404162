import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './widget-btn.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    type: {
      type: String as () => 'orange' | 'default' | 'transparent' | 'lightOrange',
      required: false,
      default: 'default'
    }
  },
  computed: {
    className(): string[] {
      return [
        styles.box,
        this.type === 'orange' ? styles.orange : '',
        this.type === 'transparent' ? styles.transparent : '',
        this.type === 'lightOrange' ? styles.lightOrange : ''
      ];
    }
  },
  render() {
    return (
      <div>
        <div class={this.className}>{this.$slots.default}</div>
      </div>
    );
  }
});
