import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import { RubricCommentsItem } from '../rubric-comments-item';

import styles from './tile-record-stats-item.styles.scss';

type Events = {
  onRecordCommentsClicked: void;
};

export type Stats = {
  hasVideo: boolean;
  hasPhoto: boolean;
  hasAudio: boolean;
  publishAt: string;
  isCommentsAllowed: boolean;
  commentsCount: number;
  canShowComments: boolean;
  urls: {
    urlComments: string;
  };
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      stats: {
        type: Object as () => Stats,
        required: true as const
      }
    },
    methods: {
      emitRecordCommentsClicked() {
        this.$emit('recordCommentsClicked');
      }
    },
    render() {
      return (
        <div class={styles.stats}>
          <div class={styles.statsBlock}>
            {this.stats.hasVideo ? (
              <i class={[styles.item, styles.videoIcon]} />
            ) : null}
            {this.stats.hasAudio ? (
              <i class={[styles.item, styles.musicIcon]} />
            ) : null}
            {this.stats.hasPhoto ? (
              <i class={[styles.item, styles.photoIcon]} />
            ) : null}

            {this.stats.canShowComments ? (
              <RubricCommentsItem
                class={[styles.item, styles.comments]}
                record={this.stats}
                color="white"
                onCommentsClicked={this.emitRecordCommentsClicked}
              />
            ) : null}
          </div>
          <div class={[styles.dateWrap, styles.statsBlock]}>
            <span class={styles.dateIcon} />
            <span class={styles.date}>{this.stats.publishAt}</span>
          </div>
        </div>
      );
    }
  });
