











import { Component, Prop, Vue } from 'vue-property-decorator';

import FntSocialViberIcon from '~icons/fnt-social/social-viber';
import FntSocialYoutubeIcon from '~icons/fnt-social/social-youtube';
import FntSocialZenIcon from '~icons/fnt-social/social-zen';
import FntSocialVkIcon from '~icons/fnt-social/social-vk';
import FntSocialTwitterIcon from '~icons/fnt-social/social-twitter';
import FntSocialOdnoklasnikiIcon from '~icons/fnt-social/social-odnoklassniki';
import FntSocialTamtamIcon from '~icons/fnt-social/social-tamtam';
import FntSocialTelegramIcon from '~icons/fnt-social/social-telegram';

import type { SocialsTypeDTO } from '@fontanka/news-api-client';

@Component({
  name: 'UiSocialIcon',
  components: {
    FntSocialViberIcon,
    FntSocialYoutubeIcon,
    FntSocialZenIcon,
    FntSocialVkIcon,
    FntSocialTwitterIcon,
    FntSocialOdnoklasnikiIcon,
    FntSocialTamtamIcon,
    FntSocialTelegramIcon
  }
})
export default class UiSocialIcon extends Vue {
  @Prop({
    required: true,
    type: String as () => SocialsTypeDTO
  })
  name: SocialsTypeDTO;
}
