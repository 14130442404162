import { lock, unlock } from '@portal/utils/util-body-scroll-lock';
import { guardUnspecified } from '@portal/utils/util-guards';
import { setCookie, getCookie, removeCookie } from 'tiny-cookie';
import { componentFactory } from 'vue-tsx-support';
import { unregisterModule, registerModule, useModule } from 'vuex-simple';

import type { YaParamsEvent } from '@apps/frontend';
import {
  UserAdBlockReachGoals,
  UserModule,
  USER_MODULE_NAMESPACE,
  ADV_MODULE_NAMESPACE,
  AdvModule,
  injectStylesMixin,
  storeMixin
} from '@apps/frontend';
import { COOKIES_ADBLOCK, AB_YANDEX_ADBLOCK_COOKIE } from '@fontanka/data';

import { adblockCookieMatched } from '../../../../../libs/adv-sdk/src/adblock-cookie-matched';

import styles from './app.styles.scss';

export default componentFactory
  .mixin(storeMixin)
  .mixin(injectStylesMixin(styles))
  .create({
    serverPrefetch() {
      return new Promise(resolve => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.registerModules();
        resolve();
      });
    },
    name: 'App',
    computed: {
      userModule(): UserModule | undefined {
        return useModule<UserModule>(this.$store, USER_MODULE_NAMESPACE);
      },
      isModalOpened() {
        return this.store.isModalOpened;
      },
      isScroogeTest() {
        return this.store.isScroogeTest;
      }
    },
    watch: {
      isModalOpened(isOpened: boolean, oldIsOpened: boolean) {
        if (isOpened !== oldIsOpened) {
          if (isOpened) {
            lock();
          } else {
            unlock();
          }
        }
      }
    },
    beforeMount() {
      this.registerModules();

      const hasTtq = document.cookie.includes('ngs_ttq');
      void this.userModule?.checkAuth(hasTtq);

      this.store.initLoyaltyServices();

      this.detectAdblock();

      this.$root.$on('sendYaParams', (params: YaParamsEvent) => {
        this.store.sendYMWithProductParams({
          ...params
        });
      });

      this.$root.$on('sendYaAdvParams', (params: YaParamsEvent) => {
        this.store.sendYMWithAdvParams({
          ...params
        });
      });
    },
    mounted() {
      this.store.sendPageAnalyticsData();
      this.sendReachGoalForAdblock();
    },
    destroyed() {
      unregisterModule(this.$store, USER_MODULE_NAMESPACE);
      unregisterModule(this.$store, ADV_MODULE_NAMESPACE);
    },
    methods: {
      registerModules() {
        registerModule(
          this.$store,
          USER_MODULE_NAMESPACE,
          new UserModule(this.store)
        );

        registerModule(this.$store, ADV_MODULE_NAMESPACE, new AdvModule(this.store));
      },
      handleClick(event: MouseEvent) {
        this.store.sendClickEventMetrics(event);

        return true;
      },
      sendReachGoalForAdblock() {
        this.store.sendYMReachGoal(
          this.store.isAdblock
            ? UserAdBlockReachGoals.Enabled
            : UserAdBlockReachGoals.Disabled
        );
      },
      detectAdblock() {
        if (guardUnspecified(getCookie(COOKIES_ADBLOCK))) {
          adblockCookieMatched();
        } else {
          removeCookie(AB_YANDEX_ADBLOCK_COOKIE);
        }

        if (this.isScroogeTest) {
          if (
            !guardUnspecified(window.HSMCreativeManager) ||
            window.HSMCreativeManager.adblock.isEnabled
          ) {
            this.setAdblockCookie();
            return;
          }

          removeCookie(COOKIES_ADBLOCK);
          return;
        }

        //Подстраховка если AdBlock заблочит саму ASDK
        if (!guardUnspecified(window.ASDK)) {
          this.setAdblockCookie();
        }
      },
      setAdblockCookie() {
        const timeLive = 1209600000;
        const date = new Date(Date.now() + timeLive);
        setCookie(COOKIES_ADBLOCK, 'true', { expires: date.toUTCString() });
      }
    },
    render() {
      return (
        <div
          id="app"
          class={[styles.app, this.isModalOpened ? styles.modalOpened : '']}
          onClick={this.handleClick}
        >
          <router-view />
        </div>
      );
    }
  });
