import { guardUnspecified } from '@smh/utils/guards';
import { truncate } from '@smh/utils/strings';

import { createImage } from '@fontanka/news';
import type {
  CommonFontankaProItemDTO,
  FontankaProDTO
} from '@fontanka/news-api-client';

import type { PageCommonFontankaPro, PageCommonFontankaProRecord } from '../domain';

const IMAGE_WIDTH = 460;

export class FontankaProMapper {
  static SLICE_COUNT = 6;
  public static toDo(
    blockData: FontankaProDTO['data']
  ): PageCommonFontankaPro | null {
    if (guardUnspecified(blockData)) {
      return {
        title: 'Фонтанка PRO',
        url: '/format/fproject/',
        records: blockData
          .map(this._presentRecord)
          .slice(0, FontankaProMapper.SLICE_COUNT)
      };
    }
    return null;
  }

  private static _presentRecord(
    item: CommonFontankaProItemDTO,
    index: number
  ): PageCommonFontankaProRecord {
    const isBusiness = item.viewType === 'business_tribune';
    return {
      isMain: index === 0 || index === 1,
      label: {
        link: isBusiness ? '/bt/' : '/format/fproject/',
        title: isBusiness ? 'Бизнес-трибуна' : 'Фонтанка PRO'
      },
      id: item.id,
      title: truncate({ length: 90, postfix: '...', subject: item.header }),
      image: guardUnspecified(item.mainPhoto)
        ? createImage(item.mainPhoto, { width: IMAGE_WIDTH })
        : null,
      url: item.urls.url,
      statistic: {
        date: {
          publishedAgo: item.publishedAgo ?? '5 минут назад'
        }
      },
      hasMainPhotoCommercialLabel: item.hasMainPhotoCommercialLabel ?? false
    };
  }
}
