import { ForeignAgentAttachDTO } from '@fontanka/news-api-client';

import { ForeignAgentAttach } from '../../../domain';

export const createForeignAgentAttach = (
  attach: ForeignAgentAttachDTO
): ForeignAgentAttach => ({
  type: 'foreignAgent',
  value: {
    content: attach.value.content
  }
});
