import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { CurrencyPageBlocks as CurrencyPageBlocksAlias } from '../domain';
import { CurrencyPageMapper } from '../services/currency-page-mapper.service';

type CurrencyPageBlocks = CurrencyPageBlocksAlias;

export const CURRENCY_PAGE_MODULE_NAMESPACE = ['currency_page_module'];

export class CurrencyPageModule {
  @State()
  public blocks: CurrencyPageBlocks;

  constructor(private _root: RootModule) {
    this.blocks = CurrencyPageMapper.toDO(this._root.pageSpec);
  }
}
