import { guardUnspecified } from '@smh/utils/guards';
import { isAbsoluteUrl } from '@smh/utils/url';

import { CropType } from '@fontanka/cropping';
import { createImage, ResponsiveImageValues } from '@fontanka/news';
import type {
  ThemeDTO,
  ThemeBlockDTO,
  CommonConfigDTO
} from '@fontanka/news-api-client';

import type { PageCommonTheme, PageCommonThemesBlock } from '../domain';

const IMAGE_WIDTH = 110;
const IMAGE_VALUES: ResponsiveImageValues[] = [
  {
    breakpoint: 0,
    width: 110,
    noMedia: true
  }
];

export type ThemesSpecData = {
  themes: ThemeBlockDTO;
};

export class ThemesMapper {
  public static toDo(input: {
    blockConfig: Exclude<CommonConfigDTO['themes'], undefined>;
    blockSpecData: ThemesSpecData;
  }): PageCommonThemesBlock | null {
    const {
      blockConfig: { path },
      blockSpecData
    } = input;

    const blockInfo = blockSpecData[path] as ThemeBlockDTO;

    if (guardUnspecified(blockInfo)) {
      return {
        articles: blockInfo.data?.map(this._presentRecord) ?? []
      };
    }
    return null;
  }

  private static _presentRecord(item: ThemeDTO): PageCommonTheme {
    return {
      path: item.path,
      title: item.title,
      status: item.status,
      target: isAbsoluteUrl(item.path) ? '_blank' : '_self',
      image: guardUnspecified(item.image)
        ? createImage(item.image, {
            width: IMAGE_WIDTH,
            sources: IMAGE_VALUES,
            crop: true,
            cropType: CropType.Square
          })
        : null,
      advCompanyName:
        !guardUnspecified(item.advCompanyName) || item.advCompanyName === 'null'
          ? ''
          : item.advCompanyName,
      advToken:
        !guardUnspecified(item.advToken) || item.advToken === 'null'
          ? ''
          : item.advToken
    };
  }
}
