export type ScriptInfo = {
  content: string;
  src: string;
};

const removeLineBreaks = (str: string): string => str.replace(/\r?\n/g, '');

const extractScriptsStrsFromHtmlStr = (htmlStr: string): string[] => {
  const SCRIPT_REG_EX = /(<script\b[^>]*>.*?<\/script>)/gm;
  const scripts = htmlStr.match(SCRIPT_REG_EX);

  if (scripts === null) {
    return [];
  }

  return scripts;
};

const extractScriptSrc = (scriptStr: string): string => {
  const SRC_REG_EX = /src="(.*?)"/gm;
  const src = SRC_REG_EX.exec(scriptStr);

  if (src === null) {
    return '';
  }

  return src[1];
};

const extractScriptContent = (scriptSrc: string): string => {
  const CONTENT_REG_EX = /<script\b[^>]*>([\s\S]*?)<\/script>/gm;
  const content = CONTENT_REG_EX.exec(scriptSrc);

  if (content === null) {
    return '';
  }

  return content[1];
};

export const extractScriptsInfoFromHtmlStr = (htmlStr: string): ScriptInfo[] => {
  const htmlStrWithoutLineBreaks = removeLineBreaks(htmlStr);
  const scriptsStrs = extractScriptsStrsFromHtmlStr(htmlStrWithoutLineBreaks);

  return scriptsStrs.reduce(
    (acc, scriptStr) => [
      ...acc,
      {
        src: extractScriptSrc(scriptStr),
        content: extractScriptContent(scriptStr)
      }
    ],
    [] as ScriptInfo[]
  );
};

export const extractDivFromHtmlStr = (htmlStr: string): string => {
  const htmlStrWithoutLineBreaks = removeLineBreaks(htmlStr);

  const divRegEx = /<div\b[^>]*>.*?<\/div>/gm;
  const content = htmlStrWithoutLineBreaks.match(divRegEx);

  if (content === null) {
    return '';
  }
  return content[0];
};

export const extractStyleContentFromHtmlStr = (htmlStr: string): string => {
  const htmlStrWithoutLineBreaks = removeLineBreaks(htmlStr);

  const CONTENT_REG_EX = /<style\b[^>]*>([\s\S]*?)<\/style>/gm;
  const content = CONTENT_REG_EX.exec(htmlStrWithoutLineBreaks);

  if (content === null) {
    return '';
  }

  return content[1];
};

export const extractTagId = (str: string): string | null => {
  const idRegEx = /\sid=("|')([^"]*?)("|')/gi;
  const res = idRegEx.exec(str);
  const idValue = 2;

  return res === null ? null : res[idValue];
};

export const generateUniqueId = (block: string, index: string): string => {
  const oldContainerId = extractTagId(block);
  let blockWithNewId = '';
  if (!oldContainerId) {
    return index;
  }
  const regex = new RegExp(oldContainerId, 'g');
  const newContainerId = `${oldContainerId}_${index}`;
  blockWithNewId = block.replace(regex, newContainerId) || '';
  return blockWithNewId;
};

export const getElementWidth = (element: HTMLElement) => element.offsetWidth;

export const getElementHeight = (element: HTMLElement) => element.offsetHeight;

export const injectScriptToHead = (script: {
  src?: string;
  content?: string;
  required?: boolean;
}) => {
  const injectedScripts = new Set();
  return function () {
    {
      return new Promise(resolve => {
        const includeScript = document.createElement('script');

        if (script.src) {
          if (injectedScripts.has(script.src) && !script.required) {
            return resolve(true);
          }

          includeScript.src = script.src;
        }

        if (script.content) {
          includeScript.innerHTML = script.content;
        }

        includeScript.type = 'text/javascript';

        includeScript.addEventListener('load', () => {
          if (script.src) {
            injectedScripts.add(script.src);
          }

          return resolve(false);
        });

        document.getElementsByTagName('head')[0].appendChild(includeScript);
      });
    }
  };
};
