import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import styles from './simple-side-block.styles.scss';

type Record = {
  id: number;
  header: string;
  urls: {
    url: string;
  };
};

type Events = {
  onViewBlock: void;
  onClickToRecord: number;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      header: {
        type: String,
        required: true,
        default: ''
      },
      items: {
        type: Array as () => Record[],
        required: true,
        default: []
      }
    },
    data() {
      return {
        blockVisibility: new ObserverVisibility('half')
      };
    },
    mounted() {
      this.blockVisibility.event.subscribe(state => {
        if (state.value) {
          this.$emit('viewBlock');
        }
      });
    },
    beforeDestroy() {
      this.blockVisibility.destroy();
    },
    methods: {
      clickToRecord(recordId: number) {
        this.$emit('clickToRecord', recordId);
      }
    },
    render() {
      return (
        <div
          class={styles.sideBlock}
          v-observe-visibility={this.blockVisibility.getOptions('half')}
        >
          <h4 class={styles.header} domPropsInnerHTML={this.header} />

          <ul class={styles.list}>
            {this.items.map(item => (
              <li class={styles.item}>
                <a
                  class={styles.link}
                  href={item.urls.url}
                  domPropsInnerHTML={item.header}
                  onClick={() => {
                    this.clickToRecord(item.id);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      );
    }
  });
