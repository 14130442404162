import { State, Mutation, Action, Getter } from 'vuex-simple';

import { FORMS_INFO } from '@fontanka/data';
import { ProfileHttpClient } from '@fontanka/news-api-client';

import type { RootModule } from '../../../core';

type Result = {
  success: boolean;
  error: string;
};

const {
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  MIN_LENGTH_PASSWORD_ERROR,
  MAX_LENGTH_PASSWORD_ERROR,
  YA_RULES_PASSWORD_SYMBOLS_ERROR,
  PASSWORD_ALLOW_SYMBOLS_REG_EX,
  SYMBOLS_REG_EX,
  LETTERS_REG_EX,
  NUMBERS_REG_EX,
  PASSWORD_LIKE_LOGIN_ERROR
} = FORMS_INFO;

export const CHANGE_PASSWORD_PAGE_MODULE_NAMESPACE = ['change_password_page_module'];

export class ChangePasswordPageModule {
  @State()
  public oldPassword = '';

  @State()
  public newPassword = '';

  @State()
  public changingResult: Result = {
    success: false,
    error: ''
  };
  @State()
  private _email = '';

  @Getter()
  public get oldPasswordError(): string {
    const trimmedPassword = this.oldPassword.trim();

    if (trimmedPassword.length < PASSWORD_MIN_LENGTH) {
      return MIN_LENGTH_PASSWORD_ERROR;
    }

    if (trimmedPassword.length > PASSWORD_MAX_LENGTH) {
      return MAX_LENGTH_PASSWORD_ERROR;
    }

    return '';
  }

  @Getter()
  public get newPasswordError(): string {
    const password = this.newPassword;

    if (password.length < PASSWORD_MIN_LENGTH) {
      return MIN_LENGTH_PASSWORD_ERROR;
    }

    if (password.length > PASSWORD_MAX_LENGTH) {
      return MAX_LENGTH_PASSWORD_ERROR;
    }

    if (password === this._email || password === this._email.split('@')[0]) {
      return PASSWORD_LIKE_LOGIN_ERROR;
    }

    if (!this._isPasswordValid(password)) {
      return YA_RULES_PASSWORD_SYMBOLS_ERROR;
    }

    return '';
  }

  private _profileHttpClient: ProfileHttpClient;

  constructor(private _root: RootModule) {
    this._profileHttpClient = new ProfileHttpClient({
      envType: this._root.envType ?? '',
      protocol: 'https'
    });
  }

  @Action()
  public async change() {
    try {
      await this._profileHttpClient.changeProfilePassword({
        oldPassword: this.oldPassword.trim(),
        newPassword: this.newPassword.trim()
      });
      this._changeChangingResult({ error: '', success: true });
    } catch (err) {
      this._changeChangingResult({ error: err.message, success: false });
    }
  }

  @Mutation()
  public changeOldPassword(oldPassword: string) {
    this.oldPassword = oldPassword;
  }

  @Mutation()
  public changeNewPassword(newPassword: string) {
    this.newPassword = newPassword;
  }

  @Mutation()
  public changeEmail(email: string) {
    this._email = email;
  }

  @Mutation()
  private _changeChangingResult(result: Result) {
    this.changingResult = result;
  }

  private _isPasswordValid(password: string) {
    const hasNumbers = password.match(NUMBERS_REG_EX) !== null;
    const hasLetters = password.match(LETTERS_REG_EX) !== null;
    const hasSymbols = password.match(SYMBOLS_REG_EX) !== null;

    return (
      hasLetters &&
      hasNumbers &&
      hasSymbols &&
      password.match(PASSWORD_ALLOW_SYMBOLS_REG_EX) !== null
    );
  }
}
