export const AUTHORS_ROUTING = [
  {
    path: '/text/author/:id',
    name: 'author',
    component: () =>
      import(/* webpackChunkName: "author-page" */ './pages/author/author.page')
  }
];

export const ALL_AUTHORS_ROUTING = [
  {
    path: '/text/author',
    name: 'allAuthors',
    component: () =>
      import(
        /* webpackChunkName: "all-authors-page" */ './pages/all-authors/all-authors.page'
      )
  }
];
