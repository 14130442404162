import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { LeftCol } from '../../domain';
import { LeftColMapper } from '../../services';

export const LEFT_COL_MODULE_NAMESPACE = ['left_col_module'];

export class LeftColModule {
  @State()
  public blocks: LeftCol['blocks'];

  @State()
  public news: LeftCol['news'];

  @State()
  public hourNews: LeftCol['hourNews'];

  constructor(private _root: RootModule) {
    const { blocks, news, hourNews } = LeftColMapper.toDO(
      this._root.pageSpec.config.content,
      this._root.pageSpec.data
    );

    this.blocks = blocks;
    this.news = news;
    this.hourNews = hourNews;
  }
}
