




















import { guardUnspecified } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../../ui-image';
import { UiLabel } from '../../ui-label';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';

import FntCommentsIcon from '~icons/fnt-ui-common/comments';

import type {
  NewsVideoImage,
  NewsVideoStatistic,
  NewsVideoLabel
} from './ui-news-video.contract';

@Component({
  components: {
    FntCommentsIcon,
    UiLabel,
    UiImage,
    UiNewsCardLabels,
    UiObserveVisibility
  },
  name: 'UiNewsVideo'
})
export default class UiNewsVideo extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsVideoImage,
    required: true
  })
  image!: NewsVideoImage;

  @Prop({
    type: Object as () => NewsVideoStatistic,
    required: true
  })
  statistic!: NewsVideoStatistic;

  @Prop({
    type: Object as () => NewsVideoLabel,
    default: null
  })
  label: NewsVideoLabel;

  get hasLabel(): boolean {
    return guardUnspecified(this.label);
  }

  @Emit('visible')
  emitVisibility(): void {
    return;
  }
}
