import { ArticleCardsDTO } from '@fontanka/news-api-client';

import { RecordCards } from '../../domain';

import { createAttach } from './attach-mapper';
import { createRecordText } from './record-text-mapper';

export const createRecordCards = (cardsDTO: ArticleCardsDTO): RecordCards =>
  cardsDTO.reduce(
    (acc, current) => {
      if (current.type === 'card') {
        acc.push({
          id: current.id,
          header: current.header,
          data: createRecordText({ textDTO: current.data }),
          type: 'card'
        });
      } else {
        const attach = createAttach({ attach: current });

        if (attach !== null) {
          acc.push(attach);
        }
      }

      return acc;
    },

    [] as RecordCards
  );
