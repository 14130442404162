import type {
  ProfilePageDTO,
  ProfilePageProfileDTO
} from '@fontanka/news-api-client';

import type { ProfilePage } from '../domain';

import { ProfileInfoMapper } from './profile-info-mapper.service';
import { UserCommentsMapper } from './user-comments-mapper.service';

export class ProfilePageMapper {
  public static toDO(pageDTO: ProfilePageDTO): ProfilePage {
    const INITIAL: ProfilePage = {
      blocks: [],
      userInfo: null,
      commentsInfo: {
        total: 0,
        comments: []
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'profile') {
        acc.blocks.push({
          type: 'profile'
        });

        acc.userInfo = ProfileInfoMapper.toDO(
          block.data as ProfilePageProfileDTO['data']
        );

        return acc;
      }

      if (block.type === 'user_comments') {
        acc.commentsInfo = {
          total: block.meta?.total ?? 0,
          comments: UserCommentsMapper.toDO(block.data)
        };

        acc.blocks.push({
          type: 'user_comments'
        });

        return acc;
      }

      return acc;
    }, INITIAL);
  }
}
