const PERCENTAGE_COEFF = 100;

type CalculatedResult = {
  calculatedResult: string;
  lineProgressWidth: string;
};

export const getCalculatedResult = (
  answerCount: number,
  totalCount: number,
  isNumber: boolean
): CalculatedResult => {
  if (isNumber) {
    const lineProgressWidth = `${(
      (answerCount / totalCount) *
      PERCENTAGE_COEFF
    ).toFixed(2)}%`;

    return {
      calculatedResult: answerCount.toString(),
      lineProgressWidth
    };
  }

  const result = Math.floor((answerCount * PERCENTAGE_COEFF) / totalCount);

  return {
    calculatedResult: result ? `${result}%` : '<1%',
    lineProgressWidth: result ? `${result}%` : '0.5%'
  };
};
