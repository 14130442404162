import { CropType } from '@fontanka/cropping';
import type {
  RubricPageDTO,
  RubricPageMainRecordDTO,
  RubricPagePrimaryWindowDTO,
  RubricPageSecondaryWindowDTO,
  RubricPageMainRecordItemDTO
} from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { BaseRecordMapper } from '../../../services';

export type RubricPageMainRecord = {
  type: RubricPageMainRecordDTO['type'];
  data: BaseRecord | null;
};

export type RubricPagePrimaryWindow = {
  type: RubricPagePrimaryWindowDTO['type'];
  data: BaseRecord[];
};

export type RubricPageSecondaryWindow = {
  type: RubricPageSecondaryWindowDTO['type'];
  data: BaseRecord[];
};

export type RubricPageBlocks = (
  | RubricPageMainRecord
  | RubricPagePrimaryWindow
  | RubricPageSecondaryWindow
)[];

export class RubricPageMapper {
  public static toDO(pageDTO: RubricPageDTO): RubricPageBlocks {
    const blocks: RubricPageBlocks = [];
    return pageDTO.config.content.reduce((acc, current) => {
      const { data } = pageDTO.data[current.path];

      if (current.type === 'main_record') {
        acc.push({
          type: current.type,
          data:
            // АПИ возвращает массив вместо null, если нет записи
            data instanceof Array
              ? null
              : BaseRecordMapper.toDO(data as RubricPageMainRecordItemDTO, {
                  crop: true,
                  cropType: CropType.Classic
                })
        });
      }

      if (current.type === 'window_primary') {
        acc.push({
          type: current.type,
          data:
            (data as RubricPagePrimaryWindowDTO['data'])?.map(record =>
              BaseRecordMapper.toDO(record)
            ) ?? []
        });
      }

      if (current.type === 'window_secondary') {
        acc.push({
          type: current.type,
          data:
            (data as RubricPageSecondaryWindowDTO['data'])?.map(record =>
              BaseRecordMapper.toDO(record)
            ) ?? []
        });
      }

      return acc;
    }, blocks);
  }
}
