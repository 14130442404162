import { State, Mutation, Action } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { EditProfilePage, ProfileInfo } from '../domain';
import { EditProfilePageMapper, EditProfileDataMapper } from '../services';

export const EDIT_PROFILE_PAGE_MODULE_NAMESPACE = ['edit_profile_page_module'];

export type AvatarInfo = {
  file: File | undefined;
  path: string;
};

export class EditProfilePageModule {
  @State()
  public blocks: EditProfilePage['blocks'];

  @State()
  public profile: EditProfilePage['profile'];

  private readonly _editProfileDataMapper: EditProfileDataMapper;

  constructor(private _root: RootModule) {
    this._editProfileDataMapper = new EditProfileDataMapper(this._root.envType);

    const { blocks, profile: userInfo } = EditProfilePageMapper.toDO(
      this._root.pageSpec
    );

    this.profile = userInfo;
    this.blocks = blocks;
  }

  @Action()
  public async updateProfile(captchaToken: string) {
    if (this.profile !== null) {
      // eslint-disable-next-line no-useless-catch
      try {
        const newProfile = await this._editProfileDataMapper.updateProfile(
          this.profile,
          captchaToken
        );

        if (newProfile !== null) {
          this._changeProfile(newProfile);
        }
      } catch (err) {
        throw err;
      }
    }
  }

  @Mutation()
  public changeAvatar(avatarInfo: AvatarInfo) {
    if (this.profile !== null) {
      this.profile = {
        ...this.profile,
        avatar: {
          url: avatarInfo.path,
          sources: []
        },
        newAvatarFile: avatarInfo.file
      };
    }
  }

  @Mutation()
  public changeNick(nick: string) {
    if (this.profile !== null) {
      this.profile = {
        ...this.profile,
        nick
      };
    }
  }

  @Mutation()
  public changeAbout(about: string) {
    if (this.profile !== null) {
      this.profile = {
        ...this.profile,
        about
      };
    }
  }

  @Mutation()
  public changeSex(sex: ProfileInfo['sex']) {
    if (this.profile !== null) {
      this.profile = {
        ...this.profile,
        sex
      };
    }
  }

  @Mutation()
  public changeBirthday(birthday: string) {
    if (this.profile !== null) {
      this.profile = {
        ...this.profile,
        birthday
      };
    }
  }

  @Mutation()
  private _changeProfile(profile: ProfileInfo | null) {
    this.profile = profile;
  }
}
