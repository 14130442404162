import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import logo from 'images/logo.svg';

import { AgeRestrictionLogo } from '../age-restriction-logo';

import styles from './logo.styles.scss';

type Events = {
  onLogoClicked: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      url: {
        type: String,
        default: '/'
      }
    },
    methods: {
      emitLogoClicked() {
        this.$emit('logoClicked');
      }
    },
    render() {
      return (
        <div class={styles.wrapper}>
          <a class={styles.link} href={this.url} onClick={this.emitLogoClicked}>
            <img
              src={logo}
              alt="FONTANKA.RU - Санкт-Петербург онлайн"
              data-test="logo"
            />
          </a>

          <AgeRestrictionLogo class={styles.cens} />
        </div>
      );
    }
  });
