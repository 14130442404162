import { LayoutBreakpoints } from '@fontanka/data';

enum DeviceType {
  Mobile = 'mobile',
  Tablet = 'tablet',
  Laptop = 'laptop',
  Desktop = 'desktop'
}

export const getDeviceByWindowWidth = (windowWidth: number) => {
  if (windowWidth >= LayoutBreakpoints.Desktop) {
    return DeviceType.Desktop;
  }

  if (
    windowWidth >= LayoutBreakpoints.Laptop &&
    windowWidth < LayoutBreakpoints.Desktop
  ) {
    return DeviceType.Laptop;
  }

  if (
    windowWidth >= LayoutBreakpoints.Tablet &&
    windowWidth < LayoutBreakpoints.Laptop
  ) {
    return DeviceType.Tablet;
  }

  return DeviceType.Mobile;
};
