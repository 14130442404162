import type { BasePageDTO, BaseRubricDTO } from '@fontanka/news-api-client';

import type { SearchRubrics } from '../domain';

export class SearchRubricsMapper {
  public static toDO(pageDTO: BasePageDTO): SearchRubrics {
    const searchRubricsConfig = pageDTO.config.common.search_rubrics;
    if (!searchRubricsConfig) {
      return [];
    }

    const { path } = searchRubricsConfig;

    const rubrics = (pageDTO.data[path]?.data as BaseRubricDTO[]) ?? [];

    return [
      {
        id: 'all',
        name: 'Все рубрики',
        path: ''
      },
      ...rubrics
    ];
  }
}
