






import { Component, Vue, Watch } from 'vue-property-decorator';
import { useStore } from 'vuex-simple';

import { RootModule } from '@apps/frontend';

import { CONFIG_DATA } from './config';

@Component({
  name: 'YandexRecommendations'
})
export default class YandexRecommendations extends Vue {
  observerOptions = {
    root: null,
    rootMargin: '1100px',
    threshold: 0
  };
  observer: IntersectionObserver | null = null;
  isViewPort = false;

  store = useStore<RootModule>(this.$store);

  get isRender() {
    return this.blockId !== '';
  }

  get isMobile() {
    return this.store.baseInfo.isMobile;
  }

  get blockId() {
    const deviceType = this.isMobile ? 'mobile' : 'desktop';

    return CONFIG_DATA[deviceType] || '';
  }

  initObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.isViewPort = entry.isIntersecting;
      });
    }, this.observerOptions);

    if (this.$el.tagName) {
      this.observer.observe(this.$el);
    }
  }

  init() {
    window.yaContextCb = window.yaContextCb || [];

    window.yaContextCb.push(() => {
      Ya.Context.AdvManager.renderWidget({
        renderTo: 'yandex-recommendations-widget',
        blockId: this.blockId
      });
    });
  }

  @Watch('isViewPort')
  isViewPortChanged(value: boolean) {
    if (value) {
      this.init();
      this.observer?.disconnect();
    }
  }
  mounted() {
    this.initObserver();
  }
  beforeDestroy() {
    this.observer?.disconnect();
  }
}
