import { AxiosRequestConfig } from 'axios';

import { Config, BaseHttpClient } from '../base-http-client';

export type MailParams = {
  recipient: 'fontanka_redaction' | 'fontanka_sales' | 'fontanka_record_feedback';
  name?: string;
  content: string;
  email: string;
  phone?: string;
  contactId?: number;
  recordId?: number;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  attaches?: File[];
  captchaToken: string;
};

export type MailResultDTO = {
  data?: {
    detail: string;
    status: number;
    title: string;
  };
  title?: string;
  status?: number;
  detail?: string;
};

export class MailHttpClient extends BaseHttpClient {
  private readonly _mailUrl = '/public/fontanka/services/mail';

  constructor(config: Config) {
    super(config);
  }

  public async sendMail(params: MailParams): Promise<MailResultDTO | null> {
    const url = `${this.host}${this._mailUrl}`;
    let data = null;

    const formData = new FormData();
    formData.append('regionId', this.regionId.toString());
    formData.append('recipient', params.recipient);
    formData.append('name', params.name ?? '');
    formData.append('content', params.content);
    formData.append('email', params.email);
    formData.append('phone', params.phone ?? '');

    if (params.contactId) {
      formData.append('contactId', params.contactId.toString());
    }

    if (params.recordId) {
      formData.append('recordId', params.recordId.toString());
    }

    params.attaches?.forEach((file, index) => {
      formData.append(`attaches[${index}]`, file);
    });
    formData.append('captchaToken', params.captchaToken);

    const options: AxiosRequestConfig = {
      withCredentials: true,
      method: 'POST',
      params: {
        regionId: this.regionId
      },
      url,
      data: formData
    };

    try {
      const result = await this.httpClient(options);
      // eslint-disable-next-line prefer-destructuring
      data = result.data.data;
    } catch (err) {
      data = null;
    }

    return data;
  }
}
