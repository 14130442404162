import { LogotypeAttachDTO } from '@fontanka/news-api-client';
import { LogotypeAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createLogotypeAttach = (attach: LogotypeAttachDTO): LogotypeAttach => ({
  type: attach.type,
  value: {
    link: attach.media.link,
    image: createImage(attach.value)
  }
});
