import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin, storeMixin } from '@apps/frontend';

import styles from './fullscreen-modal.styles.scss';

import FntCrossBtnIcon from '~icons/fnt-common/cross-btn';

type Events = {
  onClose: (value: string) => void;
};

type ComponentData = {
  isRender: boolean;
};

const CLOSE_ICON_SIZE = 24;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(storeMixin)
  .create({
    name: 'FullscreenModal',
    props: {
      modalId: {
        type: String,
        required: true
      }
    },
    data(): ComponentData {
      return {
        isRender: false
      };
    },
    mounted() {
      this.isRender = true;

      document.addEventListener('keyup', this.pressKey);
    },
    beforeDestroy() {
      document.removeEventListener('keyup', this.pressKey);
    },
    methods: {
      pressKey(event: KeyboardEvent) {
        const ESC = 27;
        const { keyCode } = event;
        if (keyCode === ESC) {
          this.hideModal();
        }
      },
      hideModal() {
        this.$emit('close', this.modalId);
      }
    },
    render() {
      if (!this.isRender) {
        return <div />;
      }

      return (
        <modal
          name={this.modalId}
          adaptive
          scrollable
          height="100%"
          width="100%"
          onClosed={() => this.hideModal()}
        >
          <div class={styles.wrapper}>
            {this.$slots.default}
            <div onClick={() => this.hideModal()} class={styles.closeIcon}>
              <FntCrossBtnIcon width={CLOSE_ICON_SIZE} height={CLOSE_ICON_SIZE} />
            </div>
          </div>
        </modal>
      );
    }
  });
