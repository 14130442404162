import { DocumentsAttachDTO } from '@fontanka/news-api-client';
import { DocumentsAttach } from '../../../domain';

export const createDocumentsAttach = (
  attach: DocumentsAttachDTO
): DocumentsAttach => ({
  type: 'documents',
  value: {
    type: attach.value.type,
    description: attach.value.description,
    url: attach.value.url
  }
});
