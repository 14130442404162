import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './form-checkbox.styles.scss';

type Events = {
  onChange: boolean;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      id: {
        type: String,
        required: true as const
      },
      checked: {
        type: Boolean,
        default: true
      },
      required: {
        type: Boolean,
        default: true
      },
      type: {
        type: String as () => 'orange' | 'gray',
        default: 'orange'
      }
    },
    data() {
      return {
        isChecked: this.checked
      };
    },
    computed: {
      typeStyles() {
        return this.type === 'gray' ? styles.gray : '';
      }
    },
    methods: {
      emitChangeEvent() {
        this.isChecked = !this.isChecked;
        this.$emit('change', this.isChecked);
      }
    },
    render() {
      return (
        <div class={[styles.formCheckbox, this.typeStyles]}>
          <input
            class={[styles.input, this.typeStyles]}
            type="checkbox"
            id={this.id}
            name={this.id}
            required={this.required}
            checked={this.isChecked}
            onChange={this.emitChangeEvent}
          />
          <label class={[styles.label, this.typeStyles]} for={this.id}>
            {this.$slots.default}
          </label>
        </div>
      );
    }
  });
