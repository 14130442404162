import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import mobileWindIcon from 'images/weather/wind-single.svg';
import windIcon from 'images/weather/wind.svg';

import styles from './weather-today.styles.scss';

type DayTimeType = {
  name: string;
  cloudTitle: string;
  humidity: number;
  icon: string;
  iconSrc: string;
  precipitationTitle: string;
  pressure: number;
  temperature: string;
  windDirection: {
    style: string;
    name: string;
  };
  windSpeed: number;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    today: {
      type: Array as () => DayTimeType[],
      required: true as const
    },
    header: {
      type: String,
      required: true as const
    }
  },
  methods: {},
  render() {
    return (
      <div class={styles.weatherToday}>
        <div class={styles.weatherTodayHeader}>
          <span class={[styles.weatherTodayColumnTitle, styles.main]}>
            {this.header}
          </span>
          <span class={[styles.weatherTodayColumnTitle, styles.pressure]}>
            Давление,
            <br />
            мм. рт. ст.
          </span>
          <span class={[styles.weatherTodayColumnTitle, styles.humidity]}>
            Влажность
          </span>
          <span class={[styles.weatherTodayColumnTitle, styles.wind]}>
            Ветер, м/с
          </span>
        </div>
        {this.today.map(dayTime => (
          <div class={styles.weatherTodayRow}>
            <span class={[styles.weatherTodayCell, styles.dayTime]}>
              {dayTime.name}
            </span>
            <span class={[styles.weatherTodayCell, styles.weather]}>
              <img
                src={dayTime.iconSrc}
                alt={dayTime.icon}
                class={styles.weatherTodayIcon}
              />
            </span>
            <span class={[styles.weatherTodayCell, styles.temperature]}>
              <span>{dayTime.temperature}</span>
              <span domPropsInnerHTML="&deg;"></span>
            </span>
            <span class={[styles.weatherTodayCell, styles.description]}>
              <span>{dayTime.cloudTitle},</span>
              <span domPropsInnerHTML="&nbsp;"></span>
              <span>{dayTime.precipitationTitle}</span>
            </span>
            <span class={[styles.weatherTodayCell, styles.pressure]}>
              {dayTime.pressure}
            </span>
            <span class={[styles.weatherTodayCell, styles.humidity]}>
              {dayTime.humidity}%
            </span>
            <span class={[styles.weatherTodayCell, styles.wind]}>
              <span class={styles.weatherTodayWindInfo}>{dayTime.windSpeed}</span>
              <img
                class={[
                  styles.weatherTodayWindIcon,
                  styles[dayTime.windDirection.style],
                  styles.weatherTodayWindInfo
                ]}
                src={windIcon}
                alt="Направление ветра"
              />
              <img
                class={[
                  styles.weatherTodayWindIcon,
                  styles[dayTime.windDirection.style],
                  styles.weatherTodayWindInfo,
                  styles.mobile
                ]}
                src={mobileWindIcon}
                alt="Направление ветра"
              />
              <span class={[styles.weatherTodayWindInfo, styles.name]}>
                {dayTime.windDirection.name}
              </span>
            </span>
          </div>
        ))}
      </div>
    );
  }
});
