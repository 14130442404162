import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { isAbsoluteUrl } from '@portal/utils/util-url';

import type {
  BasePageDTO,
  FooterConfigDTO,
  FooterAboutCompanyDTO,
  FooterProjectsDTO,
  FooterRubricsDTO,
  FooterTextDTO,
  FooterMobileAppsDTO,
  FooterSocialsDTO,
  FooterCitiesDTO,
  FooterNotEditorialCitiesDTO,
  MobileMenuDTO,
  LogotypesDTO,
  FooterRubricsListDTO
} from '@fontanka/news-api-client';
import { BaseMobileAppDTO } from '@fontanka/news-api-client';

import type { Footer } from '../domain';

const getLinkTarget = (link: string): '_self' | '_blank' => {
  if (isAbsoluteUrl(link)) {
    return link.startsWith('https://www.fontanka.ru') ? '_self' : '_blank';
  }

  return '_self';
};

export class FooterMapper {
  public static toDO(
    config: FooterConfigDTO,
    specData: BasePageDTO['data']
  ): Footer {
    return Object.entries(config).reduce((acc, current) => {
      if (current[1]) {
        const blockConfig = current[1];
        const blockType = blockConfig.type;

        if (blockType === 'about_company') {
          const { data } = specData[blockConfig.path] as FooterAboutCompanyDTO;

          const list =
            data?.map(item => {
              item.target = getLinkTarget(item.link);
              return item;
            }) || [];

          return {
            ...acc,
            aboutCompany: {
              data: list
            }
          };
        }

        if (blockType === 'footer_projects') {
          const { data } = specData[blockConfig.path] as FooterProjectsDTO;

          const list =
            data?.map(item => {
              return {
                ...item,
                target: getLinkTarget(item.link)
              };
            }) || [];

          return {
            ...acc,
            projects: {
              data: list
            }
          };
        }

        if (blockType === 'footer_rubrics') {
          const { data } = specData[blockConfig.path] as FooterRubricsDTO;

          const list =
            data?.map(item => {
              return {
                ...item,
                target: getLinkTarget(item.path)
              };
            }) || [];

          return {
            ...acc,
            rubrics: {
              data: list
            }
          };
        }

        if (blockType === 'footer_rubrics_list') {
          const { data } = specData[blockConfig.path] as FooterRubricsListDTO;

          const list =
            data?.map(item => {
              return {
                ...item,
                target: getLinkTarget(item.path)
              };
            }) || [];

          return {
            ...acc,
            rubricsList: {
              data: list
            }
          };
        }

        if (blockType === 'footer_text') {
          const { data } = specData[blockConfig.path] as FooterTextDTO;

          if (!guardUnspecified(data)) {
            return {
              ...acc,
              text: {
                data: {
                  copyright: '',
                  links: [],
                  text: ''
                }
              }
            };
          }

          const [text, linksStr] = data.info.split('<span>') as [
            string,
            string | undefined
          ];
          const links =
            linksStr
              ?.slice(0, -'</span>'.length)
              .split('\n')
              .filter(link => guardEmptyString(link) && link !== '\r') || [];

          return {
            ...acc,
            text: {
              data: {
                copyright: data.copyright,
                text,
                links
              }
            }
          };
        }

        if (blockType === 'mobile_apps') {
          const { data } = specData[blockConfig.path] as FooterMobileAppsDTO;

          if (!guardUnspecified(data)) {
            return {
              ...acc,
              mobileApps: {
                data: []
              }
            };
          }

          return {
            ...acc,
            mobileApps: {
              data: this.addMobileAppName(data)
            }
          };
        }

        if (blockType === 'socials') {
          const { data } = specData[blockConfig.path] as FooterSocialsDTO;
          return {
            ...acc,
            socials: {
              data
            }
          };
        }

        if (blockType === 'footer_cities') {
          const { data } = specData[blockConfig.path] as FooterCitiesDTO;
          return {
            ...acc,
            footerCities: {
              data
            }
          };
        }

        if (blockType === 'footer_not_editorial_cities') {
          const { data } = specData[blockConfig.path] as FooterNotEditorialCitiesDTO;
          return {
            ...acc,
            footerNotEditorialCities: {
              data
            }
          };
        }

        if (blockType === 'mobile_menu') {
          const { data } = specData[blockConfig.path] as MobileMenuDTO;
          const result = {
            ...acc,
            mobileMenu: {
              data: []
            }
          } as Footer;

          if (!guardUnspecified(data)) {
            return result;
          }

          result.mobileMenu.data = [
            ...data.mobile_menu_head,
            ...data.about_company,
            ...data.mobile_menu_tail
          ].map(item => {
            return {
              ...item,
              target: getLinkTarget(item.link)
            };
          });

          return result;
        }

        if (blockType === 'logotypes') {
          const { data } = specData[blockConfig.path] as LogotypesDTO;
          if (!guardUnspecified(data)) {
            return {
              ...acc,
              logotypes: {
                data: []
              }
            };
          }

          return {
            ...acc,
            logotypes: {
              data: Object.entries(data).filter(
                item => guardEmptyString(item[1]) && item[0] !== 'auto'
              )
            }
          };
        }
      }

      return acc;
    }, {} as Footer);
  }

  public static addMobileAppName(arr: BaseMobileAppDTO[]): BaseMobileAppDTO[] {
    return arr.map(item => {
      if (item.name === 'ios') {
        item.title = 'App Store';
      }

      if (item.name === 'android') {
        item.title = 'Google Play';
      }

      if (item.name === 'appgallery') {
        item.title = 'AppGallery';
      }

      if (item.name === 'rustore') {
        item.title = 'RuStore';
      }

      return item;
    });
  }
}
