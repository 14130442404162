



































































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { UiAdvLabel } from '../ui-adv-label';
import { UiButton } from '../ui-button';
import { UiImage } from '../ui-image';
import { UiLabel } from '../ui-label';

import FntChevronLeftIcon from '~icons/fnt-ui-common/chevron-left';

import { UiObserveVisibility } from '../ui-observe-visibility';

import FntChevronRightIcon from '~icons/fnt-ui-common/chevron-right';

import { UiSlider } from '../ui-slider';
import type { UiSliderComponent } from '../ui-slider';

import type {
  UiNewsSliderEmitValue,
  UiNewsSliderRecords
} from './ui-news-slider.contract';

@Component({
  name: 'UiNewsSlider',
  components: {
    UiSlider,
    UiLabel,
    UiObserveVisibility,
    UiImage,
    UiButton,
    FntChevronLeftIcon,
    FntChevronRightIcon,
    UiAdvLabel
  }
})
export default class UiNewsSlider extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  title!: string;
  @Prop({
    type: String,
    default: ''
  })
  link!: string;

  @Prop({
    type: Array,
    required: true
  })
  records!: UiNewsSliderRecords;

  @Prop({
    type: Boolean,
    default: true
  })
  isMobile: boolean;

  activeSlideNum = 1;

  get slidesCount(): number {
    return this.records.length;
  }

  get showFirsSlide(): boolean {
    return this.activeSlideNum === 1;
  }

  get showLastSlide(): boolean {
    return this.activeSlideNum === this.slidesCount;
  }

  prevSlide() {
    (this.$refs.slider as UiSliderComponent)?.prevSlide();
  }

  nextSlide() {
    (this.$refs.slider as UiSliderComponent)?.nextSlide();
  }

  slideChangedHandler(slideNum: number) {
    this.activeSlideNum = slideNum;
  }

  @Emit('articleClicked')
  articleClicked(event: UiNewsSliderEmitValue): UiNewsSliderEmitValue {
    return event;
  }

  @Emit('articleShown')
  articleShown(event: UiNewsSliderEmitValue): UiNewsSliderEmitValue {
    return event;
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }

  @Emit('headerClicked')
  headerClicked(): void {
    return;
  }
}
