import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { PageCommon } from '../domain';
import { NewCommonMapper } from '../services';

export const NEW_COMMON_MODULE_NAMESPACE = ['new_common_module'];

export class NewCommonModule {
  @State()
  public themes: PageCommon['themes'];

  @State()
  public feedback: PageCommon['feedback'];

  constructor(private _root: RootModule) {
    const { data, config } = this._root.pageSpec;
    const { isMobile } = this._root.baseInfo;

    const { themes, feedback } = NewCommonMapper.toDO({
      config: config.common,
      data,
      isMobile
    });

    this.themes = themes;
    this.feedback = feedback;
  }
}
