import { Action, Mutation, State } from 'vuex-simple';

import type { RootModule } from '../../../../core';
import type { BaseRecord, SearchRubrics } from '../../../../domain';
import { SearchRubricsMapper } from '../../../../services';
import type { DayArchiveBlocks as DayArchiveBlocksAlias } from '../../domain/day-archive';
import { getPageUrl, getFilterLinks } from '../../domain/day-archive';
import type { FilterLink } from '../../domain/filter-link';
import { DayArchiveMapper } from '../../services';

type DayArchiveBlocks = DayArchiveBlocksAlias;

type PageParams = {
  year: string;
  month: string;
  day: string;
  rubricId?: 'all' | string;
};

type PageSizeParams = {
  page: number;
  pagesize: number;
};

export type PageUrlParams = {
  day: string;
  month: string;
  year: string;
  type: FilterLink['type'];
  rubricId?: 'all' | string;
};

export const DAY_ARCHIVE_MODULE_NAMESPACE = ['day_archive_module'];

export class DayArchiveModule {
  @State()
  public filterLinks: FilterLink[];

  @State()
  public rubrics: SearchRubrics;

  @State()
  public blocks: DayArchiveBlocks;

  @State()
  private _params: PageParams;

  private readonly _dayArchiveMapper: DayArchiveMapper;

  constructor(private _root: RootModule, params: PageParams) {
    this._dayArchiveMapper = new DayArchiveMapper(this._root.envType);

    this._params = params;

    const { day, month, year, rubricId } = params;

    this.rubrics = SearchRubricsMapper.toDO(this._root.pageSpec);
    this.filterLinks = getFilterLinks(year, month, day, rubricId);

    this.blocks = DayArchiveMapper.toDO(this._root.pageSpec);
  }

  @Action()
  navigate(params: PageUrlParams) {
    location.href = getPageUrl(params);
  }

  @Action()
  async fetchMoreNews(pageSizeParams: PageSizeParams) {
    const records = await this._dayArchiveMapper.getMoreNews({
      ...this._params,
      ...pageSizeParams
    });
    this.addRecords(records);
  }

  @Mutation()
  addRecords(newRecords: BaseRecord[]) {
    this.blocks
      .filter(dayArchiveBlock => dayArchiveBlock.type === 'last_news')
      .map(dayArchiveBlock => {
        dayArchiveBlock.recordsCountPerPage += newRecords.length;
        dayArchiveBlock.data.map(blockData => {
          blockData.records.push(...newRecords);
        });
      });
  }
}
