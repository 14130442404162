import type {
  RecordCommentsPageDTO,
  RecordCommentsPageCommentsDTO,
  RecordCommentsPageArticleDTO,
  MetaCommentsDTO
} from '@fontanka/news-api-client';

import { RecordCommentMapper } from '../../../services';
import type { RecordCommentsPage } from '../domain';

export class RecordCommentsPageMapper {
  public static toDO(pageDTO: RecordCommentsPageDTO): RecordCommentsPage {
    const INITIAL: RecordCommentsPage = {
      blocks: [],
      parentComments: [],
      meta: {
        commentsCount: 0,
        hasAfter: false,
        searchDate: 0,
        total: 0,
        totalCaption: 0
      },
      record: null
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'parent_comments') {
        const { data, meta } = block as RecordCommentsPageCommentsDTO;

        acc.parentComments =
          data?.map(commentDTO =>
            RecordCommentMapper.getParentComment(commentDTO)
          ) ?? [];
        acc.meta = RecordCommentMapper.getMetaCommentsData(meta as MetaCommentsDTO);

        acc.blocks.push({
          type: current.type
        });

        return acc;
      }

      if (current.type === 'article') {
        const { data } = block as RecordCommentsPageArticleDTO;

        acc.record = data;

        acc.blocks.push({
          type: current.type
        });

        return acc;
      }

      return acc;
    }, INITIAL);
  }
}
