import { FeedbackAttachDTO } from '@fontanka/news-api-client';
import { FeedbackAttach, FeedbackSocials, SocialsLink } from '../../../domain';

export const createFeedbackAttach = (attach: FeedbackAttachDTO): FeedbackAttach => {
  const feedbackSocials: FeedbackSocials[] = [
    'instagram',
    'telegram',
    'viber',
    'vk',
    'whatsApp',
    'youtube'
  ];

  const value = attach.value;
  const socialsLink: SocialsLink = feedbackSocials.reduce(
    (acc: SocialsLink, social: FeedbackSocials): SocialsLink => {
      const link = value.contact[social];
      if (link.length > 0) {
        acc[social] = link;
      }

      return acc;
    },
    {}
  );

  return {
    type: 'feedback',
    value: {
      text: value.text,
      contact: {
        id: value.contact.id,
        title: value.contact.title,
        phone: value.contact.phone,
        socialsLink: Object.keys(socialsLink).length > 0 ? socialsLink : null
      }
    }
  };
};
