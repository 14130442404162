export const PROFILE_ROUTING = [
  {
    path: '/users/:profileId(\\d+)',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile-page" */ './pages/profile/profile.page')
  },
  {
    path: '/users/edit',
    name: 'profileSetting',
    component: () =>
      import(
        /* webpackChunkName: "edit-profile-page" */ './pages/edit-profile/edit-profile.page'
      )
  },
  {
    path: '/restore',
    component: () =>
      import(
        /* webpackChunkName: "restore-password-page" */ './pages/restore-password/restore-password.page'
      )
  },
  {
    path: '/changepassword',
    component: () =>
      import(
        /* webpackChunkName: "change-password-page" */ './pages/change-password/change-password.page'
      )
  },
  {
    path: '/profile',
    name: 'profileSettingUMP',
    component: () =>
      import(
        /* webpackChunkName: "edit-profile-page" */ './pages/edit-profile/edit-profile.page'
      )
  },
  {
    path: '/profile/:profileId(\\d+)',
    name: 'profileUMP',
    component: () =>
      import(/* webpackChunkName: "profile-page" */ './pages/profile/profile.page')
  }
];
