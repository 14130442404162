import { guardUnspecified } from '@smh/utils/guards';

import type {
  PictureDayArticleDTO,
  PictureDayBlockDTO,
  PictureDayTabDTO,
  SpecialNewsBlockDTO,
  SpecialNewsDTO,
  HotNewsItemDTO,
  HomePageWindowDTO,
  HomePageWindowItemDTO
} from '@fontanka/news-api-client';

import type {
  PictureDayArticle,
  PictureDayBlockData,
  PictureDayBlockDataItem
} from '../domain';

const PICTURE_OF_THE_DAY_IDS_MAP: Record<PictureDayTabDTO, string> = {
  picture_of_the_day: 'Главное',
  readers_choice: 'Читают',
  suggestions: 'Рекомендуем'
};

const NEWS_COUNT = 7;
const PINNED_ITEMS_START_IDX = 5;

export class MainNewsMapper {
  public static toDO(
    mainNewsDTO: PictureDayBlockDTO['data'],
    hourNewsDTO: SpecialNewsBlockDTO['data'],
    hotNewsDTO?: HotNewsItemDTO,
    windowDTO?: HomePageWindowDTO['data'],
    isMobile?: boolean
  ): PictureDayBlockData {
    const result = Object.entries(mainNewsDTO).map(([key, value]) => {
      return {
        title: PICTURE_OF_THE_DAY_IDS_MAP[key],
        articles: value
          .map((item, index) => MainNewsMapper._presentArticle(item, index + 1))
          .slice(0, NEWS_COUNT)
      } as PictureDayBlockDataItem;
    });

    const pinnedItems = result[0].articles.slice(PINNED_ITEMS_START_IDX);

    if (guardUnspecified(hotNewsDTO) && hotNewsDTO.type === 'urgent') {
      const specialNews = MainNewsMapper._presentHotNews(hotNewsDTO, 0, true);
      result[0].articles.unshift(specialNews);
      result[0].articles.splice(
        PINNED_ITEMS_START_IDX,
        result[0].articles.length - PINNED_ITEMS_START_IDX,
        ...pinnedItems
      );

      return result;
    }

    if (guardUnspecified(hourNewsDTO)) {
      const specialNews = MainNewsMapper._presentArticle(hourNewsDTO, 0, true);
      result[0].articles.unshift(specialNews);
      result[0].articles.splice(
        PINNED_ITEMS_START_IDX,
        result[0].articles.length - PINNED_ITEMS_START_IDX,
        ...pinnedItems
      );

      return result;
    }

    const windowMainNewsDTO = windowDTO?.[0];

    if (isMobile && guardUnspecified(windowMainNewsDTO)) {
      const windowMainNews = MainNewsMapper._presentWindowItem(windowMainNewsDTO, 0);
      result[0].articles.unshift(windowMainNews);
      result[0].articles.splice(
        PINNED_ITEMS_START_IDX,
        result[0].articles.length - PINNED_ITEMS_START_IDX,
        ...pinnedItems
      );

      return result;
    }

    return result;
  }

  private static _presentArticle(
    article: PictureDayArticleDTO | SpecialNewsDTO,
    index: number,
    isHotNews = false
  ): PictureDayArticle {
    return {
      isHotNews,
      isCommercial: article.isCommercial,
      id: article.id,
      title: article.header,
      place: index,
      url: article.urls.url,
      type: 'news',
      hasMainPhotoCommercialLabel: article.hasMainPhotoCommercialLabel ?? false
    };
  }

  private static _presentHotNews(
    article: HotNewsItemDTO,
    index: number,
    isHotNews = false
  ): PictureDayArticle {
    return {
      isHotNews,
      isCommercial: false,
      id: article.id,
      title: article.text,
      place: index,
      url: article.url,
      type: 'news'
    };
  }

  private static _presentWindowItem(
    data: HomePageWindowItemDTO,
    index: number
  ): PictureDayArticle {
    return {
      isHotNews: false,
      type: 'news',
      id: data.id,
      isCommercial: data.isCommercial,
      place: index,
      title: data.header,
      url: data.urls?.url || '',
      hasMainPhotoCommercialLabel: data.hasMainPhotoCommercialLabel ?? false
    };
  }
}
