import { Service } from 'vue-typedi';

import type {
  BasePageDTO,
  CommonCompanyNewsDTO,
  AdvBlockInfo,
  AdvBlockConfig,
  NewsFeedBlockConfig,
  BaseRecordDTO,
  NewsItemDTO,
  BaseSocialDTO
} from '@fontanka/news-api-client';
import { LeftAndRightColsBlockType } from '@fontanka/news-api-client';

import { WeSocialMapper, BaseRecordMapper, AdvMapper } from '../../services';
import type { RightCol } from '../domain';

import { CompaniesNewsMapper } from './companies-news-mapper.service';
import { NewsFeedMapper } from './news-feed-mapper.service';

@Service()
export class RightColMapper {
  public static toDO(
    config: BasePageDTO['config']['content'],
    specData: BasePageDTO['data']
  ): RightCol {
    const INITIAL: RightCol = {
      blocks: [],
      hasOnlyMobileBlocks: true
    };

    return config.reduce((acc, current) => {
      const { settings } = current;
      if (settings && settings.place === 'right') {
        if (
          acc.hasOnlyMobileBlocks &&
          current.type !== 'adv' &&
          current.type !== 'top_right_advert' &&
          !current.settings?.types?.mobile
        ) {
          acc.hasOnlyMobileBlocks = false;
        }

        if (current.type === LeftAndRightColsBlockType.CompanyNews) {
          const { data } = specData[current.path] as CommonCompanyNewsDTO;
          acc.blocks.push({
            type: current.type,
            data: data?.map(r => CompaniesNewsMapper.toDO(r)) ?? []
          });

          return acc;
        }

        if (current.type === LeftAndRightColsBlockType.Socials) {
          acc.blocks.push({
            type: current.type,
            data: WeSocialMapper.toDO(
              specData[current.path].data as BaseSocialDTO[] | undefined
            )
          });

          return acc;
        }

        if (current.type === LeftAndRightColsBlockType.News) {
          acc.blocks.push({
            type: current.type,
            data: NewsFeedMapper.toDO(
              specData[current.path].data as {
                all: NewsItemDTO[] | null;
                spb: NewsItemDTO[] | null;
              },
              current as NewsFeedBlockConfig
            )
          });

          return acc;
        }

        if (current.type === 'adv' || current.type === 'top_right_advert') {
          const info = specData[current.path] as AdvBlockInfo;

          acc.blocks.push(AdvMapper.toDO(current as AdvBlockConfig, info));

          return acc;
        }

        if (
          current.type === LeftAndRightColsBlockType.Opinions &&
          specData[current.path].data !== null
        ) {
          const { data } = specData[current.path];

          acc.blocks.push({
            type: current.type,
            data:
              (data as BaseRecordDTO[])?.map(record =>
                BaseRecordMapper.toDO(record)
              ) ?? []
          });

          return acc;
        }

        acc.blocks.push({
          type: current.type,
          params: {
            path: current.params?.path,
            title: current.params?.title
          },
          data: specData[current.path].data
        });
      }

      return acc;
    }, INITIAL);
  }
}
