import { guardUnspecified } from '@portal/utils/util-guards';
import { isAbsoluteUrl } from '@portal/utils/util-url';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { RecordStatistics } from '@fe/common/components';
import { typography } from '@fe/common/styles/typography';

import styles from './top-news-item.styles.scss';

type Events = {
  onRecordClicked: Event;
  onCommentsClicked: Event;
  onDiscussClicked: Event;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      index: {
        type: Number,
        default: 0
      },
      header: {
        type: String,
        default: ''
      },
      url: {
        required: true,
        type: String,
        default: ''
      },
      commentsUrl: {
        type: String,
        default: ''
      },
      discussUrl: {
        type: String,
        default: ''
      },
      commentsCount: {
        type: Number,
        default: 0
      },
      isCommentsAllowed: {
        type: Boolean,
        default: true
      },
      viewsCount: {
        type: Number,
        default: 0
      },
      isFirst: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      getTargetLink(path: string) {
        return isAbsoluteUrl(path) ? '_blank' : '_self';
      }
    },
    computed: {
      hasIndex() {
        return guardUnspecified(this.index);
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs
      };
      const listeners = {
        on: this.$listeners
      };

      return (
        <article class={styles.topNewsItem} {...attributes}>
          {this.hasIndex && (
            <span class={[styles.index, typography.secondary.bodyMain.mobile]}>
              {this.index}
            </span>
          )}
          <div class={styles.content}>
            {this.$slots.image}
            <a
              class={[
                styles.header,
                this.isFirst
                  ? typography.primary.h5Headline.mobile
                  : typography.primary.body2.mobile
              ]}
              href={this.url}
              target={this.getTargetLink(this.url)}
              domPropsInnerHTML={this.header}
              {...listeners}
              onClick={(event: Event) => this.$emit('recordClicked', event)}
            />
            <RecordStatistics
              class={styles.statisticBlock}
              commentsCount={this.commentsCount}
              commentsUrl={this.commentsUrl}
              discussUrl={this.discussUrl}
              isCommentsAllowed={this.isCommentsAllowed}
              viewsCount={this.viewsCount}
              onCommentsClicked={(event: Event) =>
                this.$emit('commentsClicked', event)
              }
              onDiscussClicked={(event: Event) =>
                this.$emit('discussClicked', event)
              }
              target={this.getTargetLink(this.commentsUrl)}
            />
          </div>
        </article>
      );
    }
  });
