import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';

import styles from './no-comments-badge.styles.scss';

import FntCommentsIcon from '~icons/fnt-common/comments';

const NO_COMMENTS_ICON_SIZE = 34;

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  render() {
    return (
      <div class={styles.noComments}>
        <FntCommentsIcon
          width={NO_COMMENTS_ICON_SIZE}
          height={NO_COMMENTS_ICON_SIZE}
        />
        <span
          class={[
            styles.noCommentsText,
            typography.secondary.bodyMain2Accent.mobile
          ]}
        >
          <b>Пока нет ни одного комментария.</b>
          <p class={styles.noCommentsSubText}>Добавьте комментарий первым!</p>
        </span>
      </div>
    );
  }
});
