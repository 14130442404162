import type {
  PhotosVideosPageDTO,
  PhotosVideosPageFormatNewsDTO
} from '@fontanka/news-api-client';

import { FormatRecordsMapper } from '../../../services';
import type { PhotosVideosPage } from '../domain/photos-videos-page';

export class PhotosVideosPageMapper {
  public static toDO(pageDTO: PhotosVideosPageDTO): PhotosVideosPage {
    const INITIAL_DATA: PhotosVideosPage = {
      blocks: [],
      recordsInfo: {
        total: 0,
        records: []
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'format_news') {
        acc.recordsInfo = {
          total: block.meta?.total ?? 0,
          records: FormatRecordsMapper.toDO(
            block.data as PhotosVideosPageFormatNewsDTO['data']
          )
        };

        acc.blocks.push({
          type: current.type
        });
      }

      return acc;
    }, INITIAL_DATA);
  }
}
