import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import { Slider } from '../../_sliders';

import styles from './side-slider-block.styles.scss';

type Events = {
  onSlideChanged: number;
  onReady: void;
  onClickedHeader: void;
};

export type SideSliderItem = {
  id: number;
  image?: {
    url: string;
  } | null;
  header: string;
  author?: {
    image: {
      url: string;
    } | null;
    name: string;
    url: string;
  } | null;
  urls: {
    url: string;
  };
  publishAt?: string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      link: {
        type: String,
        required: true as const
      },
      header: {
        type: String,
        required: true as const
      },
      slidesCount: {
        type: Number,
        required: true as const
      }
    },
    data() {
      return {
        currentSlide: 0
      };
    },
    methods: {
      nextSlide() {
        if (this.currentSlide !== this.slidesCount - 1) {
          this.currentSlide = this.currentSlide + 1;
          this.emitChangeEvent(this.currentSlide);
        }
      },
      prevSlide() {
        if (this.currentSlide !== 0) {
          this.currentSlide = this.currentSlide - 1;
          this.emitChangeEvent(this.currentSlide);
        }
      },
      setGroup(groupIndex: number) {
        this.currentSlide = groupIndex;
        this.emitChangeEvent(groupIndex);
      },
      emitChangeEvent(currentSlide: number) {
        this.$emit('slideChanged', currentSlide);
      },
      emitReadyEvent() {
        this.$emit('ready');
      },
      emitClickHeaderEvent() {
        this.$emit('clickedHeader');
      }
    },
    render() {
      return (
        <div class={styles.slides}>
          <div class={styles.headerWrap}>
            <h3 class={styles.header}>
              <a
                class={styles.headerLink}
                href={this.link}
                onClick={this.emitClickHeaderEvent}
                domPropsInnerHTML={this.header}
              />
            </h3>

            <div>
              <button class={styles.navBtn} onClick={this.prevSlide}>
                &lt;
              </button>
              <button class={styles.navBtn} onClick={this.nextSlide}>
                &gt;
              </button>
            </div>
          </div>

          <div class={styles.sliderWrapper}>
            <Slider
              currentGroup={this.currentSlide}
              onSlideChanged={this.setGroup}
              onReady={this.emitReadyEvent}
            >
              {this.$slots.default}
            </Slider>
          </div>
        </div>
      );
    }
  });
