import { BehaviorSubject } from 'rxjs';

import type { IFullscreenService } from '../containers';

export class FullscreenService implements IFullscreenService {
  private _status = new BehaviorSubject<'closed' | 'opened'>('closed');

  private _lastImageId = new BehaviorSubject<string>('');

  public get status() {
    return this._status.asObservable();
  }

  public get lastImageId() {
    return this._lastImageId.asObservable();
  }

  public showFullscreenForImage(imageId: string) {
    this._lastImageId.next(imageId);
    this._status.next('opened');
  }

  public hideFullscreen() {
    this._status.next('closed');
  }
}
