import { State } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { HomePage } from '../domain';
import { HomePageMapper } from '../services';

export const HOME_PAGE_MODULE_NAMESPACE = ['home_page_module'];

export class HomePageModule {
  @State()
  public window: HomePage['window'];

  @State()
  public mainNews: HomePage['mainNews'];

  @State()
  public businessNews: HomePage['businessNews'];

  @State()
  public opinions: HomePage['opinions'];

  @State()
  public rubrics: HomePage['rubrics'];

  @State()
  public ourCollection: HomePage['ourCollection'];

  @State()
  public socials: HomePage['socials'];

  @State()
  public vkWidgetCode = `
    <div id="vk_groups"></div>
    <script>
    function loadScript( url, callback ) {
      var script = document.createElement( "script" )
      script.async = true;
      script.type = "text/javascript";
      script.onload = function() {
        callback();
      };
      script.src = url;
      document.getElementsByTagName( "head" )[0].appendChild( script );
    }

    loadScript('https://vk.com/js/api/openapi.js?169', function() {
      if (VK && VK.Widgets) {
        VK.Widgets.Group("vk_groups", {mode: 1, width:"236"}, 18901857);
      }
    });
    </script>`;

  @State()
  public discussions: HomePage['discussions'];

  constructor(private _root: RootModule) {
    const {
      window,
      mainNews,
      businessNews,
      opinions,
      rubrics,
      ourCollection,
      socials,
      discussions
    } = HomePageMapper.toDO(this._root.pageSpec);

    this.window = window;
    this.mainNews = mainNews;
    this.businessNews = businessNews;
    this.opinions = opinions;
    this.rubrics = rubrics;
    this.ourCollection = ourCollection;
    this.socials = socials;
    this.discussions = discussions;
  }
}
