import { FormatsHttpClient } from '@fontanka/news-api-client';

import type { BaseRecord } from '../../../domain';
import { FormatRecordsMapper } from '../../../services';

export class FormatRecordsDataMapper {
  private readonly _formatsHttpClient: FormatsHttpClient;

  constructor(envType: string) {
    this._formatsHttpClient = new FormatsHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getFormatRecords(
    format: string,
    page: number,
    pagesize: number
  ): Promise<BaseRecord[]> {
    let formatRecords: BaseRecord[] = [];

    try {
      const { data } = await this._formatsHttpClient.fetchFormatRecords({
        format,
        page,
        pagesize
      });

      formatRecords = FormatRecordsMapper.toDO(data ?? []);
    } catch (err) {
      console.error(err);
    }

    return formatRecords;
  }
}
