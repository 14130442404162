

































import { guardEmptyString } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiObserveVisibility } from '../ui-observe-visibility';

@Component({
  name: 'UiBaseBlock',
  components: { UiObserveVisibility }
})
export default class UiBaseBlock extends Vue {
  @Prop({
    type: Boolean,
    default: true
  })
  withPadding!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  isDark!: boolean;
  @Prop({
    type: String,
    default: ''
  })
  title!: string;
  @Prop({
    type: String,
    default: ''
  })
  link!: string;

  @Prop({
    type: String,
    default: '_self'
  })
  linkTarget!: string;

  @Prop({
    type: Boolean,
    default: true
  })
  hasBackground!: boolean;

  get classes() {
    return [
      this.$style.block,
      this.isDark ? this.$style.dark : '',
      this.withPadding ? this.$style.withPadding : '',
      this.hasBackground ? '' : this.$style.noBg
    ];
  }

  get isTitle() {
    return guardEmptyString(this.title);
  }

  get titleTag() {
    return guardEmptyString(this.link) ? 'a' : 'p';
  }

  @Emit('lazyLoad')
  emitLazyLoad(): void {
    return;
  }

  @Emit('blockShown')
  emitBlockShown(): void {
    return;
  }

  @Emit('headerClicked')
  emitHeaderClicked(): void {
    return;
  }
}
