import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';

import styles from './circle-radio-btn.styles.scss';

type Events = {
  onChange: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      id: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      checked: {
        type: Boolean,
        default: false
      },
      circlePosition: {
        type: String as () => 'default' | 'left',
        default: 'default'
      }
    },
    methods: {
      emitChangeEvent() {
        this.$emit('change');
      }
    },
    render() {
      return (
        <label class={styles.radioBtn} title={this.title} for={this.id}>
          <input
            id={this.id}
            value={this.value}
            name={this.name}
            class={styles.input}
            type="radio"
            checked={this.checked}
            onChange={this.emitChangeEvent}
          />

          <p
            class={[
              styles.text,
              styles[this.circlePosition],
              typography.secondary.bodyMain2.mobile,
              typography.secondary.bodyMain.mobile
            ]}
          >
            {this.$slots.default}
          </p>
        </label>
      );
    }
  });
