import { BaseHttpClient, Config } from '../base-http-client';
import { WidgetAutoParams } from '../params';

import { WidgetAutoOfferDTO } from './widget-auto-dto';

export class WidgetAutoHttpClient extends BaseHttpClient {
  private readonly _widgetAutoOffersUrl = '/public/auto/offers/';

  // eslint-disable-next-line no-useless-constructor
  constructor(config: Config) {
    super(config);
  }

  public async fetchWidgetAutoOffers(
    params: WidgetAutoParams
  ): Promise<WidgetAutoOfferDTO[]> {
    const { count } = params;
    const url = `${this.host}${this._widgetAutoOffersUrl}`;

    const result = await this.httpClient.get<{ offers: WidgetAutoOfferDTO[] }>(url, {
      params: {
        region: this.regionId,
        count
      }
    });

    return result.data.offers;
  }
}
