import { GifAttachDTO } from '@fontanka/news-api-client';
import { GifAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createGifAttach = (attach: GifAttachDTO): GifAttach => {
  const image = createImage(attach.value.previewImage);

  return {
    type: 'gif',
    value: image
      ? {
          ...image,
          author: attach.value.author ? `Фото: ${attach.value.author}` : '',
          description: attach.value.description || '',
          url: attach.value.url
        }
      : null
  };
};
