import { Service } from 'vue-typedi';

import { generateImageUrl } from '@fontanka/news';
import type { BaseStoriesDTO } from '@fontanka/news-api-client';

const LOCAL_STORAGE_NAME = 'portal-stories';

const TIMEOUT_ANIMATION = 200;

type StorySlide = {
  id: number;
  src: string;
  type: 'photo' | 'video';
  link?: {
    url: string;
    title: string;
    target?: '_blank' | '_self';
  };
  seen: boolean;
};

type LocalStorageSlide = Record<number, { seen: boolean }>;

export type Story = {
  id: number;
  imageUrl: string;
  title: string;
  lastUpdated: number;
  seen: boolean;
  slides: StorySlide[];
};

export type LocalStorageStory = {
  seen: boolean;
  slides: LocalStorageSlide;
};

export type LocalStorageData = Record<number, LocalStorageStory>;

@Service()
export class StoriesService {
  public storiesToDO(data: BaseStoriesDTO[]): Story[] {
    const defaultStoriesImageSize = 160;

    return data.map(story => {
      const slides: StorySlide[] = this._getStoriesSlides(story);
      const imageUrl = generateImageUrl(
        story.image,
        true,
        defaultStoriesImageSize,
        defaultStoriesImageSize
      );

      return {
        id: story.id,
        imageUrl,
        title: story.title ?? '',
        lastUpdated: new Date(story.lastUpdated).getTime(),
        seen: false,
        slides
      };
    });
  }

  private _getStoriesSlides(story: BaseStoriesDTO): StorySlide[] {
    return story.slides.map(slide => {
      const src = generateImageUrl(slide.image, false);

      return {
        id: slide.id,
        src,
        type: 'photo',
        link: slide.reference
          ? {
              url: slide.reference.url,
              title: slide.reference.text,
              target: '_self'
            }
          : undefined,
        seen: false
      };
    });
  }

  public getSyncClientData(data: Story[]): Story[] {
    let storiesList = data;
    const storageData = this._getStorageData();

    if (storageData !== null) {
      storiesList = this._mergeStoriesData(storiesList, storageData);
      this.updateStorageStories(storiesList.map(story => story.id));
    }

    return this._sortStoriesList(storiesList);
  }

  private _mergeStoriesData(
    storiesList: Story[],
    storageData: LocalStorageData
  ): Story[] {
    return storiesList.map(story => {
      const storageStory = storageData[story.id];
      let mapStory = {};

      if (storageStory !== undefined) {
        mapStory = {
          seen: storageStory.seen,
          slides: this._mergeSlidesData(story, storageStory)
        };
      }

      return {
        ...story,
        ...mapStory
      };
    });
  }

  private _mergeSlidesData(
    story: Story,
    storageStory: LocalStorageStory
  ): StorySlide[] {
    return story.slides.map(slide => {
      const storageSlide = storageStory.slides[slide.id];
      let storageSlideData = {};

      if (storageSlide !== undefined) {
        storageSlideData = {
          seen: storageSlide.seen
        };
      }

      return {
        ...slide,
        ...storageSlideData
      };
    });
  }

  private _sortStoriesList(stories: Story[]): Story[] {
    const seenStories = stories.filter(story => story.seen);
    const unseenStories = stories.filter(story => !story.seen);

    seenStories.sort((a, b) => b.lastUpdated - a.lastUpdated);
    unseenStories.sort((a, b) => b.lastUpdated - a.lastUpdated);

    return [...unseenStories, ...seenStories];
  }

  public updateStorageStories(actualIdList: number[]): void {
    const storageStories = this._getStorageData();

    if (storageStories !== null) {
      const setActualStories = () => {
        let actualStories: LocalStorageData = {};

        actualStories = Object.keys(storageStories).reduce(
          (acc: LocalStorageData, key) => {
            const storyId = Number(key);
            if (actualIdList.includes(storyId)) {
              acc[storyId] = storageStories[storyId];
            }
            return acc;
          },
          {}
        );

        window.localStorage[LOCAL_STORAGE_NAME] = JSON.stringify(actualStories);
      };

      if ('requestAnimationFrame' in window) {
        requestAnimationFrame(setActualStories);
      } else {
        setTimeout(setActualStories, TIMEOUT_ANIMATION);
      }
    }
  }

  public saveStory(story: Story): void {
    const storyData = this._mapToStorageData(story);
    const storageData = this._getStorageData() ?? {};
    const data = { ...storageData, ...storyData };
    try {
      window.localStorage[LOCAL_STORAGE_NAME] = JSON.stringify(data);
    } catch (e) {
      console.error(e);
    }
  }

  private _getStorageData(): LocalStorageData | null {
    if (typeof window !== 'undefined') {
      const data = window.localStorage[LOCAL_STORAGE_NAME] as string;
      return data ? (JSON.parse(data) as LocalStorageData) : null;
    }
    return null;
  }

  private _mapToStorageData(story: Story): LocalStorageData {
    return {
      [story.id]: {
        seen: story.seen,
        slides: story.slides.reduce((acc: LocalStorageSlide, slide) => {
          acc[slide.id] = {
            seen: slide.seen
          };

          return acc;
        }, {})
      }
    };
  }

  public setStorySlideSeen(
    stories: Story[],
    storyId: number,
    slideId: number
  ): Story[] {
    const story = stories.find((item: Story) => item.id === storyId);
    const slide = story?.slides.find((item: StorySlide) => item.id === slideId);

    if (story && slide) {
      slide.seen = true;

      const isAllStorySlideSeen =
        stories
          .find((item: Story) => item.id === storyId)
          ?.slides.every((item: StorySlide) => item.seen) || false;

      if (isAllStorySlideSeen) {
        story.seen = true;
      }

      this.saveStory(story);
    }

    return this._sortStoriesList(stories);
  }
}
