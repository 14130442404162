const REACH_GOAL_EVENT = 'reachGoal';

export type ReachGoalsParamsType = {
  [key: string]: string | ReachGoalsParamsType;
};

export type VisitParams = unknown;

export class YandexAnalytics {
  public get counterId() {
    return this._counterId;
  }

  private _counterId: number;

  constructor(counterId: number) {
    this._counterId = counterId;
  }

  public sendReachGoal(goalName: string, params?: ReachGoalsParamsType | string) {
    if (typeof window !== 'undefined' && window.ym !== undefined) {
      window.ym(this.counterId, REACH_GOAL_EVENT, goalName, params || null);
    }
  }

  public sendUserParams(params?: ReachGoalsParamsType | string) {
    if (window !== undefined && window.ym !== undefined) {
      window.ym(this.counterId, 'userParams', params);
    }
  }
}
