import type { AxiosRequestConfig } from 'axios';

import { isBrowser } from '@fontanka/browser-or-node';

import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import type { CommentItemDTO, ApiErrorDTO } from '../dtos';
import type { BaseParams } from '../params';
import type { SearchResult } from '../search-http-client';

import type { AllCommentsPageDTO } from './allcomments-page-dto';
import type {
  AnswersDataDTO,
  RecordCommentsPageDTO,
  VotedCommentDTO
} from './record-comments-page-dto';

export type AllCommentsPageParams = BaseParams;

export type RecordCommentsPageParams = BaseParams & {
  day: string;
  id: string;
  month: string;
  year: string;
  sort?: 'asc' | 'desc';
  dateFrom?: number;
};

export type FetchRecordCommentsParams = Pick<
  RecordCommentsPageParams,
  Exclude<keyof RecordCommentsPageParams, 'headers'>
>;

export type FetchCommentAnswersParams = {
  parentId: number;
  dateFrom?: number;
};

export type AddCommentParams = {
  recordId: number;
  text: string;
  nick: string;
  profileId: number;
  parentId?: number;
  captchaToken: string;
};

export type VoteCommentParams = {
  commentId: string;
  vote: 1 | -1;
};

export class CommentsHttpClient extends BaseHttpClient {
  private readonly _allCommentsPageUrl = '/pages/fontanka/comments_news/';

  private readonly _recordCommentsPageUrl = '/pages/fontanka/record';

  private readonly _fetchRecordCommentsUrl = '/public/fontanka/services/comments';

  private readonly _addCommentUrl = '/public/fontanka/services/add_comment';

  private readonly _commentsVoteUrl =
    '/public/fontanka/records/{recordId}/comments/{commentId}/vote/';

  constructor(config: Config) {
    super(config);
  }

  public async fetchAllCommentsPageData(
    params: AllCommentsPageParams
  ): Promise<Result<AllCommentsPageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._allCommentsPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<AllCommentsPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchRecordCommentsPageData(
    params: RecordCommentsPageParams
  ): Promise<Result<RecordCommentsPageDTO>> {
    const { headers, year, month, day, id, sort = 'asc' } = params;
    const url = `${this.host}${this._recordCommentsPageUrl}/${year}/${month}/${day}/${id}/comments/parents`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<RecordCommentsPageDTO>>(
      url,
      {
        headers,
        withCredentials,
        params: {
          regionId: this.regionId,
          sort
        }
      }
    );

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchRecordComments(
    params: FetchRecordCommentsParams
  ): Promise<SearchResult<CommentItemDTO[] | null>> {
    const { year, month, day, id, sort, dateFrom } = params;
    const recordId = `${year}/${month}/${day}/${id}`;
    const url = `${this.host}${this._fetchRecordCommentsUrl}/parents`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<
      SearchResult<CommentItemDTO[] | null>
    >(url, {
      withCredentials,
      params: {
        regionId: this.regionId,
        recordId,
        sort,
        dateFrom
      }
    });

    return response.data;
  }

  public async fetchCommentAnswers(
    params: FetchCommentAnswersParams
  ): Promise<AnswersDataDTO> {
    const { parentId, dateFrom } = params;
    const url = `${this.host}${this._fetchRecordCommentsUrl}/${parentId}/children`;
    const withCredentials = true;

    const response = await this.httpClient.get<AnswersDataDTO>(url, {
      withCredentials,
      params: {
        regionId: this.regionId,
        dateFrom
      }
    });

    return response.data;
  }

  public async sendComment(params: AddCommentParams): Promise<CommentItemDTO> {
    const url = `${this.host}${this._addCommentUrl}`;

    const options: AxiosRequestConfig = {
      withCredentials: true,
      method: 'POST',
      headers: {
        accept: this.accept
      },
      params: {
        regionId: this.regionId
      },
      url,
      data: {
        ...params
      }
    };

    try {
      const result = await this.httpClient(options);
      return result.data.data;
    } catch (err) {
      const ex: ApiErrorDTO = err.response.data;
      throw ex;
    }
  }

  public async voteComment(
    recordId: string,
    params: VoteCommentParams
  ): Promise<VotedCommentDTO> {
    const path = this._commentsVoteUrl
      .replace('{recordId}', recordId)
      .replace('{commentId}', params.commentId);
    const data = {
      regionId: this.regionId,
      vote: params.vote
    };
    const options: AxiosRequestConfig = {
      url: `${this.host}${path}`,
      method: 'PUT',
      headers: {
        accept: 'vnd.news.v1.jtnews+json'
      },
      params: {
        regionId: this.regionId
      },
      data,
      withCredentials: true
    };

    const response = await this.httpClient(options);

    return response.data;
  }
}
