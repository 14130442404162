import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { getSpbDateInfo } from '@fontanka/dates';

import styles from './date-time.styles.scss';

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  data() {
    return {
      data: {
        date: '',
        time: ''
      }
    };
  },
  mounted() {
    this.data = this.getDate();
  },
  methods: {
    getDate() {
      const { day, month, year, hours, minutes } = getSpbDateInfo();

      return {
        date: `${day}.${month}.${year}`,
        time: `${hours}:${minutes}`
      };
    }
  },
  render() {
    return (
      <div class={styles.datetime}>
        <span class={[styles.item, styles.time]}>{this.data.time}</span>
        <span class={styles.item}>{this.data.date}</span>
      </div>
    );
  }
});
