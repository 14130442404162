import cloudyRainIcon from 'images/weather/cloudy/cloudy-rain.svg';
import cloudySnowIcon from 'images/weather/cloudy/cloudy-snow.svg';
import cloudyStormIcon from 'images/weather/cloudy/cloudy-storm.svg';
import cloudyIcon from 'images/weather/cloudy/cloudy.svg';
import sunCloudRainIcon from 'images/weather/day/sun-cloud-rain.svg';
import sunCloudSnowIcon from 'images/weather/day/sun-cloud-snow.svg';
import sunCloudStormIcon from 'images/weather/day/sun-cloud-storm.svg';
import sunCloudIcon from 'images/weather/day/sun-cloud.svg';
import sunIcon from 'images/weather/day/sun.svg';
import mostlyCloudyRainIcon from 'images/weather/mostly-cloudy/mostly-cloudy-rain.svg';
import mostlyCloudySnowIcon from 'images/weather/mostly-cloudy/mostly-cloudy-snow.svg';
import mostlyCloudyStormIcon from 'images/weather/mostly-cloudy/mostly-cloudy-storm.svg';
import mostlyCloudyIcon from 'images/weather/mostly-cloudy/mostly-cloudy.svg';
import moonCloudRainIcon from 'images/weather/night/moon-cloud-rain.svg';
import moonCloudSnowIcon from 'images/weather/night/moon-cloud-snow.svg';
import moonCloudStormIcon from 'images/weather/night/moon-cloud-storm.svg';
import moonCloudIcon from 'images/weather/night/moon-cloud.svg';
import moonIcon from 'images/weather/night/moon.svg';

export const getWeatherIconByType = (type: string): string => {
  const weatherTypeMap = {
    cloudy_none_day: cloudyIcon,
    cloudy_none_night: cloudyIcon,
    cloudy_light_rain_day: cloudyRainIcon,
    cloudy_light_rain_night: cloudyRainIcon,
    cloudy_rain_day: cloudyRainIcon,
    cloudy_rain_night: cloudyRainIcon,
    cloudy_light_snow_day: cloudySnowIcon,
    cloudy_light_snow_night: cloudySnowIcon,
    cloudy_snow_day: cloudySnowIcon,
    cloudy_snow_night: cloudySnowIcon,
    cloudy_thunderstorm_day: cloudyStormIcon,
    cloudy_thunderstorm_night: cloudyStormIcon,

    mostly_cloudy_none_day: mostlyCloudyIcon,
    mostly_cloudy_none_night: mostlyCloudyIcon,
    mostly_cloudy_light_rain_day: mostlyCloudyRainIcon,
    mostly_cloudy_light_rain_night: mostlyCloudyRainIcon,
    mostly_cloudy_rain_day: mostlyCloudyRainIcon,
    mostly_cloudy_rain_night: mostlyCloudyRainIcon,
    mostly_cloudy_light_snow_day: mostlyCloudySnowIcon,
    mostly_cloudy_light_snow_night: mostlyCloudySnowIcon,
    mostly_cloudy_snow_day: mostlyCloudySnowIcon,
    mostly_cloudy_snow_night: mostlyCloudySnowIcon,
    mostly_cloudy_thunderstorm_day: mostlyCloudyStormIcon,
    mostly_cloudy_thunderstorm_night: mostlyCloudyStormIcon,

    sunshine_none_day: sunIcon,
    partly_cloudy_none_day: sunCloudIcon,
    partly_cloudy_light_rain_day: sunCloudRainIcon,
    sunshine_rain_day: sunCloudRainIcon,
    sunshine_light_rain_day: sunCloudRainIcon,
    partly_cloudy_rain_day: sunCloudRainIcon,
    partly_cloudy_light_snow_day: sunCloudSnowIcon,
    partly_cloudy_snow_day: sunCloudSnowIcon,
    sunshine_light_snow_day: sunCloudSnowIcon,
    sunshine_snow_day: sunCloudSnowIcon,
    partly_cloudy_thunderstorm_day: sunCloudStormIcon,

    sunshine_none_night: moonIcon,
    partly_cloudy_none_night: moonCloudIcon,
    partly_cloudy_light_rain_night: moonCloudRainIcon,
    partly_cloudy_rain_night: moonCloudRainIcon,
    sunshine_light_rain_night: moonCloudRainIcon,
    sunshine_rain_night: moonCloudRainIcon,
    partly_cloudy_light_snow_night: moonCloudSnowIcon,
    partly_cloudy_snow_night: moonCloudSnowIcon,
    sunshine_light_snow_night: moonCloudSnowIcon,
    sunshine_snow_night: moonCloudSnowIcon,
    partly_cloudy_thunderstorm_night: moonCloudStormIcon
  };

  return weatherTypeMap[type] ?? '';
};
