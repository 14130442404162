import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import type { TileRecord } from '@fe/common/components';
import { AdaptiveTileItem } from '@fe/common/components';

import styles from './tiles-block.styles.scss';

type Info = {
  first: TileRecord;
  middle: TileRecord[];
  last: TileRecord;
};

type Events = {
  onClickedHeader: void;
  onViewRecord: number;
  onClickedRecord: number;
  onClickedComments: number;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      header: {
        type: String,
        required: true as const
      },
      link: {
        type: String,
        required: true as const
      },
      records: {
        type: Array as () => TileRecord[],
        required: true as const
      },
      hasWebpSupport: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      info(): Info {
        return this.records.reduce(
          (acc, current, index) => {
            if (index === 0) {
              acc.first = current;

              return acc;
            }

            if (index === this.records.length - 1) {
              acc.last = current;

              return acc;
            }

            acc.middle.push(current);

            return acc;
          },
          { middle: [] as TileRecord[] } as Info
        );
      }
    },
    methods: {
      emitClickedHeader() {
        this.$emit('clickedHeader');
      },
      emitViewRecord(recordId: number) {
        this.$emit('viewRecord', recordId);
      },
      emitClickedRecord(recordId: number) {
        this.$emit('clickedRecord', recordId);
      },
      emitClickedComments(recordId: number) {
        this.$emit('clickedComments', recordId);
      }
    },
    render() {
      return (
        <div>
          <a
            class={styles.headerLink}
            href={this.link}
            onClick={() => this.emitClickedHeader()}
          >
            <h3 class={styles.header} domPropsInnerHTML={this.header} />
          </a>

          <div class={styles.list}>
            <div class={[styles.column, styles.listItem]}>
              <AdaptiveTileItem
                class={styles.verticalTile}
                record={this.info.first}
                orientation="vertical"
                size="bigMiddle"
                hasWebpSupport={this.hasWebpSupport}
                onVisible={() => this.emitViewRecord(this.info.first.id)}
                onRecordClicked={() => this.emitClickedRecord(this.info.first.id)}
                onRecordCommentsClicked={() =>
                  this.emitClickedComments(this.info.first.id)
                }
              />
            </div>

            <div class={[styles.middle, styles.listItem]}>
              <div class={[styles.smallTile, styles.mobileOnly]}>
                <AdaptiveTileItem
                  record={this.info.first}
                  orientation="vertical"
                  size="small"
                  hasWebpSupport={this.hasWebpSupport}
                  onVisible={() => this.emitViewRecord(this.info.first.id)}
                  onRecordClicked={() => this.emitClickedRecord(this.info.first.id)}
                  onRecordCommentsClicked={() =>
                    this.emitClickedComments(this.info.first.id)
                  }
                />
              </div>
              {this.info.middle.map(record => (
                <div class={styles.smallTile}>
                  <AdaptiveTileItem
                    record={record}
                    orientation="vertical"
                    size="small"
                    hasWebpSupport={this.hasWebpSupport}
                    onVisible={() => this.emitViewRecord(record.id)}
                    onRecordClicked={() => this.emitClickedRecord(record.id)}
                    onRecordCommentsClicked={() =>
                      this.emitClickedComments(record.id)
                    }
                  />
                </div>
              ))}
              <div class={[styles.smallTile, styles.mobileOnly]}>
                <AdaptiveTileItem
                  record={this.info.last}
                  orientation="vertical"
                  size="small"
                  hasWebpSupport={this.hasWebpSupport}
                  onVisible={() => this.emitViewRecord(this.info.last.id)}
                  onRecordClicked={() => this.emitClickedRecord(this.info.last.id)}
                  onRecordCommentsClicked={() =>
                    this.emitClickedComments(this.info.last.id)
                  }
                />
              </div>
            </div>

            <div class={[styles.column, styles.listItem]}>
              <AdaptiveTileItem
                class={styles.verticalTile}
                record={this.info.last}
                orientation="vertical"
                size="bigMiddle"
                hasWebpSupport={this.hasWebpSupport}
                onVisible={() => this.emitViewRecord(this.info.last.id)}
                onRecordClicked={() => this.emitClickedRecord(this.info.last.id)}
                onRecordCommentsClicked={() =>
                  this.emitClickedComments(this.info.last.id)
                }
              />
            </div>
          </div>
        </div>
      );
    }
  });
