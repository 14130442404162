import { State, Action } from 'vuex-simple';

import type { Author } from '@fontanka/news';

import type { RootModule } from '../../../core';
import type { BaseRecord } from '../../../domain';
import { AuthorPageMapper, AuthorRecordsDataMapper } from '../services';

export const AUTHOR_PAGE_MODULE_NAMESPACE = ['author_page_module'];

export class AuthorPageModule {
  @State()
  public author: Author | null;

  @State()
  public records: BaseRecord[];

  @State()
  public totalRecords = 0;

  private readonly _authorRecordsDataMapper: AuthorRecordsDataMapper;

  private readonly _recordsPageSize = 8;

  /**
   * Начинаем со 2-ой страницы, потому что с сервера придет первая
   */
  private _currentRecordsPage = 2;

  constructor(private _root: RootModule) {
    this._authorRecordsDataMapper = new AuthorRecordsDataMapper(this._root.envType);

    const { author, recordsInfo } = AuthorPageMapper.toDO(this._root.pageSpec);

    this.author = author;
    this.records = recordsInfo.records;
    this.totalRecords = recordsInfo.total;
  }

  @Action()
  async fetchRecords(id: number) {
    const records = await this._authorRecordsDataMapper.getAuthorRecords(
      id,
      this._currentRecordsPage,
      this._recordsPageSize
    );

    this._addRecords(records);
    this._currentRecordsPage = this._currentRecordsPage + 1;
  }

  private _addRecords(records: BaseRecord[]) {
    this.records = [...this.records, ...records];
  }
}
