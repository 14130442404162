import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiAdvLabel } from '@fe/common/ui';
import { getWebpSrc } from '@fontanka/image-sources';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import type { TileHeaderData as TileHeaderDataAlias } from '../tile-header-item';
import { TileHeaderItem } from '../tile-header-item';
import type { TileRecordStats as StatsAlias } from '../tile-record-stats-item';
import { TileRecordStatsItem } from '../tile-record-stats-item';

import styles from './adaptive-tile-item.styles.scss';

type HeaderData = TileHeaderDataAlias;
type Stats = StatsAlias;

type Events = {
  onVisible: void;
  onRecordClicked: void;
  onRecordCommentsClicked: void;
};

export type TileRecord = HeaderData &
  Stats & {
    id: number;
    isCommercial: boolean;
    image: {
      url: string;
    } | null;
    urls: {
      url: string;
    };
    hasMainPhotoCommercialLabel?: boolean;
  };

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'AdaptiveTileItem',
    props: {
      record: {
        type: Object as () => TileRecord,
        required: true as const
      },
      size: {
        type: String as () => 'small' | 'middle' | 'bigMiddle' | 'big',
        default: 'middle'
      },
      orientation: {
        type: String as () => 'vertical' | 'horizontal',
        default: 'vertical'
      },
      hasAnimation: {
        type: Boolean,
        default: false
      },
      hasWebpSupport: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        adaptiveTileVisibility: new ObserverVisibility('half')
      };
    },
    computed: {
      hasCommercialLabel(): boolean {
        return this.record?.hasMainPhotoCommercialLabel ?? false;
      },
      advMarkerInImage(): boolean {
        return (
          this.hasCommercialLabel && this.size !== 'small' && this.size !== 'big'
        );
      },
      advMarkerInTitle(): boolean {
        return this.hasCommercialLabel && !this.advMarkerInImage;
      }
    },
    mounted() {
      this.adaptiveTileVisibility.event.subscribe(state => {
        if (state.value === true) {
          this.$emit('visible');
        }
      });
    },
    beforeDestroy() {
      this.adaptiveTileVisibility.destroy();
    },
    methods: {
      emitRecordClicked() {
        this.$emit('recordClicked');
      },
      emitRecordCommentsClicked() {
        this.$emit('recordCommentsClicked');
      },
      getBackgroundImageUrl(url: string) {
        return this.hasWebpSupport ? getWebpSrc(url) : url;
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.adaptiveTileVisibility.getOptions('half')}
          class={[
            styles.adaptiveTile,
            styles[this.orientation],
            styles[this.hasAnimation ? 'animated' : '']
          ]}
        >
          <div
            class={[styles.bg, this.record.image?.url ? '' : styles.defaultBg]}
            style={
              this.record.image?.url
                ? `background-image: url(${this.getBackgroundImageUrl(
                    this.record.image.url
                  )})`
                : ''
            }
          >
            <div class={styles.bgBlackout} />
          </div>
          <TileRecordStatsItem
            stats={this.record}
            onRecordCommentsClicked={this.emitRecordCommentsClicked}
          />
          <a
            class={styles.link}
            href={this.record.urls.url}
            title=""
            onClick={this.emitRecordClicked}
          >
            <div
              class={[
                styles.imageWrapper,
                styles[this.orientation],
                styles[this.size],
                this.record.image?.url ? '' : styles.defaultImage
              ]}
            >
              <div
                class={styles.image}
                style={
                  this.record.image?.url
                    ? `background-image: url(${this.getBackgroundImageUrl(
                        this.record.image.url
                      )})`
                    : ''
                }
              />
              {this.advMarkerInImage && <UiAdvLabel class={styles.advMarker} />}
            </div>
          </a>
          <a
            class={styles.link}
            href={this.record.urls.url}
            title=""
            onClick={this.emitRecordClicked}
          >
            <TileHeaderItem
              class={[
                styles.headerWrapper,
                styles[this.size],
                styles[this.orientation]
              ]}
              data={this.record}
              size={this.size}
              orientation={this.orientation}
              hasAnimation={this.hasAnimation}
              hasAdvMarker={this.advMarkerInTitle}
            />
          </a>
        </div>
      );
    }
  });
