import { componentFactoryOf } from 'vue-tsx-support';
import type {
  SyntheticEvent,
  SelectHTMLAttributes
} from 'vue-tsx-support/types/dom';

import { injectStylesMixin } from '@apps/frontend';

import styles from './form-select.styles.scss';

type Option = {
  id: string;
  name: string;
};

type Events = {
  onOptionChanged: 'all' | string;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      currentOptionId: {
        type: String as () => 'all' | string,
        required: true as const,
        default: 'all'
      },
      options: {
        type: Array as () => Option[],
        required: true as const,
        default: []
      }
    },
    methods: {
      optionChanged(e: SyntheticEvent<SelectHTMLAttributes, Event>) {
        this.$emit('optionChanged', e.target ? e.target.value : 'all');
      }
    },
    render() {
      return (
        <div class={styles.selectWrapper}>
          <select class={styles.select} onChange={this.optionChanged}>
            {this.options.map((option, index) =>
              index !== 0 ? (
                <option
                  selected={option.id === this.currentOptionId}
                  value={option.id}
                  domPropsInnerHTML={`&nbsp;&nbsp;&nbsp;${option.name}`}
                />
              ) : (
                <option
                  selected={option.id === this.currentOptionId}
                  value={option.id}
                  domPropsInnerHTML={option.name}
                />
              )
            )}
          </select>
          <div class={styles.arrow}></div>
        </div>
      );
    }
  });
