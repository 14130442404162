import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import type { CompanyNewsRecord } from '@fe/common/domain';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import { BaseSliderBlockWillDeprecate } from '../../_blocks';
import { SliderVerticalItemWillDeprecate } from '../../_items';

import styles from './companies-news-big.styles.scss';

type DirectionSlide = 'prev' | 'next';

type ComponentData = {
  blockVisibility: ObserverVisibility;
};

type Events = {
  onClickedHeader: void;
  onChangedSlide: DirectionSlide;
  onViewRecord: number;
  onClickedRecord: number;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      records: {
        type: Array as () => CompanyNewsRecord[],
        required: true as const
      }
    },
    data(): ComponentData {
      return {
        blockVisibility: new ObserverVisibility('half')
      };
    },
    mounted() {
      this.blockVisibility.event.subscribe(({ value, target }) => {
        if (value) {
          const targetElement = target as HTMLElement;
          const recordId = targetElement.dataset.recordId || '';
          this.emitViewRecord(Number(recordId));
        }
      });
    },
    beforeDestroy() {
      this.blockVisibility.destroy();
    },
    methods: {
      emitClickedHeader() {
        this.$emit('clickedHeader');
      },
      emitChangedSlide(direction: DirectionSlide) {
        this.$emit('changedSlide', direction);
      },
      emitViewRecord(recordId: number) {
        this.$emit('viewRecord', recordId);
      },
      emitClickedRecord(recordId: number) {
        this.$emit('clickedRecord', recordId);
      }
    },
    render() {
      return (
        <BaseSliderBlockWillDeprecate
          header="Новости компаний"
          link="/pressrelease/"
          slidesCount={this.records.length}
          onClickedHeader={this.emitClickedHeader}
          onChangedSlide={this.emitChangedSlide}
        >
          {this.records.map(sliderItem => (
            <div
              v-observe-visibility={this.blockVisibility.getOptions('half')}
              class={styles.sliderItem}
              data-record-id={sliderItem.id}
            >
              <SliderVerticalItemWillDeprecate
                url={sliderItem.urls.url}
                header={sliderItem.header}
                image={sliderItem.image || null}
                publishAt={sliderItem.publishAt}
                text={sliderItem.lead}
                hasCommercialLabel={sliderItem.hasMainPhotoCommercialLabel}
                onClickedRecord={() => this.emitClickedRecord(sliderItem.id)}
              />
            </div>
          ))}
        </BaseSliderBlockWillDeprecate>
      );
    }
  });
