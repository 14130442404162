type ParamsUrl = {
  [key: string]: string;
};

export const isAbsolutePath = (url: string) => /^(http(s)?|ftp):\/\//.exec(url);

export const addUrlParams = (url: string, params: ParamsUrl = {}): string => {
  const absoluteUrl = isAbsolutePath(url) ? url : `http://fontanka.ru/${url}`;
  const queryStringStart = new URL(absoluteUrl).search === '' ? '?' : '&';

  const queryString = Object.entries(params)
    .map(entry => `${entry[0]}=${entry[1]}`)
    .join('&');
  return `${url}${queryStringStart}${queryString}`;
};
