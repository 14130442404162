import { isBrowser } from '@fontanka/browser-or-node';
import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { BaseParams } from '../params';
import { ThemePageDTO } from './theme-page-dto';
import {
  SearchHttpClient,
  SearchParams
} from '../search-http-client/search-http-client';

export type ThemePageParams = BaseParams & {
  theme: string;
  page: number;
  pagesize: number;
};

export type ThemesParams = Pick<
  SearchParams,
  Exclude<keyof SearchParams, 'theme' | 'format'>
> & {
  theme: string;
};

export class ThemesHttpClient extends BaseHttpClient {
  private readonly _searchHttpClient: SearchHttpClient;

  private readonly _themePageUrl = '/pages/fontanka/themes/';

  constructor(config: Config) {
    super(config);

    this._searchHttpClient = new SearchHttpClient(config);
  }

  public async fetchThemePageData(
    params: ThemePageParams
  ): Promise<Result<ThemePageDTO>> {
    const { theme, page, pagesize, headers } = params;
    const url = `${this.host}${this._themePageUrl}${theme}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<ThemePageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        page,
        pagesize
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchThemeRecords(params: ThemesParams) {
    return this._searchHttpClient.searchRecords(params);
  }
}
