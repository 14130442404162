import { Action, State } from 'vuex-simple';

import { getYearsRange } from '@fontanka/dates';
import type { CalendarArchivePageDTO } from '@fontanka/news-api-client';

import type { RootModule } from '../../../../core';
import type { SearchRubrics } from '../../../../domain';
import { SearchRubricsMapper } from '../../../../services';
import type {
  Calendar as CalendarAlias,
  PageUrlParams as PageUrlParamsAlias
} from '../../domain/calendar-archive';
import { getPageUrl, getFilterLinks } from '../../domain/calendar-archive';
import type { FilterLink } from '../../domain/filter-link';
import type { CalendarArchiveData as CalendarArchiveDataAlias } from '../../services/calendar-archive-mapper.service';
import { CalendarArchiveMapper } from '../../services/calendar-archive-mapper.service';
import { CalendarMapper } from '../../services/calendar-mapper.service';

type CalendarArchiveData = CalendarArchiveDataAlias;
type Calendar = CalendarAlias;
type PageUrlParams = PageUrlParamsAlias;

type ArchiveParams = {
  year: string;
  rubric: string;
  type: FilterLink['type'];
};

export const CALENDAR_ARCHIVE_MODULE_NAMESPACE = ['calendar_archive_module'];

export class CalendarArchiveModule {
  @State()
  public filterYears = getYearsRange().map(year => year.toString());

  @State()
  public filterLinks: FilterLink[];

  @State()
  public rubrics: SearchRubrics;

  @State()
  public calendar: Calendar;

  @State()
  public blocks: CalendarArchiveData['blocks'];

  constructor(private _root: RootModule, params: ArchiveParams) {
    this.rubrics = SearchRubricsMapper.toDO(this._root.pageSpec);

    this._updatePageData(this._root.pageSpec, params);
  }

  @Action()
  navigate(params: PageUrlParams) {
    location.href = getPageUrl(params);
  }

  private _updatePageData(pageDTO: CalendarArchivePageDTO, params: ArchiveParams) {
    const { rubric, type, year } = params;

    this.filterLinks = getFilterLinks(year, rubric);

    const { calendar, blocks } = CalendarArchiveMapper.toDO(pageDTO);
    this.blocks = blocks;

    if (calendar !== null) {
      this.calendar = CalendarMapper.toDO({
        calendarDTO: calendar,
        type,
        year,
        rubric
      });
    }
  }
}
