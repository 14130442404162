import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import { UiAdvLabel } from '@fe/common/ui';
import { stripTags } from '@fontanka/news';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import { RubricCommentsItem } from '../rubric-comments-item';
import { RubricRecordHeaderItem } from '../rubric-record-header-item';
import { RubricRecordStatsItem } from '../rubric-record-stats-item';

import styles from './rubric-image-record-item.styles.scss';

type ComponentData = {
  recordVisibility: ObserverVisibility;
};

export type RubricImageRecord = {
  image: {
    url: string;
    description: string;
    sources: {
      srcset: string;
      type: string;
    }[];
  } | null;
  header: string;
  leadStr: string;
  urls: {
    url: string;
    urlComments: string;
  };
  commentsCount: number;
  isCommentsAllowed: boolean;
  isCommercial: boolean;
  canShowComments: boolean;
  hasPhoto: boolean;
  hasVideo: boolean;
  hasAudio: boolean;
  publishDate: string;
  id: number;
  hasMainPhotoCommercialLabel?: boolean;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'RubricImageRecordItem',
  props: {
    record: {
      type: Object as () => RubricImageRecord,
      required: true as const
    }
  },
  data(): ComponentData {
    return {
      recordVisibility: new ObserverVisibility('half')
    };
  },
  mounted() {
    this.recordVisibility.event.subscribe(state => {
      if (state.value) {
        this.$emit('viewRecord', {
          recordId: this.record.id,
          isCommercial: this.record.isCommercial,
          hasImage: this.record.image !== null
        });
      }
    });
  },
  beforeDestroy() {
    this.recordVisibility.destroy();
  },
  methods: {
    emitCommentsClicked(): void {
      this.$emit('commentsClicked', {
        recordId: this.record.id,
        isCommercial: this.record.isCommercial
      });
    },
    emitHeaderClicked(): void {
      this.$emit('headerClicked', {
        recordId: this.record.id,
        isCommercial: this.record.isCommercial
      });
    }
  },
  render() {
    return (
      <article
        v-observe-visibility={this.recordVisibility.getOptions('half')}
        class={styles.record}
      >
        {this.record.image !== null && (
          <a
            class={styles.imgLink}
            href={this.record.urls.url}
            title={stripTags(this.record.header)}
          >
            <UiImage class={styles.imageWrap} sources={this.record.image.sources}>
              <img
                class={styles.image}
                src={this.record.image.url}
                alt={this.record.image.description}
              />
            </UiImage>
          </a>
        )}

        <div class={styles.infoWrap}>
          <RubricRecordStatsItem record={this.record} />

          <RubricRecordHeaderItem
            record={this.record}
            onHeaderClicked={this.emitHeaderClicked}
          />

          {this.record.leadStr !== '' && <br />}
          {this.record.leadStr !== '' && <br />}

          {this.record.leadStr !== '' && (
            <a
              class={styles.leadLink}
              href={this.record.urls.url}
              domPropsInnerHTML={this.record.leadStr}
            />
          )}

          {this.record.canShowComments && (
            <RubricCommentsItem
              class={styles.comments}
              record={this.record}
              onCommentsClicked={this.emitCommentsClicked}
            />
          )}

          {this.record.hasMainPhotoCommercialLabel && (
            <UiAdvLabel hasFrame={false} class={styles.advMarker} />
          )}
        </div>
      </article>
    );
  }
});
