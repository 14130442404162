import { Service } from 'vue-typedi';

import type { AuthSocialData } from '@fe/common/domain';
import type {
  BasePageDTO,
  CommonConfigDTO,
  ArchiveLinksDTO,
  HotNewsDTO,
  CommonProjectsDTO,
  CommonThemesDTO,
  CommonCompanyNewsDTO,
  CommonCalendarDTO,
  SearchRubricsDTO,
  CovidWidgetDTO,
  OneSignalConfigBlockDTO,
  CommonStoriesDTO,
  FeedbackDTO,
  CommonAuthDTO,
  AuthBlockDTO,
  CommonFontankaProDTO
} from '@fontanka/news-api-client';

import {
  BaseRecordMapper,
  CovidWidgetMapper,
  FontankaProArticleMapper
} from '../../services';
import type { CommonInfo } from '../domain';

import { CompaniesNewsMapper } from './companies-news-mapper.service';

const CALENDAR_IMAGE_WIDTH = 800;
const FONTANKA_PRO_IMAGE_WIDTH = CALENDAR_IMAGE_WIDTH;
const COMPANY_NEWS_IMAGE_WIDTH = CALENDAR_IMAGE_WIDTH;
const INITIAL_SOCIAL_LIST: AuthSocialData[] = [
  {
    name: 'vk',
    id: 'vk',
    link: '',
    fill: '#4A76A8',
    reserveFill: '#4A76A8'
  },
  {
    name: 'ok',
    id: 'ok',
    link: '',
    fill: '#FFFFFF',
    reserveFill: '#EE8208'
  },
  {
    name: 'google',
    id: 'google',
    link: '',
    fill: 'none',
    reserveFill: 'none'
  },
  {
    name: 'yandex',
    id: 'ya',
    link: '',
    fill: '#FFFFFF',
    reserveFill: '#FF0000'
  },
  {
    name: 'fb',
    id: 'fb',
    link: '',
    fill: '',
    reserveFill: ''
  }
];
const RESTRICTED_SOCIALS = ['fb'];

@Service()
export class CommonInfoMapper {
  public static toDO(
    config: CommonConfigDTO,
    specData: BasePageDTO['data']
  ): CommonInfo {
    return Object.entries(config).reduce((acc, current) => {
      if (current[1]) {
        const blockConfig = current[1];
        const blockType = blockConfig.type;

        if (blockType === 'archive_links') {
          const { data } = specData[blockConfig.path] as ArchiveLinksDTO;
          return {
            ...acc,
            archiveLinks: {
              data
            }
          };
        }

        if (blockType === 'company_news_bottom') {
          const { data } = specData[blockConfig.path] as CommonCompanyNewsDTO;
          return {
            ...acc,
            companyNews: {
              data:
                data?.map(record =>
                  CompaniesNewsMapper.toDO(record, {
                    width: COMPANY_NEWS_IMAGE_WIDTH
                  })
                ) ?? []
            }
          };
        }

        if (blockType === 'hot_news') {
          const { data } = specData[blockConfig.path] as HotNewsDTO;
          return {
            ...acc,
            hotNews: {
              data
            }
          };
        }

        if (blockType === 'projects') {
          const { data } = specData[blockConfig.path] as CommonProjectsDTO;
          return {
            ...acc,
            projects: {
              data
            }
          };
        }

        if (blockType === 'themes') {
          const { data } = specData[blockConfig.path] as CommonThemesDTO;
          return {
            ...acc,
            themes: {
              data
            }
          };
        }

        if (blockType === 'calendar') {
          const { data } = specData[blockConfig.path] as CommonCalendarDTO;
          return {
            ...acc,
            calendar: {
              data:
                data?.map(record =>
                  BaseRecordMapper.toDO(record, {
                    width: CALENDAR_IMAGE_WIDTH
                  })
                ) ?? []
            }
          };
        }

        if (blockType === 'fontanka_pro') {
          const { data } = specData[blockConfig.path] as CommonFontankaProDTO;
          return {
            ...acc,
            fontankaPro: {
              data:
                data?.map(record =>
                  FontankaProArticleMapper.execute(record, {
                    width: FONTANKA_PRO_IMAGE_WIDTH
                  })
                ) ?? []
            }
          };
        }

        if (blockType === 'search_rubrics') {
          const { data } = specData[blockConfig.path] as SearchRubricsDTO;
          return {
            ...acc,
            searchRubrics: {
              data
            }
          };
        }

        if (blockType === 'covid19_statistics') {
          const { data } = specData[blockConfig.path] as CovidWidgetDTO;
          return {
            ...acc,
            covidWidget: {
              data: CovidWidgetMapper.toDO(data)
            }
          };
        }

        if (blockType === 'onesignal_config') {
          const { data } = specData[blockConfig.path] as OneSignalConfigBlockDTO;
          return {
            ...acc,
            oneSignal: {
              data
            }
          };
        }

        if (blockType === 'stories') {
          const { data } = specData[blockConfig.path] as CommonStoriesDTO;
          return {
            ...acc,
            stories: {
              data
            }
          };
        }
        if (blockType === 'editorial_office') {
          const { data } = specData[blockConfig.path] as FeedbackDTO;
          return {
            ...acc,
            editorialOffice: {
              data
            }
          };
        }

        if (blockType === 'auth') {
          const { data: dataDTO } = specData[blockConfig.path] as CommonAuthDTO;
          const {
            rules = '',
            terms: termsOfUseBlog = '',
            offer: publicOffer = '',
            register = '',
            recover = '',
            personalData = ''
          } = dataDTO ?? {};

          const data = {
            rules,
            termsOfUseBlog,
            publicOffer,
            register,
            recover,
            personalData,
            socialList: this._getSocialList(dataDTO)
          };

          return {
            ...acc,
            auth: {
              data
            }
          };
        }
      }

      return acc;
    }, {} as CommonInfo);
  }

  private static _getSocialList(block: AuthBlockDTO | null): AuthSocialData[] {
    if (block === null) {
      return [];
    }

    const socials: AuthSocialData[] = [];
    return INITIAL_SOCIAL_LIST.reduce((acc, item) => {
      if (block[item.id]?.length > 0) {
        acc.push({
          ...item,
          link: this._presentSocialLink(block[item.id])
        });
      }
      return acc;
    }, socials).sort((a, b) => {
      if (RESTRICTED_SOCIALS.includes(a.id)) {
        return 1;
      }
      if (RESTRICTED_SOCIALS.includes(b.id)) {
        return -1;
      }
      return 0;
    });
  }

  private static _presentSocialLink(link: string): string {
    return typeof window !== 'undefined'
      ? link.replace('%CURRENT_URL%', window.location.href)
      : link;
  }
}
