if (process.env.NO_CDN !== 'true') {
  const cdnHost = 'https://static.ngs.ru';

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    __webpack_public_path__ = `${cdnHost}${window.envType}/fontanka/dist/legacy/`;
  }
}

// Indicate to webpack that this file can be concatenated
export default null;
