import { guardEmptyString } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';

import FntEyeSmallIcon from '~icons/fnt-common/eye-small';
import FntRepliesToCommentsIcon from '~icons/fnt-common/replies-to-comments';
import FntReplyToCommentIcon from '~icons/fnt-common/reply-to-comment';

import styles from './record-statistics.styles.scss';

type Events = {
  onCommentsClicked: Event;
  onDiscussClicked: Event;
};

const ICON_SIZE = 16;
const ICON_STROKE_WIDTH = 1.5;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'JtnUiRecordStatistics',
    props: {
      commentsUrl: {
        type: String,
        default: ''
      },
      discussUrl: {
        type: String,
        default: ''
      },
      commentsCount: {
        type: Number,
        default: 0
      },
      isCommentsAllowed: {
        type: Boolean,
        default: false
      },
      viewsCount: {
        type: Number,
        default: 0
      },
      target: {
        type: String
      }
    },
    methods: {
      formatNumber(count: number | string) {
        return count.toLocaleString('ru-RU');
      }
    },
    render() {
      return (
        <div class={styles.statisticBlock}>
          <span class={styles.statistic} data-test="record-stats-view">
            <FntEyeSmallIcon
              class={styles.statisticIcon}
              width={ICON_SIZE}
              height={ICON_SIZE}
              stroke-width={ICON_STROKE_WIDTH}
            />
            <span
              class={[styles.statisticValue, typography.primary.subtitle2.mobile]}
            >
              {this.formatNumber(this.viewsCount)}
            </span>
          </span>
          {this.isCommentsAllowed &&
            (this.commentsCount > 0
              ? guardEmptyString(this.commentsUrl) && (
                  <a
                    href={this.commentsUrl}
                    class={styles.statistic}
                    data-test="record-stats-comments-count"
                    target={this.target}
                    onClick={(event: Event) => this.$emit('commentsClicked', event)}
                  >
                    <FntRepliesToCommentsIcon
                      class={styles.statisticIcon}
                      width={ICON_SIZE}
                      height={ICON_SIZE}
                      stroke-width={ICON_STROKE_WIDTH}
                    />
                    <span
                      class={[
                        styles.statisticValue,
                        typography.primary.subtitle2.mobile
                      ]}
                    >
                      {this.formatNumber(this.commentsCount)}
                    </span>
                  </a>
                )
              : guardEmptyString(this.discussUrl) && (
                  <a
                    href={this.discussUrl}
                    class={[styles.statistic, styles.discuss]}
                    data-test="record-stats-comments-discuss"
                    target={this.target}
                    onClick={(event: Event) => this.$emit('discussClicked', event)}
                  >
                    <FntReplyToCommentIcon
                      class={styles.statisticIcon}
                      width={ICON_SIZE}
                      height={ICON_SIZE}
                      stroke-width={ICON_STROKE_WIDTH}
                    />
                    <span
                      class={[
                        styles.statisticValue,
                        styles.text,
                        typography.primary.subtitle2.mobile
                      ]}
                    >
                      Обсудить
                    </span>
                  </a>
                ))}
        </div>
      );
    }
  });
