

























import { guardUnspecified } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiAdvLabel } from '../../ui-adv-label';
import { UiImage } from '../../ui-image';
import { UiLabel } from '../../ui-label';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  TaxonomyClickEvent,
  NewsPhotoImage,
  NewsPhotoStatistic,
  NewsPhotoLabel
} from './ui-news-photo.contract';

@Component({
  components: {
    UiLabel,
    UiStatistic,
    UiObserveVisibility,
    UiImage,
    UiNewsCardLabels,
    UiAdvLabel
  },
  name: 'UiNewsPhoto'
})
export default class UiNewsPhoto extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsPhotoImage | null,
    default: null
  })
  image: NewsPhotoImage;

  @Prop({
    type: Object as () => NewsPhotoStatistic,
    required: true
  })
  statistic!: NewsPhotoStatistic;

  @Prop({
    type: Object as () => NewsPhotoLabel,
    default: null
  })
  label: NewsPhotoLabel;

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasCommercialLabel: boolean;

  get hasLabel(): boolean {
    return guardUnspecified(this.label);
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('taxonomyClicked')
  taxonomyClicked(event: TaxonomyClickEvent): TaxonomyClickEvent {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(place: string): string {
    return place;
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }
}
