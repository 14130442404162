












































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import { UiAdvLabel } from '../ui-adv-label';
import { UiBaseBlock } from '../ui-base-block';
import { UiImage } from '../ui-image';
import { UiNewsPhoto } from '../ui-news-card';
import { UiObserveVisibility } from '../ui-observe-visibility';
import { UiStatistic } from '../ui-statistic';

import type {
  UiNewsBarEmitValue,
  UiNewsBarRecordsContract
} from './ui-news-bar.contract';

@Component({
  name: 'UiNewsBar',
  components: {
    UiStatistic,
    UiImage,
    UiNewsPhoto,
    UiBaseBlock,
    UiObserveVisibility,
    UiAdvLabel
  }
})
export default class UiNewsBar extends Vue {
  @Prop({
    type: String,
    default: ''
  })
  title!: string;
  @Prop({
    type: String,
    default: ''
  })
  link!: string;

  @Prop({
    type: Array,
    required: true
  })
  records!: UiNewsBarRecordsContract;

  @Prop({
    type: Boolean,
    default: true
  })
  hasBtn: boolean;

  @Emit('blockShown')
  blockShown(): void {
    return;
  }

  @Emit('articleShown')
  articleShown(event: UiNewsBarEmitValue): UiNewsBarEmitValue {
    return event;
  }

  @Emit('headerClicked')
  headerClicked(): void {
    return;
  }

  @Emit('articleClicked')
  articleClicked(event: UiNewsBarEmitValue): UiNewsBarEmitValue {
    return event;
  }
}
