/* eslint-disable @typescript-eslint/naming-convention */
import { Portal, PortalTarget } from 'portal-vue';
import { PortalProps, PortalTargetProps } from 'portal-vue/types/lib/types';
import { ofType } from 'vue-tsx-support';

const VuePortal = ofType<PortalProps>().convert(Portal);

interface PortalTarget {
  name: string;
}

const VuePortalTarget = ofType<PortalTargetProps>().convert(PortalTarget);

export { VuePortal, VuePortalTarget };
