import { AppendAttachDTO } from '@fontanka/news-api-client';
import { AppendAttach } from '../../../domain';

export const createAppendAttach = (attach: AppendAttachDTO): AppendAttach => ({
  type: attach.type,
  value: {
    content: attach.media.content,
    type: attach.media.img
  }
});
