import type {
  NewMainCentralColumnGrid,
  NewsMainLeftColumnGrid,
  NewMainPageGrid
} from './grid';

export const getNewMainDesktopCentralColumnGrid = (
  hasSupercover: boolean
): NewMainCentralColumnGrid[] => {
  const window: NewMainCentralColumnGrid = {
    type: 'window',
    index: 0,
    class: 'gridOneThirdLaptop'
  };
  const pictureOfTheDay: NewMainCentralColumnGrid = {
    type: 'picture_of_the_day_block',
    class: 'gridTwoThirdLaptop'
  };
  const list: NewMainCentralColumnGrid[] = [
    {
      type: 'window-wrapper',
      class: 'gridTwoThirdLaptop',
      indexes: [1, 2]
    },
    {
      type: 'fontanka_pro',
      blockType: 'slider',
      class: 'gridOneThirdLaptop'
    },
    {
      type: 'rubrics',
      class: 'gridFullLaptop'
    },
    {
      type: 'single_card',
      class: 'gridOneThirdLaptop'
    },
    {
      type: 'window-wrapper',
      class: 'gridTwoThirdLaptop',
      indexes: [3, 4]
    },
    {
      type: 'adv',
      id: 11615,
      class: 'gridTwoThirdLaptop'
    },
    {
      type: 'adv',
      id: 11618,
      class: 'gridOneThirdLaptop'
    },
    {
      type: 'window',
      class: 'gridOneThirdLaptop',
      index: 5
    },
    {
      type: 'window',
      class: 'gridOneThirdLaptop',
      index: 6
    },
    {
      type: 'window',
      class: 'gridOneThirdLaptop',
      index: 7
    },
    {
      type: 'window-wrapper',
      class: 'gridTwoThirdLaptop',
      indexes: [8, 9]
    },
    {
      type: 'newest_opinion',
      class: 'gridOneThirdLaptop'
    },
    {
      type: 'editorial_office',
      class: 'gridOneThirdLaptop'
    },
    {
      type: 'window',
      class: 'gridOneThirdLaptop',
      index: 10
    },
    {
      type: 'our_collection',
      class: 'gridOneThirdLaptop',
      index: 0
    },
    {
      type: 'calendar',
      class: 'gridFullLaptop'
    },
    {
      type: 'adv',
      id: 11621,
      class: 'gridFullLaptop'
    },
    {
      type: 'weekend',
      class: 'gridTwoThirdLaptop'
    },
    {
      type: 'our_collection',
      class: 'gridOneThirdLaptop',
      index: 1
    },
    {
      type: 'recommendation',
      class: 'gridTwoThirdLaptop'
    },
    {
      type: 'our_collection',
      class: 'gridOneThirdLaptop',
      index: 2
    },
    {
      type: 'company_news_bottom',
      class: 'gridFullLaptop'
    },
    {
      type: 'fontanka_pro',
      blockType: 'bar',
      class: 'gridFullLaptop'
    }
  ];

  if (hasSupercover) {
    const supercover: NewMainCentralColumnGrid = {
      type: 'supercover',
      class: 'gridFullLaptop'
    };

    return [supercover, pictureOfTheDay, window, ...list];
  }

  return [window, pictureOfTheDay, ...list];
};

export const newMainMobileCentralColumn: NewMainCentralColumnGrid[] = [
  {
    type: 'supercover',
    class: 'gridFullMobile'
  },
  {
    type: 'picture_of_the_day_block',
    class: 'gridFullMobile'
  },
  {
    type: 'informer',
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 0,
    class: 'gridFullMobile'
  },
  {
    type: 'adv',
    id: 11630,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 1,
    class: 'gridFullMobile'
  },
  {
    type: 'fontanka_pro',
    blockType: 'slider',
    class: 'gridFullMobile'
  },
  {
    type: 'recent_opinions',
    class: 'gridFullMobile'
  },
  {
    type: 'news_feed',
    class: 'gridFullMobile'
  },
  {
    type: 'adv',
    id: 11633,
    class: 'gridFullMobile'
  },
  {
    type: 'rubrics',
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 2,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 3,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 4,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 5,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 6,
    class: 'gridFullMobile'
  },
  {
    type: 'adv',
    id: 11636,
    class: 'gridFullMobile'
  },
  {
    type: 'single_card',
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 7,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 8,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 9,
    class: 'gridFullMobile'
  },
  {
    type: 'window',
    index: 10,
    class: 'gridFullMobile'
  },
  {
    type: 'newest_opinion',
    class: 'gridFullMobile'
  },
  {
    type: 'adv',
    id: 11639,
    class: 'gridFullMobile'
  },
  {
    type: 'calendar',
    class: 'gridFullMobile'
  },
  {
    type: 'company_news_bottom',
    class: 'gridFullMobile'
  },
  {
    type: 'adv',
    id: 11642,
    class: 'gridFullMobile'
  },
  {
    type: 'fontanka_pro',
    blockType: 'slider-mobile',
    class: 'gridFullMobile'
  },
  {
    type: 'editorial_office',
    class: 'gridFullMobile'
  },
  {
    type: 'weekend',
    class: 'gridFullMobile'
  },
  {
    type: 'adv',
    id: 11642,
    class: 'gridFullMobile'
  },
  {
    type: 'recommendation',
    class: 'gridFullMobile'
  }
];

export const newMainDesktopLeftColumnGrid: NewsMainLeftColumnGrid[] = [
  {
    type: 'news-feed',
    index: 0,
    class: 'gridFullDesktop'
  },
  {
    type: 'news-feed',
    index: 1,
    class: 'gridFullDesktop'
  },
  {
    type: 'news-feed',
    index: 2,
    class: 'gridFullDesktop'
  },
  {
    type: 'adv',
    id: 11191,
    class: 'gridFullMobile'
  }
];

export const getNewMainPageGrid = (input: {
  isMobile: boolean;
  hasSupercover: boolean;
}): NewMainPageGrid => {
  const { isMobile, hasSupercover } = input;

  return {
    left: {
      data: isMobile ? [] : newMainDesktopLeftColumnGrid
    },
    central: {
      data: isMobile
        ? newMainMobileCentralColumn
        : getNewMainDesktopCentralColumnGrid(hasSupercover)
    }
  };
};
