export const COMMENTS_ROUTING = [
  {
    path: '/allcomments.html',
    name: 'allComments',
    component: () =>
      import(
        /* webpackChunkName: "allcomments-page" */ './pages/allcomments/allcomments.page'
      )
  },
  {
    path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:id(\\d+)/all.comments.html',
    name: 'comments',
    component: () =>
      import(
        /* webpackChunkName: "record-comments-page" */ './pages/record-comments/record-comments.page'
      )
  },
  {
    path: `/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:id(\\d+)/comments/`,
    name: 'commentsUMP',
    component: () =>
      import(
        /* webpackChunkName: "record-comments-page" */ './pages/record-comments/record-comments.page'
      )
  }
];
