






import { guardUnspecified } from '@portal/utils/util-guards';
import { Component, Prop, Vue, Watch, Emit } from 'vue-property-decorator';

import { LazyHydrateSsr } from '../lazy-hydrate-ssr';

import type { Creative, ICreativeManager, ISlot } from './creative';
import { PLACEMENTS, SlotStatus } from './creative';

type CreativeStatus = 'displayed' | 'stub' | 'closed' | 'error' | '';

const REPEAT_BLOCKS = [
  PLACEMENTS.VB_RIGHT_5,
  PLACEMENTS.INLINE,
  PLACEMENTS.HB_MID_1
];

const EXCLUDED_ADV_FOR_REFERRER = [
  PLACEMENTS.STICKY,
  PLACEMENTS.STICKY_RSYA,
  PLACEMENTS.FLYROLL
];

const UNALLOWED_ADV_REFERRERS = [
  'iframe-toloka.com',
  'raterhub.com',
  'iframe-tasks.yandex',
  'iframe-yang.yandex',
  'yandex-team.ru'
];

@Component({
  name: 'AdvSlot',
  components: {
    LazyHydrateSsr
  }
})
export default class AdvSlot extends Vue {
  @Prop({
    required: true,
    type: Boolean
  })
  isMobile: boolean;
  @Prop({
    required: true,
    type: Object
  })
  creative: Creative;
  @Prop({
    type: Number,
    default: undefined
  })
  index: number;
  @Prop({
    type: Boolean,
    default: false
  })
  clientRender: boolean;
  @Prop({
    type: String,
    default: undefined
  })
  blockerCookie: string;

  creativeManager: ICreativeManager | null = null;
  creativeSlot: ISlot | null = null;
  observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 1
  };
  observer: IntersectionObserver | null = null;
  isViewPort = false;
  refreshInterval: NodeJS.Timer | undefined = undefined;
  status: CreativeStatus = '';

  @Watch('isViewPort')
  isViewPortChanged(value: boolean) {
    if (this.creative.refreshEnabled) {
      if (value) {
        this.setRefreshTimeout();
      } else {
        this.clearRefreshTimeout();
      }
    }
  }

  get placement() {
    return this.creative.placement || '';
  }

  get containerId() {
    const suffix = this.creative.isMobile ? '_m' : '_d';

    if (
      REPEAT_BLOCKS.includes(this.placement as PLACEMENTS) &&
      guardUnspecified(this.index)
    ) {
      return `${this.placement}${suffix}-${this.index}`;
    }

    return `${this.placement}${suffix}`;
  }

  get refreshIntervalStep() {
    return this.creative.refreshConfig.refreshInterval || 0;
  }

  get customCreativeParams(): { type?: 'fullscreen' | 'floorAd' } {
    if (this.placement === PLACEMENTS.STICKY_RSYA) {
      return {
        type: 'floorAd'
      };
    }

    return {};
  }

  get creativeHtml() {
    return `
          <div id="${this.containerId}"></div>
          <script>
          var isMobileScreen = window.innerWidth < 900;
          var allowMobileDevice = isMobileScreen && ${this.creative.isMobile};
          var allowDesktopDevice = !isMobileScreen && !${this.creative.isMobile};
          var urlSearchParams = new URLSearchParams(window.location.search);
          var isPushReferrer = urlSearchParams.get('utm_source') === 'push' || urlSearchParams.get('utm_medium') === 'push';
          var isBlockFullscreen = isPushReferrer && ${this.placement.includes(
            'fullscreen'
          )};
          var referrer = new URLSearchParams(window.location.search).get('_testReferrer') || document.referrer;
          var isUnallowedAdvReferrer = ${JSON.stringify(UNALLOWED_ADV_REFERRERS)}
            .some(domain => referrer.includes(domain)) && ${JSON.stringify(
              EXCLUDED_ADV_FOR_REFERRER
            )}
            .includes('${this.placement}');
          if (!document.cookie.includes('${
            this.blockerCookie
          }') && (allowMobileDevice || allowDesktopDevice) && !isUnallowedAdvReferrer && !isBlockFullscreen) {
            const container = document.getElementById(\`${this.containerId}\`);
            if(typeof window.HSMCreativeManager !== 'undefined') {
              window.HSMCreativeManager.createSlot({
                container,
                adunit: '${this.placement}',
                params: ${JSON.stringify(this.customCreativeParams)}
              }).then(creativeSlot => {
                  creativeSlot?.refresh();
                });
            } else {
              window.onHSMCreativeManagerReady = window.onHSMCreativeManagerReady || [];
              window.onHSMCreativeManagerReady.push((cm) => {
                cm.createSlot({
                container,
                adunit: '${this.placement}',
                params: ${JSON.stringify(this.customCreativeParams)}
              }).then(creativeSlot => {
                  creativeSlot?.refresh();
                });
              });
            }
          }
          <\/script>
      `;
  }

  isUnallowedAdvReferrer() {
    const referrer =
      new URLSearchParams(window.location.search).get('_testReferrer') ||
      document.referrer;

    return (
      UNALLOWED_ADV_REFERRERS.some(domain => referrer.includes(domain)) &&
      EXCLUDED_ADV_FOR_REFERRER.includes(this.placement as PLACEMENTS)
    );
  }

  async init() {
    this.creativeManager = window.HSMCreativeManager;

    if (!this.creativeManager) {
      return;
    }

    if (this.isUnallowedAdvReferrer()) {
      return;
    }

    if (this.creative.isMobile !== this.isMobile) {
      return;
    }

    if (
      this.clientRender &&
      this.placement === PLACEMENTS.INLINE &&
      guardUnspecified(this.index)
    ) {
      this.sendHBRequest();
    }

    await this.creativeManager.ready;

    if (this.clientRender) {
      await new Promise<void>(resolve => {
        setTimeout(async () => {
          if (!document.cookie.includes(this.blockerCookie)) {
            const element = document.getElementById(this.containerId);

            if (element !== null) {
              await this.initCreative(element).catch(error => {
                return false;
              });
            }
          }
          resolve();
        }, 0);
      });
    } else {
      this.creativeSlot =
        this.creativeManager.findSlots(this.creative.placement)[0] || null;
    }

    if (!this.creativeSlot) {
      return;
    }

    this.emitStatusEvent(this.creativeSlot.status);

    this.creativeSlot.on('display', this.onDisplay);
    this.creativeSlot.on('stub', this.onStub);
    this.creativeSlot.on('error', this.onError);
    this.creativeSlot.on('closed', this.onClose);

    this.initObserver();

    if (
      ([PLACEMENTS.STICKY_RSYA] as string[]).includes(this.placement) &&
      this.creative.refreshEnabled
    ) {
      this.setRefreshTimeout();
    }
  }

  async initCreative(element: HTMLElement) {
    this.creativeSlot = await this.creativeManager!.createSlot({
      container: element,
      adunit: this.placement,
      params: this.customCreativeParams
    }).catch(error => {
      throw error;
    });

    this.emitStatusEvent(this.creativeSlot.status);

    this.creativeSlot.on('display', this.onDisplay);
    this.creativeSlot.on('stub', this.onStub);
    this.creativeSlot.on('error', this.onError);
    this.creativeSlot.on('closed', this.onClose);

    await this.creativeSlot?.refresh();
  }

  emitStatusEvent(status: SlotStatus) {
    if (status === SlotStatus.READY) {
      this.onDisplay();
    } else if (status === SlotStatus.STUB) {
      this.onStub();
    } else if (status === SlotStatus.ERROR) {
      this.onError();
    }
  }

  @Emit('display')
  onDisplay() {
    this.status = 'displayed';
  }

  @Emit('stub')
  onStub() {
    this.status = 'stub';
  }

  @Emit('error')
  onError() {
    this.status = 'error';
  }

  @Emit('closed')
  onClose() {
    this.status = 'closed';

    if (this.observer) {
      this.observer.disconnect();
    }

    this.clearRefreshTimeout();
  }

  initObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.isViewPort = entry.isIntersecting;
      });
    }, this.observerOptions);

    // На dev-средах бажит без проверки
    if (this.$el.tagName) {
      this.observer.observe(this.$el);
    }
  }

  setRefreshTimeout() {
    this.refreshInterval = setInterval(async () => {
      if (
        ([PLACEMENTS.STICKY_RSYA, PLACEMENTS.STICKY] as string[]).includes(
          this.placement
        )
      ) {
        this.creativeSlot?.off('closed', this.onClose);
        await this.creativeSlot?.refresh();
        this.creativeSlot?.on('closed', this.onClose);
      } else {
        await this.creativeSlot?.refresh();
      }
    }, this.refreshIntervalStep * 1000);
  }

  clearRefreshTimeout() {
    clearInterval(this.refreshInterval);
  }

  offCreativeHandlers() {
    this.creativeSlot?.off('display');
    this.creativeSlot?.off('stub');
    this.creativeSlot?.off('closed');
  }

  sendHBRequest() {
    const w = window as any;
    const mobileIncrementAdUnits = w.mobileIncrementAdUnits || [];
    const desktopIncrementAdUnits = w.desktopIncrementAdUnits || [];

    const advContainerSuffix = this.isMobile ? '_m' : '_d';

    const adUnitParams = this.isMobile
      ? mobileIncrementAdUnits
      : desktopIncrementAdUnits;

    if (adUnitParams.length === 0) {
      return;
    }

    if (this.containerId.includes(advContainerSuffix)) {
      const newAdUnitParams = { ...adUnitParams[0], ...{ code: this.containerId } };

      w.Ya || (w.Ya = {});
      w.yaContextCb = w.yaContextCb || [];
      w.Ya.adfoxCode || (w.Ya.adfoxCode = {});
      w.Ya.adfoxCode.hbCallbacks || (w.Ya.adfoxCode.hbCallbacks = []);

      w.Ya.adfoxCode.hbCallbacks.push(function () {
        w.Ya.headerBidding.pushAdUnits([newAdUnitParams]);
      });
    }
  }

  mounted() {
    void this.init();
  }

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    clearInterval(this.refreshInterval);

    if (this.creativeSlot) {
      this.creativeManager?.destroySlot(this.creativeSlot);
      this.offCreativeHandlers();
    }
    this.creativeSlot = null;
  }
}
