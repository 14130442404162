














import { guardUnspecified } from '@smh/utils/guards';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

import type { SliderOptions } from './ui-slider.contract';

const SLIDER_OPTIONS = {
  lazyLoad: true,
  draggable: true,
  pageDots: false,
  prevNextButtons: false,
  groupCells: undefined,
  setGallerySize: false,
  cellAlign: 'left',
  contain: true,
  initialIndex: 0
};

@Component({
  name: 'UiSlider'
})
export default class UiSlider extends Vue {
  @Prop({
    default: undefined,
    type: Number
  })
  initialSliderHeight: number;

  @Prop({
    default: () => ({}),
    type: Object as () => SliderOptions
  })
  sliderOptions: SliderOptions;

  observer: MutationObserver | null = null;
  sliderIsReady = false;
  sliderHeight = '0';
  slider: typeof import('flickity').prototype | null = null;

  activeIndex = 0;
  slides: HTMLElement[] = [];

  created() {
    this.sliderHeight = !this.isFullHeight
      ? `${this.initialSliderHeight}px`
      : '100%';
  }

  beforeDestroy() {
    this.observer?.disconnect();
    this.slider?.destroy();
  }

  mounted() {
    if (guardUnspecified(this.$slots.default)) {
      void this.initFlickity();
    } else {
      this.createMutationObserver();
    }
  }

  get isFullHeight() {
    return !guardUnspecified(this.initialSliderHeight);
  }

  get wrapperStyles() {
    return {
      height: this.sliderHeight,
      ...this.opacityStyle
    };
  }

  get opacityStyle() {
    return {
      opacity: this.sliderIsReady ? 1 : 0
    };
  }

  createMutationObserver() {
    this.observer = new MutationObserver(() => {
      void this.initFlickity();

      this.observer?.disconnect();
    });

    this.observer.observe(this.$refs.slider as HTMLElement, { childList: true });
  }

  async initFlickity(): Promise<void> {
    const { default: flickity } = await import('flickity');

    await import('flickity-as-nav-for');

    this.slider = new flickity(this.$refs.slider as HTMLElement, {
      ...SLIDER_OPTIONS,
      on: {
        change: (index?: number) => {
          this.slideChanged(index);
        },
        ready: () => {
          this.sliderReady();
        }
      },
      ...this.sliderOptions
    });

    if (this.isFullHeight) {
      // хак, чтобы слайдер ресайзился после исчезновения полосы прокрутки
      // иначе элементы при прокрутке съезжают
      this.slider.resize();
    }
  }

  setSliderHeight(): void {
    if (this.isFullHeight) {
      return;
    }

    let maxHeight = this.initialSliderHeight;

    this.$slots.default?.forEach(slide => {
      const elm = slide.elm as HTMLElement;

      if (!guardUnspecified(elm)) {
        return;
      }

      this.slides.push(elm);

      const { height } = elm.getBoundingClientRect();

      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    this.slides.forEach(slide => {
      slide.style.height = `${maxHeight}px`;
    });

    this.sliderHeight = `${maxHeight}px`;
  }

  sliderReady() {
    this.setSliderHeight();

    this.sliderIsReady = true;

    this.emitSliderIsReady();
  }

  slideChanged(index?: number) {
    if (!guardUnspecified(index)) {
      return;
    }

    this.activeIndex = index;
    this.emitSlideChanged(index);
  }

  prevSlide() {
    this.slider?.previous();
  }

  nextSlide() {
    this.slider?.next();
  }

  @Emit('slideChanged')
  emitSlideChanged(index: number): number {
    return index + 1;
  }

  @Emit('sliderIsReady')
  emitSliderIsReady(e: Event) {
    return e;
  }
}
