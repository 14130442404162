import type { ProfilePageUserCommentsDTO } from '@fontanka/news-api-client';

import type { UserComment } from '../domain';

export class UserCommentsMapper {
  public static toDO(
    userCommentsDTO: ProfilePageUserCommentsDTO['data']
  ): UserComment[] {
    return userCommentsDTO !== null
      ? userCommentsDTO.map(c => ({
          ...c
        }))
      : [];
  }
}
