import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiAdvLabel } from '@fe/common/ui';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import styles from './news-feed-item.styles.scss';

type Events = {
  onVisible: void;
  onClicked: void;
};

export type NewsFeedRecord = {
  hasVideo: boolean;
  hasPhoto: boolean;
  hasAudio: boolean;
  urls: {
    url: string;
  };
  header: string;
  publishDateForWindow: string;
  isPictureOfTheDay: boolean;
  hasMainPhotoCommercialLabel?: boolean;
};

/**
 * @deprecated
 */
export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'NewsFeedItemDeprecated',
    props: {
      type: {
        type: String as () => 'default' | 'simple',
        required: false,
        default: 'default'
      },
      record: {
        type: Object as () => NewsFeedRecord,
        required: true as const
      }
    },
    data() {
      return {
        newsFeedItemVisibility: new ObserverVisibility('all')
      };
    },
    computed: {
      isSimple(): boolean {
        return this.type === 'simple';
      }
    },
    methods: {
      emitClicked() {
        this.$emit('clicked');
      }
    },
    mounted(): void {
      this.newsFeedItemVisibility.event.subscribe(state => {
        if (state.value) {
          this.$emit('visible');
        }
      });
    },
    render() {
      return (
        <div
          v-observe-visibility={this.newsFeedItemVisibility.getOptions('all')}
          class={[styles.item, this.record.isPictureOfTheDay ? styles.top : '']}
        >
          <div class={styles.header}>
            <div class={styles.time}>
              <span class={styles.timeIcon}></span>
              <span class={styles.timeText}>{this.record.publishDateForWindow}</span>
            </div>

            {!this.isSimple && (
              <div class={styles.icons}>
                {this.record.hasVideo && <i class={[styles.icon, styles.video]} />}
                {this.record.hasAudio && <i class={[styles.icon, styles.music]} />}
                {this.record.hasPhoto && <i class={[styles.icon, styles.photo]} />}
              </div>
            )}

            <div class={[styles.sep, this.isSimple ? styles.gray : '']} />
          </div>

          <a
            class={styles.link}
            href={this.record.urls.url}
            target="_self"
            onClick={this.emitClicked}
            domPropsInnerHTML={this.record.header}
          />

          {this.record.hasMainPhotoCommercialLabel && (
            <UiAdvLabel hasFrame={false} class={styles.advMarker} />
          )}
        </div>
      );
    }
  });
