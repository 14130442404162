const WEBP_POSTFIX = '.webp-portal';

export type ImageSource = { srcset: string; type: string; media?: string };

export const getWebpSrc = (imageSrc: string): string => `${imageSrc}${WEBP_POSTFIX}`;

/**
 * @deprecated
 */
export const imageSources = (imageSrc: string, media = ''): ImageSource[] => {
  const defaultSource: ImageSource = {
    srcset: imageSrc,
    type: 'image/webp'
  };

  if (media !== '') {
    defaultSource.media = media;
  }

  return [
    {
      ...defaultSource,
      srcset: getWebpSrc(imageSrc)
    },
    { ...defaultSource, type: 'image/jpg' }
  ];
};
