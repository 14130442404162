import { guardUnspecified } from '@portal/utils/util-guards';
import { setCookie } from 'tiny-cookie';
import { State, Mutation, Action } from 'vuex-simple';

import { SortType, RECORD_COMMENTS_INFO } from '@fontanka/data';

import type { RootModule } from '../../../../core';
import type {
  VoteCommentParams,
  MetaComments,
  RecordComment,
  RecordCommentAdv,
  AnswersData,
  AnswersCommentItem
} from '../../../../domain';
import type { RecordCommentsPage } from '../../domain';
import { RecordCommentsPageMapper, CommentsDataMapper } from '../../services';

type FetchCommentParams = {
  year: string;
  month: string;
  day: string;
  id: string;
};

type FetchAnswersParams = {
  parentId: number;
  dateFrom: number;
};

type AddCommentParams = {
  text: string;
  parentId?: number;
  userId: number;
  login?: string;
  captchaToken: string;
};

type VotesCommentData = {
  commentId: number;
  parentId: number;
  vote: 1 | -1;
};

export const RECORD_COMMENTS_PAGE_MODULE_NAMESPACE = ['record_comments_page_module'];

export class RecordCommentsPageModule {
  @State()
  public blocks: RecordCommentsPage['blocks'];

  @State()
  public record: RecordCommentsPage['record'];

  @State()
  public comments: RecordCommentsPage['parentComments'];

  @State()
  public metaComments: RecordCommentsPage['meta'];

  @State()
  public sortType: SortType = RECORD_COMMENTS_INFO.DEFAULT_SORT_TYPE;

  private _commentsDataMapper: CommentsDataMapper;

  constructor(private _root: RootModule) {
    this._commentsDataMapper = new CommentsDataMapper(this._root.envType);

    const { blocks, parentComments, meta, record } = RecordCommentsPageMapper.toDO(
      this._root.pageSpec
    );

    this.blocks = blocks;
    this.record = record;
    this.sortType =
      this._root.pageInfo?.sortType ?? RECORD_COMMENTS_INFO.DEFAULT_SORT_TYPE;
    this.comments = parentComments;
    this.metaComments = meta;
  }

  @Action()
  public async fetchComments(params: FetchCommentParams) {
    const { comments, meta } = await this._commentsDataMapper.getComments({
      ...params,
      sort: this.sortType,
      dateFrom: this.metaComments.searchDate
    });

    if (comments.length > 0) {
      this._setMetaComments(meta);
      this._addComments(comments);
    }
  }

  @Action()
  public async fetchAnswers(params: FetchAnswersParams) {
    const { parentId, dateFrom } = params;

    try {
      const answersData = await this._commentsDataMapper.getCommentAnswers({
        parentId,
        dateFrom
      });
      const data = {
        parentId,
        answersData
      };

      this.setAnswersForComment(data);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  @Action()
  public async toggleSortType(params: FetchCommentParams) {
    const newSortType =
      this.sortType === SortType.Asc ? SortType.Desc : SortType.Asc;

    const { comments, meta } = await this._commentsDataMapper.getComments({
      ...params,
      sort: newSortType,
      dateFrom: 0
    });

    if (comments.length > 0) {
      this._setSortType(newSortType);
      this._updateSortTypeInCookies(this.sortType);
      this._setMetaComments(meta);
      this._setComments(comments);
    }
  }

  @Action()
  public async addComment(params: AddCommentParams) {
    const recordId = this.record?.id ?? 0;
    const profileId = params.userId;
    const nick = params.login ?? '';

    const parentComment = this._getParentComment(params.parentId);

    const comment = await this._commentsDataMapper.sendComment(
      {
        recordId,
        profileId,
        nick,
        ...params
      },
      parentComment
    );

    if (guardUnspecified(comment.parentId) && comment.parentId > 0) {
      await this.fetchAnswers({
        parentId: comment.parentId,
        dateFrom: 1
      });
    }

    this._addComment({ comment, parent: parentComment });
  }

  @Action()
  private _updateSortTypeInCookies(sortType: SortType) {
    setCookie(RECORD_COMMENTS_INFO.COOKIES_SORT_TYPE_KEY, sortType);
  }

  @Action()
  public async voteComment(params: VoteCommentParams): Promise<void> {
    const { commentId, vote } = params;
    const voteParams = {
      commentId,
      vote
    };

    try {
      const recordId = (this.record?.id ?? 0).toString();
      await this._commentsDataMapper.voteComment(recordId, voteParams);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  @Mutation()
  private _setMetaComments(meta: MetaComments): void {
    this.metaComments = {
      ...this.metaComments,
      ...meta
    };
  }

  @Mutation()
  private _setSortType(sortType: SortType) {
    this.sortType = sortType;
  }

  @Mutation()
  private _addComment({
    comment,
    parent
  }: {
    comment: RecordComment;
    parent?: RecordComment;
  }) {
    if (!parent) {
      if (this.sortType === SortType.Asc && !this.metaComments.hasAfter) {
        this.comments = [...this.comments, comment];
      } else if (this.sortType === SortType.Desc) {
        this.comments = [comment, ...this.comments];
      }

      return;
    }

    this.comments = this.comments.map(p => {
      if (p.type !== 'adv' && p.id === parent.id) {
        const isDoubleChildren = p.children.data.some(
          item => comment.id === item.id
        );

        return {
          ...p,
          children: {
            data: isDoubleChildren
              ? [...p.children.data]
              : [...p.children.data, comment],
            searchDate: p.children.searchDate,
            hasAfter: p.children.hasAfter
          },
          childrenCount: p.childrenCount + 1
        };
      }

      return p;
    });
  }

  @Mutation()
  private _addComments(comments: (RecordComment | RecordCommentAdv)[]) {
    const commentsWithoutDoubles = this._cutDoubleComments(comments, this.comments);

    this.comments = [...this.comments, ...commentsWithoutDoubles];
  }

  @Mutation()
  private _setComments(comments: (RecordComment | RecordCommentAdv)[]) {
    this.comments = comments;
  }

  @Mutation()
  public setAnswersForComment(data: {
    parentId: number;
    answersData: AnswersData;
  }): void {
    const { parentId, answersData } = data;
    const parentComment = this.comments.find(
      comment => comment.type !== 'adv' && comment.id === parentId
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parentAnswers = (parentComment?.children?.data ||
      []) as AnswersCommentItem[];

    const answers =
      parentAnswers.length > 0
        ? this._cutDoubleAnswers(answersData.data, parentAnswers)
        : answersData.data;

    this.comments = this.comments.map(comment => {
      if (comment.type !== 'adv' && comment.id === parentId) {
        return this._updateCommentsAnswersData(comment, answersData, answers);
      }

      return comment;
    });
  }

  @Mutation()
  public setVoteCommentInList(data: VotesCommentData): void {
    if (data.parentId && data.parentId > 0) {
      this.comments = this.comments.map((item: RecordComment | RecordCommentAdv) => {
        if (item.type !== 'adv' && item.id === data.parentId) {
          const comment = this._updateVoteInAnswersList(item.children.data, data);
          return {
            ...item,
            children: {
              ...item.children,
              data: comment
            }
          };
        }

        return item;
      });
    } else {
      this.comments = this._updateVoteInList(this.comments, data);
    }
  }

  public updateVotesComment(comment: VotesCommentData): void {
    this.setVoteCommentInList(comment);
  }

  private _getParentComment(commentId?: number) {
    if (commentId === undefined || commentId === 0) {
      return undefined;
    }

    for (let i = 0; i < this.comments.length; i++) {
      const parentComment = this.comments[i];

      if (parentComment.type === 'adv') {
        return undefined;
      }

      if (parentComment.id === commentId) {
        return parentComment;
      }

      const childComment = parentComment.children.data.find(
        child => child.id === commentId
      );

      if (childComment) {
        return parentComment;
      }
    }

    return undefined;
  }

  private _cutDoubleComments(
    comments: (RecordComment | RecordCommentAdv)[],
    compareComments: (RecordComment | RecordCommentAdv)[]
  ): (RecordComment | RecordCommentAdv)[] {
    if (compareComments.length > 0) {
      return comments.filter(
        item =>
          item.type === 'adv' ||
          !compareComments.some(
            comment => comment.type !== 'adv' && comment.id === item.id
          )
      );
    }

    return comments;
  }

  private _cutDoubleAnswers(
    answers: AnswersCommentItem[],
    compareAnswers: AnswersCommentItem[]
  ): AnswersCommentItem[] {
    return answers.filter(
      item => !compareAnswers.some(comment => comment.id === item.id)
    );
  }

  private _updateCommentsAnswersData(
    comment: RecordComment,
    answersData: AnswersData,
    answers: AnswersCommentItem[]
  ): RecordComment {
    const children = [...comment.children.data, ...answers];

    return {
      ...comment,
      children: {
        data: children,
        searchDate: answersData.searchDate || comment.children.searchDate,
        hasAfter: answersData.hasAfter
      }
    };
  }

  private _updateVoteInAnswersList(
    commentsList: AnswersCommentItem[],
    data: VotesCommentData
  ): AnswersCommentItem[] {
    return commentsList.map(item => {
      if (item.id === data.commentId) {
        return {
          ...item,
          votesDislike: data.vote === -1 ? item.votesDislike + 1 : item.votesDislike,
          votesLike: data.vote === 1 ? item.votesLike + 1 : item.votesLike,
          userVote: data.vote
        };
      }

      return item;
    });
  }

  private _updateVoteInList(
    commentsList: (RecordComment | RecordCommentAdv)[],
    data: VotesCommentData
  ): (RecordComment | RecordCommentAdv)[] {
    return commentsList.map(item => {
      if (item.type !== 'adv' && item.id === data.commentId) {
        return {
          ...item,
          votesDislike: data.vote === -1 ? item.votesDislike + 1 : item.votesDislike,
          votesLike: data.vote === 1 ? item.votesLike + 1 : item.votesLike,
          userVote: data.vote
        };
      }

      return item;
    });
  }
}
