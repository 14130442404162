import { guardEmptyString } from '@portal/utils/util-guards';
import { componentFactoryOf, modifiers as m } from 'vue-tsx-support';

import { injectStylesMixin } from '@fe/common/mixins';
import { typography } from '@fe/common/styles/typography';

import FntCheckIcon from '~icons/fnt-common/check';

import styles from './comment-header.styles.scss';

type UserType = {
  nick: string;
  link: string;
  trusted: boolean;
};

type Events = {
  onCommentAuthorClicked: void;
};

const COMMENT_ICON_SIZE = 14;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'CommentHeader',
    props: {
      user: {
        type: Object as () => UserType,
        default: null
      },
      date: {
        type: String,
        default: ''
      }
    },
    methods: {
      emitAuthorClicked() {
        this.$emit('commentAuthorClicked');
      }
    },
    render() {
      const TAG = guardEmptyString(this.user?.link) ? 'a' : 'span';

      return (
        <div class={styles.info}>
          <TAG
            href={this.user?.link || undefined}
            class={[
              styles.name,
              this.user?.trusted ? styles.trusted : '',
              typography.primary.subtitle1Accent.mobile
            ]}
            onClick={() => m.stop(this.emitAuthorClicked)}
          >
            {this.user?.nick}
            {this.user?.trusted && (
              <FntCheckIcon
                class={styles.trustedBadge}
                width={COMMENT_ICON_SIZE}
                height={COMMENT_ICON_SIZE}
              />
            )}
          </TAG>

          <span class={[styles.date, typography.primary.subtitle2.mobile]}>
            {this.date}
          </span>
        </div>
      );
    }
  });
