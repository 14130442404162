import { Subject } from 'rxjs';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import closeIcon from 'images/actual/close.svg';

// eslint-disable-next-line import/no-useless-path-segments
import { AdvCreative } from '../../components/adv-creative';
import type { AdvData as _AdvData } from '../adv/adv.component';

import styles from './adv-desktop-sticky-bottom.styles.scss';

const CLOSE_DELAY_TIME = 3000;

const RECORD_WITHOUT_ADV = [71983220];

type ComponentData = {
  isCloseBtnVisible: boolean;
  wrapperWidth: number;
  destroy$: Subject<unknown>;
  hasAdv: boolean;
};

type AdvData = _AdvData;

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'AdvDesktopStickyBottom',
  props: {
    adv: {
      type: Object as () => AdvData,
      required: true as const
    }
  },
  data(): ComponentData {
    return {
      isCloseBtnVisible: false,
      wrapperWidth: 728,
      destroy$: new Subject(),
      hasAdv: false
    };
  },
  computed: {
    recordId(): number {
      return this.$route.name === 'record' ? Number(this.$route.params.id) : 0;
    },
    isRender(): boolean {
      if (RECORD_WITHOUT_ADV.includes(this.recordId)) {
        return false;
      }

      return true;
    }
  },
  mounted() {
    setTimeout(() => {
      this.isCloseBtnVisible = true;
    }, CLOSE_DELAY_TIME);
  },
  methods: {
    clickCloseBtn(): void {
      this.isCloseBtnVisible = false;
      this.hasAdv = false;

      this.destroy$.next();
      this.destroy$.unsubscribe();
    },
    onDisplay() {
      this.hasAdv = true;
    }
  },
  render() {
    return this.isRender ? (
      <div
        ref="advWrapper"
        class={[
          styles.wrapper,
          {
            [styles.hidden]: !this.hasAdv
          }
        ]}
        style={`width: ${this.wrapperWidth}px`}
      >
        <AdvCreative adv={this.adv} onDisplay={this.onDisplay} />
        <div class={[styles.closeBtnWrap, !this.isCloseBtnVisible && styles.hidden]}>
          <img
            class={styles.closeBtn}
            src={closeIcon}
            onClick={() => {
              this.clickCloseBtn();
            }}
            alt="close"
          />
        </div>
      </div>
    ) : (
      <div />
    );
  }
});
