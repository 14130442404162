import type { AdvBlockConfig, AdvBlockInfo } from '@fontanka/news-api-client';

import type { Adv } from '../domain';

export class AdvMapper {
  public static toDO(config: AdvBlockConfig, info: AdvBlockInfo): Adv {
    return {
      ...config.settings,
      sticky: config.settings?.sticky ?? false,
      data: info.data,
      type: 'adv',
      responsive: config.settings?.type === 'responsive' ?? false
    };
  }
}
