import { State, Getter, Mutation } from 'vuex-simple';

import type { Adv, AdvData, MobileBrandingCreatives } from '../../../domain';
import { AdvDataMapper } from '../../../services';
import type { RootModule } from '../root.store';

export const ADV_MODULE_NAMESPACE = ['adv_module'];

export class AdvModule {
  @State()
  public advData: AdvData;

  @State()
  public mobileBrandingCreatives: MobileBrandingCreatives;

  @State()
  public isFullscreenCounterSkipped: boolean;

  @Mutation()
  public setMobileBrandingCreatives(creatives: MobileBrandingCreatives): void {
    this.mobileBrandingCreatives = creatives;
  }

  @Mutation()
  public setIsFullscreenCounterSkipped(skipped: boolean): void {
    this.isFullscreenCounterSkipped = skipped;
  }

  @Getter()
  public get branding() {
    return this.advData.branding ?? null;
  }

  @Getter()
  public get headerAdvert() {
    return this.advData.header_advert ?? null;
  }

  @Getter()
  public get bottomAdvert() {
    return this.advData.bottom_advert ?? null;
  }

  @Getter()
  public get newsFeedAdv() {
    return this.advData.news_feed_advert ?? null;
  }

  @Getter()
  public get windowAdv1_1() {
    return this.advData.window_adv_1_1 ?? null;
  }

  @Getter()
  public get windowAdv1_2() {
    return this.advData.window_adv_1_2 ?? null;
  }

  @Getter()
  public get windowAdv1_3() {
    return this.advData.window_adv_1_3 ?? null;
  }

  @Getter()
  public get windowAdv2() {
    return this.advData.window_adv_2 ?? null;
  }

  @Getter()
  public get windowAdv3_1() {
    return this.advData.window_adv_3_1 ?? null;
  }

  @Getter()
  public get windowAdv3_2() {
    return this.advData.window_adv_3_2 ?? null;
  }

  @Getter()
  public get rubricAdv1() {
    return this.advData.rubric_adv_1 ?? null;
  }

  @Getter()
  public get rubricAdv2() {
    return this.advData.rubric_adv_2 ?? null;
  }

  @Getter()
  public get desktopCentralBottom1(): Adv | null {
    return this.advData.desktop_central_bottom_1 ?? null;
  }

  @Getter()
  public get desktopCentralBottom2(): Adv | null {
    return this.advData.desktop_central_bottom_2 ?? null;
  }

  @Getter()
  public get recordAdv1() {
    return this.advData.record_adv_1 ?? null;
  }

  @Getter()
  public get recordAdv2() {
    return this.advData.record_adv_2 ?? null;
  }

  @Getter()
  public get recordAdv3() {
    return this.advData.record_adv_3 ?? null;
  }

  @Getter()
  public get mobileHeaderAdvert() {
    return this.advData.mobile_header_advert ?? null;
  }

  @Getter()
  public get mobileFullscreenAdvert() {
    return this.advData.mobile_fullscreen_advert ?? null;
  }

  @Getter()
  public get mobileFullscreen2Advert() {
    return this.advData.mobile_fullscreen_2_advert ?? null;
  }

  @Getter()
  public get mobileStickyAdvert() {
    return this.advData.mobile_sticky_advert ?? null;
  }

  @Getter()
  public get mobileStickyRsyaAdvert() {
    return this.advData.fontanka_sticky_rsya_advert ?? null;
  }

  @Getter()
  public get mobileAdv1() {
    return this.advData.mobile_adv_1 ?? null;
  }

  @Getter()
  public get mobileWindowAdv1() {
    return this.advData.mobile_window_adv_1 ?? null;
  }

  @Getter()
  public get mobileWindowAdv2() {
    return this.advData.mobile_window_adv_2 ?? null;
  }

  @Getter()
  public get desktopInfoWidget() {
    return this.advData.desktop_info_widget ?? null;
  }

  @Getter()
  public get mobileInfoWidget() {
    return this.advData.mobile_info_widget ?? null;
  }

  @Getter()
  public get flyrollAdv() {
    return this.advData.flyroll_advert ?? null;
  }

  @Getter()
  public get interstitialsAdv() {
    return this.advData.interstitial_advert ?? null;
  }

  @Getter()
  public get beforeReadAlsoAdv() {
    return this.advData.before_read_also_mobile ?? null;
  }

  @Getter()
  public get mobileCommentsAdv() {
    return this.advData.mobile_comments_advert ?? null;
  }

  @Getter()
  public get desktopStickyAdvert() {
    return this.advData.desktop_sticky_advert ?? null;
  }

  @Getter()
  public get fancyBoxAdv() {
    return this.advData.fancybox_advert ?? null;
  }

  @Getter()
  public get logoAdvert() {
    return this.advData.logo_advert ?? null;
  }

  @Getter()
  public get mobileInfinityFeedAdv() {
    return this.advData.mob_infinity_feed_advert ?? null;
  }

  constructor(private _root: RootModule) {
    this.advData = AdvDataMapper.toDO(
      this._root.pageSpec.config.advert,
      this._root.pageSpec.data
    );
  }

  public getAdvBlockByType(type: string): Adv | null {
    return this.advData[type] ?? null;
  }
}
