import { format } from 'date-fns';
import { SearchSortType } from '@fontanka/news-api-client';
import { SEARCH_INFO } from '../domain';

export const REQUEST_DATE_FORMAT = 'yyyy-MM-dd';
export const API_DATE_FORMAT = 'dd.MM.yyyy';

export const SEARCH_PARAMS_MAP = {
  page: 'offset',
  text: 'query',
  option: 'rubric',
  sort: 'sortt',
  dateFrom: 'fdate',
  dateTo: 'tdate'
};

export class SearchMapper {
  public static getDateFrom(dateFrom?: string): string {
    return format(
      new Date(dateFrom ? dateFrom : SEARCH_INFO.DEFAULT_DATE_FROM),
      REQUEST_DATE_FORMAT
    );
  }

  public static getDateTo(dateTo?: string): string {
    return format(
      new Date(dateTo ? dateTo : SEARCH_INFO.getDefaultDateTo()),
      REQUEST_DATE_FORMAT
    );
  }

  public static getApiDateFrom(dateFrom?: string): string {
    return format(
      new Date(dateFrom ? dateFrom : SEARCH_INFO.DEFAULT_DATE_FROM),
      API_DATE_FORMAT
    );
  }

  public static getApiDateTo(dateTo?: string): string {
    return format(
      new Date(dateTo ? dateTo : SEARCH_INFO.getDefaultDateTo()),
      API_DATE_FORMAT
    );
  }

  public static getSortType(sortType?: string): SearchSortType {
    return sortType === SearchSortType.Weight
      ? SearchSortType.Weight
      : SearchSortType.Date;
  }

  public static getPageSize() {
    return SEARCH_INFO.PAGE_SIZE;
  }

  public static getPageStart(page?: string | number) {
    const pageNumber = Number(page);
    return page && !isNaN(pageNumber) ? pageNumber : SEARCH_INFO.DEFAULT_START_PAGE;
  }

  public static getText(text?: string) {
    return text && typeof text === 'string' ? text : SEARCH_INFO.DEFAULT_TEXT;
  }

  public static getRubric(rubric?: string) {
    return rubric && typeof rubric === 'string'
      ? rubric
      : SEARCH_INFO.DEFAULT_RUBRIC;
  }
}
