import { State, Action } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { BaseRecord } from '../../../domain';
import type { FormatPage as FormatPageAlias } from '../services/format-page-mapper.service';
import { FormatPageMapper } from '../services/format-page-mapper.service';
import { FormatRecordsDataMapper } from '../services/format-records-data-mapper.service';

type FormatPage = FormatPageAlias;

export const FORMAT_MODULE_NAMESPACE = ['format_module'];

export class FormatModule {
  @State()
  public blocks: FormatPage['blocks'];

  @State()
  public records: FormatPage['recordsInfo']['records'];

  @State()
  public totalRecords = 0;

  private readonly _formatRecordsDataMapper: FormatRecordsDataMapper;

  private readonly _recordsPageSize = 12;

  /**
   * Начинаем со 2-ой страницы, потому что с сервера придет первая
   */
  private _currentRecordsPage = 2;

  constructor(private _root: RootModule) {
    this._formatRecordsDataMapper = new FormatRecordsDataMapper(this._root.envType);

    const { blocks, recordsInfo } = FormatPageMapper.toDO(this._root.pageSpec);

    this.records = recordsInfo.records;
    this.totalRecords = recordsInfo.total;
    this.blocks = blocks;
  }

  @Action()
  async fetchRecords(format: string) {
    const records = await this._formatRecordsDataMapper.getFormatRecords(
      format,
      this._currentRecordsPage,
      this._recordsPageSize
    );

    if (records.length !== 0) {
      this._addRecords(records);

      this._currentRecordsPage = this._currentRecordsPage + 1;
    }
  }

  private _addRecords(records: BaseRecord[]) {
    this.records = [...this.records, ...records];
  }
}
