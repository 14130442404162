import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { LayoutBreakpoints } from '@fontanka/data';
import { isOutsideClick } from '@fontanka/outside-click';

import FntCloseIcon from '~icons/fnt-common/close';
import FntTrustedIcon from '~icons/fnt-common/trusted';

import styles from './trusted-badge.styles.scss';

const DEFAULT_TITLE = 'Подтвержденный пользователь';
const DEFAULT_TOOLTIP_MSG = 'Это подтвержденный аккаунт личности, СМИ или бренда';
const TOOLTIP_WIDTH = 266;
const SMALL_ICON_SIZE = 16;
const BIG_ICON_SIZE = 24;

type ComponentData = {
  isTooltipVisible: boolean;
  leftPosition: number;
};

type Events = {
  toggleTooltip: boolean;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'TrustedBadge',
    props: {
      size: {
        type: String as () => 'small' | 'big',
        default: 'small'
      },
      titleText: {
        type: String,
        default: DEFAULT_TITLE
      },
      tooltipText: {
        type: String,
        default: DEFAULT_TOOLTIP_MSG
      },
      parentElement: {
        type: Object as () => unknown,
        default: null
      }
    },
    data(): ComponentData {
      return {
        isTooltipVisible: false,
        leftPosition: 0
      };
    },
    computed: {
      hasPopup(): boolean {
        return this.isTooltipVisible && this.tooltipText !== '';
      },
      tooltipStyles(): string {
        return `left: ${this.leftPosition}px;`;
      }
    },
    mounted() {
      this.addListeners();
    },
    destroyed() {
      this.removeListeners();
    },
    methods: {
      closeTooltip() {
        this.isTooltipVisible = false;
        this.$emit('toggleTooltip', false);
      },
      toggleTooltip() {
        this.isTooltipVisible = !this.isTooltipVisible;
        this.$emit('toggleTooltip', this.isTooltipVisible);

        if (this.isTooltipVisible) {
          this.getCoordsTooltips();
        }
      },
      processOutsideClickOnPopup(event: MouseEvent) {
        if (!this.hasPopup) {
          return;
        }

        const isOutside = isOutsideClick({
          popup: this.$refs['trustedTooltip'] as Node,
          button: this.$refs['trustedTooltipBtn'] as Node,
          event: event
        });

        this.isTooltipVisible = !isOutside;
      },
      getCoordsTooltips() {
        const parentElement = (this.parentElement ||
          this.$el.parentElement) as HTMLElement;
        const parentCoords = parentElement.getBoundingClientRect();
        const elementCoords = (this.$el as HTMLElement).getBoundingClientRect();
        const isMobile = window.innerWidth < LayoutBreakpoints.Tablet;
        const iconSize = isMobile ? SMALL_ICON_SIZE : BIG_ICON_SIZE;
        const minLeftCoords = (TOOLTIP_WIDTH - iconSize) * -1;
        const leftCoords = parentCoords.left - elementCoords.left;
        this.leftPosition = leftCoords < minLeftCoords ? minLeftCoords : leftCoords;
      },
      addListeners() {
        if (window) {
          window.addEventListener('click', this.processOutsideClickOnPopup);
        }
      },
      removeListeners() {
        window.removeEventListener('click', this.processOutsideClickOnPopup);
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs,
        on: this.$listeners
      };
      return (
        <div class={[styles.trustedBadge, styles[this.size]]} {...attributes}>
          <FntTrustedIcon
            ref="trustedTooltipBtn"
            class={styles.trustedIcon}
            title={this.titleText}
            alt={DEFAULT_TITLE}
            onClick={this.toggleTooltip}
          />

          <div
            ref="trustedTooltip"
            class={[styles.tooltip, !this.hasPopup ? styles.hidden : '']}
            style={this.tooltipStyles}
          >
            <button
              class={styles.closeBtn}
              type="button"
              title="Закрыть"
              onClick={this.closeTooltip}
            >
              <FntCloseIcon alt="Закрыть" />
            </button>
            <p>{this.tooltipText}</p>
          </div>
        </div>
      );
    }
  });
