import { render, staticRenderFns } from "./ui-mobile-app-icon.component.vue?vue&type=template&id=62005d9e&"
import script from "./ui-mobile-app-icon.component.vue?vue&type=script&lang=ts&"
export * from "./ui-mobile-app-icon.component.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports