import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { TransparentBtn } from '@fe/common/components';
import ShareIcon from 'images/pictograms/share-orange.svg';

import styles from './share.styles.scss';

type Events = {
  onShared: SocialType;
  onLinkCopied: void;
};

const POPOVER_TIMEOUT = 2000;
const MAX_COUNT_OF_ICONS_ON_ROW = 5;

type PopupPosition = 'topLeft' | 'bottomRight' | 'bottomLeft' | 'topRight';

type ComponentData = {
  hidePopup: boolean;
  timeoutId: number;
  isCopyMessage: boolean;
};

export type SocialType = 'vk' | 'twitter' | 'lj' | 'telegram';

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'Share',
    props: {
      socials: {
        type: Array as () => SocialType[],
        default() {
          return ['vk', 'twitter', 'telegram'];
        }
      },
      withCaption: {
        type: Boolean,
        default: false
      },
      isPopup: {
        type: Boolean,
        default: false
      },
      copyLink: {
        type: String,
        default: ''
      },
      popupPosition: {
        type: String as () => PopupPosition,
        default: 'topRight'
      },
      btnSize: {
        type: Number,
        default: 40
      },
      btnMargin: {
        type: Number,
        default: 8
      }
    },
    data(): ComponentData {
      return {
        hidePopup: true,
        timeoutId: 0,
        isCopyMessage: false
      };
    },
    computed: {
      hasCopyBtn(): boolean {
        return this.copyLink.length > 0;
      },
      rowIconsCount(): number {
        const socialsCount = this.hasCopyBtn
          ? this.socials.length + 1
          : this.socials.length;
        let rowIconsCount =
          socialsCount >= MAX_COUNT_OF_ICONS_ON_ROW
            ? MAX_COUNT_OF_ICONS_ON_ROW
            : socialsCount;

        if (socialsCount > MAX_COUNT_OF_ICONS_ON_ROW) {
          rowIconsCount =
            // считает деление на 2 магическим числом
            socialsCount % 2 === 0 ? socialsCount / 2 : (socialsCount + 1) / 2;
        }
        return rowIconsCount;
      },
      socialListWidth(): string {
        return this.isPopup
          ? `${
              this.btnSize * this.rowIconsCount +
              this.btnMargin * (this.rowIconsCount - 1)
            }px`
          : 'auto';
      }
    },
    methods: {
      togglePopup() {
        if (this.isPopup) {
          this.hidePopup = !this.hidePopup;
        }
      },
      getSocialItemStyles(index: number) {
        if (this.isPopup) {
          const marginRight =
            index !== 0 && index % (this.rowIconsCount - 1) === 0
              ? 0
              : this.btnMargin;
          if (index < this.rowIconsCount) {
            return `margin-right: ${marginRight}px;`;
          }

          if ((index + 1) % this.rowIconsCount === 0) {
            return `margin-top: ${this.btnMargin}px`;
          }

          return `margin: ${this.btnMargin}px ${this.btnMargin}px 0 0`;
        }

        return `margin-right: ${this.btnMargin}px`;
      },
      getIconBtnStyle() {
        return {
          width: `${this.btnSize}px`,
          height: `${this.btnSize}px`
        };
      },
      copyShareLink() {
        const link = this.$refs.link as HTMLElement;
        const range = document.createRange();
        range.selectNode(link);

        const selection = window.getSelection();

        if (selection) {
          selection.removeAllRanges();
          selection.addRange(range);

          try {
            document.execCommand('copy');
            this.showCopyMessage();
            this.$emit('linkCopied');
          } catch (error) {
            console.error(
              `Произошла ошибка при копировании ссылки на пост: ${
                (error as Error).message
              }`
            );
          }

          selection.removeRange(range);
        }
      },
      showCopyMessage() {
        clearTimeout(this.timeoutId);

        this.isCopyMessage = true;
        this.timeoutId = window.setTimeout(() => {
          this.isCopyMessage = false;
        }, POPOVER_TIMEOUT);
      }
    },
    render() {
      const attributes = {
        attrs: this.$attrs
      };

      return (
        <div
          class={[
            styles.share,
            this.withCaption ? styles.showCaption : '',
            this.isPopup ? styles.popup : ''
          ]}
          {...attributes}
        >
          <span class={styles.caption} onClick={this.togglePopup}>
            <img src={ShareIcon} alt="Поделиться" />
            {this.$slots.caption || 'Поделиться'}
          </span>

          <div
            class={[
              styles.socialsListWrap,
              styles[this.popupPosition],
              this.hidePopup ? styles.hidePopup : ''
            ]}
          >
            <ul class={styles.socialsList} style={`width: ${this.socialListWidth}`}>
              {this.socials.map((item: SocialType, index: number) => (
                <li key={index} style={this.getSocialItemStyles(index)}>
                  <TransparentBtn
                    style={this.getIconBtnStyle()}
                    class={[styles.shareIconBtn, styles[item]]}
                    nativeOnClick={() => {
                      this.$emit('shared', item);
                    }}
                  />
                </li>
              ))}
              {this.hasCopyBtn ? (
                <li
                  class={styles.copy}
                  style={this.getSocialItemStyles(this.socials.length)}
                >
                  <span
                    class={[
                      styles.copyMessage,
                      this.isCopyMessage ? styles.isVisible : ''
                    ]}
                  >
                    Ссылка скопирована!
                  </span>
                  <TransparentBtn
                    style={this.getIconBtnStyle()}
                    class={[styles.shareIconBtn, styles.copy]}
                    nativeOnClick={() => {
                      this.copyShareLink();
                    }}
                  />
                  <span class={styles.link} ref="link">
                    {this.copyLink}
                  </span>
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      );
    }
  });
