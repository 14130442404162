import { guardUnspecified } from '@portal/utils/util-guards';

import { plural } from '@fontanka/strings';

import { Author, AuthorInList } from '../../domain';

import {
  createAuthorImage,
  AuthorImageParams,
  AuthorImageDTO as AuthorImageDTOAlias
} from './author-image-mapper.service';

type AuthorImageDTO = AuthorImageDTOAlias;

const RECORDS_ARRAY = ['публикация', 'публикации', 'публикаций'];

export type AuthorDTO = {
  readonly id: number;
  readonly image: AuthorImageDTO | null;
  readonly jobPosition: string;
  readonly name: string;
  readonly email: string;
  readonly url: string;
  readonly description: string;
  readonly socialLinks: { name: string; link: string }[] | null;
  readonly recordCount?: number;
};

export const createAuthor = (
  authorDTO?: AuthorDTO | null,
  params?: AuthorImageParams
): Author | null =>
  authorDTO && authorDTO !== null
    ? {
        id: authorDTO.id,
        image: createAuthorImage(authorDTO.image, params),
        jobPosition: authorDTO.jobPosition ?? '',
        name: authorDTO.name,
        email: authorDTO.email,
        url: authorDTO.url,
        socialLinks: authorDTO.socialLinks ?? [],
        description: authorDTO.description
      }
    : null;

export const createAllAuthors = (
  authorsDTO: AuthorDTO[],
  params?: AuthorImageParams
): AuthorInList[] =>
  authorsDTO?.map(author => ({
    id: author.id,
    image: createAuthorImage(author.image, params),
    name: author.name,
    url: author.url,
    jobPosition: author.jobPosition,
    recordCountText: guardUnspecified(author.recordCount)
      ? `${author.recordCount} ${plural(author.recordCount, RECORDS_ARRAY)}`
      : ''
  }));
