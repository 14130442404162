import { State } from 'vuex-simple';

import type { RootModule } from '../../../../core';
import type { AllCommentsPage } from '../../domain';
import { AllCommentsPageMapper } from '../../services/allcomments-page-mapper.service';

export const ALLCOMMENTS_PAGE_MODULE_NAMESPACE = ['allcomments_page_module'];

export class AllCommentsPageModule {
  @State()
  public blocks: AllCommentsPage['blocks'];

  constructor(private _root: RootModule) {
    const { blocks } = AllCommentsPageMapper.toDO(this._root.pageSpec);

    this.blocks = blocks;
  }
}
