import { guardUnspecified } from '@portal/utils/util-guards';

import {
  type HomePageBusinessNewsDTO,
  type HomePageCompanyNewsDTO,
  type HomePageDiscussionsDTO,
  type HomePageSocialsDTO,
  type HomePageWeekendDTO,
  type HomePageWindowDTO,
  type HomePageWindowItemDTO,
  type NewHomePageDTO,
  type PictureDayBlockDTO,
  type SpecialNewsDTO,
  type FontankaProDTO,
  type OpinionDTO,
  type NewsDTO,
  type HotNewsItemDTO,
  type CommonCalendarDTO,
  type SupercoverDTO,
  type SingleCardBlockDTO
} from '@fontanka/news-api-client';

import type { WeSocial, BaseRecord, Rubric } from '../../../domain';
import { WeSocialMapper, BaseRecordMapper } from '../../../services';
import type { NewHomePage, NewsfeedVM } from '../domain';

import { CalendarMapper } from './calendar-mapper.service';
import { CompanyNewsMapper } from './company-news-mapper.service';
import { FontankaProMapper } from './fontanka-pro-mapper.service';
import { MainNewsMapper } from './main-news-mapper.service';
import { NewsfeedMapper } from './newsfeed-mapper.service';
import { OpinionMapper } from './opinion-mapper.service';
import { RecommendationMapper } from './recommendation-mapper.service';
import { RubricsMapper } from './rubrics-mapper.service';
import { SingleCardBlockPresenter } from './single-card-mapper.service';
import { SupercoverMapper } from './supercover-mapper.service';
import { WeekendMapper } from './weekend-mapper.service';
import { WindowMapper } from './window-mapper.service';

const BUSINESS_NEWS_IMAGE_WIDTH = 220;

export class NewHomePageMapper {
  public static toDO(pageDTO: NewHomePageDTO, isMobile: boolean): NewHomePage {
    const INITIAL = {
      companyNews: null,
      businessNews: [] as BaseRecord[],
      ourCollection: null,
      discussions: [] as BaseRecord[],
      fontankaPro: null,
      mainNews: null,
      newestOpinion: null,
      singleCard: null,
      recentOpinions: null,
      recommendation: null,
      rubrics: [] as Rubric[],
      socials: [] as WeSocial[],
      weekend: null,
      window: null,
      newsFeed: {} as NewsfeedVM,
      calendar: null
    } as NewHomePage;

    INITIAL.rubrics = RubricsMapper.toDO(pageDTO);

    const hotNewsPath = pageDTO.config.common.hot_news?.path;
    const hotNewsData = guardUnspecified(hotNewsPath)
      ? (pageDTO.data[hotNewsPath].data as HotNewsItemDTO)
      : undefined;

    const hourNewsPath = pageDTO.config.blocks.hour_news.path;
    const hourNewsData = pageDTO.data[hourNewsPath].data as SpecialNewsDTO;

    const windowMainNewsPath = pageDTO.config.blocks.window.path;
    const windowMainNewsData = pageDTO.data[windowMainNewsPath]
      .data as HomePageWindowItemDTO[];

    return Object.entries(pageDTO.config.blocks).reduce((acc, current) => {
      const blockConfig = current[1];
      const { type, path } = blockConfig;

      const { data } = pageDTO.data[path];

      if (data === null) {
        return acc;
      }

      if (type === 'window') {
        acc.window = WindowMapper.present(
          data as HomePageWindowDTO['data'],
          isMobile,
          false
        );

        return acc;
      }

      if (type === 'main_news') {
        acc.mainNews = MainNewsMapper.toDO(
          data as unknown as PictureDayBlockDTO['data'],
          hourNewsData,
          hotNewsData,
          windowMainNewsData,
          isMobile
        );

        return acc;
      }

      if (type === 'calendar') {
        acc.calendar = CalendarMapper.toDo(
          data as CommonCalendarDTO['data'],
          isMobile
        );

        return acc;
      }

      if (type === 'network_content') {
        acc.weekend = WeekendMapper.toDO(data as HomePageWeekendDTO['data']);

        return acc;
      }

      if (type === 'recommendation') {
        acc.recommendation = RecommendationMapper.toDO(
          data as HomePageWeekendDTO['data']
        );

        return acc;
      }

      if (type === 'business_news') {
        const block = (data as HomePageBusinessNewsDTO['data']) ?? [];
        acc.businessNews = block.map(record =>
          BaseRecordMapper.toDO(record, {
            crop: false,
            width: BUSINESS_NEWS_IMAGE_WIDTH
          })
        );

        return acc;
      }

      if (type === 'company_news_bottom') {
        acc.companyNews = CompanyNewsMapper.toDo(
          data as HomePageCompanyNewsDTO['data']
        );

        return acc;
      }

      if (type === 'opinions') {
        const block = (data as OpinionDTO[]) ?? [];

        const opinions = block.map(article => OpinionMapper.toDo(article));
        acc.newestOpinion = {
          article: opinions[0] ?? null,
          title: {
            link: '/mention',
            text: 'Мнение'
          }
        };
        acc.recentOpinions = {
          articles: opinions,
          title: {
            link: '/mention',
            text: 'Мнение'
          }
        };

        return acc;
      }

      if (type === 'our_collection') {
        acc.ourCollection = WindowMapper.present(
          data as HomePageWindowDTO['data'],
          isMobile,
          true
        );
        return acc;
      }

      if (type === 'socials') {
        acc.socials = WeSocialMapper.toDO(data as HomePageSocialsDTO['data']);

        return acc;
      }

      if (type === 'discussions') {
        const block = (data as HomePageDiscussionsDTO['data']) ?? [];
        acc.discussions = block.map(record => BaseRecordMapper.toDO(record));

        return acc;
      }

      if (type === 'fontanka_pro') {
        acc.fontankaPro = FontankaProMapper.toDo(data as FontankaProDTO['data']);

        return acc;
      }

      if (type === 'main_page_custom_text_block') {
        acc.singleCard = SingleCardBlockPresenter.presentSingleCard(
          data as SingleCardBlockDTO['data']
        );
      }

      if (type === 'news') {
        acc.newsFeed = NewsfeedMapper.present(data as NewsDTO['data'], isMobile);

        return acc;
      }

      if (type === 'supercover') {
        acc.supercover = SupercoverMapper.present(data as SupercoverDTO['data']);

        return acc;
      }

      return acc;
    }, INITIAL);
  }
}
