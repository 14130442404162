export const MENTIONS_ROUTING = [
  {
    path: '/mention',
    name: 'mention',
    component: () =>
      import(
        /* webpackChunkName: "mentions-page" */ './pages/mentions/mentions.page'
      )
  }
];
