import { createClassNames } from '@portal/classnames';

import type {
  TypographyBaseClass,
  TypographyClass,
  TypographyModification
} from './types';

type ClassConfig = Record<TypographyModification, string>;
type PrimaryConfig = Record<TypographyBaseClass['primary'], ClassConfig>;
type SecondaryConfig = Record<TypographyBaseClass['secondary'], ClassConfig>;

export const tc = createClassNames<TypographyClass>();

export const typography: {
  primary: PrimaryConfig;
  secondary: SecondaryConfig;
} = {
  primary: {
    h1Headline: {
      mobile: tc('primaryH1HeadlineMobile'),
      tablet: tc('primaryH1HeadlineTablet'),
      laptop: tc('primaryH1HeadlineLaptop'),
      desktop: tc('primaryH1HeadlineDesktop')
    },
    h2Headline: {
      mobile: tc('primaryH2HeadlineMobile'),
      tablet: tc('primaryH2HeadlineTablet'),
      laptop: tc('primaryH2HeadlineLaptop'),
      desktop: tc('primaryH2HeadlineDesktop')
    },
    h3Headline: {
      mobile: tc('primaryH3HeadlineMobile'),
      tablet: tc('primaryH3HeadlineTablet'),
      laptop: tc('primaryH3HeadlineLaptop'),
      desktop: tc('primaryH3HeadlineDesktop')
    },
    h4Headline: {
      mobile: tc('primaryH4HeadlineMobile'),
      tablet: tc('primaryH4HeadlineTablet'),
      laptop: tc('primaryH4HeadlineLaptop'),
      desktop: tc('primaryH4HeadlineDesktop')
    },
    h5Headline: {
      mobile: tc('primaryH5HeadlineMobile'),
      tablet: tc('primaryH5HeadlineTablet'),
      laptop: tc('primaryH5HeadlineLaptop'),
      desktop: tc('primaryH5HeadlineDesktop')
    },
    h6Headline: {
      mobile: tc('primaryH6HeadlineMobile'),
      tablet: tc('primaryH6HeadlineTablet'),
      laptop: tc('primaryH6HeadlineLaptop'),
      desktop: tc('primaryH6HeadlineDesktop')
    },
    body1: {
      mobile: tc('primaryBody1Mobile'),
      tablet: tc('primaryBody1Tablet'),
      laptop: tc('primaryBody1Laptop'),
      desktop: tc('primaryBody1Desktop')
    },
    body1Accent: {
      mobile: tc('primaryBody1AccentMobile'),
      tablet: tc('primaryBody1AccentTablet'),
      laptop: tc('primaryBody1AccentLaptop'),
      desktop: tc('primaryBody1AccentDesktop')
    },
    body2: {
      mobile: tc('primaryBody2Mobile'),
      tablet: tc('primaryBody2Tablet'),
      laptop: tc('primaryBody2Laptop'),
      desktop: tc('primaryBody2Desktop')
    },
    body2Accent: {
      mobile: tc('primaryBody2AccentMobile'),
      tablet: tc('primaryBody2AccentTablet'),
      laptop: tc('primaryBody2AccentLaptop'),
      desktop: tc('primaryBody2AccentDesktop')
    },
    subtitle1: {
      mobile: tc('primarySubtitle1Mobile'),
      tablet: tc('primarySubtitle1Tablet'),
      laptop: tc('primarySubtitle1Laptop'),
      desktop: tc('primarySubtitle1Desktop')
    },
    subtitle1Accent: {
      mobile: tc('primarySubtitle1AccentMobile'),
      tablet: tc('primarySubtitle1AccentTablet'),
      laptop: tc('primarySubtitle1AccentLaptop'),
      desktop: tc('primarySubtitle1AccentDesktop')
    },
    subtitle2: {
      mobile: tc('primarySubtitle2Mobile'),
      tablet: tc('primarySubtitle2Tablet'),
      laptop: tc('primarySubtitle2Laptop'),
      desktop: tc('primarySubtitle2Desktop')
    },
    subtitle2Accent: {
      mobile: tc('primarySubtitle2AccentMobile'),
      tablet: tc('primarySubtitle2AccentTablet'),
      laptop: tc('primarySubtitle2AccentLaptop'),
      desktop: tc('primarySubtitle2AccentDesktop')
    },
    button: {
      mobile: tc('primaryButtonMobile'),
      tablet: tc('primaryButtonTablet'),
      laptop: tc('primaryButtonLaptop'),
      desktop: tc('primaryButtonDesktop')
    },
    overline: {
      mobile: tc('primaryOverlineMobile'),
      tablet: tc('primaryOverlineTablet'),
      laptop: tc('primaryOverlineLaptop'),
      desktop: tc('primaryOverlineDesktop')
    }
  },
  secondary: {
    heading3: {
      mobile: tc('secondaryHeading3Mobile'),
      tablet: tc('secondaryHeading3Tablet'),
      laptop: tc('secondaryHeading3Laptop'),
      desktop: tc('secondaryHeading3Desktop')
    },
    heading4: {
      mobile: tc('secondaryHeading4Mobile'),
      tablet: tc('secondaryHeading4Tablet'),
      laptop: tc('secondaryHeading4Laptop'),
      desktop: tc('secondaryHeading4Desktop')
    },
    heading5: {
      mobile: tc('secondaryHeading5Mobile'),
      tablet: tc('secondaryHeading5Tablet'),
      laptop: tc('secondaryHeading5Laptop'),
      desktop: tc('secondaryHeading5Desktop')
    },
    lead: {
      mobile: tc('secondaryLeadMobile'),
      tablet: tc('secondaryLeadTablet'),
      laptop: tc('secondaryLeadLaptop'),
      desktop: tc('secondaryLeadDesktop')
    },
    bodyMain: {
      mobile: tc('secondaryBodyMainMobile'),
      tablet: tc('secondaryBodyMainTablet'),
      laptop: tc('secondaryBodyMainLaptop'),
      desktop: tc('secondaryBodyMainDesktop')
    },
    bodyMainAccent: {
      mobile: tc('secondaryBodyMainAccentMobile'),
      tablet: tc('secondaryBodyMainAccentTablet'),
      laptop: tc('secondaryBodyMainAccentLaptop'),
      desktop: tc('secondaryBodyMainAccentDesktop')
    },
    bodyMain2: {
      mobile: tc('secondaryBodyMain2Mobile'),
      tablet: tc('secondaryBodyMain2Tablet'),
      laptop: tc('secondaryBodyMain2Laptop'),
      desktop: tc('secondaryBodyMain2Desktop')
    },
    bodyMain2Accent: {
      mobile: tc('secondaryBodyMain2AccentMobile'),
      tablet: tc('secondaryBodyMain2AccentTablet'),
      laptop: tc('secondaryBodyMain2AccentLaptop'),
      desktop: tc('secondaryBodyMain2AccentDesktop')
    },
    label1: {
      mobile: tc('secondaryLabel1Mobile'),
      tablet: tc('secondaryLabel1Tablet'),
      laptop: tc('secondaryLabel1Laptop'),
      desktop: tc('secondaryLabel1Desktop')
    },
    label2: {
      mobile: tc('secondaryLabel2Mobile'),
      tablet: tc('secondaryLabel2Tablet'),
      laptop: tc('secondaryLabel2Laptop'),
      desktop: tc('secondaryLabel2Desktop')
    }
  }
};
