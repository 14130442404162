import { isBrowser } from '@fontanka/browser-or-node';
import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { BaseParams } from '../params';
import { MentionsPageDTO } from './mentions-page-dto';
import { SearchParams, SearchHttpClient } from '../search-http-client';

export type MentionsPageParams = BaseParams & {
  pagesize: number;
};

export type MentionsParams = Pick<
  SearchParams,
  Exclude<keyof SearchParams, 'theme' | 'format'>
>;

export class MentionsHttpClient extends BaseHttpClient {
  private readonly _searchHttpClient: SearchHttpClient;

  private readonly _mentionsPageUrl = '/pages/fontanka/opinions/';

  private readonly _searchFormat = 'mention';

  constructor(config: Config) {
    super(config);

    this._searchHttpClient = new SearchHttpClient(config);
  }

  public async fetchMentionsPageData(
    params: MentionsPageParams
  ): Promise<Result<MentionsPageDTO>> {
    const { pagesize, headers } = params;
    const url = `${this.host}${this._mentionsPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<MentionsPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId,
        pagesize
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }

  public async fetchMentionRecords(params: MentionsParams) {
    return this._searchHttpClient.searchRecords({
      ...params,
      format: this._searchFormat
    });
  }
}
