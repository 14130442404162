import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import { DefaultPageHeader } from '../_headers';

import styles from './stat-page-wrapper.styles.scss';

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    header: {
      type: String,
      required: false,
      default: ''
    }
  },
  render() {
    return (
      <div class={styles.container}>
        <DefaultPageHeader>
          <span domPropsInnerHTML={this.header} />
        </DefaultPageHeader>

        {this.$slots.default}
      </div>
    );
  }
});
