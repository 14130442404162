
















import { Component, Prop, Emit, Vue } from 'vue-property-decorator';

/**
 * @description Компонент, позволяющий скроллить содержимое как стандартными средствами
 * (колесо мыши, тачпад, экран смартфона), так и перетаскиванием контента мышью
 */
@Component({
  name: 'UiScrollable'
})
export default class UiScrollable extends Vue {
  @Prop({
    type: String,
    default: 'div'
  })
  tag: string;

  @Prop({
    type: String,
    default: 'horizontal'
  })
  direction: 'horizontal' | 'vertical';

  @Prop({
    type: Boolean,
    default: false
  })
  fadeOut: boolean;

  isFadeOutShown = false;

  mousePosition = {
    x: 0,
    y: 0
  };

  scrollPosition = {
    x: 0,
    y: 0
  };

  isDragging = false;
  isMoved = false;

  @Emit('onScrolled')
  scrollHandler() {
    this.fadeOutHandler();
  }

  mouseMoveHandler(event: MouseEvent) {
    this.isMoved = true;

    const [dx, dy] = [
      event.clientX - this.mousePosition.x,
      event.clientY - this.mousePosition.y
    ];

    if (this.direction === 'horizontal') {
      this.$el.scrollLeft = this.scrollPosition.x - dx;
    } else {
      this.$el.scrollTop = this.scrollPosition.y - dy;
    }
  }

  mouseUpHandler() {
    this.isDragging = false;

    /* eslint-disable @typescript-eslint/unbound-method */
    document.removeEventListener('mousemove', this.mouseMoveHandler);
    document.removeEventListener('mouseup', this.mouseUpHandler);
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  mouseDownHandler(event: MouseEvent) {
    event.preventDefault();
    this.isDragging = true;

    const { clientX, clientY } = event;

    this.mousePosition = {
      x: clientX,
      y: clientY
    };

    this.scrollPosition = {
      x: this.$el.scrollLeft,
      y: this.$el.scrollTop
    };

    /* eslint-disable @typescript-eslint/unbound-method */
    document.addEventListener('mousemove', this.mouseMoveHandler);
    document.addEventListener('mouseup', this.mouseUpHandler);
    /* eslint-enable @typescript-eslint/unbound-method */
  }

  clickHandler(event: MouseEvent) {
    if (this.isMoved) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.isMoved = false;
  }

  fadeOutHandler() {
    if (this.fadeOut && this.direction === 'horizontal') {
      this.isFadeOutShown =
        this.$el.scrollLeft + this.$el.clientWidth < this.$el.scrollWidth;
    }
  }

  mounted() {
    this.scrollHandler();
  }

  beforeDestroy() {
    this.mouseUpHandler();
  }
}
