import { State, Action } from 'vuex-simple';

import { PHOTOS_VIDEOS_INFO } from '@fontanka/data';

import type { RootModule } from '../../../core';
import type { BaseRecord } from '../../../domain';
import type { PhotosVideosPage as _PhotosVideosPage } from '../domain';
import { PhotosVideosPageMapper, PhotosVideosRecordsDataMapper } from '../services';

type PhotosVideosPage = _PhotosVideosPage;

export const PHOTOS_VIDEOS_MODULE_NAMESPACE = ['photos_videos_module'];

export class PhotosVideosPageModule {
  @State()
  public blocks: PhotosVideosPage['blocks'];

  @State()
  public records: PhotosVideosPage['recordsInfo']['records'];

  @State()
  public totalRecords = 0;

  private readonly _photosVideosRecordsDataMapper: PhotosVideosRecordsDataMapper;

  private readonly _recordsPageSize = PHOTOS_VIDEOS_INFO.PAGE_SIZE;

  private _currentRecordsPage = PHOTOS_VIDEOS_INFO.DEFAULT_START_PAGE + 1;

  constructor(private _root: RootModule) {
    this._photosVideosRecordsDataMapper = new PhotosVideosRecordsDataMapper(
      this._root.envType
    );

    const { blocks, recordsInfo } = PhotosVideosPageMapper.toDO(this._root.pageSpec);

    this.records = recordsInfo.records;
    this.totalRecords = recordsInfo.total;
    this.blocks = blocks;
  }

  @Action()
  async fetchRecords(type: 'photo' | 'video') {
    let records = [];

    if (type === 'photo') {
      records = await this._photosVideosRecordsDataMapper.getPhotosRecords(
        this._currentRecordsPage,
        this._recordsPageSize
      );
    } else {
      records = await this._photosVideosRecordsDataMapper.getVideosRecords(
        this._currentRecordsPage,
        this._recordsPageSize
      );
    }

    if (records.length !== 0) {
      this._addRecords(records);

      this._currentRecordsPage = this._currentRecordsPage + 1;
    }
  }

  private _addRecords(records: BaseRecord[]) {
    this.records = [...this.records, ...records];
  }
}
