import { createAllAuthors } from '@fontanka/news';
import { AuthorHttpClient } from '@fontanka/news-api-client';

import type { AuthorsData } from '../domain';

type FetchAuthorsParams = {
  page?: number;
  pagesize?: number;
  text?: string;
  sort?: string;
};

export class AuthorsDataMapper {
  private readonly _authorHttpClient: AuthorHttpClient;

  constructor(envType: string) {
    this._authorHttpClient = new AuthorHttpClient({
      envType,
      protocol: 'https'
    });
  }

  public async getAuthors(params: FetchAuthorsParams): Promise<AuthorsData> {
    const result: AuthorsData = {
      authors: [],
      meta: {
        total: 0
      }
    };

    try {
      const {
        data: { data, meta }
      } = await this._authorHttpClient.fetchAuthors(params);

      result.authors = createAllAuthors(data);
      result.meta.total = meta.total;
    } catch (err) {
      console.error(err);
    }

    return result;
  }
}
