import { createAuthor } from '@fontanka/news';
import type {
  AuthorPageDTO,
  AuthorPageAuthorDTO,
  AuthorPageAuthorRecordsDTO
} from '@fontanka/news-api-client';

import { BaseRecordMapper } from '../../../services';
import type { AuthorPage } from '../domain';

export class AuthorPageMapper {
  public static toDO(pageDTO: AuthorPageDTO): AuthorPage {
    const INITIAL: AuthorPage = {
      author: null,
      recordsInfo: {
        records: [],
        total: 0
      }
    };

    return pageDTO.config.content.reduce((acc, current) => {
      const block = pageDTO.data[current.path];

      if (current.type === 'author') {
        acc.author = createAuthor(block.data as AuthorPageAuthorDTO['data']);

        return acc;
      }

      if (current.type === 'author_records') {
        acc.recordsInfo.records =
          (block.data as AuthorPageAuthorRecordsDTO['data'])?.map(record =>
            BaseRecordMapper.toDO(record)
          ) ?? [];

        acc.recordsInfo.total = block.meta?.total ?? 0;

        return acc;
      }

      return acc;
    }, INITIAL);
  }
}
