import * as tsx from 'vue-tsx-support';

import type { BaseRecord } from '@apps/frontend';
import { injectStylesMixin } from '@apps/frontend';
import { ObserverVisibility } from '@fontanka/observe-visibility';

import { SideSliderBlock } from '../_blocks';
import { SliderHorizontalItem } from '../_items';

import styles from './business-news.styles.scss';

type BusinessHeader = {
  text: string;
  link: string;
};

type Events = {
  onClickedRecord: number;
  onChangedSlide: 'next' | 'prev';
  onClickedHeaderSlide: void;
  onVisible: void;
};

export default tsx
  .componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'BusinessNews',
    props: {
      records: {
        type: Array as () => BaseRecord[],
        required: true as const
      }
    },
    data() {
      return {
        currentSlideId: 0,
        isBusinessRecordActive: false,
        isSliderReady: false,
        businessNewsVisibility: new ObserverVisibility('half')
      };
    },
    computed: {
      businessHeader(): BusinessHeader {
        return this.isBusinessRecordActive
          ? {
              link: '/bt',
              text: 'Бизнес-трибуна'
            }
          : {
              link: '/format/fproject',
              text: 'Фонтанка Pro'
            };
      }
    },
    beforeMount() {
      if (this.records[0]) {
        this.isBusinessRecordActive = this.records[0].isBusiness;
      }
    },
    mounted() {
      this.businessNewsVisibility.event.subscribe(state => {
        if (state.value === true) {
          this.$emit('visible');
        }
      });
    },
    beforeDestroy() {
      this.businessNewsVisibility.destroy();
    },
    methods: {
      changeBusinessHeader(slideId: number) {
        this.isBusinessRecordActive = this.records?.[slideId]?.isBusiness ?? false;
      },
      changedSlide(slideId: number) {
        if (this.currentSlideId !== slideId) {
          this.changeBusinessHeader(slideId);

          const emitValue = this.currentSlideId < slideId ? 'next' : 'prev';
          this.$emit('changedSlide', emitValue);
          this.currentSlideId = slideId;
        }
      },
      clickedRecord(record: BaseRecord, checkAuthor = false) {
        if (checkAuthor && record.author?.url) {
          return;
        }

        this.$emit('clickedRecord', record.id);
      },
      emitClickedHeaderSlide() {
        this.$emit('clickedHeaderSlide');
      }
    },
    render() {
      return (
        <SideSliderBlock
          v-observe-visibility={this.businessNewsVisibility.getOptions('half')}
          class={this.isSliderReady ? styles.ready : ''}
          header={this.businessHeader.text}
          link={this.businessHeader.link}
          slidesCount={this.records.length}
          onSlideChanged={this.changedSlide}
          onReady={() => (this.isSliderReady = true)}
          onClickedHeader={this.emitClickedHeaderSlide}
        >
          {this.records.map(sliderItem => {
            const imageObj = sliderItem.author?.image || sliderItem?.image || null;
            const image = {
              url: imageObj?.url || '',
              sources: imageObj?.sources || []
            };
            return (
              <div class={styles.sliderItem}>
                <SliderHorizontalItem
                  url={sliderItem.urls?.url || ''}
                  header={sliderItem.header}
                  image={image}
                  author={{
                    name: sliderItem.author?.name || '',
                    url: sliderItem.author?.url || ''
                  }}
                  hasCommercialLabel={sliderItem.hasMainPhotoCommercialLabel}
                  onClickedPhoto={() => this.clickedRecord(sliderItem, true)}
                  onClickedHeader={() => this.clickedRecord(sliderItem)}
                />
              </div>
            );
          })}
        </SideSliderBlock>
      );
    }
  });
