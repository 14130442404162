import type {
  WeatherPageDTO,
  WeatherPageContentDTO
} from '@fontanka/news-api-client';

import type { WeatherPage } from '../domain';

import { WeatherMapper } from './weather-mapper.service';

export class WeatherPageMapper {
  public static toDO(pageDTO: WeatherPageDTO): WeatherPage {
    const INITIAL: WeatherPage = {
      blocks: []
    };

    return pageDTO.config.content.reduce((acc, current) => {
      if (current.type === 'weather_data') {
        const weatherPageData = pageDTO.data[current.path];
        const data = weatherPageData.data as WeatherPageContentDTO;

        if (data) {
          acc.blocks.push({
            type: current.type,
            data: WeatherMapper.toDO(data)
          });
        }
      }

      return acc;
    }, INITIAL);
  }
}
