import { guardUnspecified, guardZeroNumber } from '@smh/utils/guards';

import { createAuthorImage } from '@fontanka/news';
import type { OpinionAuthorDTO, OpinionDTO } from '@fontanka/news-api-client';

import type { Opinion, OpinionAuthor, OpinionStatistic } from '../domain';

export class OpinionMapper {
  public static toDo(item: OpinionDTO): Opinion {
    return {
      id: item.id,
      author: this._presentAuthor(item.authors[0]),
      header: {
        link: item.urls?.url ?? '',
        text: item.header
      },
      statistic: {
        comments: this._presentComments(item),
        date: item.publishedAgo
          ? {
              publishedAgo: item.publishedAgo
            }
          : undefined
      }
    };
  }

  public static _presentComments(
    data: OpinionDTO
  ): OpinionStatistic['comments'] | undefined {
    const commentsCount = data.commentsCount ?? 0;
    const isDiscuss = !guardZeroNumber(commentsCount);
    const commentsUrl = data.urls?.urlComments ?? '';
    const discussUrl = `${commentsUrl}?discuss=1`;

    return {
      text: isDiscuss ? 'Обсудить' : `${commentsCount}`,
      link: isDiscuss ? discussUrl : commentsUrl
    };
  }

  private static _presentAuthor(author: OpinionAuthorDTO | null): OpinionAuthor {
    const result: OpinionAuthor = {
      name: 'Анонимный автор',
      jobPosition: '',
      image: null,
      sex: 'unknown',
      url: '',
      isAnonymous: true
    };

    if (guardUnspecified(author)) {
      result.name = author.name;
      result.jobPosition = author.jobPosition;
      result.image = guardUnspecified(author.image)
        ? createAuthorImage(author.image, { crop: true })
        : null;
      result.sex = author.sex ?? 'unknown';
      result.url = author.url ?? '';
      result.isAnonymous = false;
    }
    return result;
  }
}
