import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './transparent-btn.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    type: {
      type: String,
      required: false,
      default: 'button'
    },
    link: {
      type: String,
      default: ''
    }
  },
  render() {
    return this.link ? (
      <a class={styles.transparentBtn} href={this.link} title="">
        {this.$slots.default}
      </a>
    ) : (
      <button class={styles.transparentBtn} type={this.type}>
        {this.$slots.default}
      </button>
    );
  }
});
