import { AttachDTO } from './AttachDTO';
import { ImageDTO } from '../BaseDTOs';

type Media = {
  class: 'center' | 'center-vertical';
  // '0' | '1'
  'main-photo': string;
  // '1' | '2' | etc.
  position: string;
  'image-type'?: ImageType;
};

export type ImageType =
  | 'photo'
  | 'infographics'
  | 'collage'
  | 'map'
  | 'scheme'
  | 'screenshot'
  | 'picture'
  | 'sketch';

export const DATA_IMAGE_TYPE_TO_NAME_MAP: Record<ImageType, string> = {
  photo: 'Фото',
  infographics: 'Инфографика',
  collage: 'Коллаж',
  map: 'Карта',
  scheme: 'Схема',
  screenshot: 'Скриншот',
  picture: 'Иллюстрация',
  sketch: 'Эскиз'
};

export type ImageAttachDTO = AttachDTO<Media, 'images', ImageDTO>;
