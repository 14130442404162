import {
  guardEmptyArray,
  guardEmptyString,
  guardUnspecified
} from '@smh/utils/guards';

import type { Feedback } from '@fontanka/news-api-client';

import type { PageCommonFeedback, SocialVM } from '../domain';

const FEEDBACK_SOCIALS_MAP: SocialVM[] = [
  {
    name: 'Telegram',
    icon: 'telegram'
  },
  {
    name: 'Viber',
    icon: 'viber',
    target: '_self'
  },
  {
    name: 'Группа вконтакте',
    icon: 'vk'
  },
  {
    name: 'Whatsapp',
    icon: 'whatsapp'
  },
  {
    name: 'Одноклассники',
    icon: 'odnoklassniki'
  }
];

const blockSpecDataFeedback = {
  phone: '',
  phoneText: '',
  socialLinksDesktop: {
    instagram: '',
    odnoklassniki: '',
    telegram: 'https://t.me/fontanka_reporter_bot',
    viber: '',
    vk: 'https://vk.com/fontanka',
    whatsapp: ''
  },
  socialLinksMobile: {
    instagram: '',
    odnoklassniki: '',
    telegram: 'https://t.me/fontanka_reporter_bot',
    viber: '',
    vk: 'https://vk.com/fontanka',
    whatsapp: ''
  },
  text: 'Отправьте свою новость в редакцию, расскажите о проблеме или подкиньте тему для публикации. Сюда же загружайте ваше видео и фото.'
};

export class FeedbackMapper {
  public static toDo(isMobile: boolean): PageCommonFeedback | null {
    const phonesList = [];
    const socialsList = this._getSocialsList(blockSpecDataFeedback, isMobile);

    if (
      guardUnspecified(blockSpecDataFeedback.phone) &&
      guardEmptyString(blockSpecDataFeedback.phone)
    ) {
      phonesList.push({
        url: this._getPhoneLink(blockSpecDataFeedback.phone),
        number: blockSpecDataFeedback.phone
      });
    }

    return {
      title: 'Сообщить новость',
      text: blockSpecDataFeedback.text,
      phones: {
        label: '',
        isShow: guardEmptyArray(phonesList),
        list: phonesList
      },
      socials: {
        label: '',
        isShow: guardEmptyArray(socialsList),
        list: socialsList
      }
    };
  }

  private static _getPhoneLink(value: string): string {
    return `tel:${value.replace(/-|\s/g, '')}`;
  }

  private static _getSocialsList(data: Feedback, isMobile: boolean): SocialVM[] {
    const socialLinks = isMobile ? data.socialLinksMobile : data.socialLinksDesktop;

    const availableSocials = FEEDBACK_SOCIALS_MAP.filter((social: SocialVM) => {
      const link: string | undefined = socialLinks[social.icon];
      return guardUnspecified(link) && guardEmptyString(link);
    });

    return availableSocials.map((social: SocialVM) => {
      const socialName = social.icon;
      const getViberTitle = () =>
        isMobile ? '' : 'Должен быть установлен Viber для ПК';

      return {
        ...social,
        url: socialLinks[socialName],
        title: socialName === 'viber' ? getViberTitle() : social.title
      };
    });
  }
}
