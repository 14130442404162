import { State, Mutation, Getter, Action } from 'vuex-simple';

import { FORMS_INFO } from '@fontanka/data';
import { isEmailValid } from '@fontanka/email-validation';
import { ProfileHttpClient } from '@fontanka/news-api-client';

import type { RootModule } from '../../../core';

type Result = {
  success: boolean;
  error: string;
};

const {
  EMPTY_EMAIL_ERROR,
  INVALID_EMAIL_ERORR,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  MIN_LENGTH_PASSWORD_ERROR,
  CONFIRM_PASSRORD_ERROR,
  MAX_LENGTH_PASSWORD_ERROR,
  YA_RULES_PASSWORD_SYMBOLS_ERROR,
  PASSWORD_ALLOW_SYMBOLS_REG_EX,
  SYMBOLS_REG_EX,
  LETTERS_REG_EX,
  NUMBERS_REG_EX,
  PASSWORD_LIKE_LOGIN_ERROR
} = FORMS_INFO;

export const RESTORE_PASSWORD_PAGE_MODULE_NAMESPACE = [
  'restore_password_page_module'
];

export class RestorePasswordPageModule {
  @State()
  public password = '';

  @State()
  public confirmPassword = '';

  @State()
  public restoreResult: Result = {
    success: false,
    error: ''
  };

  @State()
  public confirmRestoreResult: Result = {
    success: false,
    error: ''
  };

  @State()
  private _email = '';

  @Getter()
  public get code(): string {
    return this._root?.pageInfo?.code ?? '';
  }

  @Getter()
  public get isConfirmationStage(): boolean {
    return this.code ? true : false;
  }

  @Getter()
  public get email(): string {
    return this.isConfirmationStage ? this._confirmationLogin : this._email;
  }

  @Getter()
  public get emailError(): string {
    const trimmedEmail = this.email.trim();

    if (trimmedEmail.length === 0) {
      return EMPTY_EMAIL_ERROR;
    }

    if (!isEmailValid(trimmedEmail)) {
      return INVALID_EMAIL_ERORR;
    }

    return '';
  }

  @Getter()
  public get passwordError(): string {
    const { password } = this;

    if (password.length < PASSWORD_MIN_LENGTH) {
      return MIN_LENGTH_PASSWORD_ERROR;
    }

    if (password.length > PASSWORD_MAX_LENGTH) {
      return MAX_LENGTH_PASSWORD_ERROR;
    }

    if (password === this.email || password === this.email.split('@')[0]) {
      return PASSWORD_LIKE_LOGIN_ERROR;
    }

    if (!this._isPasswordValid(password)) {
      return YA_RULES_PASSWORD_SYMBOLS_ERROR;
    }

    return '';
  }

  @Getter()
  public get confirmPasswordError(): string {
    if (this.password !== this.confirmPassword) {
      return CONFIRM_PASSRORD_ERROR;
    }

    return '';
  }

  @Getter()
  private get _confirmationLogin(): string {
    return this._root?.pageInfo?.login ?? '';
  }

  private _profileHttpClient: ProfileHttpClient;

  constructor(private _root: RootModule) {
    this._profileHttpClient = new ProfileHttpClient({
      envType: this._root.envType ?? '',
      protocol: 'https'
    });
  }

  @Action()
  public async restore() {
    try {
      await this._profileHttpClient.restorePassword({
        login: this.email.trim()
      });
      this._changeRestoreResult({ error: '', success: true });
    } catch (err) {
      this._changeRestoreResult({ error: err.message, success: false });
    }
  }

  @Action()
  public async confirmRestore() {
    try {
      await this._profileHttpClient.confirmRestorePassword({
        login: this.email.trim(),
        code: this.code,
        password: this.password.trim()
      });
      this._changeConfirmRestoreResult({ error: '', success: true });
    } catch (err) {
      this._changeConfirmRestoreResult({ error: err.message, success: false });
    }
  }

  @Mutation()
  public changeEmail(email: string) {
    this._email = email;
  }

  @Mutation()
  public changePassword(password: string) {
    this.password = password;
  }

  @Mutation()
  public changeConfirmPassword(confirmPassword: string) {
    this.confirmPassword = confirmPassword;
  }

  @Mutation()
  private _changeRestoreResult(result: Result) {
    this.restoreResult = result;
  }

  @Mutation()
  private _changeConfirmRestoreResult(result: Result) {
    this.confirmRestoreResult = result;
  }

  private _isPasswordValid(password: string) {
    const hasNumbers = password.match(NUMBERS_REG_EX) !== null;
    const hasLetters = password.match(LETTERS_REG_EX) !== null;
    const hasSymbols = password.match(SYMBOLS_REG_EX) !== null;

    return (
      hasLetters &&
      hasNumbers &&
      hasSymbols &&
      password.match(PASSWORD_ALLOW_SYMBOLS_REG_EX) !== null
    );
  }
}
