import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin, storeMixin } from '@apps/frontend';

import styles from './recaptcha.styles.scss';

type Events = {
  onTokenGenerated: string;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IRecaptchaComponent {
  execute: () => Promise<void>;
}

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .mixin(storeMixin)
  .create({
    props: {
      recaptchaKey: {
        type: String,
        required: true as const
      },
      action: {
        type: String as () =>
          | 'add_comment'
          | 'send_news'
          | 'send_mistakes'
          | 'user_auth',
        required: true as const
      }
    },
    methods: {
      async execute() {
        try {
          const token = await window.grecaptcha.execute(this.recaptchaKey, {
            action: this.action
          });
          this.$emit('tokenGenerated', token);
        } catch (error) {
          console.error(error);
        }
      }
    },
    render() {
      return (
        <div class={styles.recaptchaText}>
          Этот сайт защищен reCAPTCHA и Google. Применяются&ensp;
          <a href="https://policies.google.com/privacy" target="_blank">
            Политика конфиденциальности
          </a>
          &ensp;и&nbsp;
          <a href="https://policies.google.com/terms" target="_blank">
            Условия использования
          </a>
          .
        </div>
      );
    }
  });
