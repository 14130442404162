import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import ArrowIcon from 'images/pictograms/arrow-left.svg';

import { Slider } from '../../_sliders';

import styles from './auto-slider-block.styles.scss';

type ComponentData = {
  currentSlide: number;
  sliderWrapperHeight: number;
  isSliderReady: boolean;
};

type Events = {
  onSlideChanged: number;
  onTitleClicked: void;
  onSellAutoBtnClicked: void;
};

const SLIDE_IMG_RATIO_HEIGHT = 16;
const SLIDE_IMG_RATIO_WIDTH = 9;
const SLIDER_ITEMS_MARGIN_X = 20;
const SLIDE_IMG_RATIO = SLIDE_IMG_RATIO_HEIGHT / SLIDE_IMG_RATIO_WIDTH;
const SLIDE_CONTENT_HEIGHT = 71;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'AutoSliderBlock',
    props: {
      titleUrl: {
        type: String,
        required: true as const
      },
      sellAutoUrl: {
        type: String,
        required: true as const
      },
      title: {
        type: String,
        required: true as const
      },
      header: {
        type: String,
        required: true as const
      },
      slidesCount: {
        type: Number,
        required: true as const
      },
      slidesPerView: {
        type: Number,
        default: 1
      }
    },
    data(): ComponentData {
      return {
        currentSlide: 0,
        isSliderReady: false,
        sliderWrapperHeight: 0
      };
    },
    computed: {
      isFirstSlide(): boolean {
        return this.currentSlide === 0;
      },
      isLastSlide(): boolean {
        return this.currentSlide === this.slidesCount - this.slidesPerView;
      }
    },
    methods: {
      getSliderWrapperHeight(): number {
        const sliderItemWidth =
          Math.ceil(this.$el.scrollWidth / this.slidesPerView) -
          SLIDER_ITEMS_MARGIN_X * (this.slidesPerView - 1);
        const sliderImageHeight = Math.ceil(sliderItemWidth / SLIDE_IMG_RATIO);
        return sliderImageHeight + SLIDE_CONTENT_HEIGHT;
      },
      nextSlide() {
        if (!this.isLastSlide) {
          this.currentSlide = this.currentSlide + 1;
        }
      },
      prevSlide() {
        if (!this.isFirstSlide) {
          this.currentSlide = this.currentSlide - 1;
        }
      },
      setGroup(groupIndex: number) {
        const slideIndex =
          groupIndex > this.slidesCount - this.slidesPerView
            ? this.slidesCount - this.slidesPerView
            : groupIndex;
        this.currentSlide = slideIndex;
        this.emitChangeEvent(groupIndex);
      },
      emitChangeEvent(currentSlide: number) {
        this.$emit('slideChanged', currentSlide);
      },
      showSlider() {
        this.isSliderReady = true;
      },
      emitTitleClicked() {
        this.$emit('titleClicked');
      },
      emitSellAutoBtnClicked() {
        this.$emit('sellAutoBtnClicked');
      }
    },
    mounted() {
      this.sliderWrapperHeight = this.getSliderWrapperHeight();
    },
    render() {
      return (
        <div class={styles.autoSliderBlock}>
          <div class={styles.header}>
            <a
              href={this.titleUrl}
              class={styles.title}
              target="_blank"
              onClick={this.emitTitleClicked}
            >
              <h3>{this.title}</h3>
            </a>
            <a
              href={this.sellAutoUrl}
              class={styles.link}
              target="_blank"
              domPropsInnerHTML={this.header}
              onClick={this.emitSellAutoBtnClicked}
            />
          </div>

          {this.sliderWrapperHeight && (
            <div
              class={[
                styles.sliderWrapper,
                this.isSliderReady ? styles.visible : ''
              ]}
              style={`height: ${this.sliderWrapperHeight}px`}
            >
              <Slider
                currentGroup={this.currentSlide}
                onSlideChanged={this.setGroup}
                onReady={this.showSlider}
              >
                {this.$slots.default}
              </Slider>
            </div>
          )}
          <button
            class={[styles.prevBtn, this.isFirstSlide ? styles.disabled : '']}
            onClick={this.prevSlide}
          >
            <img src={ArrowIcon} alt="nav-prev" />
          </button>
          <button
            class={[styles.nextBtn, this.isLastSlide ? styles.disabled : '']}
            onClick={this.nextSlide}
          >
            <img src={ArrowIcon} alt="nav-next" />
          </button>
        </div>
      );
    }
  });
