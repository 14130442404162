import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';

import styles from './ui-button.styles.scss';

type BtnType = 'primary' | 'white' | 'lightGray' | 'transparent2';
type BtnSize = 'default' | 'small';

type Events = {
  onClick: Event;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'UiButton',
    props: {
      theme: {
        type: String as () => BtnType,
        default: 'primary'
      },
      height: {
        type: String as () => BtnSize,
        default: 'default'
      },
      isRounded: {
        type: Boolean,
        default: false
      },
      isDisabled: {
        type: Boolean,
        default: false
      },
      isTextOnly: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasText(): boolean {
        return this.$slots.text !== undefined;
      },
      hasIcon(): boolean {
        return this.$slots.icon !== undefined;
      },
      isSmallBtn(): boolean {
        return this.hasIcon && !this.hasText;
      },
      themeClass(): string {
        return `${this.theme}Theme`;
      },
      heightClass(): string {
        return `${this.height}Height`;
      }
    },
    render() {
      const attrs = {
        attrs: this.$attrs,
        on: this.$listeners
      };
      let TAG: 'a' | 'button' = 'button';

      if (attrs.attrs.href !== undefined) {
        TAG = 'a';
      }

      if (TAG !== 'a' && this.isDisabled) {
        attrs.attrs.disabled = 'true';
      }

      return (
        <TAG
          class={[
            styles.uiButton,
            styles[this.themeClass],
            styles[this.heightClass],
            this.isDisabled ? styles.disabled : '',
            this.isSmallBtn ? styles.small : '',
            this.isRounded ? styles.rounded : '',
            typography.primary.button.mobile
          ]}
          {...attrs}
        >
          {this.$slots.icon && <span class={styles.icon}>{this.$slots.icon}</span>}
          {this.$slots.text && (
            <span class={[styles.text, this.isTextOnly ? styles.textOnly : '']}>
              {this.$slots.text}
            </span>
          )}
          {this.$slots.arrow && (
            <span class={styles.arrow}>{this.$slots.arrow}</span>
          )}
        </TAG>
      );
    }
  });
