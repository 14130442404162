import { ArticleParamsDTO, RecordPageArticle } from '@fontanka/news-api-client';

import { Record, isMentionRecord, isRecordBroadcast } from '../../domain';
import { getMainRubric } from '../rubrics';

import { createAuthor } from './author-mapper.service';
import { createImage, createSimpleImage } from './image-mapper.service';
import { leadToString } from './lead-mapper';
import { createRecordCards } from './record-cards-mapper';
import { createRecordPosts } from './record-posts-mapper';
import { createRecordText } from './record-text-mapper';

// TODO: объявить DTO в самом маппере?

const BAA_IMAGE_URL = 'https://static.ngs.ru/info/ne-lekarstvo-2.png';
const CONTRAINDICATIONS_IMAGE_URL = 'https://static.ngs.ru/info/disclaimer.png';

export const createRecord = (
  article: RecordPageArticle,
  params?: ArticleParamsDTO
): Record => {
  const isBroadcast = isRecordBroadcast(article);
  const isMention = isMentionRecord(article.rubrics);
  const isCardsTemplate = article.cards.length > 0;

  const recordText = createRecordText({
    textDTO: article.text,
    recordId: article.id,
    rotatedInserts: article.rotatedInserts
  });
  const recordPosts = createRecordPosts(article.posts);
  const recordCards = createRecordCards(article.cards);

  return {
    id: article.id,
    commentsCount: article.commentsCount,
    originalHeader: article.originalHeader,
    header: article.header,
    headerKeywords: article.headerKeywords ?? [],
    isBroadcast,
    isCommentsAllowed: article.isCommentsAllowed,
    isCommercial: article.isCommercial,
    isMatureContent: article.isMatureContent,
    leadStr: leadToString(article.lead),
    mainPhoto: createImage(article.mainPhoto),
    text: recordText,
    isCardsTemplate,
    cards: recordCards,
    posts: recordPosts,
    rubrics: article.rubrics,
    mainRubric: getMainRubric(article.rubrics),
    subheader: article.subheader,
    urls: {
      url: article.urls.url,
      urlComments: article.urls.urlComments ?? ''
    },
    publishAt: article.publishAt,
    // TODO: Временное решение, тк пока в разных местах нужен разный формат даты (dd MMMM yyyy, HH:mm)
    publishDateForRecordHeader: article.publishDateForRecordHeader ?? '',
    sourceName: article.sourceName,
    isMention,
    mentionAuthor: isMention ? createAuthor(article.authors[0]) : null,
    authors: article.authors
      .map(author => createAuthor(author))
      .filter(<T>(author: T): author is NonNullable<T> => Boolean(author)),
    copyright: article.copyright,
    commercialLabel: article.commercialLabel,
    contraindications: createSimpleImage(
      article.isContraindications ? CONTRAINDICATIONS_IMAGE_URL : ''
    ),
    baa: createSimpleImage(article.isBaa ? BAA_IMAGE_URL : ''),
    themes: article.themes,
    yandexNewsHeader: article.yandexNewsHeader,
    voicedTextUrl: article.voicedTextUrl || '',
    viewsCount: article.viewsCount ?? 0,
    votes: article.votes,
    formats: article.formats ?? [],
    socialShareImage: article?.socialShare?.[0]?.image || '',
    params,
    hasMainPhotoCommercialLabel: article.hasMainPhotoCommercialLabel ?? false
  };
};
