import type { BaseSocialDTO } from '@fontanka/news-api-client';

import type { WeSocial } from '../domain';

export class WeSocialMapper {
  public static toDO(socialsFields?: BaseSocialDTO[]): WeSocial[] {
    if (!socialsFields) {
      return [];
    }

    return socialsFields.reduce((acc, current) => {
      switch (current.id) {
        case 'vk':
        case 'instagram':
        case 'telegram':
        case 'viber':
          acc.push({
            ...current
          } as WeSocial);
          break;
        default:
          break;
      }
      return acc;
    }, [] as WeSocial[]);
  }
}
