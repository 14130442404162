import { State, Getter } from 'vuex-simple';

import type { Image } from '@fontanka/news';

import type { RootModule } from '../../../core';
import type { RecordPage } from '../domain';
import { RecordPageMapper, RecordPageImagesMapper } from '../services';

export const RECORD_PAGE_MODULE_NAMESPACE = ['record_page_module'];

export class RecordPageModule {
  @State()
  public blocks: RecordPage['blocks'];

  @State()
  public record: RecordPage['record'];

  @State()
  public comments: RecordPage['comments'];

  @State()
  public socials: RecordPage['socials'];

  @State()
  public envType = '';

  @State()
  public images: Image[] = [];

  @Getter()
  public get recordId(): number {
    return this.record?.id ?? -1;
  }

  @Getter()
  public get imagesForFullscreen(): Image[] | [] {
    return this.images;
  }

  constructor(private _root: RootModule) {
    this.envType = this._root.envType;

    const { blocks, record, socials, comments } = RecordPageMapper.toDO(
      this._root.pageSpec
    );

    const imageMapper = new RecordPageImagesMapper(record);

    this.blocks = blocks;
    this.record = record;
    this.comments = comments;
    this.socials = socials;
    this.images = imageMapper.getRecordImages();
  }
}
