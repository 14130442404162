import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import { typography } from '@fe/common/styles/typography';
import { UiAdvLabel } from '@fe/common/ui';

import styles from './slider-horizontal-small-item.styles.scss';

type Author = {
  name: string;
  url: string;
};

type Image = {
  url: string;
  sources: {
    type: string;
    srcset: string;
  }[];
};

type Events = {
  onRecordClicked: Event;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SliderHorizontalSmallItem',
    props: {
      url: {
        type: String,
        required: true as const
      },
      header: {
        type: String,
        required: true as const
      },
      image: {
        type: Object as () => Image,
        required: false
      },
      publishAt: {
        type: String,
        required: true as const
      },
      author: {
        type: Object as () => Author,
        required: false
      },
      lead: {
        type: String,
        default: ''
      },
      hasCommercialLabel: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      clearedLead() {
        return this.lead.replace(new RegExp('<[^>]*>', 'g'), '');
      }
    },
    methods: {
      emitClickToRecord(event: Event) {
        this.$emit('recordClicked', event);
      }
    },
    render() {
      return (
        <div class={styles.item}>
          <div class={styles.mainInfo}>
            <div class={styles.contentWrap}>
              {this.publishAt ? (
                <time class={[styles.time, typography.primary.subtitle2.mobile]}>
                  {this.publishAt}
                </time>
              ) : null}
              <div class={styles.titleWrap}>
                {this.author?.url && this.author?.name ? (
                  <a
                    class={[styles.link, styles.author]}
                    href={this.author.url}
                    title={this.author.name}
                  >
                    {this.author.name}:
                  </a>
                ) : null}

                <a
                  class={[
                    styles.link,
                    styles.recordTitle,
                    typography.primary.body2Accent.mobile
                  ]}
                  href={this.url}
                  domPropsInnerHTML={this.header}
                  onClick={event => this.emitClickToRecord(event)}
                />
              </div>
            </div>
            {this.image?.url && (
              <a
                class={styles.imgWrapLink}
                href={this.author?.url || this.url}
                title={this.header}
                onClick={event => this.emitClickToRecord(event)}
              >
                <UiImage sources={this.image.sources}>
                  <img class={styles.img} src={this.image.url} alt="" />
                </UiImage>

                {this.hasCommercialLabel && <UiAdvLabel class={styles.advMarker} />}
              </a>
            )}
          </div>

          {this.clearedLead ? (
            <a
              class={[styles.leadLink, typography.primary.subtitle1.mobile]}
              href={this.author?.url || this.url}
              domPropsInnerHTML={this.clearedLead}
              onClick={event => this.emitClickToRecord(event)}
            />
          ) : null}
        </div>
      );
    }
  });
