import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import avatar from 'images/avatar.png';

import styles from './user-avatar.styles.scss';

type Avatar = {
  sources: {
    srcset: string;
    type: string;
  }[];
  url: string;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    avatar: {
      type: Object as () => Avatar,
      required: true as const
    }
  },
  render() {
    return (
      <UiImage sources={this.avatar.sources}>
        <img class={styles.userAvatar} src={this.avatar.url || avatar} alt="" />
      </UiImage>
    );
  }
});
