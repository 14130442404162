import { componentFactory } from 'vue-tsx-support';

import type { ArchiveRecord } from '../../_items';
import { ArchiveRecordItem } from '../../_items';

import styles from './search-records-list.styles.scss';

export default componentFactory.create({
  props: {
    records: {
      type: Array as () => ArchiveRecord[],
      required: true as const
    }
  },
  render() {
    return (
      <div>
        {this.records.length > 0 ? (
          this.records.map(record => (
            <ArchiveRecordItem
              class={styles.item}
              usePublishDate={true}
              record={record}
            />
          ))
        ) : (
          <span class={styles.noResults}>Ничего не найдено.</span>
        )}
      </div>
    );
  }
});
