import { ArticlePostsDTO } from '@fontanka/news-api-client';

import { RecordPosts } from '../../domain';

import { createAttach } from './attach-mapper';
import { createRecordText } from './record-text-mapper';

export const createRecordPosts = (postsDTO: ArticlePostsDTO): RecordPosts =>
  postsDTO.reduce(
    (acc, current) => {
      if (current.type === 'post') {
        acc.push({
          id: current.id,
          date: current.date,
          data: createRecordText({ textDTO: current.data }),
          type: 'post'
        });
      } else {
        const attach = createAttach({ attach: current });

        if (attach !== null) {
          acc.push(attach);
        }
      }

      return acc;
    },

    [] as RecordPosts
  );
