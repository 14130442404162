import { guardUnspecified } from '@portal/utils/util-guards';

import {
  RecordCommentMapper,
  BaseRecordMapper,
  BaseTagMapper,
  AdvMapper
} from '@fe/common/services';
import { createAuthor, createRecord } from '@fontanka/news';
import type {
  RecordPageDTO,
  RecordPageArticleDTO,
  RecordPageRecordCommentsDTO,
  RecordPageLinkedRecordsDTO,
  RecordPageTagsRecordDTO,
  ArticleParamsDTO,
  RecordPageAuthorsDTO,
  RecordPageAuthorRecordDTO,
  AdvBlockInfo,
  AdvBlockConfig,
  Smi2BlockDTO,
  Smi24BlockDTO,
  SparrowBlockDTO
} from '@fontanka/news-api-client';

import type { RecordPage } from '../domain';
import { getRecordSocials } from '../domain';

export class RecordPageMapper {
  public static toDO(pageDTO: RecordPageDTO): RecordPage {
    const INITIAL: RecordPage = {
      blocks: [],
      record: null,
      comments: null,
      socials: getRecordSocials()
    };

    const recordPage = pageDTO.config.content.reduce((acc, current) => {
      const { settings } = current;

      if (settings && settings.place === 'central') {
        if (current.type === 'article') {
          const { data } = pageDTO.data[current.path];
          const { params } = current;

          acc.blocks.push({
            type: current.type
          });

          acc.record = createRecord(
            data as RecordPageArticleDTO['data'],
            params as ArticleParamsDTO
          );
        }

        if (current.type === 'record_socials') {
          acc.blocks.push({
            type: current.type
          });
        }

        if (current.type === 'record_comments') {
          const MAX_COMMENTS_IN_BLOCK = 3;

          const block = pageDTO.data[current.path] as RecordPageRecordCommentsDTO;

          acc.blocks.push({
            type: 'record_comments'
          });

          const commentsList =
            block.data
              ?.map(c => RecordCommentMapper.getParentComment(c))
              .map((comment, index) => {
                if (comment.type === 'adv') {
                  return comment;
                }

                comment.isShowOnDesktop =
                  index < (current.params.pagesize.desktop || MAX_COMMENTS_IN_BLOCK);

                return comment;
              }) ?? [];

          acc.comments = {
            data: commentsList,
            total: block.meta?.commentsCount ?? 0
          };
        }

        if (current.type === 'linked_records') {
          const block = pageDTO.data[current.path] as RecordPageLinkedRecordsDTO;

          if (guardUnspecified(block.data)) {
            acc.blocks.push({
              type: 'linked_records',
              data: block.data.map(recordDTO => BaseRecordMapper.toDO(recordDTO))
            });
          }
        }

        if (current.type === 'tags') {
          const block = pageDTO.data[current.path] as RecordPageTagsRecordDTO;

          if (guardUnspecified(block.data)) {
            acc.blocks.push({
              type: current.type,
              data: block.data.map(tagDTO => BaseTagMapper.toDO(tagDTO))
            });
          }
        }

        if (current.type === 'record_footer_authors') {
          const formats =
            (pageDTO.data?.article?.data as RecordPageArticleDTO['data'])?.formats ||
            [];
          const isOpinion =
            formats?.some(format => format.name === 'Мнение') ?? false;
          const block = pageDTO.data[current.path] as RecordPageAuthorsDTO;
          if (block.data !== null) {
            acc.blocks.push({
              type: 'record_footer_authors',
              data: block.data.map(author => createAuthor(author)!)
            });
          } else if (block.data === null && isOpinion) {
            acc.blocks.push({
              type: 'record_footer_authors',
              data: [
                {
                  id: 0,
                  image: null,
                  jobPosition: '',
                  name: 'Анонимное мнение',
                  email: '',
                  url: '',
                  socialLinks: [],
                  description: ''
                }
              ]
            });
          }
        }

        if (current.type === 'votes') {
          acc.blocks.push({
            type: 'votes'
          });
        }

        if (current.type === 'authors_records_article_footer') {
          const block = pageDTO.data[
            current.path
          ] as unknown as RecordPageAuthorRecordDTO;

          acc.blocks.push({
            type: 'authors_records_article_footer',
            data: block.data || []
          });
        }

        if (current.type === 'smi2') {
          const block = pageDTO.data[current.path] as Smi2BlockDTO;

          acc.blocks.push({
            type: 'smi2',
            data: {
              partnerId: block.data.id
            }
          });
        }

        if (current.type === 'smi24') {
          const block = pageDTO.data[current.path] as Smi24BlockDTO;

          acc.blocks.push({
            type: 'smi24',
            data: {
              partnerId: block.data.id
            }
          });
        }

        if (current.type === 'sparrow') {
          const block = pageDTO.data[current.path] as SparrowBlockDTO;

          acc.blocks.push({
            type: 'sparrow',
            data: {
              sid: block.data.sid.toString(),
              keyMobile: block.data.dataKeyMobile,
              keyDesktop: block.data.dataKeyDesktop
            }
          });
        }

        if (
          current.type === 'adv' ||
          current.type === 'desktop_record_inread_advert'
        ) {
          const info = pageDTO.data[current.path] as AdvBlockInfo;

          acc.blocks.push(AdvMapper.toDO(current as AdvBlockConfig, info));
        }
      }

      return acc;
    }, INITIAL);

    return recordPage;
  }
}
