import type { BaseTagDTO } from '@fontanka/news-api-client';

import type { Tag } from '../domain';

export class BaseTagMapper {
  public static toDO(tagsSpec: BaseTagDTO): Tag {
    return {
      name: tagsSpec.name || '',
      url: tagsSpec.url || ''
    };
  }
}
