import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './pagination.styles.scss';

type Page = {
  n: number;
  text: string | number;
  active: boolean;
};

/** @deprecated устаревший дизайн */
export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'PaginationDeprecated',
  props: {
    url: {
      type: String,
      required: true as const
    },
    page: {
      type: Number,
      required: true as const
    },
    pagesize: {
      type: Number,
      required: true as const
    },
    totalItems: {
      type: Number,
      required: true as const
    },
    maxSize: {
      type: Number,
      required: false,
      default: 3
    },
    pageFieldName: {
      type: String,
      required: true as const
    }
  },
  computed: {
    noPrevious(): boolean {
      return this.page === 1;
    },
    noNext(): boolean {
      return this.page === this.totalPages;
    },
    totalPages(): number {
      const totalPages =
        this.pagesize < 1 ? 1 : Math.ceil(this.totalItems / this.pagesize);
      return Math.max(totalPages || 0, 1);
    },
    pages(): Page[] {
      const pages: Array<Page> = [];

      if (this.page <= 0 || this.page > this.totalPages) {
        return pages;
      }

      // Default page limits
      let startPage = 1;
      let endPage = this.totalPages;
      const isMaxSized = this.maxSize && this.maxSize < this.totalPages;

      // recompute if maxSize
      if (isMaxSized) {
        // Current page is displayed in the middle of the visible ones
        startPage = Math.max(this.page - Math.floor(this.maxSize / 2), 1);
        endPage = startPage + this.maxSize - 1;

        // Adjust if limit is exceeded
        if (endPage > this.totalPages) {
          endPage = this.totalPages;
          startPage = endPage - this.maxSize + 1;
        }
      }

      // Add page number links
      for (let num = startPage; num <= endPage; num++) {
        const page = this.makePage(num, num, num === this.page);
        pages.push(page);
      }

      if (isMaxSized && this.maxSize > 0) {
        if (startPage > 1) {
          if (startPage > 3) {
            // need ellipsis for all options unless range is too close to beginning
            const previousPageSet = this.makePage(startPage - 1, '...', false);
            pages.unshift(previousPageSet);
          }

          if (startPage === 3) {
            // need to replace ellipsis when the buttons would be sequential
            const secondPageLink = this.makePage(2, '2', false);
            pages.unshift(secondPageLink);
          }
          // add the first page
          const firstPageLink = this.makePage(1, '1', false);
          pages.unshift(firstPageLink);
        }

        if (endPage < this.totalPages) {
          if (endPage < this.totalPages - 2) {
            // need ellipsis for all options unless range is too close to end
            const nextPageSet = this.makePage(endPage + 1, '...', false);
            pages.push(nextPageSet);
          }

          if (endPage === this.totalPages - 2) {
            // need to replace ellipsis when the buttons would be sequential
            const secondToLastPageLink = this.makePage(
              this.totalPages - 1,
              this.totalPages - 1,
              false
            );
            pages.push(secondToLastPageLink);
          }
          // add the last page
          const lastPageLink = this.makePage(
            this.totalPages,
            this.totalPages,
            false
          );
          pages.push(lastPageLink);
        }
      }
      return pages;
    }
  },
  methods: {
    makePage(n: number, text: string | number, isActive: boolean): Page {
      return { n, text, active: isActive };
    },
    makePageUrl(page: number) {
      return `${this.url}&${this.pageFieldName}=${page}`;
    }
  },
  render() {
    return (
      <div class={styles.pagination}>
        <div class={styles.prevNextWrapper}>
          <a
            class={[styles.prevNext, this.noPrevious ? styles.active : '']}
            href={this.makePageUrl(this.page - 1)}
          >
            <span class={styles.arrow}></span>
            <span class={styles.wide} domPropsInnerHTML="&nbsp;Предыдущая"></span>
          </a>
          <a
            class={[styles.firstLast, this.noPrevious ? styles.active : '']}
            href={this.makePageUrl(1)}
          >
            Первая
          </a>
        </div>

        {this.pages.map(page => (
          <a
            class={[styles.item, page.active ? styles.active : '']}
            href={this.makePageUrl(page.n)}
          >
            {page.text}
          </a>
        ))}

        <div class={[styles.prevNextWrapper, styles.next]}>
          <a
            class={[styles.prevNext, this.noNext ? styles.active : '']}
            href={this.makePageUrl(this.page + 1)}
          >
            <span class={styles.wide} domPropsInnerHTML="Следующая&nbsp;"></span>
            <span class={[styles.arrow, styles.right]}></span>
          </a>
          <a
            class={[styles.firstLast, this.noNext ? styles.active : '']}
            href={this.makePageUrl(this.totalPages)}
          >
            Последняя
          </a>
        </div>
      </div>
    );
  }
});
