import { createSimpleImage } from '@fontanka/news';
import type { CommentUserDTO } from '@fontanka/news-api-client';

import type { CommentUser } from '../domain';

export class CommentUserMapper {
  public static toDO(userDTO: CommentUserDTO): CommentUser {
    return {
      ...userDTO,
      avatar: createSimpleImage(userDTO.avatar),
      sex:
        userDTO.sex === 'male' || userDTO.sex === 'female' ? userDTO.sex : 'unknown'
    };
  }
}
