import { ViqeoAttachDTO } from '@fontanka/news-api-client';
import { ViqeoAttach } from '../../../domain';
import { WIDE_RATIO_HEIGHT, WIDE_RATIO_WIDTH } from '@fontanka/cropping';

/**
 * Викео передает соотношение сторон в обратном порядке
 */
const DEFAULT_RATIO = WIDE_RATIO_HEIGHT / WIDE_RATIO_WIDTH;

export const createViqeoAttach = (attach: ViqeoAttachDTO): ViqeoAttach => ({
  type: 'viqeoVideo',
  value: {
    ...attach.value,
    aspectRatio: attach.value.aspectRatio || DEFAULT_RATIO,
    author: attach.value.author || '',
    description: attach.value.description || ''
  }
});
