import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import { FormSelect } from '../../_forms';

import styles from './archive-filter.styles.scss';

type Link = {
  url: string;
  type: ArchiveFilterLinkType;
  text: string;
};

type Option = {
  id: string;
  name: string;
};

type Events = {
  onLinkClicked: ArchiveFilterLinkType;
  onOptionChanged: 'all' | string;
};

export type ArchiveFilterLinkType = 'all' | 'news' | 'articles' | 'commercial';

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      currentLinkType: {
        type: String as () => ArchiveFilterLinkType,
        required: true as const,
        default: 'all'
      },
      links: {
        type: Array as () => Link[],
        required: true as const
      },
      currentOptionId: {
        type: String as () => 'all' | string,
        required: true as const
      },
      options: {
        type: Array as () => Option[],
        required: true as const
      }
    },
    methods: {
      linkClicked(e: MouseEvent, linkType: ArchiveFilterLinkType) {
        e.preventDefault();
        this.$emit('linkClicked', linkType);
      },
      optionChanged(optionId: string) {
        this.$emit('optionChanged', optionId);
      }
    },
    render() {
      return (
        <div class={styles.filter}>
          <div class={styles.left}>
            <div class={styles.linksWrapper}>
              {this.links.map(link => (
                <a
                  class={[
                    styles.link,
                    link.type === this.currentLinkType ? styles.active : ''
                  ]}
                  href={link.url}
                  onClick={e => this.linkClicked(e, link.type)}
                >
                  {link.text}
                </a>
              ))}
            </div>
          </div>

          <div class={styles.right}>
            {this.$slots.isSpb ? (
              <div class={styles.spb}>{this.$slots.isSpb}</div>
            ) : null}
            {this.$slots.yearSelect ? (
              <div class={styles.yearSelect}>{this.$slots.yearSelect}</div>
            ) : null}
            <div class={styles.rubricSelect}>
              <FormSelect
                currentOptionId={this.currentOptionId}
                options={this.options}
                onOptionChanged={this.optionChanged}
              />
            </div>
          </div>
        </div>
      );
    }
  });
