import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiImage } from '@fe/common/components';
import { stripTags } from '@fontanka/news';

import { RubricCommentsItem } from '../rubric-comments-item';

import styles from './format-record-item.styles.scss';

export type Record = {
  image: {
    url: string;
    sources: {
      type: string;
      srcset: string;
    }[];
  } | null;
  header: string;
  isCommentsAllowed: boolean;
  commentsCount: number;
  canShowComments: boolean;
  hasAudio: boolean;
  hasPhoto: boolean;
  hasVideo: boolean;
  leadStr: string;
  urls: {
    url: string;
    urlComments: string;
  };
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'FormatRecordItem',
  props: {
    record: {
      type: Object as () => Record,
      required: true
    },
    type: {
      type: String as () => 'wide' | '',
      default: ''
    },
    isFirst: {
      type: Boolean as () => boolean,
      default: false
    }
  },
  render() {
    return (
      <div class={[styles.formatRecord, this.type ? styles[this.type] : '']}>
        {this.record.image !== null ? (
          <a
            class={styles.imageLink}
            href={this.record.urls.url}
            title={stripTags(this.record.header)}
          >
            <UiImage sources={this.record.image.sources}>
              <img
                class={styles.img}
                src={this.record.image.url}
                alt=""
                fetchpriority={this.isFirst ? 'high' : 'auto'}
              />
            </UiImage>
          </a>
        ) : null}

        <div class={styles.infoWrap}>
          <a
            class={styles.headerLink}
            href={this.record.urls.url}
            domPropsInnerHTML={this.record.header}
          />
        </div>

        <div class={styles.topWrap}>
          {this.record.canShowComments ? (
            <div class={styles.topItem}>
              <RubricCommentsItem record={this.record} />
            </div>
          ) : (
            // пустой блок для красивого выравнивания
            <div />
          )}

          <div class={styles.icons}>
            {this.record.hasVideo ? (
              <div class={[styles.topItem, styles.iconWrapper]}>
                <i class={[styles.icon, styles.video]} />
              </div>
            ) : null}
            {this.record.hasAudio ? (
              <div class={[styles.topItem, styles.iconWrapper]}>
                <i class={[styles.icon, styles.music]} />
              </div>
            ) : null}
            {this.record.hasPhoto ? (
              <div class={[styles.topItem, styles.iconWrapper]}>
                <i class={[styles.icon, styles.photo]} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
});
