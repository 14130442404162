import { State, Getter, Action, Mutation } from 'vuex-simple';

import { createSimpleImage } from '@fontanka/news';
import type { AuthUserInfoDTO } from '@fontanka/news-api-client';
import { AuthHttpClient } from '@fontanka/news-api-client';

import type { RootModule } from '../root.store';

type UserInfo = {
  avatar: {
    url: string;
    sources: {
      type: string;
      srcset: string;
    }[];
  };
  editProfileLink: string;
  email: string;
  hasProfile: boolean;
  isBlocked: boolean;
  link: string;
  login: string;
  sex: 'female' | 'male' | 'unknown' | '';
  trusted: boolean;
  userId: number;
};

type AuthCheckStatus = 'none' | 'pending' | 'complete';

export const USER_MODULE_NAMESPACE = ['user_module'];

export class UserModule {
  @State()
  public userInfo: UserInfo | null = null;

  @State()
  public authCheckStatus: AuthCheckStatus = 'none';

  @Getter()
  public get isUserAuthorized() {
    return this.userInfo !== null;
  }

  @Getter()
  public get currentAuthCheckStatus() {
    return this.authCheckStatus;
  }

  private _authHttpClient: AuthHttpClient;

  constructor(private _root: RootModule) {
    this._authHttpClient = new AuthHttpClient({
      envType: this._root?.envType ?? '',
      protocol: 'https'
    });
  }

  @Action()
  public async checkAuth(withRequest = true) {
    this._updateAuthCheckStatus('pending');

    if (withRequest) {
      const result = await this._authHttpClient.checkAuthorization();
      const userInfo = this._userInfoToDo(result);
      this._updateUserInfo(userInfo);
    }

    this._updateAuthCheckStatus('complete');
  }

  @Action()
  public async login({
    login,
    password,
    captchaToken
  }: {
    login: string;
    password: string;
    captchaToken: string;
  }) {
    try {
      this._updateAuthCheckStatus('pending');

      const result = await this._authHttpClient.login(login, password, captchaToken);
      const userInfo = this._userInfoToDo(result);
      this._updateUserInfo(userInfo);

      this._updateAuthCheckStatus('complete');
    } catch (err) {
      this._updateAuthCheckStatus('complete');
      throw err.response.data;
    }
  }

  @Action()
  public async logout() {
    await this._authHttpClient.logout();
    this._updateUserInfo(null);
  }

  @Mutation()
  private _updateUserInfo(userInfo: UserInfo | null) {
    this.userInfo = userInfo;
  }

  @Mutation()
  private _updateAuthCheckStatus(status: AuthCheckStatus) {
    this.authCheckStatus = status;
  }

  private _userInfoToDo(userInfo: AuthUserInfoDTO | null): UserInfo | null {
    return userInfo !== null
      ? {
          ...userInfo,
          avatar: createSimpleImage(userInfo.avatar)
        }
      : null;
  }
}
