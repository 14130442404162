import { WQuoteAttachDTO } from '@fontanka/news-api-client';
import { WQuoteAttach } from '../../../domain';

export const createWQuoteAttach = (attach: WQuoteAttachDTO): WQuoteAttach => ({
  type: 'wquote',
  value: {
    title: attach.media.title,
    author: attach.media.author || ''
  }
});
