import { State, Action, Mutation } from 'vuex-simple';

import { PROFILE_INFO } from '@fontanka/data';

import type { RootModule } from '../../../core';
import type { ProfilePage, UserComment } from '../domain';
import { ProfilePageMapper, UserCommentsDataMapper } from '../services';

export const PROFILE_PAGE_MODULE_NAMESPACE = ['profile_page_module'];

export class ProfilePageModule {
  @State()
  public blocks: ProfilePage['blocks'];

  @State()
  public userInfo: ProfilePage['userInfo'];

  @State()
  public comments: ProfilePage['commentsInfo']['comments'];

  @State()
  public totalComments = 0;

  private readonly _userCommentsDataMapper: UserCommentsDataMapper;

  private readonly _userCommentsPageSize = PROFILE_INFO.PAGE_SIZE;

  private _currentUserCommentsPage = PROFILE_INFO.DEFAULT_START_PAGE;

  constructor(private _root: RootModule) {
    this._userCommentsDataMapper = new UserCommentsDataMapper(this._root.envType);

    const { blocks, commentsInfo, userInfo } = ProfilePageMapper.toDO(
      this._root.pageSpec
    );

    this.userInfo = userInfo;
    this.comments = commentsInfo.comments;
    this.totalComments = commentsInfo.total;
    this.blocks = blocks;
  }

  @Action()
  async fetchUserComments(profileId: number) {
    const page = this._currentUserCommentsPage + 1;

    const userComments = await this._userCommentsDataMapper.getUserComments({
      profileId,
      page,
      pagesize: this._userCommentsPageSize
    });

    if (userComments.length !== 0) {
      this._addUserComments(userComments);
      this._updateCurrentPage(page);
    }
  }

  @Mutation()
  private _addUserComments(userComments: UserComment[]) {
    this.comments = [...this.comments, ...userComments];
  }

  @Mutation()
  private _updateCurrentPage(currentPage: number) {
    this._currentUserCommentsPage = currentPage;
  }
}
