import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { typography } from '@fe/common/styles/typography';

import { Slider } from '../../_sliders';

import FntSliderArrowIcon from '~icons/fnt-common/slider-arrow';

import styles from './side-slider-block-small.styles.scss';

type ComponentData = {
  currentSlide: number;
  isSliderReady: boolean;
  isVisibleSlider: boolean;
};

type Events = {
  onSlideChanged: 'влево' | 'вправо';
  onHeaderClicked: Event;
};
export type SideSliderItem = {
  id: number;
  image?: {
    url: string;
  } | null;
  header: string;
  author?: {
    image: {
      url: string;
    } | null;
    name: string;
    url: string;
  } | null;
  urls: {
    url: string;
  };
  publishAt?: string;
};
export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'SideSliderBlockSmall',
    props: {
      link: {
        type: String,
        required: true as const
      },
      header: {
        type: String,
        required: true as const
      },
      slidesCount: {
        type: Number,
        required: true as const
      }
    },
    data(): ComponentData {
      return {
        currentSlide: 0,
        isSliderReady: false,
        isVisibleSlider: false
      };
    },
    mounted() {
      this.isVisibleSlider = true;
    },
    methods: {
      nextSlide() {
        if (this.currentSlide !== this.slidesCount - 1) {
          this.currentSlide = this.currentSlide + 1;
          this.emitChangeEvent('вправо');
        }
      },
      prevSlide() {
        if (this.currentSlide !== 0) {
          this.currentSlide = this.currentSlide - 1;
          this.emitChangeEvent('влево');
        }
      },
      setGroup(groupIndex: number) {
        if (groupIndex !== this.currentSlide) {
          this.emitChangeEvent(this.currentSlide < groupIndex ? 'вправо' : 'влево');
        }

        this.currentSlide = groupIndex;
      },
      emitChangeEvent(direction: 'влево' | 'вправо') {
        this.$emit('slideChanged', direction);
      },
      emitHeaderClick(event: Event) {
        this.$emit('headerClicked', event);
      },
      showSlider() {
        this.isSliderReady = true;
      }
    },
    render() {
      return (
        <div
          class={styles.slides}
          style={this.isVisibleSlider ? undefined : `height: 0; border: none;`}
        >
          <div class={styles.headerWrap}>
            <h3 class={[styles.header, typography.secondary.label1.mobile]}>
              <a
                class={styles.headerLink}
                href={this.link}
                domPropsInnerHTML={this.header}
                onClick={this.emitHeaderClick}
              />
            </h3>

            <div class={styles.nav}>
              <button
                class={[
                  styles.navBtn,
                  this.currentSlide === 0 ? styles.disabled : ''
                ]}
                onClick={this.prevSlide}
              >
                <FntSliderArrowIcon />
              </button>
              <button
                class={[
                  styles.navBtn,
                  styles.navBtnNext,
                  this.currentSlide === this.slidesCount - 1 ? styles.disabled : ''
                ]}
                onClick={this.nextSlide}
              >
                <FntSliderArrowIcon />
              </button>
            </div>
          </div>

          <div
            class={[styles.sliderWrapper, this.isSliderReady ? styles.visible : '']}
          >
            <Slider
              ref="slider"
              currentGroup={this.currentSlide}
              onSlideChanged={this.setGroup}
              onReady={this.showSlider}
            >
              {this.$slots.default}
            </Slider>
          </div>
        </div>
      );
    }
  });
