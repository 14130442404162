import { componentFactoryOf } from 'vue-tsx-support';
import type { SyntheticEvent, InputHTMLAttributes } from 'vue-tsx-support/types/dom';

import { injectStylesMixin } from '@apps/frontend';

import { ErrorMsg } from '../error-msg';

import styles from './form-input.styles.scss';

type Events = {
  onInput: string;
};

const TEXTAREA_ROWS = 5;

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      theme: {
        type: String as () => 'default' | 'white',
        default: 'default'
      },
      type: {
        type: String as () => 'text' | 'password' | 'email' | 'textarea',
        required: true as const
      },
      id: {
        type: String,
        required: true as const
      },
      label: {
        type: String,
        required: false
      },
      value: {
        type: String,
        default: ''
      },
      error: {
        type: String,
        default: ''
      },
      placeholder: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      maxLength: {
        type: Number,
        required: false
      },
      showCounter: {
        type: Boolean,
        default: false
      },
      autocomplete: {
        type: String as () => 'username' | 'current-password' | 'new-password',
        default: undefined
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      hasError(): boolean {
        return this.error !== undefined && this.error.length > 0;
      }
    },
    methods: {
      emitInputEvent(event: SyntheticEvent<InputHTMLAttributes, Event>) {
        this.$emit('input', event.target.value?.toString() ?? '');
      }
    },
    render() {
      return (
        <div class={[styles.formInput, styles[this.theme]]}>
          {this.showCounter ? (
            <div class={styles.counterWrapper}>
              <span class={[styles.textLength, styles.current]}>
                {this.value.length}
              </span>
              <span class={styles.textLength}> / </span>
              <span class={styles.textLength}>{this.maxLength}</span>
            </div>
          ) : null}

          {this.label ? (
            <label
              class={[styles.label, this.required ? styles.required : '']}
              for={this.id}
            >
              {this.label}
            </label>
          ) : null}

          {this.type === 'textarea' ? (
            <textarea
              class={[styles.textarea, this.hasError ? styles.error : '']}
              value={this.value}
              rows={TEXTAREA_ROWS}
              maxlength={this.maxLength}
              placeholder={this.placeholder}
              disabled={this.disabled ? this.disabled : undefined}
              onInput={this.emitInputEvent}
            ></textarea>
          ) : (
            <input
              class={[styles.input, this.hasError ? styles.error : '']}
              value={this.value}
              maxlength={this.maxLength}
              type={this.type}
              id={this.id}
              name={this.id}
              placeholder={this.placeholder}
              disabled={this.disabled ? this.disabled : undefined}
              autocomplete={this.autocomplete}
              onInput={this.emitInputEvent}
            />
          )}

          {this.hasError ? (
            <ErrorMsg class={styles.errorMsg}>{this.error}</ErrorMsg>
          ) : null}
        </div>
      );
    }
  });
