import { Service } from 'vue-typedi';

import type {
  BasePageDTO,
  AdvBlockInfo,
  AdvBlockConfig,
  NewsFeedBlockConfig,
  NewsItemDTO,
  BaseSocialDTO
} from '@fontanka/news-api-client';
import { LeftAndRightColsBlockType } from '@fontanka/news-api-client';

import { WeSocialMapper, AdvMapper } from '../../services';
import type { LeftCol } from '../domain';

import { NewsFeedMapper } from './news-feed-mapper.service';

type NewsRedesignType = 'original' | 'redesign' | 'redesign_line';

@Service()
export class LeftColMapper {
  public static toDO(
    config: BasePageDTO['config']['content'],
    specData: BasePageDTO['data']
  ): LeftCol {
    const INITIAL: LeftCol = {
      blocks: [],
      news: null,
      hourNews: null
    };

    return config.reduce((acc, current) => {
      const { settings } = current;
      if (settings && settings.place === 'left') {
        if (current.type === LeftAndRightColsBlockType.Socials) {
          acc.blocks.push({
            type: current.type,
            data: WeSocialMapper.toDO(
              specData[current.path].data as BaseSocialDTO[] | undefined
            )
          });

          return acc;
        }

        if (current.type === LeftAndRightColsBlockType.News) {
          acc.news = NewsFeedMapper.toDO(
            specData[current.path].data as {
              all: NewsItemDTO[] | null;
              spb: NewsItemDTO[] | null;
            },
            current as NewsFeedBlockConfig
          );

          acc.blocks.push({
            type: current.type,
            redesign: (current.settings?.type as NewsRedesignType) ?? 'original'
          });

          return acc;
        }

        if (current.type === LeftAndRightColsBlockType.HourNews) {
          acc.hourNews = specData[current.path].data;

          acc.blocks.push({
            type: current.type,
            redesign: (current.settings?.type as NewsRedesignType) ?? 'original'
          });

          return acc;
        }

        if (current.type === 'adv') {
          const info = specData[current.path] as AdvBlockInfo;

          acc.blocks.push(AdvMapper.toDO(current as AdvBlockConfig, info));

          return acc;
        }

        acc.blocks.push({
          type: current.type,
          data: specData[current.path].data
        });
      }

      return acc;
    }, INITIAL);
  }
}
