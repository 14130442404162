import type { FilterLink } from './filter-link';

const ALL_PAGE = 'all.html';
const NEWS_PAGE = 'news.html';
const ARTICLES_PAGE = 'article.html';
const COMMERCIAL_PAGE = 'businews.html';

export type Month = {
  index: number;
  name: string;
  weeks: WeekDay[][];
};

export type MonthItem = {
  index: number;
  name: string;
};

export type WeekDay = {
  day: string;
  path?: string;
};

export type Calendar = Month[];

export const getDayPath = (
  year: number,
  month: string,
  day: string,
  type: FilterLink['type'],
  rubric?: string
) => {
  let page = '';
  if (type === 'all') {
    page = 'all.html';
  }

  if (type === 'news') {
    page = 'news.html';
  }

  if (type === 'articles') {
    page = 'article.html';
  }

  if (type === 'commercial') {
    page = 'businews.html';
  }

  const baseUrl = `/${year}/${month}/${day}/${page}`;

  return rubric && rubric !== 'all' ? `/${rubric}${baseUrl}` : baseUrl;
};

export const getFilterLinks = (year: string, rubricId?: string): FilterLink[] => [
  {
    url: getPageUrl({ type: 'all', year, rubricId }),
    text: 'Все',
    type: 'all'
  },
  {
    url: getPageUrl({ type: 'news', year, rubricId }),
    text: 'Новости',
    type: 'news'
  },
  {
    url: getPageUrl({ type: 'articles', year, rubricId }),
    text: 'Статьи',
    type: 'articles'
  },
  {
    url: getPageUrl({ type: 'commercial', year, rubricId }),
    text: 'Новости компаний',
    type: 'commercial'
  }
];

export type PageUrlParams = {
  rubricId?: 'all' | string;
  type: FilterLink['type'];
  year: string;
};

export const getPageUrl = ({ type, year, rubricId }: PageUrlParams) => {
  const isCurrentYear = new Date().getFullYear() === Number(year);
  const yearPath = isCurrentYear ? '' : `${year}/`;
  const rubricPath = rubricId && rubricId !== 'all' ? `/${rubricId}` : '';

  if (type === 'all') {
    return `${rubricPath}/arc/${yearPath}${ALL_PAGE}`;
  }

  if (type === 'news') {
    return `${rubricPath}/arc/${yearPath}${NEWS_PAGE}`;
  }

  if (type === 'articles') {
    return `${rubricPath}/arc/${yearPath}${ARTICLES_PAGE}`;
  }

  if (type === 'commercial') {
    return `${rubricPath}/arc/${yearPath}${COMMERCIAL_PAGE}`;
  }

  return '';
};
