import { LeadDTO } from '@fontanka/news-api-client';
import { createTextAttach, createImageAttach } from './attaches';
import { Lead } from '../../domain';

export const createLead = (leadDTO: LeadDTO): Lead =>
  leadDTO.reduce((acc, current) => {
    if (current.type === 'text') {
      acc.push(createTextAttach(current));
    }

    if (current.type === 'images') {
      acc.push(createImageAttach(current));
    }

    return acc;
  }, [] as Lead);

export const leadToString = (leadDTO: LeadDTO): string =>
  leadDTO.reduce(
    (acc, current) => (current.type === 'text' ? acc + current.value.text : acc),
    ''
  );
