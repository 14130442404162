import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import successIcon from 'images/actual/success.svg';

import styles from './title-success-msg.styles.scss';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  props: {
    title: {
      type: String,
      required: true as const
    }
  },
  render() {
    return (
      <div class={styles.successMsg}>
        <img class={styles.icon} src={successIcon} alt="" />

        <div class={styles.infoWrapper}>
          <h4 class={styles.title}>{this.title}</h4>

          <div class={[styles.content, styles.top]}>{this.$slots.default}</div>
        </div>

        <div class={[styles.content, styles.bottom]}>{this.$slots.default}</div>
      </div>
    );
  }
});
