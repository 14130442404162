import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './square-checkbox.styles.scss';

type Events = {
  onChange: boolean;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      id: {
        type: String,
        default: ''
      },
      checked: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        isChecked: this.checked
      };
    },
    methods: {
      emitChangeEvent() {
        this.isChecked = !this.isChecked;
        this.$emit('change', this.isChecked);
      }
    },
    render() {
      return (
        <label class={styles.checkbox} title={this.title} for={this.id}>
          <input
            id={this.id}
            name={this.name}
            class={styles.input}
            type="checkbox"
            checked={this.checked}
            onChange={this.emitChangeEvent}
          />

          <p class={styles.text}>{this.$slots.default}</p>
        </label>
      );
    }
  });
