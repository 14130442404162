





























import { guardUnspecified } from '@smh/utils/guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { UiImage } from '../ui-image';

import UiAvatarFemaleIcon from '~icons/fnt-ui-common/avatar-female';

import type { Image } from '../ui-image';

import UiAvatarMaleIcon from '~icons/fnt-ui-common/avatar-male';
import UiAvatarUnknownIcon from '~icons/fnt-ui-common/avatar-unknown';

import type { AvatarShape, AvatarSize, AvatarSex } from './ui-avatar.contract';

@Component({
  name: 'UiAvatar',
  components: {
    UiImage,
    UiAvatarFemaleIcon,
    UiAvatarMaleIcon,
    UiAvatarUnknownIcon
  }
})
export default class UiAvatar extends Vue {
  @Prop({
    type: Number as () => AvatarSize,
    default: 64
  })
  avatarSize: AvatarSize;

  @Prop({
    type: String as () => AvatarShape,
    default: 'square'
  })
  avatarShape: AvatarShape;

  @Prop({
    type: String as () => AvatarSex,
    default: 'unknown'
  })
  sex: AvatarSex;

  @Prop({
    type: Object as () => Image,
    default: null
  })
  image: Image;

  get sizeClass(): string {
    return `size-${this.avatarSize}`;
  }

  get hasImage(): boolean {
    return guardUnspecified(this.image);
  }
}
