import { GalleryAttachDTO } from '@fontanka/news-api-client';

import { GalleryAttach } from '../../../domain';
import { createImage } from '../image-mapper.service';

export const createGalleryAttach = (attach: GalleryAttachDTO): GalleryAttach => ({
  type: 'gallery',
  mediaClass: attach.media.class,
  value: attach.value.map(imageDTO => {
    const imageDO = createImage(imageDTO);
    if (imageDO) {
      return {
        ...imageDO,
        author: imageDO.author ? `Фото: ${imageDO.author}` : ''
      };
    }

    return imageDO;
  })
});
