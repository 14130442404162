import { guardEmptyString, guardUnspecified } from '@portal/utils/util-guards';
import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import FntAvatarFemaleIcon from '~icons/fnt-avatar/avatar-female';
import FntAvatarMaleIcon from '~icons/fnt-avatar/avatar-male';

import styles from './ui-avatar.styles.scss';

import FntAvatarUnknownIcon from '~icons/fnt-avatar/avatar-unknown';

type Sex = 'male' | 'female' | 'unknown';
type Size = 'sm' | 'md' | 'lg' | 'xl';
const AVATAR_ICONS = {
  male: FntAvatarMaleIcon,
  female: FntAvatarFemaleIcon,
  unknown: FntAvatarUnknownIcon
};
const SIZES_MAP = {
  sm: 32,
  md: 48,
  lg: 64,
  xl: 96
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'UiAvatar',
  props: {
    size: {
      type: String as () => Size,
      default: 'md' as Size
    },
    isRounded: {
      type: Boolean,
      default: false
    },
    sex: {
      type: String as () => Sex,
      default: 'unknown' as const
    },
    url: {
      type: String,
      default: undefined
    }
  },
  computed: {
    hasUrl(): boolean {
      return guardUnspecified(this.url) && guardEmptyString(this.url);
    }
  },
  render() {
    const MISSING_AVATAR_COMPONENT = AVATAR_ICONS[this.sex];
    const SIZE = SIZES_MAP[this.size];

    if (this.hasUrl) {
      return (
        <img
          class={[
            styles.avatar,
            styles[this.size],
            this.isRounded && styles.rounded
          ]}
          src={this.url}
          width={SIZE}
          height={SIZE}
        />
      );
    }

    return (
      <MISSING_AVATAR_COMPONENT
        class={[styles.avatar, styles[this.size], this.isRounded && styles.rounded]}
        width={SIZE}
        height={SIZE}
      />
    );
  }
});
