import { PageNames } from '../page-names';

import { PageScheme } from './header-bidding-data';

export const HB_DESKTOP_PAGE_SCHEME: PageScheme = [
  {
    type: [PageNames.MAIN],
    ads: ['adv_hb-top-index', 'adv_vb-2-index', 'adv_vb-1-left']
  },
  {
    type: [PageNames.RECORD],
    ads: [
      'adv_hb-top',
      'adv_vb-top',
      'adv_vb-top-left',
      'adv_vb-1-left',
      'adv_vb-1',
      'adv_inread-flyroll-desktop',
      'adv_hb-inline'
    ]
  },
  {
    type: [PageNames.COMMENTS, PageNames.ALL_COMMENTS],
    ads: ['adv_hb-top', 'adv_vb-top', 'adv_vb-top-left', 'adv_vb-1-left', 'adv_vb-1']
  },
  {
    type: [
      PageNames.ARCHIVE,
      PageNames.ARCHIVE_BY_24HOURS,
      PageNames.ARCHIVE_BY_DATE,
      PageNames.ARCHIVE_CALENDAR,
      PageNames.AUTHOR,
      PageNames.DEFAULT,
      PageNames.FORMAT,
      PageNames.MENTION,
      PageNames.PROFILE,
      PageNames.PROFILE_SETTINGS,
      PageNames.SEARCH,
      PageNames.RUBRIC,
      PageNames.THEME,
      PageNames.VIDEO,
      PageNames.PROJECTS
    ],
    ads: ['adv_hb-top']
  }
];

export const HB_MOBILE_PAGE_SCHEME: PageScheme = [
  {
    type: [PageNames.MAIN],
    ads: ['adv_mob_hb-top', 'adv_mob_sticky-mobile-smi']
  },
  {
    type: [PageNames.RECORD],
    ads: [
      'adv_mob_hb-top',
      'adv_mob_mob-in-1',
      'adv_mob_mob-in-2',
      'adv_mob_mob-in-3',
      'adv_mob_mob-in-4',
      'adv_mob_sticky-mobile-smi',
      'adv_mob_inread-mobile-new',
      'adv_mob_inread-mobile-new-2',
      'adv_mob_fullscreen'
    ]
  },
  {
    type: [PageNames.COMMENTS, PageNames.ALL_COMMENTS],
    ads: ['adv_mob_hb-top', 'adv_mob_sticky-mobile-smi', 'adv_mob_comment_3']
  },
  {
    type: [
      PageNames.ARCHIVE,
      PageNames.ARCHIVE_BY_24HOURS,
      PageNames.ARCHIVE_BY_DATE,
      PageNames.ARCHIVE_CALENDAR,
      PageNames.AUTHOR,
      PageNames.DEFAULT,
      PageNames.FORMAT,
      PageNames.MENTION,
      PageNames.PROFILE,
      PageNames.PROFILE_SETTINGS,
      PageNames.SEARCH,
      PageNames.RUBRIC,
      PageNames.THEME,
      PageNames.VIDEO,
      PageNames.PROJECTS
    ],
    ads: ['adv_mob_hb-top']
  }
];
