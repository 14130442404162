import { State, Action } from 'vuex-simple';

import type { RootModule } from '@fe/common/core';

import type { WidgetAutoSliderOffer } from '../domain';
import { WidgetAutoDataMapperService } from '../services';

type Config = {
  envType?: string;
  protocol?: 'http' | 'https';
};

type WidgetAutoOffersParams = {
  count: number;
};

export const WIDGET_AUTO_MODULE_NAMESPACE = ['widget_auto_module'];

export class WidgetAutoModule {
  @State()
  private _widgetAutoDataMapper: WidgetAutoDataMapperService;

  @State()
  public offers: WidgetAutoSliderOffer[];

  constructor(private _root: RootModule) {
    const config: Config = { envType: this._root.envType, protocol: 'https' };

    this._widgetAutoDataMapper = new WidgetAutoDataMapperService(config);
  }

  @Action()
  public async fetchWidgetAutoOffers(
    params: WidgetAutoOffersParams
  ): Promise<WidgetAutoSliderOffer[]> {
    return this._widgetAutoDataMapper.fetchWidgetAutoOffers(params);
  }
}
