import type { HomePageWindowDTO } from '@fontanka/news-api-client';

import { BaseRecordMapper } from '../../../services';
import type { Window } from '../domain';

const WINDOW_RECORD_IMAGE_WIDTH = 800;

export class WindowMapper {
  public static toDO(windowDTO: HomePageWindowDTO['data']): Window | undefined {
    return windowDTO !== null
      ? windowDTO.reduce((acc, windowItemDTO, index) => {
          acc[index + 1] = BaseRecordMapper.toDO(windowItemDTO, {
            width: WINDOW_RECORD_IMAGE_WIDTH
          });

          return acc;
        }, {} as Window)
      : undefined;
  }
}
