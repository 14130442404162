import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { ObserverVisibility } from '@fontanka/observe-visibility';
import instIcon from 'images/pictograms/socials/inst.png';
import telegIcon from 'images/pictograms/socials/teleg.png';
import viberIcon from 'images/pictograms/socials/viber.png';
import vkIcon from 'images/pictograms/socials/vk.png';

import styles from './we-socials.styles.scss';

const SOCIAL_TO_ICON_MAP = {
  vk: vkIcon,
  instagram: instIcon,
  telegram: telegIcon,
  viber: viberIcon
};

type SocialType = 'vk' | 'instagram' | 'telegram' | 'viber';

type Social = {
  id: SocialType;
  text: string;
  link: string;
};

type ComponentData = {
  blockVisibility: ObserverVisibility;
};

type Events = {
  onSocialsView: void;
  onSocialClicked: SocialType;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      socials: {
        type: Array as () => Social[],
        required: true as const,
        default: []
      }
    },
    data(): ComponentData {
      return {
        blockVisibility: new ObserverVisibility('half')
      };
    },
    mounted() {
      this.blockVisibility.event.subscribe(({ value }) => {
        if (value) {
          this.emitSocialsView();
        }
      });
    },
    beforeDestroy() {
      this.blockVisibility.destroy();
    },
    methods: {
      emitSocialsView() {
        this.$emit('socialsView');
      },
      emitSocialClicked(social: Social) {
        this.$emit('socialClicked', social);
      }
    },
    render() {
      return (
        <div
          v-observe-visibility={this.blockVisibility.getOptions('half')}
          class={styles.weSocials}
        >
          <h5 class={styles.header}>Мы в соцсетях</h5>

          <ul class={styles.list}>
            {this.socials.map(social => (
              <li>
                <a
                  class={styles.link}
                  href={social.link}
                  target="_blank"
                  onClick={() => this.emitSocialClicked(social)}
                >
                  <img src={SOCIAL_TO_ICON_MAP[social.id]} alt="" />
                </a>
              </li>
            ))}
          </ul>
        </div>
      );
    }
  });
