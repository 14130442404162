






import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { useStore } from 'vuex-simple';

import { RootModule } from '@apps/frontend';

import { CONFIG_DATA } from './config';

@Component({
  name: 'AdvInfinityFeed'
})
export default class AdvInfinityFeed extends Vue {
  @Prop({
    type: Number,
    default: 1001
  })
  statId: number;

  observerOptions = {
    root: null,
    rootMargin: '1100px',
    threshold: 0
  };
  observer: IntersectionObserver | null = null;
  isViewPort = false;

  @Watch('isViewPort')
  isViewPortChanged(value: boolean) {
    if (value) {
      this.init();
      this.observer?.disconnect();
    }
  }

  store = useStore<RootModule>(this.$store);

  get isRender() {
    return this.blockId !== '';
  }

  get isMobile() {
    return this.store.baseInfo.isMobile;
  }

  get blockId() {
    const deviceType = this.isMobile ? 'mobile' : 'desktop';

    return CONFIG_DATA[deviceType] || '';
  }

  mounted() {
    this.initObserver();
  }

  initObserver() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.isViewPort = entry.isIntersecting;
      });
    }, this.observerOptions);

    // На dev-средах бажит без проверки
    if (this.$el.tagName) {
      this.observer.observe(this.$el);
    }
  }

  init() {
    window.yaContextCb = window.yaContextCb || [];
    window.yaContextCb.push(() => {
      Ya.Context.AdvManager.renderFeed({
        renderTo: 'adv-infinity-feed',
        blockId: this.blockId,
        type: 'feed',
        statId: this.statId
      });
    });
  }
}
