export const RECORDS_ROUTING = [
  {
    path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:id(fin\\d+|fia\\d+|fip\\d+|fipl\\d+|fid\\d+|\\d+)',
    name: 'record',
    component: () =>
      import(/* webpackChunkName: "record-page" */ './pages/record/record.page')
  },
  {
    path: '/:year(\\d{4})/:month(\\d{2})/:day(\\d{2})/:id(fin\\d+|fia\\d+|fip\\d+|fipl\\d+|fid\\d+|\\d+)/print.html',
    component: () =>
      import(/* webpackChunkName: "print-page" */ './pages/print/print.page'),
    meta: {
      isPrintPage: true
    }
  }
];
