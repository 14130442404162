import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { stripTags } from '@fontanka/news';

import styles from './mention-record-item.styles.scss';

export type MentionRecord = {
  header: string;
  author: {
    name: string;
    url: string;
    image: {
      url: string;
    } | null;
  } | null;
  urls: {
    url: string;
  };
};

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'MentionRecordItem',
  props: {
    record: {
      type: Object as () => MentionRecord,
      required: true
    }
  },
  render() {
    return (
      <div class={styles.record}>
        {this.record.author?.image !== null ? (
          <a
            class={styles.itemImg}
            href={this.record.urls.url}
            title={stripTags(this.record.header)}
            style={`background-image: url(${this.record.author?.image.url})`}
          ></a>
        ) : null}

        <div class={styles.infoWrap}>
          <a class={styles.headerLink} href={this.record.urls.url}>
            <span domPropsInnerHTML={this.record.header} />
          </a>

          {this.record.author !== null ? (
            <a
              class={styles.authorLink}
              href={this.record.author.url}
              title={this.record.author.name}
            >
              {this.record.author.name}
            </a>
          ) : null}
        </div>
      </div>
    );
  }
});
