/* eslint-disable */
import './set-public-path';
import 'reflect-metadata';
import 'vue-tsx-support/enable-check';

import { createApp } from './app/desktop/main';

import('./web-vitals');

const render = () => {
  const { app, router, store } = createApp();

  // prime the store with server-initialized state.
  // the state is determined during SSR and inlined in the page markup.
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  }

  // wait until router has resolved all async before hooks
  // and async components...
  router.onReady(() => {
    // actually mount to DOM
    app.$mount('#app');
  });

  // TODO: добавить, когда правильно сделаю генерацию service-worker.js
  // service worker
  // if (window.location.protocol === 'https:' && navigator.serviceWorker) {
  //   navigator.serviceWorker.register('/service-worker.js');
  // }
};

const isIntersectionObserverNotSupported = () =>
  !('IntersectionObserver' in window) ||
  !('IntersectionObserverEntry' in window) ||
  !('intersectionRatio' in window.IntersectionObserverEntry.prototype);

if (!isIntersectionObserverNotSupported && 'matches' in Element.prototype) {
  render();
} else {
  import(/* webpackChunkName: "polyfills" */ './polyfills').then(render);
}
