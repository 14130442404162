import { guardEmptyString } from '@portal/utils/util-guards';
import { componentFactoryOf } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './comments-vote.styles.scss';

type RecordComment = {
  id: number;
  parentId: number;
  userVote: -1 | 0 | 1;
  votesDislike: number;
  votesLike: number;
};

type Events = {
  onLikeClicked: { id: number; parentId?: number };
  onDislikeClicked: { id: number; parentId?: number };
};

const VOTE_TYPE: [string, string] = ['like', 'dislike'];

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    props: {
      comment: {
        type: Object as () => RecordComment,
        required: true as const
      },
      gotoCommentLink: {
        type: String,
        default: ''
      }
    },
    computed: {
      isUserVoted(): boolean {
        return Math.abs(this.comment?.userVote) === 1;
      },
      likeStr(): string {
        return `+${this.comment.votesLike}`;
      },
      dislikeStr(): string {
        return `\u{2212}${this.comment.votesDislike}`;
      }
    },
    methods: {
      emitVoteClicked(type: string, id: number, parentId?: number): void {
        this.$emit(`${type}Clicked`, { id, parentId });
      },
      checkUserVote(type: string): boolean {
        if (type === VOTE_TYPE[0] && this.comment?.userVote === 1) {
          return true;
        }

        return type === VOTE_TYPE[1] && this.comment?.userVote === -1;
      }
    },
    render() {
      return (
        <div class={[styles.commentsVote, this.isUserVoted ? styles.locked : '']}>
          {VOTE_TYPE.map(vote =>
            guardEmptyString(this.gotoCommentLink) ? (
              <a href={this.gotoCommentLink} class={[styles.vote, styles[vote]]}>
                {this[`${vote}Str`]}
              </a>
            ) : (
              <button
                class={[
                  styles.vote,
                  styles[vote],
                  this.checkUserVote(vote) ? styles.voted : ''
                ]}
                onClick={() =>
                  this.emitVoteClicked(vote, this.comment.id, this.comment?.parentId)
                }
              >
                {this[`${vote}Str`]}
              </button>
            )
          )}
        </div>
      );
    }
  });
