export const PROJECTS_ROUTING = [
  {
    path: '/projects',
    name: 'projects',
    component: () =>
      import(
        /* webpackChunkName: "projects-page" */ './pages/projects/projects.page'
      ),
    alias: []
  }
];
