import { Getter } from 'vuex-simple';

import type { RootModule } from '../../../core';
import type { Footer } from '../../domain';
import { FooterMapper } from '../../services';

export const FOOTER_MODULE_NAMESPACE = ['footer_module'];

export class FooterModule {
  constructor(private _root: RootModule) {}

  @Getter()
  public get footer(): Footer {
    return FooterMapper.toDO(
      this._root.pageSpec.config.footer,
      this._root.pageSpec.data
    );
  }
}
