type DeviceModification = 'mobile' | 'laptop' | 'desktop';

type GridModification = 'full' | 'half' | 'oneThird' | 'twoThird';

type GridClass =
  | 'gridFullMobile'
  | 'gridFullLaptop'
  | 'gridFullDesktop'
  | 'gridHalfMobile'
  | 'gridHalfLaptop'
  | 'gridHalfDesktop'
  | 'gridOneThirdMobile'
  | 'gridOneThirdLaptop'
  | 'gridOneThirdDesktop'
  | 'gridTwoThirdMobile'
  | 'gridTwoThirdLaptop'
  | 'gridTwoThirdDesktop';

type ClassConfig = Record<DeviceModification, GridClass>;

export type GridClassConfig = Record<GridModification, ClassConfig> & {
  container: 'gridContainer';
};

export const grid: GridClassConfig = {
  container: 'gridContainer',
  full: {
    mobile: 'gridFullMobile',
    laptop: 'gridFullLaptop',
    desktop: 'gridFullDesktop'
  },
  half: {
    mobile: 'gridHalfMobile',
    laptop: 'gridHalfLaptop',
    desktop: 'gridHalfDesktop'
  },
  oneThird: {
    mobile: 'gridOneThirdMobile',
    laptop: 'gridOneThirdLaptop',
    desktop: 'gridOneThirdDesktop'
  },
  twoThird: {
    mobile: 'gridTwoThirdMobile',
    laptop: 'gridTwoThirdLaptop',
    desktop: 'gridTwoThirdDesktop'
  }
};
