import type { CropType } from '@fontanka/cropping';
import { createImage } from '@fontanka/news';
import type { CompanyNewsItemDTO } from '@fontanka/news-api-client';

import type { CompaniesNewsRecord } from '../domain';

const DEFAULT_IMAGE_PARAMS = {
  crop: true,
  imageWidth: 84,
  imageHeight: 64
};

type Params = {
  crop?: boolean;
  cropType?: CropType;
  width?: number;
  height?: number;
};

export class CompaniesNewsMapper {
  public static toDO(
    recordDTO: CompanyNewsItemDTO,
    params?: Params
  ): CompaniesNewsRecord {
    return {
      id: recordDTO.id,
      lead: recordDTO.leadForNews,
      header: recordDTO.header,
      subheader: recordDTO.subheader,
      publishAt: recordDTO.publishAt,
      urls: recordDTO.urls,
      image: createImage(recordDTO.mainPhoto, params ?? DEFAULT_IMAGE_PARAMS)!,
      hasMainPhotoCommercialLabel: recordDTO.hasMainPhotoCommercialLabel ?? false
    };
  }
}
