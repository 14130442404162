








import { guardUnspecified } from '@portal/utils/util-guards';
import { Component, Prop, Vue } from 'vue-property-decorator';

import { ICreativeManager, IStickyRsyaManager } from '../adv-slot';

import type AdvData from './adv-mobile-sticky-rsya-body.component.vue';
import AdvMobileStickyRsyaBody from './adv-mobile-sticky-rsya-body.component.vue';

const STICKY_RSYA_DELAY = 15 * 1000;

@Component({
  name: 'AdvMobileStickyRsya',
  components: {
    AdvMobileStickyRsyaBody
  }
})
export default class AdvMobileStickyRsya extends Vue {
  @Prop({
    default: null,
    type: Object as () => AdvData
  })
  adv: AdvData | null;
  @Prop({
    default: false,
    type: Boolean
  })
  isReload: boolean;

  isHidden = true;
  shouldDelaySticky = true;
  delayTimeout: NodeJS.Timeout | null = null;

  get isStickyDisplayed() {
    return !this.isHidden && !this.shouldDelaySticky;
  }

  handleStickyRsyaManager() {
    const stickyRsyaManager: IStickyRsyaManager | undefined = (
      window.HSMCreativeManager as ICreativeManager
    )?.stickyRsya;

    if (!guardUnspecified(stickyRsyaManager)) {
      console.error('HSMCreativeManager.stickyRsya is not defined');
      return;
    }
    stickyRsyaManager.setBottomOffset(80);
    stickyRsyaManager.setzIndex(1000);
  }

  mounted() {
    this.handleStickyRsyaManager();

    this.delayTimeout = setTimeout(() => {
      this.shouldDelaySticky = false;
    }, STICKY_RSYA_DELAY);

    document.addEventListener('showRecordMobileStickyPanel', this.showSticky);
  }

  beforeDestroy() {
    if (this.delayTimeout) {
      clearTimeout(this.delayTimeout);
      document.removeEventListener('showRecordMobileStickyPanel', this.showSticky);
    }
  }

  showSticky() {
    this.isHidden = false;
  }
}
