import { isBrowser } from '@fontanka/browser-or-node';
import { Config, BaseHttpClient, PageResult, Result } from '../base-http-client';
import { BaseParams } from '../params';
import { CurrencyPageDTO } from './currency-page-dto';

export type CurrencyPageParams = BaseParams;

export class CurrencyHttpClient extends BaseHttpClient {
  private readonly _currencyPageUrl = '/pages/fontanka/currency/';

  constructor(config: Config) {
    super(config);
  }

  public async fetchCurrencyPageData(
    params: CurrencyPageParams
  ): Promise<Result<CurrencyPageDTO>> {
    const { headers } = params;
    const url = `${this.host}${this._currencyPageUrl}`;

    const withCredentials = isBrowser() ? true : undefined;

    const response = await this.httpClient.get<PageResult<CurrencyPageDTO>>(url, {
      headers,
      withCredentials,
      params: {
        regionId: this.regionId
      }
    });

    return {
      data: response.data.result,
      headers: response.headers
    };
  }
}
