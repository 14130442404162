import * as tsx from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';
import { UiAdvLabel } from '@fe/common/ui';

import styles from './tile-header-item.styles.scss';

export type Data = {
  header: string;
  leadStr: string;
};

export default tsx.componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'TileHeaderItem',
  props: {
    data: {
      type: Object as () => Data,
      required: true as const
    },
    size: {
      type: String as () => 'small' | 'middle' | 'big',
      default: 'middle'
    },
    orientation: {
      type: String as () => 'vertical' | 'horizontal',
      default: 'vertical'
    },
    hasAnimation: {
      type: Boolean,
      default: false
    },
    hasAdvMarker: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasLead() {
      return this.data.leadStr !== '' && (this.size === 'big' || this.hasAnimation);
    }
  },
  render() {
    return (
      <div
        class={[
          styles.wrapper,
          styles[this.orientation],
          styles[this.size],
          styles[this.hasAnimation ? 'animated' : '']
        ]}
      >
        <div class={styles.container}>
          <h2 class={styles.header} domPropsInnerHTML={this.data.header} />

          {this.hasAdvMarker && (
            <UiAdvLabel hasFrame={false} class={styles.advMarker} />
          )}

          {this.hasLead ? (
            <div
              data-lead
              class={[styles.lead, styles[this.size === 'big' ? 'visible' : '']]}
              domPropsInnerHTML={this.data.leadStr}
            />
          ) : null}
        </div>
      </div>
    );
  }
});
