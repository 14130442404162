import { componentFactoryOf } from 'vue-tsx-support';

import type { BaseRecord } from '@apps/frontend';
import { injectStylesMixin } from '@apps/frontend';
import { RecordStatistics, UiAvatar } from '@fe/common/components';
import { typography } from '@fe/common/styles/typography';

import styles from './opinion-item.styles.scss';

type Events = {
  onClickedPhoto: void;
  onClickedAuthor: void;
  onClickedHeader: void;
  onClickedComments: void;
};

export default componentFactoryOf<Events>()
  .mixin(injectStylesMixin(styles))
  .create({
    name: 'OpinionItem',
    props: {
      item: {
        type: Object as () => BaseRecord,
        required: true
      }
    },
    methods: {
      emitClickPhotoEvent(): void {
        this.$emit('clickedPhoto');
      },
      emitClickAuthorEvent(): void {
        this.$emit('clickedAuthor');
      },
      emitClickHeaderEvent(): void {
        this.$emit('clickedHeader');
      },
      emitCommentsClicked(): void {
        this.$emit('clickedComments');
      }
    },
    render() {
      return (
        <div class={styles.item}>
          <a href={this.item.author?.url} onClick={this.emitClickPhotoEvent}>
            <UiAvatar size="md" url={this.item.author?.image?.url} />
          </a>
          <div class={styles.content}>
            <a
              href={this.item.author?.url}
              title={this.item.author?.name}
              class={[styles.name, typography.secondary.label2.mobile]}
              onClick={this.emitClickAuthorEvent}
            >
              {this.item.author?.name}
            </a>
            <a
              href={this.item.urls.url}
              class={[styles.text, typography.primary.body2.mobile]}
              domPropsInnerHTML={this.item.header}
              onClick={this.emitClickHeaderEvent}
            />
            <RecordStatistics
              commentsCount={this.item.commentsCount}
              commentsUrl={this.item.urls.urlComments}
              discussUrl={this.item.urls.urlComments}
              isCommentsAllowed={this.item.isCommentsAllowed}
              viewsCount={this.item.viewsCount}
              onCommentsClicked={this.emitCommentsClicked}
              onDiscussClicked={this.emitCommentsClicked}
            />
          </div>
        </div>
      );
    }
  });
