/*
eslint-disable
@typescript-eslint/ban-ts-comment,
@typescript-eslint/restrict-plus-operands,
@typescript-eslint/naming-convention,
@typescript-eslint/no-unsafe-argument,
@typescript-eslint/no-explicit-any
*/
import { guardUnspecified } from '@smh/utils/guards';
import { addQueryString, getQueryParamValue } from '@smh/utils/url';

const VK_SHARE_URL = 'https://vk.com/share.php';
const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';
const LJ_SHARE_URL = 'https://www.livejournal.com/update.bml';
const TELEGRAM_SHARE_URL = 'https://telegram.me/share/url';
const EXCEPTION_QUERY_PARAMS = ['erid'];

type ljParams = {
  event: string;
  subject: string;
};

type twitterParams = {
  text: string;
  url: string;
};

type vkParams = {
  noparse: boolean;
  title: string;
  url: string;
};

type telegramParams = {
  url: string;
};

type SocialInfo<P> = {
  params: P;
  url: string;
};

type SocialsShareInfo = {
  lj: SocialInfo<ljParams>;
  twitter: SocialInfo<twitterParams>;
  vk: SocialInfo<vkParams>;
  telegram: SocialInfo<telegramParams>;
};

const getURLWithQueryParams = (params: QueryParams[] | null = null) => {
  const { origin, pathname, search } = window.location;
  const exceptionParams = getExceptionQueryParams(search);

  let url = origin + pathname;
  if (Object.keys(exceptionParams).length) {
    url = addQueryString(url, exceptionParams);
  }

  if (params === null) return url;

  return params.reduce((acc: string, current: QueryParams) => {
    return addQueryString(acc, { [current.name]: current.value });
  }, url);
};

const getOgImageUrl = (): string => {
  const ogImageMeta = document.querySelector<HTMLMetaElement>(
    'meta[property="og:image"]'
  );
  return ogImageMeta !== null ? ogImageMeta.content : '';
};

const share = (info: SocialsShareInfo[SocialTarget]) => {
  const url = Object.entries(info.params).reduce((acc, current, index) => {
    if (index === 0) {
      return `${acc}?${current[0]}=${encodeURIComponent(current[1].toString())}`;
    }

    return `${acc}&${current[0]}=${encodeURIComponent(current[1].toString())}`;
  }, info.url);

  window.open(url, '_blank', 'width=640,height=570');
};

const shareBySocials = (
  socialTarget: SocialTarget,
  url: string,
  title: string,
  imgUrl?: string
) => {
  switch (socialTarget) {
    case 'vk':
      share({
        url: VK_SHARE_URL,
        params: {
          url,
          title,
          noparse: true
        }
      });
      break;

    case 'twitter':
      share({
        url: TWITTER_SHARE_URL,
        params: {
          text: title,
          url
        }
      });
      break;

    case 'lj':
      share({
        url: LJ_SHARE_URL,
        params: {
          event: `<a href="${url}">Смотреть</a>`,
          subject: title
        }
      });
      break;

    case 'telegram':
      share({
        url: TELEGRAM_SHARE_URL,
        params: {
          url
        }
      });
      break;

    default:
      break;
  }
};

const getExceptionQueryParams = (query: string) =>
  EXCEPTION_QUERY_PARAMS.reduce<Record<string, string>>((acc, param) => {
    const paramValue = getQueryParamValue(query, param);
    if (guardUnspecified(paramValue)) {
      acc[param] = paramValue;
    }
    return acc;
  }, {});

export const sharePage = (
  socialTarget: SocialTarget,
  queryParams: QueryParams | null = null
) => {
  const urlParams = queryParams ? [queryParams] : [];
  const url = getURLWithQueryParams(urlParams);
  const { title } = document;
  const imageUrl = getOgImageUrl();

  shareBySocials(socialTarget, url, title, imageUrl);
};

export const sharePost = (
  socialTarget: SocialTarget,
  postId: number,
  title: string
) => {
  const targetUrl = getURLWithQueryParams([
    { name: 'sharePost', value: postId.toString() }
  ]);
  const imageUrl = getOgImageUrl();

  shareBySocials(socialTarget, targetUrl, title, imageUrl);
};

export const shareCard = (
  socialTarget: SocialTarget,
  cardId: number,
  title: string
) => {
  const targetUrl = getURLWithQueryParams([
    { name: 'shareCard', value: `${cardId}` }
  ]);
  const imageUrl = getOgImageUrl();

  shareBySocials(socialTarget, targetUrl, title, imageUrl);
};

export const SHARE_IMAGE_QUERY_PARAM_NAME = 'shareRecordImage';
export const getShareImageParams = (imageId: string): QueryParams[] => [
  {
    name: SHARE_IMAGE_QUERY_PARAM_NAME,
    value: imageId
  }
];
export const shareImage = (
  socialTarget: SocialTarget,
  title: string,
  imageId: string,
  imageUrl: string
) => {
  const url = getURLWithQueryParams(getShareImageParams(imageId));

  shareBySocials(socialTarget, url, title, imageUrl);
};

/**
 * Для виджета 'quiz-system'.
 * Патчим метод window.quizSystem, чтобы виджет мог инициализироваться на месте контейнера,
 * например, вместо '<div id="fg-test-91" class="fg-test-wrap"></div>'
 */
export const patchQuizSystemInit = () => {
  if (window.quizSystem) {
    // @ts-ignore
    window.quizSystem.init = function (arInit: any) {
      // @ts-ignore
      let element;

      document.addEventListener(
        'DOMContentLoaded',
        // @ts-ignore
        window.quizSystem.addCss(
          '//fgsoft.ru/widgets/quiz/css/quiz_default.css?v=201805211200'
        )
      );

      if (!arInit['charset']) {
        arInit['charset'] = document.charset.toLowerCase();
      }
      if (!arInit['domain']) arInit['domain'] = document.location.host;

      if (arInit['charset'] == 'windows-1251') {
        document.addEventListener(
          'DOMContentLoaded',
          // @ts-ignore
          window.quizSystem.addJs(
            '//fgsoft.ru/widgets/quiz/' +
              arInit['domain'] +
              '/quiz' +
              arInit['test_id'] +
              '/quiz' +
              arInit['test_id'] +
              '_win1251.js?v=' +
              Math.random()
          )
        );
        // @ts-ignore
        window.quizSystem.CHARSET = 'windows-1251';
      } else {
        document.addEventListener(
          'DOMContentLoaded',
          // @ts-ignore
          window.quizSystem.addJs(
            '//fgsoft.ru/widgets/quiz/' +
              arInit['domain'] +
              '/quiz' +
              arInit['test_id'] +
              '/quiz' +
              arInit['test_id'] +
              '_utf8.js?v=' +
              Math.random()
          )
        );
        // @ts-ignore
        window.quizSystem.CHARSET = 'utf-8';
      }

      return '';
    };
  }
};

export const copyShareLink = (element: HTMLElement, callback?: () => void) => {
  const range = document.createRange();
  range.selectNode(element);

  const selection = window?.getSelection();

  if (selection) {
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
      callback?.();
    } catch (error) {
      console.error(
        `Произошла ошибка при копировании ссылки на пост: ${
          (error as Error).message
        }`
      );
    }

    selection.removeRange(range);
  }
};

export type SocialType = 'twitter' | 'instagram' | 'quiz-system' | 'unknown';

export const getSocialTypeByUrl = (url: string): SocialType => {
  if (url.indexOf('twitter') !== -1) {
    return 'twitter';
  }

  if (url.indexOf('instagram') !== -1) {
    return 'instagram';
  }

  if (url.indexOf('quizSystemWidget') !== -1) {
    return 'quiz-system';
  }

  return 'unknown';
};

export const initInstagramEmbeds = () => {
  if (typeof window !== 'undefined' && typeof window.instgrm !== 'undefined') {
    // @ts-ignore
    window.instgrm.Embeds.process();
  }
};

export const initTwitterWidgets = () => {
  if (typeof window !== 'undefined' && typeof window.twttr !== 'undefined') {
    // @ts-ignore
    window.twttr.widgets.load();
  }
};

export const getShareLink = (params?: QueryParams[]) =>
  getURLWithQueryParams(params);

export type SocialTarget = keyof SocialsShareInfo;

export type QueryParams = {
  name: string;
  value: string;
};
