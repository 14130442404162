





































import { guardUnspecified } from '@smh/utils/guards';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import { UiAdvLabel } from '../../ui-adv-label';
import { UiImage } from '../../ui-image';
import { UiNewsCardLabels } from '../../ui-news-card-labels';
import { UiObserveVisibility } from '../../ui-observe-visibility';
import { UiStatistic } from '../../ui-statistic';

import type {
  TaxonomyClickEvent,
  NewsMImage,
  NewsMStatistic,
  NewsMFormat,
  NewsMRubric,
  NewsMTheme
} from './ui-news-m.contract';

@Component({
  components: {
    UiNewsCardLabels,
    UiStatistic,
    UiObserveVisibility,
    UiImage,
    UiAdvLabel
  },
  name: 'UiNewsM'
})
export default class UiNewsM extends Vue {
  @Prop({
    type: String,
    required: true
  })
  title!: string;

  @Prop({
    type: String,
    required: true
  })
  url!: string;

  @Prop({
    type: Object as () => NewsMImage,
    required: true
  })
  image!: NewsMImage;

  @Prop({
    type: Object as () => NewsMStatistic,
    required: true
  })
  statistic!: NewsMStatistic;

  @Prop({
    type: Boolean,
    default: false
  })
  isOnline: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasImageMark: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasVideoMark: boolean;

  @Prop({
    type: Array as () => NewsMFormat[],
    default: () => []
  })
  formats: NewsMFormat[];

  @Prop({
    type: Array as () => NewsMRubric[],
    default: () => []
  })
  rubrics: NewsMRubric[];

  @Prop({
    type: Array as () => NewsMTheme[],
    default: () => []
  })
  themes: NewsMTheme[];

  @Prop({
    type: Boolean,
    default: false
  })
  isBookmarked: boolean;

  @Prop({
    type: Boolean,
    default: false
  })
  hasCommercialLabel: boolean;

  get hasImage() {
    return guardUnspecified(this.image);
  }

  @Emit('blockShown')
  blockShown(): void {
    return;
  }

  @Emit('commentClicked')
  commentClicked(event: Event): Event {
    return event;
  }

  @Emit('bookmarkClicked')
  bookmarkClicked(event: Event): Event {
    return event;
  }

  @Emit('articleClicked')
  articleClicked(place: string): string {
    return place;
  }

  @Emit('taxonomyClicked')
  taxonomyClicked(event: TaxonomyClickEvent): TaxonomyClickEvent {
    return event;
  }
}
