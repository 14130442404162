import { componentFactory } from 'vue-tsx-support';

import { injectStylesMixin } from '@apps/frontend';

import styles from './ui-spinner.styles.scss';

type SpinnerSize = 'sm' | 'md' | 'lg';
type SpinnerTheme = 'primary' | 'gray';

export default componentFactory.mixin(injectStylesMixin(styles)).create({
  name: 'UiSpinner',
  props: {
    size: {
      type: String as () => SpinnerSize,
      default: 'sm'
    },
    theme: {
      type: String as () => SpinnerTheme,
      default: 'primary'
    }
  },
  render() {
    return (
      <div class={[styles.uiSpinner, styles[this.size], styles[this.theme]]}>
        Загрузка...
      </div>
    );
  }
});
